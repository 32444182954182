import React from 'react';
import { Form, Formik } from 'formik';
import { IEquipmentTypeCompressorFormProps } from './IEquipmentTypeCompressorFormProps';
import { Card } from '../../../shared/utils/sxStyleObjects';
import { EquipmentGeneralInfo } from '../equipmentGeneralInfo/EquipmentGeneralInfo';
import { EquipmentCustomerInfo } from '../equipmentCustomerInfo/EquipmentCustomerInfo';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { EquipmentTypeCompressorSection } from '../equipmentTypeCompressorSection/EquipmentTypeCompressorSection';
import { compressorEquipmentFormValidationSchema, equipmentGeneralInfoIsValid } from '../../services/EquipmentUtility';
import { EquipmentDetailInfo } from '../equipmentDetailInfo/EquipmentDetailInfo';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { userCanEditEquipmentContractInfo, userCanEditEquipmentCustomerInfo, userCanEditEquipmentMainInfo } from '../../../stores/user/usersSlice';
import { EquipmentCategories } from '../../../shared/enums/EquipmentCategories';
import { EquipmentFormContainerStep } from '../equipmentFormContainer/IEquipmentFormContainerProps';

export const EquipmentTypeCompressorForm = (props: IEquipmentTypeCompressorFormProps): JSX.Element => {
    const { equipment, readonly,
        isEquipmentLinked, isNewForm, equipmentTypeOptions, brandOptions,
        equipmentStatusOptions, distributorOrganizationOptions, activeStep, handleFormSubmit, handleBack, handleClose, handleNext } = props;

    const canEditEquipmentCustomerInfo: boolean = useAppSelector(state => userCanEditEquipmentCustomerInfo(state));
    const canEditEquipmentMainInfo: boolean = useAppSelector(state => userCanEditEquipmentMainInfo(state));
    const canEditContractInfo: boolean = useAppSelector(state => userCanEditEquipmentContractInfo(state));

    return (
        <>
            <Formik
                initialValues={equipment}
                validationSchema={compressorEquipmentFormValidationSchema}
                enableReinitialize
                onSubmit={handleFormSubmit}
            >
                {({ values, dirty, isSubmitting, isValid, handleSubmit }) => (

                    <Form>
                        {
                            activeStep === EquipmentFormContainerStep.EQUIPMENTGENERALINFO &&
                            <>
                                <Card>
                                    <EquipmentGeneralInfo
                                        equipmentCategory={EquipmentCategories.Compressor}
                                        equipmentTypeOptions={equipmentTypeOptions}
                                        distributorOrganizationOptions={distributorOrganizationOptions}
                                        readonly={readonly || (!isNewForm && !canEditEquipmentMainInfo)}
                                        contractSectionEditable={!readonly && canEditContractInfo}
                                        isNewForm={isNewForm}
                                        isEquipmentLinked={isEquipmentLinked}
                                    />
                                </Card>

                                <SaveSection
                                    validateNext={() => equipmentGeneralInfoIsValid(values)}
                                    handleCancel={handleClose}
                                    handleNext={handleNext}
                                />
                            </>
                        }

                        {
                            activeStep === EquipmentFormContainerStep.EQUIPMENTDETAILS &&
                            <>
                                <Card>
                                    <EquipmentTypeCompressorSection
                                        brandOptions={brandOptions}
                                        readonly={readonly || (!isNewForm && !canEditEquipmentMainInfo)}
                                    />
                                </Card>
                                <Card>
                                    <EquipmentDetailInfo
                                        readonly={readonly}
                                        equipmentStatusOptions={equipmentStatusOptions}
                                    />
                                </Card>

                                {
                                    canEditEquipmentCustomerInfo &&
                                    <Card>
                                        <EquipmentCustomerInfo readonly={readonly} />
                                    </Card>
                                }

                                <SaveSection
                                    handleBack={handleBack}
                                    handleSubmit={handleSubmit}
                                    validateSubmit={() => dirty && isValid && !isSubmitting}
                                />
                            </>
                        }
                    </Form>
                )}
            </Formik>
        </>
    );
};