import React, { useState } from 'react';
import { orderBy } from 'lodash';
import { useFormikContext, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, Typography, DialogActions, Grid } from '@mui/material';
import { GridEnrichedColDef, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { RoleToAssign, UserFormValues, UserOrganizationRoleFormValues } from '../../models/user';
import { BrandViewModel } from '../../../models/brand/brandModel';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { userService } from '../../services/UserService';
import { Button, DataGrid, Dropdown, DropdownOption, Icon, StyledDefaultDropdownProps, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { InnerTableSxObject } from '../../../shared/utils/sxStyleObjects';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

interface IUserRoleOrganizationSection {
    formIsReadonly: boolean;
    roleOptions: RoleToAssign[];
    roleEditingRows: {};
    brandOptions: { organizationBrands: BrandViewModel[] }[];
    organizationOptions: { roleOrganizations: OrganizationOptionModel[] }[];
    updateRoleEditningRows(roleId: number, isEdited: boolean): void;
    updateBrandOptions(organizationId: number, index: number): Promise<void>;
    updateOrganizationOptions(roleId: number, index: number): Promise<number | undefined>;
}

export const UserRoleOrganizationSection = (props: IUserRoleOrganizationSection): JSX.Element => {
    const { formIsReadonly, roleOptions, roleEditingRows, brandOptions, organizationOptions, updateRoleEditningRows,
        updateBrandOptions, updateOrganizationOptions } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const apiRef = useGridApiRef();
    const { values: user, handleBlur, setFieldValue, setValues, validateForm, setFieldTouched } = useFormikContext<UserFormValues>();

    const [deleteRoleDialogIsVisible, setDeleteDialogIsVisible] = useState<boolean>(false);
    const [roleToDelete, setRoleToDelete] = useState<UserOrganizationRoleFormValues | undefined>(undefined);
    const [rolesAreLoading, setRolesAreLoading] = useState<boolean>(false);

    const translatedUserRoles = user.userOrganizationRoles.map(uor => ({ ...uor, role: t(uor.role) }));

    const onRoleChange = async (value: any, index: number) => {
        setRolesAreLoading(true);
        const organizationId: number | undefined = await updateOrganizationOptions(value, index);
        await setFieldValue(`userOrganizationRoles[${index}].roleId`, value, true);
        await setFieldValue(`userOrganizationRoles[${index}].organizationId`, organizationId, true);
        await setFieldValue(`userOrganizationRoles[${index}].brandIds`, [], true);
        await setFieldTouched(`userOrganizationRoles[${index}].roleId`);
        await setFieldTouched(`userOrganizationRoles[${index}].organizationId`);
        await setFieldTouched(`userOrganizationRoles[${index}].brandIds`);
        await validateForm();
        setRolesAreLoading(false);
    };

    const loadExistingRoleOrganizationOptions = async (roleId: number, index: number) => {
        await updateOrganizationOptions(roleId, index);
    };

    const onOrganizationChange = async (value: any, index: number) => {
        setRolesAreLoading(true);
        await setFieldValue(`userOrganizationRoles[${index}].organizationId`, value, true);
        await setFieldValue(`userOrganizationRoles[${index}].brandIds`, [], true);
        await setFieldTouched(`userOrganizationRoles[${index}].organizationId`);
        await setFieldTouched(`userOrganizationRoles[${index}].brandIds`);
        if (value) {
            await getBrandsForOrganization(value, index);
        }
        await validateForm();
        setRolesAreLoading(false);
    };

    const getBrandsForOrganization = async (organizationId: number, index: number) => {
        await updateBrandOptions(organizationId, index);
    };

    const hideDeleteRoleDialog = () => {
        setRoleToDelete(undefined);
        setDeleteDialogIsVisible(false);
    };

    const onRoleDelete = async () => {
        if (roleToDelete && roleToDelete.id > 0) {
            const deleteResponse = await userService.DeleteUserOrganizationRole(roleToDelete.id);
            if (deleteResponse.isSuccess) {
                const updatedUser: UserFormValues = user;
                const filteredRoles = updatedUser.userOrganizationRoles.filter(uor => uor.id !== roleToDelete.id);
                updatedUser.userOrganizationRoles = filteredRoles;
                setValues(updatedUser);
                hideDeleteRoleDialog();
                dispatch(showSuccess(SUCCESS_MESSAGE));
            } else {
                hideDeleteRoleDialog();
                dispatch(showErrors(deleteResponse.errorModel?.errors));
            }
        } else if (roleToDelete && roleToDelete.tempKey) {
            const updatedUser: UserFormValues = user;
            const filteredRoles = updatedUser.userOrganizationRoles.filter(a => a.tempKey !== roleToDelete.tempKey);
            updatedUser.userOrganizationRoles = filteredRoles;
            setValues(updatedUser);
            hideDeleteRoleDialog();
            dispatch(showSuccess(SUCCESS_MESSAGE));
        }
    };

    const roleEditor = (params: GridRenderCellParams) => {
        const dropdownOptions = roleOptions.map(ro => ({ id: ro.id, label: t(ro.key), value: ro.id }));
        const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id);

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Dropdown<StyledDefaultDropdownProps>
                        id={`userOrganizationRoles[${rowIndex}].roleId`}
                        name={`userOrganizationRoles[${rowIndex}].roleId`}
                        options={dropdownOptions}
                        value={user.userOrganizationRoles[rowIndex].roleId ?? ''}
                        onChange={(e) => onRoleChange(e.target.value, rowIndex)}
                        onBlur={handleBlur}
                        required={true}
                        sx={{ '&.MuiFormControl-root .MuiInputBase-formControl': { height: '48px' } }}
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ErrorMessage name={'userOrganizationRoles[0].roleId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        );
    };

    const organizationEditor = (params: GridRenderCellParams) => {
        const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id);
        const dropdownOptions = orderBy(organizationOptions[rowIndex]?.roleOrganizations, o => o.name).map(ro => {
            return { id: ro.id, label: ro.name, value: ro.id };
        });
        const valueOption = dropdownOptions?.find(o => o.id === user.userOrganizationRoles[rowIndex]?.organizationId);

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={`userOrganizationRoles[${rowIndex}].organizationId`}
                        name={`userOrganizationRoles[${rowIndex}].organizationId`}
                        options={dropdownOptions}
                        value={valueOption ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => onOrganizationChange(item ? (item as DropdownOption).value : null, rowIndex)}
                        onBlur={handleBlur}
                        disabled={
                            !organizationOptions[rowIndex] ||
                            organizationOptions[rowIndex].roleOrganizations.length === 0
                        }
                        required={true}
                        greyedOutLabelOnDisabled
                        variant={'searchable'}
                        usePopper
                        sx={{ '.MuiAutocomplete-root': { marginTop: '0 !important' } }}
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                        optionListStyles={{ '& li': { minHeight: '50px !important', height: 'max-content !important' } }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ErrorMessage name={`userOrganizationRoles[${rowIndex}].organizationId`} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        );
    };

    const famCodeEditor = (params: GridRenderCellParams) => {
        const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id);
        const dropdownOptions = orderBy(organizationOptions[rowIndex]?.roleOrganizations, o => o.famCode).map((o: OrganizationOptionModel) => {
            return { id: o.id, label: `${o.famCode} (${o.name})`, value: o.id };
        });
        const valueOption = dropdownOptions?.find(o => o.id === user.userOrganizationRoles[rowIndex]?.organizationId);

        return (
            <Dropdown<StyledSearchableDropdownProps>
                variant={'searchable'}
                options={dropdownOptions}
                value={valueOption ?? { id: '', label: '', value: '' }}
                onChange={(e, item) => onOrganizationChange(item ? (item as DropdownOption).value : null, rowIndex)}
                disabled={
                    !organizationOptions[rowIndex] ||
                    organizationOptions[rowIndex].roleOrganizations.length === 0
                }
                usePopper
                sx={{ '.MuiAutocomplete-root': { marginTop: '0 !important' } }}
                noOptionsText={t(NO_OPTIONS_TEXT)}
                optionListStyles={{ '& li': { minHeight: '50px !important', height: 'max-content !important' } }}
            />
        );
    };

    const handleMultiSelectChange = (items: DropdownOption[] | null, index: number) => {
        let values = [];
        if ((items as any).length > 0) {
            values = (items as any).map((i: any) => i.value);
        }
        setFieldValue(`userOrganizationRoles[${index}].brandIds`, values, true);
    };

    const brandsEditor = (params: GridRenderCellParams) => {
        const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id);
        const dropdownOptions = brandOptions[rowIndex]?.organizationBrands.map((o) => {
            return { id: o.id, label: o.name, value: o.id };
        });
        let value = user.userOrganizationRoles[rowIndex].brandIds;
        const selectedValues = dropdownOptions?.filter(obj => value.includes(obj.id));

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Dropdown<StyledSearchableDropdownProps>
                        variant={'searchable'}
                        id={`userOrganizationRoles[${rowIndex}].brandIds`}
                        name={`userOrganizationRoles[${rowIndex}].brandIds`}
                        options={dropdownOptions}
                        value={selectedValues}
                        onChange={(e, items) => handleMultiSelectChange(items ? items as DropdownOption[] : null, rowIndex)}
                        onBlur={handleBlur}
                        disabled={!brandOptions[rowIndex] || brandOptions[rowIndex].organizationBrands.length === 0}
                        multiple
                        usePopper
                        sx={{ '.MuiAutocomplete-root': { marginTop: '0 !important' } }}
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ErrorMessage name={`userOrganizationRoles[${rowIndex}].brandIds`} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        );
    };

    const setActiveRow = async (rowData: UserOrganizationRoleFormValues, rowIndex: number) => {
        setRolesAreLoading(true);
        if (rowData.roleId > 0) {
            await loadExistingRoleOrganizationOptions(rowData.roleId, rowIndex);
        }

        const currentOrganizationIsInOptions = organizationOptions[rowIndex] &&
            organizationOptions[rowIndex].roleOrganizations.some(o => o.id === rowData.organizationId);

        if (!currentOrganizationIsInOptions) {
            setFieldValue(`userOrganizationRoles[${rowIndex}].brandIds`, [], true);
        }

        if (rowData.organizationId && currentOrganizationIsInOptions) {
            await getBrandsForOrganization(rowData.organizationId, rowIndex);
        }

        updateRoleEditningRows(rowData.id, true);
        setRolesAreLoading(false);
    };

    const isRowBeingEdited = (roleId: number): boolean => {
        return roleEditingRows[roleId.toString() as keyof typeof roleEditingRows];
    };

    let columns: GridEnrichedColDef[] =
        [
            {
                headerName: t('Role'),
                field: 'role',
                renderCell: (params) => isRowBeingEdited(params.row.id) ? roleEditor(params) : undefined,
                disableExport: true,
                sortable: false,
                flex: 2,
            },
            {
                headerName: t('Organization'),
                field: 'organization',
                renderCell: (params) => isRowBeingEdited(params.row.id) ? organizationEditor(params) : undefined,
                renderEditCell: (params) => isRowBeingEdited(params.row.id) ? organizationEditor(params) : undefined,
                disableExport: true,
                editable: true,
                sortable: false,
                flex: 2,
            },
            {
                headerName: t('Fam Code'),
                field: 'famCode',
                renderCell: (params) => isRowBeingEdited(params.row.id) ? famCodeEditor(params) : undefined,
                renderEditCell: (params) => isRowBeingEdited(params.row.id) ? famCodeEditor(params) : undefined,
                disableExport: true,
                editable: true,
                sortable: false,
                flex: 2,
            },
            {
                headerName: t('Brands'),
                field: 'brands',
                renderCell: (params) => isRowBeingEdited(params.row.id) ? brandsEditor(params) : undefined,
                renderEditCell: (params) => isRowBeingEdited(params.row.id) ? brandsEditor(params) : undefined,
                disableExport: true,
                editable: true,
                sortable: false,
                flex: 3,
            },
        ];

    let actionsColumn: GridEnrichedColDef[] =
        [
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                flex: 1,
                getActions: (params: GridRowParams<UserOrganizationRoleFormValues>) => {
                    const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id);
                    const rowData = params.row;

                    if (isRowBeingEdited(params.row.id)) {
                        return [];
                    }

                    return ([
                        <DataGridActionOverview
                            key={'edit'}
                            icon={<Icon type='edit' />}
                            label={t('Edit')}
                            onClick={() => {
                                apiRef.current.startRowEditMode({ id: params.row.id });
                                setActiveRow(rowData, rowIndex);
                            }}
                            showInMenu
                        />
                    ]);
                }
            }
        ];

    columns = formIsReadonly ? [...columns] : [...columns, ...actionsColumn];

    return (
        <>
            <div id="userRoleDataGridEditor">
                <Box pt={5}>
                    <DataGrid
                        apiRef={apiRef}
                        rows={translatedUserRoles}
                        columns={columns}
                        loading={rolesAreLoading}
                        editMode='row'
                        autoHeight
                        containerHeight='auto'
                        getRowHeight={() => 'auto'}
                        disableColumnMenu={true}
                        hideFooter
                        sx={InnerTableSxObject}
                        experimentalFeatures={{ newEditingApi: true }}
                    />
                    {
                        deleteRoleDialogIsVisible &&
                        <Dialog
                            open={deleteRoleDialogIsVisible}
                            onClose={hideDeleteRoleDialog}
                        >
                            <DialogContent>
                                <Typography variant='body1' sx={{ fontFamily: 'system-ui' }}>
                                    {t('Are you sure you want to delete this role?')}
                                </Typography>
                            </DialogContent>
                            <DialogActions sx={{ paddingBottom: '1rem' }}>
                                <Button id='confirm-delete-role' label={t('Yes')} startIcon='check' variant='primary' onClick={onRoleDelete} />
                                <Button id='cancel-delete-role' label={t('Cancel')} startIcon='cancel' variant='secondary' onClick={hideDeleteRoleDialog} />
                            </DialogActions>
                        </Dialog>
                    }
                </Box>
            </div>
        </>
    );
};
