import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceExecutionFormModel } from '../../models/serviceExecutionModel';
import { Box, Grid } from '@mui/material';
import { TextField } from '@ui-components/ui-library';
import { Title } from '../../../components/form/title/Title';

interface IEquipmentDetailSection {
    showHeader: boolean;
    readonly: boolean;
}

export const EquipmentDetailSection = (props: IEquipmentDetailSection): JSX.Element => {
    const { t } = useTranslation();
    const { values: serviceExecution, handleChange, handleBlur} = useFormikContext() as FormikProps<ServiceExecutionFormModel>;
    const { showHeader, readonly } = props;

    return (                               
        <Box marginTop={'1rem'}>
            {
                showHeader && <Title variant={'h4'} text={t('Equipment details')} align='left'/>
            }

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Equipment Model')}
                        value={serviceExecution.equipmentDetail.model}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}></Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Serial Number')}
                        value={serviceExecution.equipmentDetail.serialNumber}
                        disabled
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Service Order Number')}
                        value={serviceExecution.equipmentDetail.serviceOrderNumber}
                        disabled
                    />  
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id={'equipmentDetail.runningHours'}
                        name={'equipmentDetail.runningHours'}
                        label={t('Running Hours')}
                        value={serviceExecution.equipmentDetail.runningHours}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 10 }}
                        inputRestriction='numeric'
                        disabled={readonly}
                        required
                    />
                    <ErrorMessage name={'equipmentDetail.runningHours'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        id={'equipmentDetail.loadedHours'}
                        name={'equipmentDetail.loadedHours'}
                        label={t('Loaded Hours')}
                        value={serviceExecution.equipmentDetail.loadedHours}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 10 }}
                        inputRestriction='numeric'
                        disabled={readonly}
                    />
                    <ErrorMessage name={'equipmentDetail.loadedHours'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </Box>
    );
};