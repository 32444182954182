import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { ServiceExecutionActivityCreateModel, ServiceExecutionActivityFormModel, ServiceExecutionActivityUpdateModel } from '../models/serviceExecutionActivityModel';

class ServiceExecutionActivityService {
    async CreateServiceExecutionActivity(serviceExecutionActivityCreateModel: ServiceExecutionActivityCreateModel): Promise<ActionResult<number>> {
        const url = '/serviceExecutionActivities/CreateServiceExecutionActivity';

        return await api.post<number>(url, JSON.stringify(serviceExecutionActivityCreateModel));
    }

    async GetServiceExecutionActivitieForServiceExecution(serviceExecutionId: number): Promise<ActionResult<ServiceExecutionActivityFormModel[]>> {
        const url = `/serviceExecutionActivities/GetActivitiesForServiceExecution/${serviceExecutionId}`;
        const response = await api.get<ServiceExecutionActivityFormModel[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async UpdateServiceExecutionActivity(serviceExecutionActivityUpdateModel: ServiceExecutionActivityUpdateModel): Promise<ActionResult<void>> {
        const url = '/serviceExecutionActivities/UpdateServiceExecutionActivity';

        return await api.put<void>(url, JSON.stringify(serviceExecutionActivityUpdateModel));
    }

    async DeleteServiceExecutionActivity(serviceExecutionActivityId: number): Promise<ActionResult<void>> {
        const url = `/serviceExecutionActivities/DeleteServiceExecutionActivity/${serviceExecutionActivityId}`;

        return await api.delete<void>(url);
    }
}

export const serviceExecutionActivityService = new ServiceExecutionActivityService();