import { roundToNearestFutureHour } from '../../shared/utils/Utils';
import { PlanningType } from './planningType';
import moment from 'moment-timezone';

export type ServicePlanningFormModel = {
    id: number | undefined;
    serviceOrderId: number | undefined;
    serviceOrderNumber: string;
    customer: string;
    assigneeIds: number[];
    start: Date;
    end: Date;
    type: PlanningType,
    estimatedWorkHours: number | undefined;
    visitReportIds: number[];
    distributorOrganziationId: number | undefined;
}

export const getDefaultServicePlanning = (): ServicePlanningFormModel => {
    let start = moment().toDate();
    start = roundToNearestFutureHour(start);

    let end = moment().add(1, 'h').toDate();
    end = roundToNearestFutureHour(end);

    return {
        id: undefined,
        serviceOrderId: undefined,
        serviceOrderNumber: '',
        customer: '',
        assigneeIds: [],
        start: start,
        end: end,
        type: PlanningType.Service,
        estimatedWorkHours: undefined,
        visitReportIds: [],
        distributorOrganziationId: undefined,
    };
};

export type ServicePlanningCreateModel = {
    serviceOrderId: number | undefined;
    assigneeIds: number[];
    start: Date;
    end: Date | null;
    type: PlanningType;
}

export type ServicePlanningUpdateModel = {
    servicePlanningId: number | undefined;
    assigneeIds: number[];
    start: Date;
    end: Date | null;
    type: PlanningType;
}

export const mapServicePlanningToCreateModel = (servicePlanning: ServicePlanningFormModel): ServicePlanningCreateModel => {
    const endDate: Date | null = servicePlanning.end ? moment(servicePlanning.end).toDate() : null;

    return {
        serviceOrderId: servicePlanning.serviceOrderId,
        assigneeIds: servicePlanning.assigneeIds,
        start: moment(servicePlanning.start).toDate(),
        end: endDate,
        type: servicePlanning.type,
    };
};

export const mapServicePlanningToUpdateModel = (servicePlanning: ServicePlanningFormModel): ServicePlanningUpdateModel => {
    const endDate: Date | null = servicePlanning.end ? moment(servicePlanning.end).toDate() : null;

    return {
        servicePlanningId: servicePlanning.id,
        assigneeIds: servicePlanning.assigneeIds,
        start: moment(servicePlanning.start).toDate(),
        end: endDate,
        type: servicePlanning.type,
    };
};