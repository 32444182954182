import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILayoutState {
    isLoading: boolean;
    loadingMessage: string;
    successfullyLoaded: boolean;
}

const initialState: ILayoutState = {
    isLoading: false,
    loadingMessage: 'Processing...',
    successfullyLoaded: false,
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            const isLoading: boolean = action.payload;

            state.isLoading = isLoading;
        },

        setLoadingMessage: (state, action: PayloadAction<string>) => {
            const loadingMessage: string = action.payload;

            state.loadingMessage = loadingMessage;
        },

        setSuccessfullyLoaded: (state, action: PayloadAction<boolean>) => {
            const successfullyLoaded: boolean = action.payload;

            state.successfullyLoaded = successfullyLoaded;
        },
    },
});

// Export Actions
export const { setIsLoading, setLoadingMessage, setSuccessfullyLoaded } = layoutSlice.actions;

export default layoutSlice.reducer;