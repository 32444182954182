
export enum RoleActions {
    None = 0,

    Read = 1,

    Create = 2,

    Update = 4,

    Delete = 8,
}