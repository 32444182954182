import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { ChartLegendLabel } from '../../../shared/utils/sxStyleObjects';
import { truncateStringWithEllipsis } from '../../../shared/utils/Utils';

export interface CustomChartLegendComponentProps {
    legendData: { label: string, color: string }[];
    labelMaxCharacters?: number;
}

export const CustomChartLegendComponent = (props: CustomChartLegendComponentProps): JSX.Element => {
    const { legendData, labelMaxCharacters } = props;

    const defaultLabelMaxCharacters = 20;

    return (
        <Box display={'flex'} justifyContent={'space-between'} paddingTop={1} paddingLeft={1.5} paddingRight={1.5}>
            {
                legendData.map((d, i) => {
                    return (
                        <div key={i}>
                            <Tooltip title={labelMaxCharacters ? (d.label.length > labelMaxCharacters ? d.label : '') : (d.label.length > defaultLabelMaxCharacters ? d.label : '')} >
                                <Box
                                    key={i}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '6px'
                                    }}
                                >
                                    <Box sx={{ height: '10px', width: '10px', backgroundColor: d.color }}></Box>
                                    <Box>
                                        <ChartLegendLabel>
                                            {
                                                truncateStringWithEllipsis(d.label, labelMaxCharacters ? labelMaxCharacters : defaultLabelMaxCharacters)
                                            }
                                        </ChartLegendLabel>
                                    </Box>
                                </Box>
                            </Tooltip>
                        </div>
                    )
                })
            }
        </Box>
    )
}
