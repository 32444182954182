import { ActionResult, api } from '../../shared/utils/api';
import { NotificationCreateModel } from '../models/notificationCreateModel';

class NotificationService {
    async CreateServiceExecutionNotification(notificationCreateModel: NotificationCreateModel): Promise<ActionResult<string>> {
        const url = '/notifications/CreateVisitReportNotification';

        return await api.post<string>(url, JSON.stringify(notificationCreateModel));
    }
}

export const notificationService = new NotificationService();