import { DownloadFileModel } from '../../shared/models/DownloadFileModel';
import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { ServiceExecutionAttachmentSection } from '../models/enums/serviceExecutionAttachmentSection';
import { ServiceExecutionAssigneeModel } from '../models/serviceExecutionAssigneeModel';
import { ServiceExecutionAttachment } from '../models/serviceExecutionAttachment';
import { getDefaultServiceExecutionCustomerDetail } from '../models/serviceExecutionCustomerDetailModel';
import { ServiceExecutionExportFilterModel } from '../models/serviceExecutionExportFilterModel';
import { ServiceExecutionFutureRecommendationModel } from '../models/serviceExecutionFutureRecommendationModel';
import { ServiceEquipmentMeasuredDataModel, ServiceExecutionCreateModel, ServiceExecutionFinalizationModel, ServiceExecutionFormModel } from '../models/serviceExecutionModel';
import { ServiceExecutionOverviewModel, ServiceExecutionStartupOverviewModel } from '../models/serviceExecutionOverviewModel';
import { ServiceExecutionWorkCommentCreateModel, ServiceExecutionWorkCommentModel } from '../models/serviceExecutionWorkCommentModel';
import moment from 'moment-timezone';

class ServiceExecutionService {
    async SetEquipmentMeasuredData(equipmentMeasuredDataModel: ServiceEquipmentMeasuredDataModel) {
        const url = '/visitReports/SetEquipmentMeasuredData';

        return await api.post<number>(url, JSON.stringify(equipmentMeasuredDataModel));
    }

    async CreateServiceExecution(serviceExecutionCreateModel: ServiceExecutionCreateModel): Promise<ActionResult<number>> {
        const url = '/visitReports/CreateVisitReport';

        return await api.post<number>(url, JSON.stringify(serviceExecutionCreateModel));
    }

    async GetServiceExecutions(): Promise<ActionResult<ServiceExecutionOverviewModel>> {
        const url = '/visitReports/GetAllVisitReports';
        const response = await api.get<ServiceExecutionOverviewModel>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        response.result.visitReports.forEach(se => se.creationDate = moment(se.creationDate).toDate());
        return response;
    }

    async GetStartupServiceExecutions(): Promise<ActionResult<ServiceExecutionStartupOverviewModel[]>> {
        const url = '/visitReports/GetStartupVisitReports';
        const response = await api.get<ServiceExecutionStartupOverviewModel[]>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        response.result.forEach(se => se.completedDate = moment(se.completedDate).toDate());
        response.result.forEach(se => se.createdOn = moment(se.createdOn).toDate());

        return response;
    }

    async ExportStartupServiceExecutionsAsExcel(filters: ServiceExecutionExportFilterModel): Promise<ActionResult<DownloadFileModel>> {
        const url = '/visitReports/ExportStartupServiceExecutionsAsExcel';

        var response = await api.get<DownloadFileModel>(url, filters);

        return response;
    }

    async GetServiceExecutionById(serviceExecutionId: number): Promise<ActionResult<ServiceExecutionFormModel>> {
        const url = `/visitReports/GetVisitReportById/${serviceExecutionId}`;
        const response = await api.get<ServiceExecutionFormModel>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        response.result.customerDetail ??= getDefaultServiceExecutionCustomerDetail();

        return response;
    }

    async SetServiceExecutionWorkComment(workCommentsModel: ServiceExecutionWorkCommentCreateModel): Promise<ActionResult<number>> {
        const url = '/workComments/SetWorkComments';
        const response = await api.post<number>(url, JSON.stringify(workCommentsModel));

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        return response;
    }

    async GetServiceExecutionWorkcomment(visitReportId: number): Promise<ActionResult<ServiceExecutionWorkCommentModel>> {
        const url = `/workComments/GetWorkComment/${visitReportId}`;
        const response = await api.get<ServiceExecutionWorkCommentModel>(url);

        if (!response.isSuccess)
            return response;

        return response;
    }

    async SetServiceExecutionFutureRecommendation(futureRecommendationsModel: ServiceExecutionFutureRecommendationModel): Promise<ActionResult<number>> {
        const url = '/futureServiceRecommendations/SetFutureRecommendations';
        const response = await api.post<number>(url, JSON.stringify(futureRecommendationsModel));

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        return response;
    }

    async GetServiceExecutionFutureRecommendation(visitReportId: number): Promise<ActionResult<ServiceExecutionFutureRecommendationModel>> {
        const url = `/futureServiceRecommendations/GetFutureRecommendation/${visitReportId}`;
        const response = await api.get<ServiceExecutionFutureRecommendationModel>(url);

        if (!response.isSuccess)
            return response;

        return response;
    }

    async GetServiceExecutionAssigneeOptions(serviceExecutionId: number): Promise<ActionResult<ServiceExecutionAssigneeModel[]>> {
        const url = `/users/GetVisitReportAssigneeOptions/${serviceExecutionId}`;
        const response = await api.get<ServiceExecutionAssigneeModel[]>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        return response;
    }

    async CompleteServiceExecution(serviceExecutionFinalizationModel: ServiceExecutionFinalizationModel): Promise<ActionResult<void>> {
        const url = '/visitReports/SetStatusToCompleted';
        const response = await api.put<void>(url, JSON.stringify(serviceExecutionFinalizationModel));

        return response;
    }

    async UploadAttachment(serviceExecutionAttachment: File, visitReportId: number, serviceExecutionAttachmentSection: ServiceExecutionAttachmentSection): Promise<ActionResult<ServiceExecutionAttachment>> {
        const url = '/visitReports/UploadAttachment';

        const response = await api.filePut<ServiceExecutionAttachment>(url, serviceExecutionAttachment, [
            { key: 'visitReportId', value: visitReportId },
            { key: 'serviceExecutionAttachmentSection', value: serviceExecutionAttachmentSection },
        ]);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        return response;
    }

    async DeleteAttachment(serviceExecutionId: number, attachmentId: number): Promise<ActionResult<void>> {
        const url = `/visitReports/DeleteAttachment/${serviceExecutionId}/${attachmentId}`;
        const response = await api.put<void>(url, {});

        return response;
    }
}

export const serviceExecutionService = new ServiceExecutionService();