import moment from 'moment-timezone';

export type OnboardedDistributorsFilterModel = {
    year: number;
    customerCenterId: number | undefined;
    userOffset: number;
}

export const getDefaultOnboardedDistributorsFilterModel = (): OnboardedDistributorsFilterModel => {
    return {
        year: moment().startOf('y').year(),
        customerCenterId: undefined,
        userOffset: moment().utcOffset(),
    };
};
