
export type ServiceExecutionActivityFormModel = {
    id: number;
    value: number;
    activityTypeId: number;
    activityTypeLabel: string;
    assigneeId: number;
    assigneeFullName: string;
    unitId: number;
    unitLabel: string;
    visitReportId: number;
};

export const getDefaultServiceExecutionActivity = (visitReportId: number): ServiceExecutionActivityFormModel => {
    return {
        id: 0,
        value: 0,
        activityTypeId: 0,
        activityTypeLabel: '',
        assigneeId: 0,
        assigneeFullName: '',
        unitId: 0,
        unitLabel: '',
        visitReportId: visitReportId,
    };
};

export type ServiceExecutionActivityCreateModel = {
    value: number;
    activityTypeId: number;
    assigneeId: number;
    unitId: number;
    visitReportId: number;
}

export const mapServiceExecutionActivityToCreateModel = (serviceExecutionActivity: ServiceExecutionActivityFormModel) : ServiceExecutionActivityCreateModel => {
    return {
        value: serviceExecutionActivity.value,
        activityTypeId: serviceExecutionActivity.activityTypeId,
        assigneeId: serviceExecutionActivity.assigneeId,
        unitId: serviceExecutionActivity.unitId,
        visitReportId: serviceExecutionActivity.visitReportId,
    };
};

export type ServiceExecutionActivityUpdateModel = {
    id: number;
    value: number;
    activityTypeId: number;
    assigneeId: number;
    unitId: number;
}

export const mapServiceExecutionActivityToUpdateModel = (serviceExecutionActivity: ServiceExecutionActivityFormModel) : ServiceExecutionActivityUpdateModel => {
    return {
        id: serviceExecutionActivity.id,
        value: serviceExecutionActivity.value,
        activityTypeId: serviceExecutionActivity.activityTypeId,
        assigneeId: serviceExecutionActivity.assigneeId,
        unitId: serviceExecutionActivity.unitId,
    };
};
