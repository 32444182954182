import { BrandViewModel } from '../../../models/brand/brandModel';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { CountryFormModel } from '../../models/countryModel';
import { ErpTypeModel } from '../../models/erpTypeModel';
import { OrganizationFormModel, ParentOrganizationOptionModel } from '../../models/organizationModel';

export interface IOrganizationFormProps {
	organization: OrganizationFormModel;
	organizationType: OrganizationType;
	brands: BrandViewModel[];
	erpTypes: ErpTypeModel[];
	countries: CountryFormModel[];
	parentOrganizations: ParentOrganizationOptionModel[];
	handleClose: () => void;
	getOrganizations(): Promise<void>;
}

export enum OrganizationFormStep {
	GENERALINFO = 0,
	ADDRESS = 1
}
