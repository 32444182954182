import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { userLoaded } from '../../../stores/user/usersSlice';
import { AuthenticatedUser } from '../../../users/models/user';
import { IRestrictedProps } from './IRestrictedProps';

export const Restricted: React.FC<IRestrictedProps> = ({ to, fallbackElement, children }): JSX.Element => {

    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));

    const userHasPermissions = useMemo(() => {
        if (!userIsLoaded) return false;
        return currentUser.permissions && currentUser.permissions[to] === true;
    }, [userIsLoaded, currentUser, to]);

    const [shouldRenderFallbackElement, setShouldRenderFallbackElement] = useState<boolean>(!userHasPermissions);

    useEffect(() => {
        if (userIsLoaded && userHasPermissions) {
            setShouldRenderFallbackElement(false);
        }

    }, [userIsLoaded, currentUser, userHasPermissions]);

    return (
        <>
            {shouldRenderFallbackElement ? fallbackElement : children}
        </>
    );
};
