import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserPreferencePage } from '../userPreferences/UserPreferencePage';
import { UserSettingsTabPanel } from '../userSettingsTabPanel/UserSettingsTabPanel';

export const UserSettingsPage = () => {

    const { t } = useTranslation();
    const [tabPanelIndex, setTabPanelIndex] = React.useState(0);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTabPanelIndex(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 5, bgcolor: 'background', display: 'flex', height: '200px' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabPanelIndex}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: '#1ca8dd'
                    }
                }}
            >
                <Tab style={{ fontFamily: 'inherit', color: '#1ca8dd' }} label={t('User Preferences')} />
            </Tabs>
            <UserSettingsTabPanel value={tabPanelIndex} index={0}>
                <UserPreferencePage />
            </UserSettingsTabPanel>
        </Box>
    );
};
