import React from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateDownloadSection } from '../../../components/templateDownloadSection/TemplateDownloadSection';
import { UploadComponent } from '../../../components/uploadComponent/UploadComponent';
import { Title } from '../../../components/form/title/Title';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { customerService } from '../../services/CustomerService';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';

export const CustomersUploadPage = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const submitFile = async (file: File) => {
        dispatch(setIsLoading(true));
        
        const uploadResponse = await customerService.UploadBulkCustomerFile(file!);

        if (uploadResponse.isSuccess) {
            dispatch(showSuccess(t('You can check your upload progress in \'My Uploads\'')));
        } else {
            dispatch(showErrors(uploadResponse.errorModel?.errors));
        }

        dispatch(setIsLoading(false));        
    };

    return (
        <>
            <Title variant={'h2'} text={t('Upload customers')} />


            <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                <UploadComponent
                    maxFileSizeInMb={5}
                    acceptedFileTypes={['xlsx', 'xls']}
                    accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                    placeholderText={'Drag and Drop Excel Import File Here'}
                    uploadButtonLabel={'Upload customers'}
                    allowMultiple={false}
                    maximumFilesAllowed={1}
                    disabled={false}
                    onSubmit={submitFile}
                />

                <TemplateDownloadSection />
            </Box>
        </>
    );
};