import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAlertState {
    message: any;
    successMessage: string | undefined;
    errorMessages: string[] | undefined;
}

const initialState: IAlertState = {
    message: undefined,
    successMessage: undefined,
    errorMessages: undefined,
};

export const alertSlice = createSlice(
    {
        name: 'alert',
        initialState,
        reducers: {
            showMessage: (state, action: PayloadAction<any>) => {
                state.message = action.payload;
            },
            showSuccess: (state, action: PayloadAction<string | undefined>) => {
                state.successMessage = action.payload;
            },
            showErrors: (state, action: PayloadAction<string[] | undefined>) => {
                state.errorMessages = action.payload;
            },
        }
    }
);

export const { showMessage, showSuccess, showErrors } = alertSlice.actions;

export default alertSlice.reducer;
