import React, { useMemo, useState } from 'react';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TuneIcon from '@mui/icons-material/Tune';
import { isEqual } from 'lodash';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { CustomerCreationKpiFilterModel, getDefaultCustomerCreationFilterModel } from '../../models/CustomerCreationKpiFilterModel';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import moment from 'moment-timezone';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { ALL_TEXT, NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import { CustomerCreationMonthView } from './CustomerCreationMonthView';
import { CustomerCreationYearView } from './CustomerCreationYearView';
import { DropdownListItemLongContentSxObject } from '../../../shared/utils/sxStyleObjects';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';

export const CustomerCreationDashboard = (): JSX.Element => {
    const { t } = useTranslation();

    const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));

    const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useState<CustomerCreationKpiFilterModel>(getDefaultCustomerCreationFilterModel());
    const [notAppliedFilters, setNotAppliedFilters] = useState<any>({});

    const [yearViewIsSelected, setYearViewIsSelected] = useState<boolean>(false);

    const organizationOptions: OrganizationOptionModel[] = useAppSelector(state => state.Dashboard.organizationOptions);

    const customerCenterOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.CustomerCenter), [organizationOptions]);
    const distributorOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.Distributor), [organizationOptions]);

    const onViewChange = () => {
        setYearViewIsSelected(!yearViewIsSelected);
    };

    const handleFilterChange = (propName: keyof CustomerCreationKpiFilterModel, value: any): void => {
        if (Object.keys(notAppliedFilters).length === 0) {
            setNotAppliedFilters({ ...appliedFilters, [propName]: value });
        } else {
            setNotAppliedFilters({ ...notAppliedFilters, [propName]: value });
        }
    };

    const handleCustomerCenterChange = (value: number | undefined) => {
        if (value) {
            const childrenOrganizationsIds = organizationOptions.filter(oo => oo.parentOrganizationId === value).map(co => co.id);
            const currentSelectedDistributorId = notAppliedFilters['distributorOrganizationId'] || appliedFilters['distributorOrganizationId'];
            const shouldClearDistributor: boolean = currentSelectedDistributorId !== undefined &&
                !childrenOrganizationsIds.some(co => co === currentSelectedDistributorId);

            if (shouldClearDistributor) {
                setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value, distributorOrganizationId: undefined });
            } else {
                setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value });
            }
        } else {
            setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: undefined });
        }
    };

    const handleDistributorChange = (item: DropdownOption | null) => {
        handleFilterChange('distributorOrganizationId', item ? item.id : undefined);
    };


    const getSelectedDropdownOption = (propName: keyof CustomerCreationKpiFilterModel, optionsCollection: any[]): DropdownOption | null | undefined => {
        if ((!appliedFilters[propName as keyof typeof appliedFilters] && !notAppliedFilters[propName as keyof typeof notAppliedFilters]) ||
            (propName in notAppliedFilters && notAppliedFilters[propName as keyof typeof notAppliedFilters] === undefined)) {
            return null;
        }

        if (notAppliedFilters[propName as keyof typeof notAppliedFilters]) {
            return optionsCollection.find(et => et.id === notAppliedFilters[propName as keyof typeof notAppliedFilters]);
        } else if (appliedFilters[propName as keyof typeof appliedFilters]) {
            return optionsCollection.find(o => o.id === appliedFilters[propName as keyof typeof appliedFilters]);
        }
    };

    const applyButtonIsDisabled = (): boolean => {
        return Object.keys(notAppliedFilters).length === 0 ||
            !Object.keys(notAppliedFilters).some(naf =>
                !isEqual(appliedFilters[naf as keyof typeof appliedFilters], notAppliedFilters[naf]));
    };

    const applyFilters = async (): Promise<void> => {
        setAppliedFilters({ ...appliedFilters, ...notAppliedFilters });
        closeFilterModal();
    };

    const clearFilters = async () => {
        setAppliedFilters(getDefaultCustomerCreationFilterModel());
    };

    const closeFilterModal = () => {
        setFilterModalIsOpen(false);
        setNotAppliedFilters({});
    };

    const hasAppliedFilters: boolean = appliedFilters.distributorOrganizationId !== undefined ||
        appliedFilters.customerCenterId !== undefined ||
        (appliedFilters.year !== undefined && appliedFilters.year !== moment().year());

    const cacheLtr = createCache({
        key: 'mui',
    });

    const customerCenterDropdownOptions = useMemo(() => {
        if (customerCenterOptions && customerCenterOptions.length > 0) {
            const distributorIsSelected: boolean = notAppliedFilters['distributorOrganizationId'] !== undefined ||
                (appliedFilters['distributorOrganizationId'] !== undefined && !('distributorOrganizationId' in notAppliedFilters));

            if (distributorIsSelected) {
                if (notAppliedFilters['distributorOrganizationId'] !== undefined) {
                    const selectedDistributor = distributorOptions.find(dop => dop.id === notAppliedFilters['distributorOrganizationId']);

                    return customerCenterOptions
                        .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
                        .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
                } else if (appliedFilters['distributorOrganizationId'] !== undefined) {
                    const selectedDistributor = distributorOptions.find(dop => dop.id === appliedFilters['distributorOrganizationId']);

                    return customerCenterOptions
                        .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
                        .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
                }
            }
            
            return customerCenterOptions.map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        }

        return [];
    }, [customerCenterOptions, distributorOptions, appliedFilters, notAppliedFilters]);

    const distributorDropdownOptions = useMemo(() => {
        if (distributorOptions && distributorOptions.length > 0) {
            const customerCenterIsSelected: boolean = notAppliedFilters['customerCenterId'] !== undefined ||
                (appliedFilters['customerCenterId'] !== undefined && !('customerCenterId' in notAppliedFilters));

            if (customerCenterIsSelected) {
                if (notAppliedFilters['customerCenterId'] !== undefined) {
                    return distributorOptions
                        .filter(dop => dop.parentOrganizationId === notAppliedFilters['customerCenterId'])
                        .map(et => ({ id: et.id, label: et.name, value: et.id }));
                } else if (appliedFilters['customerCenterId'] !== undefined) {
                    return distributorOptions
                        .filter(dop => dop.parentOrganizationId === appliedFilters['customerCenterId'])
                        .map(et => ({ id: et.id, label: et.name, value: et.id }));
                }
            }

            return distributorOptions.map(option => ({ id: option.id, label: option.name, value: option.id }));
        }

        return [];
    }, [distributorOptions, appliedFilters, notAppliedFilters]);

    return (
        <>
            <Box dir={'ltr'}>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'} dir={shouldUseRtl ? 'rtl' : 'ltr'}>
                    <Box fontSize={18} flex={2} textAlign={'left'}>{t('Customer Creation KPI')}</Box>

                    <Tooltip
                        title={yearViewIsSelected ? t('Month view') : t('Year view')}
                    >
                        <IconButton id={'view_toggle'} onClick={onViewChange}>
                            <ViewModuleOutlinedIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={t('Filter')}
                    >
                        <IconButton id={'filter'} onClick={() => setFilterModalIsOpen(true)}>
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>

                    {
                        hasAppliedFilters &&
                        <Tooltip
                            title={t('Clear filters')}
                        >
                            <IconButton id={'clear_filters'} onClick={clearFilters}>
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>

                <CacheProvider value={cacheLtr}>
                    {
                        !yearViewIsSelected &&
                        <CustomerCreationMonthView appliedFilters={appliedFilters} />
                    }

                    {
                        yearViewIsSelected &&
                        <CustomerCreationYearView appliedFilters={appliedFilters} />
                    }
                </CacheProvider>

            </Box>

            <FilterModal
                isOpen={filterModalIsOpen}
                disableApply={applyButtonIsDisabled()}
                onClose={closeFilterModal}
                onApply={applyFilters}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            id={'year'}
                            inputFormat={'YYYY'}
                            value={notAppliedFilters['year'] ? moment(notAppliedFilters['year'], 'YYYY').startOf('y').toDate() : moment(appliedFilters['year'], 'YYYY').startOf('y').toDate()}
                            onChange={value => value ? handleFilterChange('year', moment(value).startOf('y').year()) : undefined}
                            disableFuture
                            views={['year']}
                            title={t('Year')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>

                    <Grid item xs={12} md={6}>
                        <Dropdown<StyledSearchableDropdownProps>
                            label={t('Customer Center')}
                            options={customerCenterDropdownOptions}
                            value={getSelectedDropdownOption('customerCenterId', customerCenterDropdownOptions)}
                            onChange={(e, item) => handleCustomerCenterChange(item ? Number((item as DropdownOption).value) : undefined)}
                            variant='searchable'
                            usePopper
                            greyedOutLabelOnDisabled
                            disabled={customerCenterDropdownOptions.length === 0}
                            noOptionsText={t(NO_OPTIONS_TEXT)}
                            placeholder={t(ALL_TEXT)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Dropdown<StyledSearchableDropdownProps>
                            label={t('Distributor Organization')}
                            options={distributorDropdownOptions}
                            value={getSelectedDropdownOption('distributorOrganizationId', distributorDropdownOptions)}
                            onChange={(e, item) => handleDistributorChange(item ? item as DropdownOption : null)}
                            variant='searchable'
                            usePopper
                            noOptionsText={t(NO_OPTIONS_TEXT)}
                            placeholder={t(ALL_TEXT)}
                            optionListStyles={DropdownListItemLongContentSxObject}
                        />
                    </Grid>
                </Grid>
            </FilterModal>
        </>
    );
};