import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.colors.neutral[50], 
	marginBottom: '2rem'
})) as typeof Typography;

interface TitleProps {
	variant: 'h1' | 'h2' | 'h3'|  'h4' | 'h5' | 'h6';
	text: string;
	marginBottom?: string;
	align?: 'center' | 'left' | 'right'
}

export const Title = (props: TitleProps): JSX.Element => {
	return (
		<StyledTitle 
			variant={props.variant} 
			textAlign={props.align ? props.align : 'center'} 
			marginBottom={props.marginBottom ? props.marginBottom : '2rem'}
		>
			{props.text}
		</StyledTitle>
	);
};
