export type ServiceExecutionEquipmentDetailModel = {
    model: string;
    serialNumber: string;
    serviceOrderNumber: string;
    runningHours: number | undefined;
    loadedHours: number | undefined;
};

export const getDefaultServiceExecutionEquipmentDetail = (): ServiceExecutionEquipmentDetailModel => {
    return {
        model: '',
        serialNumber: '',
        serviceOrderNumber: '',
        runningHours: undefined,
        loadedHours: undefined,
    };
};