import React from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormikContext, ErrorMessage, FormikProps } from 'formik';
import { Box, Grid, Typography } from '@mui/material';
import { Dropdown, DropdownOption, Radio, StyledSearchableDropdownProps, TextField } from '@ui-components/ui-library';
import { ServiceOrderFormModel } from '../../models/serviceOrderModel';
import { EquipmentOptionModel } from '../../../equipments/models/equipmentOptionModel';
import { IServiceOrderGeneralInfoProps } from './IServiceOrderGeneralInfoProps';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { EquipmentValidationMessages } from '../../../shared/utils/validationMessages';
import { ServiceOrderFormStep } from '../serviceOrderForm/IServiceOrderFormProps';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import './ServiceOrderGeneralInfo.scss';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const ServiceOrderGeneralInfo = (props: IServiceOrderGeneralInfoProps): JSX.Element => {
	const { readonly, activeStep, serviceOrderTypeOptions, equipmentOptions, getCustomerRelatedData, clearCustomerRelatedData, clearPersonOfInterestForCreation } = props;
	const dispatch = useAppDispatch();
	const { values: serviceOrder, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext() as FormikProps<ServiceOrderFormModel>;
	const { t } = useTranslation();

	const isServiceOrderTypeDisabled = serviceOrderTypeOptions.length < 1 || readonly;

	const onEquipmentChange = async (value: any) => {
		setFieldValue('equipmentId', value);
		setFieldValue('personOfInterestVersionId', 0);
		const selectedEquipment: EquipmentOptionModel | undefined = equipmentOptions.find(e => e.id === value);

		setFieldValue('isEquipmentLinkedToCustomer', selectedEquipment ? selectedEquipment.linkedToCustomer : undefined);
		setFieldTouched('isEquipmentLinkedToCustomer');

		if (selectedEquipment && !selectedEquipment.linkedToCustomer) {			
			clearCustomerRelatedData();
		} else {
			if (value !== 0) {
				dispatch(setIsLoading(true));
				
				await getCustomerRelatedData(value);
				
				dispatch(setIsLoading(false));
			}
		}

		clearPersonOfInterestForCreation();
	};

	const radioOptions = [
		{ label: `${t('Yes')}`, value: 'true', disabled: readonly },
		{ label: `${t('No')}`, value: 'false', disabled: readonly },
	];

	const equipmentDropdownOptions: DropdownOption[] = orderBy(equipmentOptions, s => s.serialNumber).map(e => {
		return {
			id: e.id,
			label: `${e.serialNumber} ${e.linkedToCustomer ? '' : '[' + t('not linked to customer') + ']'}`,
			value: e.id
		};
	});

	const equipmentValueOption: DropdownOption | undefined = equipmentDropdownOptions?.find(o => o.id === serviceOrder.equipmentId);

	const typeOptions = serviceOrderTypeOptions.map(sot => ({ id: sot.id, label: t(sot.description), value: sot.id }));
	const typeValueOption = typeOptions.find(obj => obj.id === serviceOrder.serviceOrderDetail.serviceOrderTypeId);

	const serviceDescriptionMaxLength = 4000;
	const serviceDescriptionCharacterCount = serviceOrder.serviceOrderDetail.description.length;

	return (
		<>
			{activeStep === ServiceOrderFormStep.GENERALINFO &&
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<TextField
							label={t('Service Order Number')}
							id={'number'}
							name={'number'}
							value={serviceOrder.number}
							onChange={handleChange}
							onBlur={handleBlur}
							inputProps={{ maxLength: 50 }}
							disabled
						/>
						<ErrorMessage name={'number'} render={msg => <span className='p-error'>{t(msg)}</span>} />
					</Grid>

					<Grid item xs={12} md={3}>
						<TextField
							label={t('Service Order ID')}
							id={'externalId'}
							name={'externalId'}
							value={serviceOrder.externalId}
							onChange={handleChange}
							onBlur={handleBlur}
							inputProps={{ maxLength: 100 }}
							disabled={readonly}
						/>
						<ErrorMessage name={'externalId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
					</Grid>

					<Grid item xs={12} md={6}>
						<Dropdown<StyledSearchableDropdownProps>
							id={'serviceOrderDetail.serviceOrderTypeId'}
							label={t('Type of Service Order')}
							name={'serviceOrderDetail.serviceOrderTypeId'}
							options={typeOptions}
							value={typeValueOption ?? { id: '', label: '', value: '' }}
							onChange={(e, item) => setFieldValue('serviceOrderDetail.serviceOrderTypeId', item ? (item as DropdownOption).value : 0)}
							onBlur={handleBlur}
							required={true}
							disabled={isServiceOrderTypeDisabled}
							greyedOutLabelOnDisabled
							variant={'searchable'}
							noOptionsText={t(NO_OPTIONS_TEXT)}
						/>
						<ErrorMessage name={'serviceOrderDetail.serviceOrderTypeId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
					</Grid>

					<Grid item xs={12} md={6}>
						<Radio
							title={t('Is Machine Operating?')}
							options={radioOptions}
							value={serviceOrder.serviceOrderDetail.isEquipmentOperating.toString().toLowerCase()}
							onChange={(value: string) => setFieldValue('serviceOrderDetail.isEquipmentOperating', value === 'true' ? true : false)}
							direction='row'
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Dropdown<StyledSearchableDropdownProps>
							id={'equipmentId'}
							label={t('Serial Number')}
							variant={'searchable'}
							name={'equipmentId'}
							options={equipmentDropdownOptions}
							value={equipmentValueOption ?? { id: '', label: '', value: '' }}
							onChange={(e, item) => onEquipmentChange(item ? (item as DropdownOption).value : 0)}
							onBlur={handleBlur}
							disabled={equipmentOptions.length < 1 || readonly}
							required={true}
							greyedOutLabelOnDisabled
							usePopper
							noOptionsText={t(NO_OPTIONS_TEXT)}
						/>
						<ErrorMessage name={'equipmentId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
						<ErrorMessage name={'isEquipmentLinkedToCustomer'} render={() => <span className='p-error'>{t(EquipmentValidationMessages.noCustomer)}</span>} />
					</Grid>

					<Grid item xs={12}>
						<TextField
							label={t('Description of Order')}
							id={'serviceOrderDetail.description'}
							name={'serviceOrderDetail.description'}
							value={serviceOrder.serviceOrderDetail.description}
							onChange={handleChange}
							onBlur={handleBlur}
							inputProps={{ maxLength: serviceDescriptionMaxLength }}
							disabled={readonly}
							required={true}
							multiline={true}
							minRows={2}
							style={{ resize: 'vertical'}}
						/>
						<Box>
							<Typography sx={{ fontSize: '14px', fontFamily: 'system-ui' }}>
								{`${serviceDescriptionCharacterCount} / ${serviceDescriptionMaxLength}`} {t('characters')}
							</Typography>
						</Box>
						<ErrorMessage name={'serviceOrderDetail.description'} render={msg => <span className='p-error'>{t(msg)}</span>} />
					</Grid>

				</Grid>
			}
		</>
	);
};