import React, { useState } from 'react';
import { ISaveSectionProps } from './ISaveSectionProps';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Button } from '@ui-components/ui-library';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';

export const SaveSection = (props: ISaveSectionProps): JSX.Element => {
    const { handleClose, handleBack, validateBack, handleNext, validateNext, handleSubmit, validateSubmit, handleCancel, validateCancel, additionalButtons } = props;
    const { t } = useTranslation();

    const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState<boolean>(false);

    const onCancelClick = () => {
        if (!validateCancel!()) {
            setCancelDialogIsOpen(true);
        } else {
            handleCancel!();
        }
    };
 
    return (
        <>
            <Box mt={5} display='flex' justifyContent='flex-end' gap={2}>

                {
                    handleCancel &&
                    <Button
                        id={'Cancel'}
                        label={t('Cancel')}
                        onClick={validateCancel ? onCancelClick : handleCancel}
                        variant='secondary'
                    />
                }

                {
                    handleBack &&
                    <Button
                        id='back'
                        type={'button'}
                        label={t('Back')}
                        variant='secondary'
                        onClick={handleBack}
                        startIcon={shouldUseRtl ? 'chevron_right' : 'chevron_left'}
                        disabled={validateBack ? !validateBack() : false}
                    />
                }

                {
                    handleNext &&
                    <Button
                        id='next'
                        type={'button'}
                        label={t('Next')}
                        onClick={handleNext}
                        endIcon={shouldUseRtl ? 'chevron_left' : 'chevron_right'}
                        disabled={validateNext ? !validateNext() : false}
                    />
                }

                {
                    handleSubmit &&
                    <Button
                        id='submit'
                        type={'submit'}
                        variant='primary'
                        label={t('Save')}
                        onClick={() => {
                            handleSubmit();

                            if (handleClose) {
                                handleClose();
                            }
                        }}
                        disabled={(validateSubmit ? !validateSubmit() : false)}
                    />
                }

                {
                    additionalButtons &&
                    additionalButtons.map(b => {
                        return (
                            <Button
                                key={b.id}
                                id={b.id}
                                type={b.type}
                                variant={b.variant}
                                label={b.label}
                                onClick={b.onClick}
                                disabled={b.validate ? !b.validate() : b.disabled}
                                startIcon={b.startIcon}
                                endIcon={b.endIcon}
                            />
                        );
                    })
                }

                {
                    cancelDialogIsOpen &&
                    <Dialog 
                        open={cancelDialogIsOpen}
                        onClose={() => setCancelDialogIsOpen(false)}
                    >
                        <DialogContent>
                            <Typography variant='body1' sx={{ fontFamily: 'system-ui' }}>
                                {t('You have unsaved changes. Are you sure you want to close the form?')}
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ paddingBottom: '1rem'}}>
                            <Button 
                                id='confirm-delete-poi' 
                                label={t('OK')} startIcon='check' 
                                variant='primary' 
                                type={'button'} 
                                onClick={handleCancel} 
                            />
                            <Button 
                                id='cancel-delete-poi' 
                                label={t('Cancel')} 
                                startIcon='cancel' 
                                variant='secondary' 
                                type={'button'} 
                                onClick={() => setCancelDialogIsOpen(false)} 
                            />
                        </DialogActions>
                    </Dialog>
                }               

            </Box>
        </>
    );
};