export enum Language {
    en,
    nl,
    it,
    pt,
    fr,
    tr,
    es,
    ar,
}

export type TimeZone = {
    value: string;
    abbr: string;
    offset: number;
    isdst: boolean;
    text: string;
    utc: string[];
}

export type UsePreferencesPartial = Pick<UserPreferences, 'language' | 'timeZone' | 'timeZoneIana'> & {
    userId: number
};

export type UserLanguageUpdateModel = {
    userId: number;
    language: Language;
}

export type UserPreferences = {
    language: Language;
    locale: string;
    momentLocale: string;
    dateFormat: string;
    calendarDateFormat: string;
    timeFormat: string;
    hourType: string;
    hourFormat: string;
    timeZone: string;
    timeZoneIana: string;
    offset: number;
    schedulerPreferences: SchedulerPreferences
    overviewPreferences: OverviewPreferences
};

export type SchedulerPreferences = {
    startLongDayHour: number;
    endLongDayHour: number;
    startShortDayHour: number;
    endShortDayHour: number;
    cellDuration: number;
    firstDayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
    excludedDays: number[];
    userColor: string;
}

export type OverviewPreferences = {
    rowCount: number;
}

export const getDefaultPreferences = (): UserPreferences => {
    // These are just hardcoded values for now
    return {
        language: Language.en,
        locale: 'en-US',
        momentLocale: 'en',
        dateFormat: 'DD/MM/YYYY',
        calendarDateFormat: 'dd/mm/yy',
        timeFormat: 'HH:mm',
        hourType: '24',
        hourFormat: 'HH',
        // TODO: Standartize (one format of date) everywhere - scheduler or not
        timeZone: '',
        timeZoneIana: Intl.DateTimeFormat().resolvedOptions().timeZone,
        offset: 0,
        schedulerPreferences: {
            startLongDayHour: 6,
            endLongDayHour: 22,
            startShortDayHour: 8,
            endShortDayHour: 17,
            cellDuration: 60,
            firstDayOfWeek: 1,
            excludedDays: [0, 6],
            userColor: '#6a95d9',
        },
        overviewPreferences: {
            rowCount: 15
        }
    };
};

export const localesByLanguage = {
    [Language.en]: 'en',
    [Language.nl]: 'nl',
    [Language.it]: 'it',
    [Language.pt]: 'pt',
    [Language.fr]: 'fr',
    [Language.tr]: 'tr',
    [Language.es]: 'es',
    [Language.ar]: 'ar',
};