import React, { useEffect, useRef } from 'react';
import { IFinalizationSectionProps } from './IFinalizationSectionProps';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Grid, ImageList, ImageListItem, useMediaQuery, useTheme } from '@mui/material';
import { Button, TextField } from '@ui-components/ui-library';
import { Title } from '../../../components/form/title/Title';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import { ServiceExecutionFormModel } from '../../models/serviceExecutionModel';
import styles from './FinalizationSection.module.scss';
import { ServiceExecutionAttachmentSection } from '../../models/enums/serviceExecutionAttachmentSection';

export const FinalizationSection = (props: IFinalizationSectionProps): JSX.Element => {
    const { t } = useTranslation();
    const { values: serviceExecution, handleChange, handleBlur, setFieldValue } = useFormikContext() as FormikProps<ServiceExecutionFormModel>;

    const { readonly } = props;

    const signatureRef: any = useRef();

    const theme = useTheme();
    const matchDownLg = useMediaQuery(theme.breakpoints.down('md'));

    const handleSignatureEnd = () => {
        setFieldValue('serviceExecutionSigner.signature', signatureRef.current.toDataURL());
    };

    const clearSignature = () => {
        setFieldValue('serviceExecutionSigner.signature', undefined);
        signatureRef.current.clear();
    };

    useEffect(() => {
        if (signatureRef.current) {
            if (readonly) {
                signatureRef.current.off();
            } else {
                signatureRef.current.on();
            }
        }
    }, [readonly]);

    useEffect(() => {
        if (signatureRef.current) {
            signatureRef.current.fromDataURL(serviceExecution.serviceExecutionSigner.signature);
        }
    }, []);

    const signatureImages = serviceExecution.serviceExecutionAttachments
        .filter(seat => seat.serviceExecutionAttachmentSection === ServiceExecutionAttachmentSection.Signature);

    return (
        <>
            <Box mt={2}>
                <Title variant={'h4'} text={t('Finalization')} align='left' />

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id={'serviceExecutionSigner.firstName'}
                            name={'serviceExecutionSigner.firstName'}
                            label={t('Signer First Name')}
                            value={serviceExecution.serviceExecutionSigner.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete={'new-password'}
                            inputProps={{ maxLength: 50 }}
                            disabled={readonly}
                            required
                        />
                        <ErrorMessage name={'serviceExecutionSigner.firstName'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id={'serviceExecutionSigner.lastName'}
                            name={'serviceExecutionSigner.lastName'}
                            label={t('Signer Last Name')}
                            value={serviceExecution.serviceExecutionSigner.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete={'new-password'}
                            inputProps={{ maxLength: 50 }}
                            disabled={readonly}
                            required
                        />
                        <ErrorMessage name={'serviceExecutionSigner.lastName'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                    </Grid>
                </Grid>
            </Box>

            {
                !readonly &&
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} md={6}>
                        <SignatureCanvas
                            penColor={'#24353F'}
                            canvasProps={{ className: styles.signature_canvas }}
                            ref={signatureRef}
                            onEnd={handleSignatureEnd}
                            clearOnResize={false}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            id={'clear'}
                            key={'clear'}
                            variant={'primary'}
                            label={t('Clear signature')}
                            type={'button'}
                            onClick={clearSignature}
                            sx={{ maxWidth: '100px' }}
                        />
                    </Grid>
                </Grid>
            }

            {
                readonly &&
                <Box mt={2}>
                    {
                        <ImageList
                            cols={matchDownLg ? 1 : 2}>
                            {
                                signatureImages
                                    .map((attachment, index) => (
                                        <ImageListItem key={index}>
                                            <img src={attachment.fileUrl} alt={attachment.fileName} className={styles.signature_image} />
                                        </ImageListItem>
                                    ))
                            }
                        </ImageList>
                    }
                </Box>

            }

        </>
    );
};
