import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IEquipmentCustomerInfoProps } from './IEquipmentCustomerInfoProps';
import { EndCustomerOptionModel } from '../../models/EndCustomerOptionModel';
import { equipmentCustomerService } from '../../services/EquipmentCustomerService';
import { EquipmentFormModel } from '../../models/equipment';
import { Grid } from '@mui/material';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

const DropdownSxObject = {
    width: '100%',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            '& legend': {
                marginBottom: 0, width: 0
            }
        }
    },
    '& .MuiFormControl-root': { marginTop: '4px'}
};

export const EquipmentCustomerInfo = (props: IEquipmentCustomerInfoProps): JSX.Element => {
    const { readonly } = props;

    const { t } = useTranslation();

    const { values: equipment, handleBlur, setFieldValue } = useFormikContext() as FormikProps<EquipmentFormModel>;

    const [customerOptions, setCustomerOptions] = useState<EndCustomerOptionModel[]>([]);
    const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(true);

    const getCustomerOptions = async () => {
        const customerOptionsResponse = await equipmentCustomerService.GetCustomersForEquipment(equipment.distributorOrganizationId!);

        if (customerOptionsResponse.isSuccess) {
            setCustomerOptions(customerOptionsResponse.result!);
        }
    };

    useEffect(() => {
        getCustomerOptions().then(() => {
            setIsLoadingOptions(false);
        });
    }, []);

    //#region Dropdown and Radio options and values
    
    const customerDropdownOptions = customerOptions.map(o => { 
        return { 
            id: o.id, 
            label: o.isActive ? o.customerName : o.customerName + ' [' + t('inactive') + ']',
            value: o.id 
        }; 
    });
    const customerValueOption = customerDropdownOptions.find(o => o.id === equipment.customerId);
    
    //#endregion

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'customerId'}
                        label={t('Customer')}
                        name={'customerId'}
                        variant={'searchable'}    
                        options={customerDropdownOptions ? orderBy(customerDropdownOptions, o => o.label) : []}
                        value={customerValueOption ?? {id: '', label: '', value: ''}}
                        onChange={(e, item) => setFieldValue('customerId', item ? (item as DropdownOption).value : undefined)}
                        onBlur={handleBlur}
                        optionListStyles={{ fontFamily: 'system-ui', minWidth: '433px !important', width: 'fit-content !important', '& li': { minHeight: '50px !important', height: 'max-content !important', whiteSpace: 'normal' } }}
                        sx={DropdownSxObject}
                        disabled={readonly}
                        greyedOutLabelOnDisabled
                        loading={isLoadingOptions}
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'customerId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};