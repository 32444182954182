import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers-pro';
import { Box, InputAdornment } from '@mui/material';
import { DatePickerTextField } from '@ui-components/ui-library';
import { useTheme } from '@mui/material/styles';
import { CalendarPickerView } from '@mui/x-date-pickers';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { DatePickerActionBar } from '../datePickerActionBar/DatePickerActionBar';
import { DatePickerLocalizationProvider } from '../../datePickerLocalizationProvider/DatePickerLocalizationProvider';
import { DatePickerSxObject } from '../../../shared/utils/sxStyleObjects';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';

interface DatePickerProps {
  id: string;
  title?: string;
  value?: Date;
  onChange: (value: Date | null) => void;
  inputFormat: string;
  disabled?: boolean;
  disableFuture?: boolean;
  views?: CalendarPickerView[];
  actions?: PickersActionBarAction[];
  minDate?: Date;
  maxDate?: Date;
}

export const DatePicker = (props: DatePickerProps): JSX.Element => {
  const theme = useTheme();

  const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
        <DatePickerLocalizationProvider>
          <MuiDatePicker
            value={props.value || null}
            onChange={props.onChange}
            inputFormat={props.inputFormat}
            disableFuture={props.disableFuture}
            showDaysOutsideCurrentMonth
            disabled={props.disabled}
            views={props.views}
            minDate={props.minDate}
            maxDate={props.maxDate}
            components={{
              ActionBar: (actionBarProps) => <DatePickerActionBar {...actionBarProps} minDate={props.minDate} maxDate={props.maxDate}  />,
            }}
            componentsProps={{
              actionBar: {
                actions: props.actions,
              },
              
            }}
            PopperProps={{
              sx: {
                ...DatePickerSxObject,
                '& .MuiPickersArrowSwitcher-root .MuiButtonBase-root': { transform: shouldUseRtl ? 'rotate(180deg)' : '' }
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Calendar />
                </InputAdornment>
              ),
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => {
              inputProps!.id = props.id;
              inputProps!.placeholder = '';
              inputProps!.readOnly = false;
              inputProps!.onKeyDown = onKeyDown;
              inputProps!.sx = { 
                ...inputProps!.sx,
                '&.MuiInputBase-input': { textAlign: shouldUseRtl ? 'end !important' : 'start' }
              };

              return (
                <DatePickerTextField
                  inputRef={inputRef}
                  inputProps={inputProps}
                  InputProps={InputProps}
                  label={props.title}
                  labelSx={{
                    '&.MuiFormLabel-root.MuiInputLabel-root': {
                      fontSize: '18px !important',
                      lineHeight: '24px',
                      color: props.disabled
                        ? theme.palette.colors.neutral[200]
                        : theme.palette.colors.neutral[50],
                      top: '4px',
                    },
                  }}
                  disabled={props.disabled}
                />
              );
            }}
          />
        </DatePickerLocalizationProvider>
      </Box>
    </>
  );
};
