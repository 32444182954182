import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { showErrors } from '../../../stores/alert/alertSlice';
import { ServiceOrderViewModel } from '../../models/serviceOrderModel';
import { userCanViewServiceOrders, userLoaded } from '../../../stores/user/usersSlice';
import { serviceOrderService } from '../../services/ServiceOrderService';
import { ServiceOrdersOverview } from '../serviceOrdersOverview/ServiceOrdersOverview';
import { setIsLoading } from '../../../stores/layout/layoutSlice';

export const ServiceOrdersOverviewContainer = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
    const userCanAccessServiceOrdersView = useAppSelector(state => userCanViewServiceOrders(state));

    const [serviceOrders, setServiceOrders] = useState<ServiceOrderViewModel[]>([]);

    const getServiceOrders = async () => {
        dispatch(setIsLoading(true));
        const serviceOrdersResponse = await serviceOrderService.GetServiceOrders();
        if (serviceOrdersResponse.isSuccess) {
            setServiceOrders(serviceOrdersResponse.result!);
        } else {
            dispatch(showErrors(serviceOrdersResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (!userIsLoaded) {
            return;
        }

        if (userCanAccessServiceOrdersView) {
            getServiceOrders();
        } else {
            dispatch(setIsLoading(false));
        }
    }, [userIsLoaded, userCanAccessServiceOrdersView]);

    return (
        <>
            {
                userIsLoaded && userCanAccessServiceOrdersView &&
                <ServiceOrdersOverview
                    serviceOrders={serviceOrders}
                    getServiceOrders={getServiceOrders}
                />
            }
        </>
    );
};
