import React from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { TextField, Dropdown, Button, StyledSearchableDropdownProps, DropdownOption } from '@ui-components/ui-library';
import { IPersonOfInterestFormProps } from './IPersonOfInterestFormProps';
import { lengthIsLessThanTwoChars, validateEmail } from '../../../shared/utils/Utils';
import { StyledTitle } from '../../../components/form/title/Title';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const PersonOfInterestForm = (props: IPersonOfInterestFormProps): JSX.Element => {
	const { t } = useTranslation();
	const { personOfInterest, countries, onPersonOfInterestChange, onPersonOfInterestSubmit, handleBack } = props;

	const isPersonOfInterestValid = (): boolean => {
		return personOfInterest !== undefined &&
			!lengthIsLessThanTwoChars(personOfInterest.phoneNumber) &&
			!lengthIsLessThanTwoChars(personOfInterest.firstName) &&
			!lengthIsLessThanTwoChars(personOfInterest.lastName) &&
			personOfInterest.countryId > 0 &&
			personOfInterest.role !== undefined &&
			!lengthIsLessThanTwoChars(personOfInterest.role) &&
			validateEmail(personOfInterest.emailAddress) !== null;
	};

	const handleSubmit = async () => {
		if (onPersonOfInterestSubmit) {
			onPersonOfInterestSubmit();
			if (handleBack) {
				handleBack();
			}
		}
	};

	const roleOptions: { label: string, value: string }[] = [
		{
			label: t('Purchaser'),
			value: 'Purchaser'
		},
		{
			label: t('Technical Manager'),
			value: 'Technical Manager'
		},
		{
			label: t('Maintenance Manager'),
			value: 'Maintenance Manager'
		},
		{
			label: t('Other'),
			value: 'Other'
		}
	];

	const roleDropdownOptions = roleOptions?.map(b => {
		return { id: b.value, label: b.label, value: b.value };
	});
	const selectedRole = roleDropdownOptions.find(obj => obj.id === personOfInterest?.role);

	const phonePrefixOptions = orderBy(countries, c => c.phonePrefix)?.map(b => {
		return { id: b.id, label: `${b.phonePrefix} (${b.description})`, value: b.id };
	});
	const selectedPhonePrefix = phonePrefixOptions?.find(obj => obj.id === personOfInterest?.countryId);

	return (
		<>
			{
				personOfInterest &&
				<Box>
					<Box pt={5} display={'flex'} justifyContent={'start'}>
						<StyledTitle variant='h4'>{t('Person of Interest')}</StyledTitle>
					</Box>
					<Box>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={t('First Name')}
									value={personOfInterest.firstName}
									onChange={(e) => onPersonOfInterestChange('firstName', e.target.value)}
									inputProps={{ maxLength: 50 }}
									error={lengthIsLessThanTwoChars(personOfInterest.firstName)}
									required={true}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={t('Last Name')}
									value={personOfInterest.lastName}
									onChange={(e) => onPersonOfInterestChange('lastName', e.target.value)}
									inputProps={{ maxLength: 50 }}
									error={lengthIsLessThanTwoChars(personOfInterest.lastName)}
									required={true}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={t('Email Address')}
									value={personOfInterest.emailAddress}
									onChange={(e) => onPersonOfInterestChange('emailAddress', e.target.value)}
									inputProps={{ maxLength: 50 }}
									error={!validateEmail(personOfInterest.emailAddress)}
									required={true}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Dropdown<StyledSearchableDropdownProps>
									label={t('Role')}
									value={selectedRole ?? { id: '', label: '', value: '' }}
									options={roleDropdownOptions}
									onChange={(e, item) => onPersonOfInterestChange('role', item ? (item as DropdownOption).value : 0)}
									error={lengthIsLessThanTwoChars(personOfInterest.role)}
									required={true}
									variant='searchable'
									usePopper
									noOptionsText={t(NO_OPTIONS_TEXT)}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<Dropdown<StyledSearchableDropdownProps>
									label={t('Phone Number Prefix')}
									options={phonePrefixOptions ?? []}
									value={selectedPhonePrefix ?? { id: '', label: '', value: '' }}
									onChange={(e, item) => onPersonOfInterestChange('countryId', item ? (item as DropdownOption).value : 0)}
									required={true}
									error={personOfInterest.countryId < 1}
									variant='searchable'
									usePopper
									noOptionsText={t(NO_OPTIONS_TEXT)}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<TextField
									label={t('Phone Number')}
									value={personOfInterest.phoneNumber}
									onChange={(e) => onPersonOfInterestChange('phoneNumber', e.target.value)}
									inputProps={{ maxLength: 50 }}
									error={lengthIsLessThanTwoChars(personOfInterest.phoneNumber)}
									required={true}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box mt={5} display='flex' justifyContent='flex-end' gap={2}>
						{
							onPersonOfInterestSubmit &&
							<Button
								id='add-poi'
								label={t('OK')}
								startIcon={'check'}
								variant='primary'
								onClick={handleSubmit}
								disabled={!isPersonOfInterestValid()}
								type={'button'}
							/>
						}						

						{
							handleBack &&
							<Button
								id='cancel-poi'
								label={t('Cancel')}
								startIcon={'cancel'}
								variant='secondary'
								onClick={handleBack}
								type={'button'}
							/>
						}

					</Box>
				</Box>
			}
		</>
	);
};
