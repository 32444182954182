import { ActionResult, api } from '../../shared/utils/api';
import { PersonOfInterestCreateModel, PersonOfInterestUpdateModel } from '../models/personOfInterestModel';

class PeopleOfInterestService {

    async AddPersonOfInterest(createModel: PersonOfInterestCreateModel): Promise<ActionResult<number>> {
        const url = '/peopleOfInterest/AddPersonOfInterest';

        return await api.post<number>(url, JSON.stringify(createModel));
    }

    async DeletePersonOfInterest(personOfInterestId: number): Promise<ActionResult<void>> {
        const url = `/peopleOfInterest/DeletePersonOfInterest/${personOfInterestId}`;

        return await api.delete(url);
    }

    async SetDefaultPersonOfInterest(personOfInterestId: number): Promise<ActionResult<void>> {
        const url = `/peopleOfInterest/SetDefaultPersonOfInterest/${personOfInterestId}`;

        return await api.put(url, {});
    }

    async UpdatePersonOfInterest(updateModel: PersonOfInterestUpdateModel): Promise<ActionResult<void>> {
        const url = '/peopleOfInterest/UpdatePersonOfInterest';

        return await api.put<void>(url, JSON.stringify(updateModel));
    }
}

export const peopleOfInterestService = new PeopleOfInterestService();