import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, IconButton } from '@mui/material';
import { GridEnrichedColDef, GridRenderCellParams, GridRowParams, useGridApiRef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { Button, TextField, Icon } from '@ui-components/ui-library';
import { getDefaultPlannedPart, PlannedPartModel } from '../../models/plannedPartModel';
import { IServiceOrderPartsProps } from './IServiceOrderPartsProps';
import { StyledTitle } from '../../../components/form/title/Title';
import { DataGridForm } from '../../../components/datagrid/DataGridForm';
import { ConfirmationDialog } from '../confirmationDialog/ConfirmationDialog';
import { useFormikContext } from 'formik';
import { ServiceOrderFormModel } from '../../models/serviceOrderModel';

export const ServiceOrderParts = (props: IServiceOrderPartsProps): JSX.Element => {
	const { readonly, markPartAsUpdated, markPartAsCreated, markPartAsDeleted } = props;
	const { t } = useTranslation();
	const apiRef = useGridApiRef();

	const [deletePartDialogIsVisible, setDeletePartDialogIsVisible] = useState<boolean>(false);
	const [partToDelete, setPartToDelete] = useState<PlannedPartModel | undefined>(undefined);

	const { values: serviceOrder, setFieldValue } = useFormikContext<ServiceOrderFormModel>();

	const renderHeader = () => {
		return (
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
				<StyledTitle variant={'h4'}>{t('Parts to be used')}</StyledTitle>
				{
					!readonly &&
					<Button
						id='add'
						label={t('Add Part')}
						startIcon={'add'}
						aria-label={t('Add')}
						onClick={onPartAdd}
						type={'button'}
					/>
				}
			</Box>
		);
	};

	const nameEditor = (params: GridRenderCellParams) => {
		const index = serviceOrder.plannedParts.map(pp => pp.id).indexOf(params.row.id);

		return (
			<TextField
				key={params.row.id}
				value={params.row.name}
				onChange={e => handlePartChange(params.row.id, 'name', e.target.value)}
				inputProps={{ maxLength: 100 }}
				// When a key down event happens for one of the special navigation characters (arrows up/down/left/right or space), 
				// MUI calls event.preventDefault() which prevents the key from having its default effect within the input.
				// (space cannot be inserted in the text and user cannot move left/right in the text) 
				// Using event.stopPropagation() prevents that 
				onKeyDown={(e) => e.stopPropagation()}
				error={index >= 0 && !serviceOrder.plannedParts[index].name.trim()}
			/>
		);
	};

	const numberEditor = (params: GridRenderCellParams) => {
		const index = serviceOrder.plannedParts.map(pp => pp.id).indexOf(params.row.id);

		return (
			<TextField
				value={params.row.number}
				onChange={e => handlePartChange(params.row.id, 'number', e.target.value)}
				inputProps={{ maxLength: 50 }}
				// When a key down event happens for one of the special navigation characters (arrows up/down/left/right or space), 
				// MUI calls event.preventDefault() which prevents the key from having its default effect within the input.
				// (space cannot be inserted in the text and user cannot move left/right in the text) 
				// Using event.stopPropagation() prevents that 
				onKeyDown={(e) => e.stopPropagation()}
				error={index >= 0 && !serviceOrder.plannedParts[index].number.trim()}
			/>
		);
	};

	const quantityEditor = (params: GridRenderCellParams) => {
		const index = serviceOrder.plannedParts.map(pp => pp.id).indexOf(params.row.id);

		return (
			<TextField
				value={params.row.quantity}
				onChange={e => handlePartChange(params.row.id, 'quantity', (e.target.value ? e.target.value : ''))}
				inputProps={{ maxLength: 10 }}
				inputRestriction='decimal'
				numericRestrictionAllowNegative={false}
				// When a key down event happens for one of the special navigation characters (arrows up/down/left/right or space), 
				// MUI calls event.preventDefault() which prevents the key from having its default effect within the input.
				// (space cannot be inserted in the text and user cannot move left/right in the text) 
				// Using event.stopPropagation() prevents that 
				onKeyDown={(e) => e.stopPropagation()}
				error={index >= 0 && (!serviceOrder.plannedParts[index].quantity || serviceOrder.plannedParts[index].quantity <= 0)}
			/>
		);
	};

	const onPartAdd = (): void => {
		let partToAdd = getDefaultPlannedPart();

		let _plannedPartsUpdated = [...serviceOrder.plannedParts];
		_plannedPartsUpdated.push(partToAdd);

		setFieldValue('plannedParts', _plannedPartsUpdated);

		markPartAsCreated(partToAdd.id);
	};

	const handlePartChange = (id: number, propName: keyof PlannedPartModel, value: any) => {
		let _plannedPartsUpdated = [...serviceOrder.plannedParts];

		let _partToUpdate = _plannedPartsUpdated.find(pp => pp.id === id);

		if (_partToUpdate) {
			_partToUpdate = { ..._partToUpdate, [propName]: value };

			const partIndex = _plannedPartsUpdated.findIndex(p => p.id === id);

			_plannedPartsUpdated = [
				..._plannedPartsUpdated.slice(0, partIndex),
				_partToUpdate,
				..._plannedPartsUpdated.slice(partIndex + 1),
			];
		}

		markPartAsUpdated(_plannedPartsUpdated);

		setFieldValue('plannedParts', _plannedPartsUpdated);
	};

	const showDeletePartDialog = (part: PlannedPartModel) => {
		setPartToDelete(part);
		setDeletePartDialogIsVisible(true);
	};

	const hideDeletePartDialog = () => {
		setPartToDelete(undefined);
		setDeletePartDialogIsVisible(false);
	};

	const onPartDelete = () => {
		if (partToDelete) {
			let _partsUpdated = [...serviceOrder.plannedParts];

			const _partUpdated = _partsUpdated.find(p => p.id === partToDelete.id);

			if (_partUpdated) {
				markPartAsDeleted(_partUpdated);
			}

			_partsUpdated = _partsUpdated.filter(p => p.id !== partToDelete.id);

			setFieldValue('plannedParts', _partsUpdated);

			hideDeletePartDialog();
		}
	};

	let columns: GridEnrichedColDef[] =
		!readonly ?
			[
				{ field: 'name', headerName: t('Name'), renderCell: nameEditor, disableExport: true, sortable: false, flex: 2 },
				{ field: 'number', headerName: t('Number'), renderCell: numberEditor, disableExport: true, sortable: false, flex: 2 },
				{ field: 'quantity', headerName: t('Quantity'), renderCell: quantityEditor, disableExport: true, sortable: false, flex: 2 },
				{
					field: 'actions',
					type: 'actions',
					headerName: t('Actions'),
					flex: 1,
					getActions: (params: GridRowParams<PlannedPartModel>) => {
						let actions = [
							<Tooltip id="delete" key='delete' title={t('Delete')}>
								<IconButton
									aria-label="delete"
									onClick={() => showDeletePartDialog(params.row)}
								>
									<Icon type='delete' />
								</IconButton>
							</Tooltip>
						];

						return actions;
					}
				}
			] :
			[
				{ field: 'name', headerName: t('Name'), disableExport: true, sortable: false, flex: 2 },
				{ field: 'number', headerName: t('Number'), disableExport: true, sortable: false, flex: 2 },
				{ field: 'quantity', headerName: t('Quantity'), disableExport: true, sortable: false, flex: 2 },
			];

	return (
		<form>
			<Box pt={5}>
				{renderHeader()}
				<DataGridForm
					rows={serviceOrder.plannedParts}
					columns={columns}
					apiRef={apiRef}
					getRowId={(row: GridValidRowModel) => row.id}
					noRowsMessage={t('No parts')}
				/>
				{
					deletePartDialogIsVisible && partToDelete &&
					<ConfirmationDialog
						open={deletePartDialogIsVisible}
						message={`${t('Are you sure you want to delete part {{partNumber}}?', { partNumber: partToDelete.number })}`}
						onConfirm={onPartDelete}
						onClose={hideDeletePartDialog}
					/>
				}
			</Box>
		</form>
	);
};
