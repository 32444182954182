import { ContractType } from '../../shared/enums/ContractType';
import { EquipmentDetail, getDefaultEquipmentDetail } from './equipmentDetail';
import moment from 'moment-timezone';

export type EquipmentViewModel = {
    id: number;
    serialNumber: string;
    model: string;
    equipmentCategory: number;
    brand: string;
    customerName: string | undefined;
    country: string | undefined;
    customerCenter: string | undefined;
    distributorOrganization: string | undefined;
}

export type EquipmentFormModel = {
    id: number;
    serialNumber: string;
    modelId: number | undefined;
    competitorModel: string | undefined;
    equipmentCategory: number;
    brandId: number | undefined;
    textBrand: string;
    description: string;
    equipmentTypeId: number;
    size: number | undefined;
    estimatedProductionDate: Date | undefined;
    startupDate: Date | undefined;
    inverterDrive: boolean | undefined;
    installedBaseCode: string;
    createdOn: Date;
    lastModifiedOn: Date | undefined;
    distributorOrganizationId: number | undefined;
    customerId: number | undefined;
    equipmentDetail: EquipmentDetail;
    typeOfGas: string | undefined;
    flow: number | undefined;
    contractType: number | undefined;
    otherContractType: string | undefined;
    contractStartDate: Date | undefined;
    contractExpirationDate: Date | undefined;
}

export const getDefaultEquipmentModel = (): EquipmentFormModel => {
    return {
        id: 0,
        serialNumber: '',
        modelId: undefined,
        competitorModel: '',
        equipmentCategory: 0,
        brandId: undefined,
        textBrand: '',
        description: '',
        equipmentTypeId: 0,
        size: undefined,
        estimatedProductionDate: undefined,
        startupDate: undefined,
        inverterDrive: false,
        installedBaseCode: '',
        createdOn: moment().toDate(),
        lastModifiedOn: undefined,
        distributorOrganizationId: 0,
        customerId: undefined,
        equipmentDetail: getDefaultEquipmentDetail(),
        typeOfGas: undefined,
        flow: undefined,
        contractType: ContractType.None,
        otherContractType: undefined,
        contractStartDate: undefined,
        contractExpirationDate: undefined,
    };
};

export type EquipmentCreateModel = {
    serialNumber: string;
    modelId: number | undefined;
    competitorModel: string | undefined;
    equipmentCategory: number;
    brandId: number | undefined;
    textBrand: string;
    description: string;
    equipmentTypeId: number;
    size: number | undefined;
    estimatedProductionDate: Date | undefined;
    startupDate: Date | undefined;
    inverterDrive: boolean | undefined;
    distributorOrganizationId: number | undefined;
    customerId: number | undefined;
    equipmentStatusId: number | undefined;
    lastMaintenanceDate: string | undefined;
    estimatedWorkingHoursPerYear: number | undefined;
    isConnected: boolean;
    typeOfGas: string | undefined;
    flow: number | undefined;
    contractType: ContractType | undefined;
    otherContractType: string | undefined;
    contractStartDate: Date | undefined;
    contractExpirationDate: Date | undefined;
}

export type EquipmentUpdateModel = {
    id: number;
    serialNumber: string;
    modelId: number | undefined;
    competitorModel: string | undefined;
    brandId: number | undefined;
    textBrand: string | undefined;
    description: string | undefined;
    equipmentTypeId: number;
    size: number | undefined;
    estimatedProductionDate: Date | undefined;
    startupDate: Date | undefined;
    inverterDrive: boolean | undefined;
    customerId: number | undefined;
    equipmentStatusId: number | undefined;
    lastMaintenanceDate: string | undefined;
    estimatedWorkingHoursPerYear: number | undefined;
    isConnected: boolean | undefined;
    typeOfGas: string | undefined;
    flow: number | undefined;
    contractType: ContractType | undefined;
    otherContractType: string | undefined;
    contractStartDate: Date | undefined;
    contractExpirationDate: Date | undefined;
}

export const mapEquipmentToCreateModel = (equipment: EquipmentFormModel): EquipmentCreateModel => {
    return {
        serialNumber: equipment.serialNumber,
        modelId: equipment.modelId,
        competitorModel: equipment.competitorModel,
        brandId: equipment.brandId,
        textBrand: equipment.textBrand,
        description: equipment.description,
        equipmentTypeId: equipment.equipmentTypeId,
        equipmentCategory: equipment.equipmentCategory,
        size: equipment.size,
        estimatedProductionDate: equipment.estimatedProductionDate,
        startupDate: equipment.startupDate,
        inverterDrive: equipment.inverterDrive,
        distributorOrganizationId: equipment.distributorOrganizationId,
        customerId: equipment.customerId,
        equipmentStatusId: equipment.equipmentDetail.equipmentStatusId,
        lastMaintenanceDate: equipment.equipmentDetail.lastMaintenanceDate ? moment(equipment.equipmentDetail.lastMaintenanceDate).toISOString() : undefined,
        estimatedWorkingHoursPerYear: equipment.equipmentDetail.estimatedWorkingHoursPerYear,
        isConnected: equipment.equipmentDetail.connectivityDetail.isConnected,
        typeOfGas: equipment.typeOfGas,
        flow: equipment.flow,
        contractType: equipment.contractType,
        otherContractType: equipment.otherContractType,
        contractStartDate: equipment.contractStartDate,
        contractExpirationDate: equipment.contractExpirationDate,
    };
};

export const mapEquipmentToUpdateModel = (equipment: EquipmentFormModel): EquipmentUpdateModel => {
    return {
        id: equipment.id,
        serialNumber: equipment.serialNumber,
        modelId: equipment?.modelId,
        competitorModel: equipment.competitorModel,
        brandId: equipment.brandId,
        textBrand: equipment.textBrand,
        description: equipment.description,
        equipmentTypeId: equipment.equipmentTypeId,
        size: equipment.size,
        estimatedProductionDate: equipment?.estimatedProductionDate,
        startupDate: equipment.startupDate,
        inverterDrive: equipment.inverterDrive,
        customerId: equipment.customerId,
        equipmentStatusId: equipment.equipmentDetail.equipmentStatusId,
        lastMaintenanceDate: equipment.equipmentDetail.lastMaintenanceDate ? moment(equipment.equipmentDetail.lastMaintenanceDate).toISOString() : undefined,
        estimatedWorkingHoursPerYear: equipment.equipmentDetail.estimatedWorkingHoursPerYear,
        isConnected: equipment?.equipmentDetail?.connectivityDetail?.isConnected,
        typeOfGas: equipment.typeOfGas,
        flow: equipment.flow,
        contractType: equipment.contractType,
        otherContractType: equipment.otherContractType,
        contractStartDate: equipment.contractStartDate,
        contractExpirationDate: equipment.contractExpirationDate,
    };
};