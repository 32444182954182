export type OnboardedDistributorsCountByMonthViewModel = {
    January: number;
    February: number;
    March: number;
    April: number;
    May: number;
    June: number;
    July: number;
    August: number;
    September: number;
    October: number;
    November: number;
    December: number;
};

export const getDefaultOnboardedDistributorsCountByMonth = (): OnboardedDistributorsCountByMonthViewModel => {
    return {
        January: 0,
        February: 0,
        March: 0,
        April: 0,
        May: 0, 
        June: 0,
        July: 0,
        August: 0,
        September: 0,
        October: 0,
        November: 0,
        December: 0,
    };
};
