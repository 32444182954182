import React from 'react';
import { styled } from '@mui/material/styles';
import { Alert, AlertColor, AlertProps, AlertTitle, Snackbar, Portal } from '@mui/material';
import { useState } from 'react';

const StyledAlert = styled(Alert)<AlertProps>(({ theme }) => ({
	color: theme.palette.colors.neutral[50],
	backgroundColor: theme.palette.primary.main,
	minWidth: '400px',
	maxWidth: '600px',
	height: 'auto',
	marginBottom: '1rem',

	'&.MuiAlert-filledError': {
		backgroundColor: theme.palette.error.main,
	},

	'&.MuiAlert-filledInfo': {
		backgroundColor: theme.palette.background.paper,
	},

})) as typeof Alert;

export const useSnackbar = () => {
	const [isVisible, setVisible] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');
	const [title, setTitle] = useState<string>('');
	const [variant, setVariant] = useState<AlertColor>('success');

	const showMessage = () => setVisible(true);
	const hideMessage = () => setVisible(false);

	const getSnackbarMessage = (variant: AlertColor, title: string, message: string,) => {
		setTimeout(() => {
			setVariant(variant);
			setTitle(title);
			setMessage(message);
			showMessage();
		}, 300);
	};

	const SnackbarMessage = () => (
		<Portal>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={isVisible}
				autoHideDuration={variant !== 'error' ? 3000 : null}
				onClose={hideMessage}
				ClickAwayListenerProps={{ onClickAway: () => null }}
			>
				<StyledAlert onClose={hideMessage} variant='filled' color={variant}>
					{title &&
						<AlertTitle sx={{ marginBottom: 0 }}>{title}</AlertTitle>
					}
					{message}
				</StyledAlert>
			</Snackbar>
		</Portal>
	);

	return [SnackbarMessage, isVisible, getSnackbarMessage] as const;
};
