
export type UserAdditionalInfoFormModel = {
    id: number;
    phoneNumber: string;
    city: string;
    countryId: number;
    userId: number;
}

export const getDefaultUserAdditionalInfo = (): UserAdditionalInfoFormModel => {
    return {
        id: 0,
        phoneNumber: '',
        city: '',
        countryId: 0,
        userId: 0,
    };
};