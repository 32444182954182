import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { TextField } from '@ui-components/ui-library';
import { StyledTitle } from '../../../components/form/title/Title';

export const CustomerCenterDetail = (): JSX.Element => {
    const { t } = useTranslation();
    const { values: organization, handleChange, handleBlur } = useFormikContext() as any;

    return (
        <>
            {
                organization &&
                <>
                    <Box pt={5} display={'flex'} justifyContent={'space-between'}>
                        <StyledTitle variant='h4'>{t('Company details')}</StyledTitle>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('Fam Code')}
                                id={'customerCenterDetail.famCode'}
                                name={'customerCenterDetail.famCode'}
                                value={organization.customerCenterDetail?.famCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: 10 }}
                                required
                            />
                            <ErrorMessage name={'customerCenterDetail.famCode'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
};
