
export type ServiceExecutionCustomerDetailModel = {
    name: string;
    personOfInterestFirstName: string;
    personOfInterestLastName: string;
    personOfInterestPhoneNumber: string;
    personOfInterestPhoneNumberPrefix: string;
    country: string;
    city: string;
    postalCode: string;
    street: string;
}

export const getDefaultServiceExecutionCustomerDetail = (): ServiceExecutionCustomerDetailModel => {
    return {
        name: '',
        personOfInterestFirstName: '',
        personOfInterestLastName: '',
        personOfInterestPhoneNumber: '',
        personOfInterestPhoneNumberPrefix: '',
        country: '',
        city: '',
        postalCode: '',
        street: '',
    };
};
