import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { getGridDateOperators } from '@mui/x-data-grid';
import { FilterDatePicker } from '../../../components/filterDatePicker/FilterDatePicker';
import { Box, Grid } from '@mui/material';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';
import { Button, Dropdown, DropdownOption, Icon, SearchBar, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { GridEnrichedColDef, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { DropdownSxObject, StyledGridCellContent } from '../../../shared/utils/sxStyleObjects';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { NotificationCreateModel } from '../../models/notificationCreateModel';
import { notificationService } from '../../services/NotificationService';
import { EmailDialog } from '../../../components/emailDialog/EmailDialog';
import { ServiceExecutionForm } from '../serviceExecutionForm/ServiceExecutionForm';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { ServiceExecutionStartupOverviewModel } from '../../models/serviceExecutionOverviewModel';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { serviceExecutionService } from '../../services/ServiceExecutionService';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { debounce } from 'lodash';
import { ServiceExecutionExportFilterModel, getDefaultServiceExecutionExportFilterModel } from '../../models/serviceExecutionExportFilterModel';
import { convertStringToByteArray } from '../../../shared/utils/Utils';
import { ExportFileNameDateFormat, NO_OPTIONS_TEXT, ServerDateFormat } from '../../../shared/utils/constants';
import saveAs from 'file-saver';
import { languages } from '../../../i18n/i18nConstants';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { i18n } from '../../../i18n/config';

export const StartupServiceExecutionOverview = (): JSX.Element => {
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [serviceExecutions, setServiceExecutions] = useState<ServiceExecutionStartupOverviewModel[]>([]);
    const [serviceExecutionsFiltered, setServiceExecutionsFiltered] = useState<ServiceExecutionStartupOverviewModel[]>([]);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [clearGridFilters, setClearGridFilters] = useState<boolean | undefined>(undefined);

    const [selectedServiceExecution, setSelectedServiceExecution] = useState<ServiceExecutionStartupOverviewModel | undefined>(undefined);
    const [emailDialogIsVisible, setEmailDialogIsVisible] = useState<boolean>(false);

    const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

    // export as Excel
    const [exportFilterModalisOpen, setExportFilterModalIsOpen] = useState<boolean>(false);
    const [exportFilters, setExportFilters] = useState<ServiceExecutionExportFilterModel>(getDefaultServiceExecutionExportFilterModel(languages.en));

    const exportButtonIsDisabled = !exportFilters['completedDateFrom'] || !exportFilters['completedDateTo'] || !exportFilters['languageCode'];

    const languageChoices = Object.keys(languages).map(k => (
        {
            id: languages[k as keyof typeof languages],
            label: k.toUpperCase(),
            value: languages[k as keyof typeof languages]
        }
    ));

    const languageValueOption = languageChoices.find(o => o.id === exportFilters.languageCode);

    const handleExportFilterChange = (propName: keyof ServiceExecutionExportFilterModel, value: any): void => {
        setExportFilters({ ...exportFilters, [propName]: value });
    };

    const exportServiceExecutionsAsExcel = async () => {
        dispatch(setIsLoading(true));
        const exportResponse = await serviceExecutionService.ExportStartupServiceExecutionsAsExcel(exportFilters);
        if (exportResponse.isSuccess) {
            const bytes: Uint8Array = convertStringToByteArray(exportResponse.result!.content);
            let objectUrl = window.URL.createObjectURL(new Blob([bytes]));

            const formatedDateFrom = moment(exportFilters.completedDateFrom).format(ExportFileNameDateFormat);
            const formatedDateTo = moment(exportFilters.completedDateTo).format(ExportFileNameDateFormat);
            const exportFileName = `MSM_Startup_Service_Executions_${exportFilters.languageCode.toUpperCase()}_${formatedDateFrom}_${formatedDateTo}.xlsx`;

            saveAs(objectUrl, exportFileName);
            setExportFilterModalIsOpen(false);
        } else {
            dispatch(showErrors(exportResponse?.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };
    // export as excel end

    const getStartupServiceExecutions = async () => {
        dispatch(setIsLoading(true));
        const serviceOrdersResponse = await serviceExecutionService.GetStartupServiceExecutions();
        if (serviceOrdersResponse.isSuccess) {
            const orderedServiceExecutions = orderBy(serviceOrdersResponse.result, vr => vr.completedDate, 'desc');
            setServiceExecutions(orderedServiceExecutions);
            setServiceExecutionsFiltered(orderedServiceExecutions);
        } else {
            dispatch(showErrors(serviceOrdersResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const onGlobalFilterChange = (value: string) => {
        setGlobalFilterValue(value);
    };

    const debounceUpdateState = useCallback(
        debounce((values: ServiceExecutionStartupOverviewModel[]) => {
            setServiceExecutionsFiltered(values);
        }, 100), []
    );

    const initFilters = () => {
        setGlobalFilterValue('');
        setServiceExecutionsFiltered([...serviceExecutions]);
    };

    const clearFilters = () => {
        initFilters();
        setClearGridFilters(!clearGridFilters);
    };

    const sendServiceExecutionEmail = async (email: string, languageCode: string) => {
        if (!selectedServiceExecution) {
            dispatch(showErrors([t('Please select a record')]));
            return;
        }

        dispatch(setIsLoading(true));
        const notificationCreateModel: NotificationCreateModel = {
            serviceExecutionId: selectedServiceExecution.id,
            email: email,
            languageCode: languageCode,
        };

        const notificationCreateResponse = await notificationService.CreateServiceExecutionNotification(notificationCreateModel);

        if (notificationCreateResponse.isSuccess) {
            setEmailDialogIsVisible(false);
            dispatch(showSuccess(t(SUCCESS_MESSAGE)));
        } else {
            dispatch(showErrors(notificationCreateResponse.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const openEmailDialog = (serviceExecution: ServiceExecutionStartupOverviewModel) => {
        setSelectedServiceExecution(serviceExecution);
        setEmailDialogIsVisible(true);
    };

    const closeEmailDialog = () => {
        setEmailDialogIsVisible(false);
        setSelectedServiceExecution(undefined);
    };

    const getDateBody = (value: Date) => {
        const formatedDate = moment(value).format(currentUser.preferences.dateFormat);

        return (
            <StyledGridCellContent>{formatedDate}</StyledGridCellContent>
        );
    };

    const handleView = (id: number) => {
        setSelectedId(id);
        setFormIsOpen(true);
    };

    const handleCloseFormDialog = () => {
        setSelectedId(undefined);
        setFormIsOpen(false);
    };

    const renderHeader = (): JSX.Element => {
        return (
            <Box display='flex' justifyContent='space-between' marginBottom={2}>
                <SearchBar
                    variant='default'
                    onChange={(value) => onGlobalFilterChange(value)}
                    value={globalFilterValue}
                    placeholder={t('Search')}
                />

                <Box display={'flex'} gap='5px'>
                    <Button
                        id='export-excel'
                        variant='primary'
                        label={t('Export as Excel')}
                        startIcon='file_download'
                        onClick={() => setExportFilterModalIsOpen(true)}
                    />

                    <Button
                        id='clear-filter'
                        variant='secondary'
                        label={t('Clear')}
                        startIcon='filter_list_off'
                        onClick={clearFilters}
                    />
                </Box>
            </Box>
        );
    };

    const getColumns = (): GridEnrichedColDef[] => {
        const columns: GridEnrichedColDef[] = [
            {
                field: 'completedDate',
                headerName: t('Completed on'),
                description: t('Completed on'),
                renderCell: (params: GridRenderCellParams<ServiceExecutionStartupOverviewModel>) => getDateBody(params.row.completedDate),
                type: 'date',
                sortable: true,
                filterable: true,
                pinnable: false,
                flex: 1.5,
                filterOperators: getGridDateOperators()
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? FilterDatePicker
                            : undefined,
                        InputComponentProps: { inputFormat: currentUser.preferences.dateFormat }
                    })),
            },
            { field: 'distributorName', headerName: t('Distributor organization'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('Distributor organization') },
            { field: 'customerName', headerName: t('Customer'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Customer') },
            { field: 'equipmentSerialNumber', headerName: t('Serial Number'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('Serial Number') },
            { field: 'equipmentModel', headerName: t('Model'), sortable: true, filterable: true, pinnable: false, flex: 1, description: t('Model') },
            {
                field: 'createdOn',
                headerName: t('Created on'),
                description: t('Created on'),
                renderCell: (params: GridRenderCellParams<ServiceExecutionStartupOverviewModel>) => getDateBody(params.row.createdOn),
                type: 'date',
                sortable: true,
                filterable: true,
                pinnable: false,
                flex: 1.5,
                filterOperators: getGridDateOperators()
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? FilterDatePicker
                            : undefined,
                        InputComponentProps: { inputFormat: currentUser.preferences.dateFormat }
                    })),
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                description: t('Actions'),
                flex: 1,
                getActions: (params: GridRowParams<ServiceExecutionStartupOverviewModel>) => {
                    return [
                        <DataGridActionOverview
                            key={'view'}
                            icon={<Icon type='visibility' />}
                            label={t('View')}
                            onClick={() => handleView(params.row.id)}
                            showInMenu
                        />,
                        <DataGridActionOverview
                            key={'email'}
                            icon={<Icon type='email' />}
                            label={t('Send as Email')}
                            onClick={() => openEmailDialog(params.row)}
                            showInMenu
                        />
                    ];
                },
            },
        ];

        return columns;
    };

    const memoizedColumns = useMemo(() => getColumns(), [i18n.language]);

    useEffect(() => {
        getStartupServiceExecutions();
    }, []);

    const debouncedFilterServiceExecutions = useCallback(
        debounce((value: string) => {
			if (serviceExecutions.length > 0) {
				let _serviceExecutionsFiltered = [...serviceExecutions];
				_serviceExecutionsFiltered = _serviceExecutionsFiltered.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value.toLowerCase())));
				setServiceExecutionsFiltered(_serviceExecutionsFiltered);
			}
        }, 500), [serviceExecutions]
    );

    useEffect(() => {
        return () => debouncedFilterServiceExecutions.cancel();
    }, [debouncedFilterServiceExecutions]);

    useEffect(() => {
        debouncedFilterServiceExecutions(globalFilterValue);
    }, [globalFilterValue]);

    return (
        <>

            <Box>
                {renderHeader()}
            </Box>
            <DataGridOverview
                rows={serviceExecutionsFiltered}
                columns={memoizedColumns}
                pagination
                clearFilters={clearGridFilters}
            />

            {formIsOpen &&
                <ServiceExecutionForm
                    formIsOpen={formIsOpen}
                    handleClose={handleCloseFormDialog}
                    serviceExecutionId={selectedId}
                    getServiceExecutions={getStartupServiceExecutions}
                />
            }

            {
                emailDialogIsVisible &&
                <EmailDialog
                    header={t('Send as Email')}
                    visible={emailDialogIsVisible}
                    onClose={closeEmailDialog}
                    onSubmit={sendServiceExecutionEmail}
                />
            }

            <FilterModal
                isOpen={exportFilterModalisOpen}
                disableApply={exportButtonIsDisabled}
                onClose={() => setExportFilterModalIsOpen(false)}
                onApply={exportServiceExecutionsAsExcel}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            id={'export_date_from'}
                            inputFormat={currentUser.preferences.dateFormat}
                            onChange={value => value ? handleExportFilterChange('completedDateFrom', moment(value).startOf('d').format(ServerDateFormat)) : undefined}
                            value={moment(exportFilters['completedDateFrom'])?.toDate()}
                            disableFuture
                            actions={['today']}
                            title={t('Completed Date From')}
                            maxDate={moment(exportFilters['completedDateTo'])?.toDate()}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DatePicker
                            id={'export_date_to'}
                            inputFormat={currentUser.preferences.dateFormat}
                            onChange={value => value ? handleExportFilterChange('completedDateTo', moment(value).endOf('d').format(ServerDateFormat)) : undefined}
                            value={moment(exportFilters['completedDateTo'])?.toDate()}
                            disableFuture
                            actions={['today']}
                            title={t('Completed Date To')}
                            minDate={moment(exportFilters['completedDateFrom'])?.toDate()}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Dropdown<StyledSearchableDropdownProps>
                            id={'language'}
                            label={t('Language')}
                            name={'export_language'}
                            variant={'searchable'}
                            options={languageChoices}
                            value={languageValueOption ?? { id: '', label: '', value: '' }}
                            onChange={(_, item) => handleExportFilterChange('languageCode', item ? (item as DropdownOption).value.toString() : undefined)}
                            optionListStyles={{ fontFamily: 'system-ui' }}
                            sx={DropdownSxObject}
                            usePopper
                            error={!languageValueOption?.id}
                            noOptionsText={t(NO_OPTIONS_TEXT)}
                        />
                    </Grid>
                </Grid>
            </FilterModal>
        </>
    );
};
