import React, { useEffect, useState } from 'react';
import { dashboardService } from '../../../services/DashboardService';
import { ChartSxObject } from '../../../shared/utils/sxStyleObjects';
import { CustomerCreationKpiFilterModel } from '../../models/CustomerCreationKpiFilterModel';
import { ChartsAxisContentProps, LineChart } from '@mui/x-charts';
import { CustomerCreationForYearRangeViewModel } from '../../../models/dashboard/CustomerCreationForYearRangeViewModel';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { CustomChartTooltipContent } from '../customChartTooltipContent/CustomChartTooltipContent';
import moment from 'moment-timezone';
import { showErrors } from '../../../stores/alert/alertSlice';

interface ICustomerCreationYearViewProps {
    appliedFilters: CustomerCreationKpiFilterModel;
}

export const CustomerCreationYearView = (props: ICustomerCreationYearViewProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const { appliedFilters } = props;
    const minYear = moment().year() - 9;
    const maxYear = moment().year();
    const yearsRange = Array.from({ length: 10 }, (_, i) => i + minYear);

    const [customersData, setCustomersData] = useState<CustomerCreationForYearRangeViewModel[]>([]);

    const getCustomersCountForYearRange = async (filters: any) => {
        dispatch(setIsLoading(true));

        // Currently we are always getting current year as an End year
        const customersForYearRangeResponse = await dashboardService.GetCustomersCreationCountForYearRange({ ...filters, year: maxYear });

        if (customersForYearRangeResponse.isSuccess) {
            setCustomersData(customersForYearRangeResponse.result!);
        } else {
            dispatch(showErrors(customersForYearRangeResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getCustomersCountForYearRange(appliedFilters);
    }, [appliedFilters]);

    const distributorNames: string[] = [...new Set(customersData.map(cd => cd.distributor))];

    return (
        <>
            <LineChart
                xAxis={[{
                    data: yearsRange,
                    valueFormatter: (val) => val.toString(),
                    tickLabelInterval: () => true,
                    max: maxYear,
                    min: minYear
                }]}
                series={distributorNames.map(d => (
                    {
                        data: yearsRange.map(year => {
                            const result = customersData.find(cc => cc.distributor === d && cc.year === year);
                            const value = result ? result.count : 0;
                            return value;
                        }),
                        label: d
                    }
                ))}
                slotProps={{ legend: { hidden: true } }}
                sx={ChartSxObject}
                slots={{
                    axisContent: (props: ChartsAxisContentProps) =>
                        <CustomChartTooltipContent
                            axisValue={props.axisValue}
                            series={props.series}
                            dataIndex={props.dataIndex}
                            seriesLabels={distributorNames}
                        />
                }}
            />
        </>
    );
};