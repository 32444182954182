import { CustomerCreationKpiFilterModel as CustomerCreationKpiFilterModel } from '../dashboard/models/CustomerCreationKpiFilterModel';
import { EquipmentTrafficLightFilterModel } from '../dashboard/models/EquipmentTrafficLightFilterModel';
import { InstalledBaseFilterModel } from '../dashboard/models/InstalledBaseFilterModel';
import { LeadsFilterModel } from '../dashboard/models/LeadsFilterModel';
import { OnboardedDistributorsFilterModel } from '../dashboard/models/OnboardedDistributorsFilterModel';
import { OneToOneFilterModel } from '../dashboard/models/OneToOneFilterModel';
import { ServiceOrderCountFilterModel } from '../dashboard/models/ServiceOrderCountFilterModel';
import { CustomerCreationByMonthViewModel } from '../models/dashboard/CustomerCreationByMonthViewModel';
import { CustomerCreationForYearRangeViewModel } from '../models/dashboard/CustomerCreationForYearRangeViewModel';
import { EquipmentTrafficLightViewModel } from '../models/dashboard/EquipmentTrafficLightViewModel';
import { InstalledBaseCountByCategoryViewModel } from '../models/dashboard/InstalledBaseCountByMonthViewModel';
import { LeadsKpiViewModel } from '../models/dashboard/LeadsKpiViewModel';
import { OnboardedDistributorsCountByMonthViewModel } from '../models/dashboard/OnboardedDistributorsCountByMonthViewModel';
import { OneToOneKpiViewModel } from '../models/dashboard/OneToOneKpiViewModel';
import { ServiceOrderCountByMonthViewModel } from '../models/dashboard/ServiceOrderCountByMonthViewModel';
import { ServiceOrderStatusesViewModel } from '../models/dashboard/ServiceOrderStatusesViewModel';
import { ActionResult, FailActionResult, api } from '../shared/utils/api';
import { NoResultMessage } from '../shared/utils/validationMessages';

class DashboardService {

    async OneToOneKpi(filters? : OneToOneFilterModel): Promise<ActionResult<OneToOneKpiViewModel>> {
        const url = '/Dashboard/OneToOneKpi';

        const response = await api.get<OneToOneKpiViewModel>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async LeadsKpi(filters? : LeadsFilterModel): Promise<ActionResult<LeadsKpiViewModel>> {
        const url = '/Dashboard/LeadsKpi';

        const response = await api.get<LeadsKpiViewModel>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetServiceOrderStatusesCount(): Promise<ActionResult<ServiceOrderStatusesViewModel>> {
        const url = '/Dashboard/GetServiceOrderStatusesCount';

        const response = await api.get<ServiceOrderStatusesViewModel>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async ServiceOrdersCountByMonth(filters: ServiceOrderCountFilterModel): Promise<ActionResult<ServiceOrderCountByMonthViewModel>> {
        const url = '/Dashboard/ServiceOrdersCountByMonth';

        const response = await api.get<ServiceOrderCountByMonthViewModel>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async ServiceOrdersCountForYearRange(filters: ServiceOrderCountFilterModel): Promise<ActionResult<any>> {
        const url = '/Dashboard/ServiceOrdersCountForYearRange';

        const response = await api.get<any>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetCustomersCreationCountByMonth(filters: CustomerCreationKpiFilterModel): Promise<ActionResult<CustomerCreationByMonthViewModel[]>> {
        const url = '/Dashboard/CustomersCreationCountByMonth';

        const response = await api.get<CustomerCreationByMonthViewModel[]>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetCustomersCreationCountForYearRange(filters: CustomerCreationKpiFilterModel): Promise<ActionResult<CustomerCreationForYearRangeViewModel[]>> {
        const url = '/Dashboard/CustomersCreationCountForYearRange';

        const response = await api.get<CustomerCreationForYearRangeViewModel[]>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetInstalledBaseCountByCategory(filters: InstalledBaseFilterModel): Promise<ActionResult<InstalledBaseCountByCategoryViewModel>> {
        const url = '/Dashboard/GetInstalledBaseByCategoryKpiData';

        const response = await api.get<InstalledBaseCountByCategoryViewModel>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetOnboardedDistributorsCount(filters?: OnboardedDistributorsFilterModel): Promise<ActionResult<OnboardedDistributorsCountByMonthViewModel>> {
        const url = '/Dashboard/GetOnboardedDistributorsCount';

        const response = await api.get<OnboardedDistributorsCountByMonthViewModel>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetEquipmentTrafficLightData(filters?: EquipmentTrafficLightFilterModel): Promise<ActionResult<EquipmentTrafficLightViewModel>> {
        const url = '/Dashboard/GetEquipmentTrafficLightKpiData';

        const response = await api.get<EquipmentTrafficLightViewModel>(url, filters);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }
}

export const dashboardService = new DashboardService();