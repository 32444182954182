import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { Home } from './components/Home';
import { Routes } from './routes';
import { MsalProvider } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { OrganizationsOverview } from './organizations/components/organizationsOverview/OrganizationsOverview';
import { msalInstance } from '.';
import { CustomersOverview } from './customers/components/customersOverview/CustomersOverview';
import { EquipmentsOverviewContainer } from './equipments/components/equipmentsOverviewContainer/EquipmentsOverviewContainer';
import { ServiceOrdersOverviewContainer } from './serviceOrders/components/serviceOrdersOverviewContainer/ServiceOrdersOverviewContainer';
import { ServicePlanningOverviewContainer } from './servicePlanning/components/servicePlanningOverviewContainer/ServicePlanningOverviewContainer';
import { ServiceExecutionOverview } from './serviceExecutions/components/serviceExecutionOverview/ServiceExecutionOverview';
import { CustomersUploadPage } from './customers/components/customersUploadPage/CustomersUploadPage';
import { CustomerUploadProgress } from './customers/components/customerUploadProgress/CustomerUploadProgress';
import { ScrollToTop } from './components/elements/scrollToTop/ScrollToTop';
import { UserSettingsPage } from './users/components/userSettings/UserSettingsPage';
import { ThemeMode } from './shared/enums/ThemeMode';
import { ThemeProvider } from '@mui/material/styles';
import { CustomThemeProvider } from '@ui-components/ui-library';
import { extendTheme } from './shared/utils/sxStyleObjects';
import { StartupServiceExecutionOverview } from './serviceExecutions/components/startupServiceExecutionOverview/StartupServiceExecutionOverview';
import { UsersPagedOverview } from './users/components/usersPagedOverview/UsersPagedOverview';
import { Restricted } from './components/elements/restricted/Restricted';
import { LimitedAccessComponent } from './components/limitedAccessComponent/LimitedAccessComponent';
import { useAppSelector } from './hooks/reduxHooks';
import { userLanguageIsRtl } from './stores/user/usersSlice';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

export const App = () => {
    const shouldUseRtlTheme: boolean = useAppSelector(state => userLanguageIsRtl(state));
    
    const createRtlCache = () => createCache({
		key: 'muirtl',
		stylisPlugins: [prefixer, rtlPlugin],
	});
	const createLtrCache = () => createCache({
		key: 'mui',
	});

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
            >
                <CacheProvider value={shouldUseRtlTheme ? createRtlCache() : createLtrCache()}>
                    <ThemeProvider theme={extendTheme(shouldUseRtlTheme)}>
                        <CustomThemeProvider mode={ThemeMode.Dark} brand={'Smartlink'} isRtl={shouldUseRtlTheme}>
                            <Layout>
                                <ScrollToTop />
                                <Switch>
                                    <Route exact path={Routes.home.pattern} component={Home} />

                                    <Route exact path={Routes.users.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewUsers'} fallbackElement={<LimitedAccessComponent />}>
                                                    <UsersPagedOverview />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.organizations.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewOrganizations'} fallbackElement={<LimitedAccessComponent />}>
                                                    <OrganizationsOverview />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.customers.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewCustomers'} fallbackElement={<LimitedAccessComponent />}>
                                                    <CustomersOverview />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.customersUpload.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canCreateCustomers'} fallbackElement={<LimitedAccessComponent />}>
                                                    <CustomersUploadPage />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.customerUploads.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canCreateCustomers'} fallbackElement={<LimitedAccessComponent />}>
                                                    <CustomerUploadProgress />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.equipments.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewEquipments'} fallbackElement={<LimitedAccessComponent />}>
                                                    <EquipmentsOverviewContainer />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.serviceOrders.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewServiceOrders'} fallbackElement={<LimitedAccessComponent />}>
                                                    <ServiceOrdersOverviewContainer />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.servicePlanning.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewServicePlannings'} fallbackElement={<LimitedAccessComponent />}>
                                                    <ServicePlanningOverviewContainer />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.serviceExecution.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewServiceExecutons'} fallbackElement={<LimitedAccessComponent />}>
                                                    <ServiceExecutionOverview />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.startupServiceExecution.pattern}
                                        component={
                                            () =>
                                                <Restricted to={'canViewStartupServiceExecutions'} fallbackElement={<LimitedAccessComponent />}>
                                                    <StartupServiceExecutionOverview />
                                                </Restricted>
                                        }
                                    />

                                    <Route path={Routes.userPreferences.pattern} component={UserSettingsPage} />
                                </Switch>
                            </Layout>
                        </CustomThemeProvider>
                    </ThemeProvider>
                </CacheProvider>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};
