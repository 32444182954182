
export type UsersPagedQueryParameters = {
    globalSearchValue: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    city: string | undefined;
    country: string | undefined;
    distributorName: string | undefined;
    famCode: string | undefined;
    brands: string | undefined;
    createdOn: Date | undefined;
    role: string | undefined;
    isActive: boolean | undefined;
    sortBy: string | undefined;
    sortDesc: boolean;
    pageNumber: number;
    pageSize: number;
}

export const getDefaultUsersPagedQueryParameters = (): UsersPagedQueryParameters => {
    return {
        globalSearchValue: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        city: undefined,
        country: undefined,
        distributorName: undefined,
        famCode: undefined,
        brands: undefined,
        createdOn: undefined,
        role: undefined,
        isActive: undefined,
        sortBy: undefined,
        sortDesc: false,
        pageNumber: 1,
        pageSize: 15,
    };
};
