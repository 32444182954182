import { styled } from '@mui/material/styles';
import { Drawer, Typography } from '@mui/material';
import { getTheme } from '@ui-components/ui-library';
import { ThemeMode } from '../enums/ThemeMode';

export const extendTheme = (isRtl: boolean) => {
  const theme = getTheme(ThemeMode.Dark, 'Smartlink');

  theme.typography.fontFamily = 'system-ui';

  theme.direction = isRtl ? 'rtl' : 'ltr';
  
    theme.components = {
      ...theme.components,
      MuiTypography: {
        defaultProps: {
          fontFamily: 'system-ui',
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            fontFamily: 'system-ui',
            fontSize: '14px'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: 'system-ui'
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontFamily: 'system-ui'
          },
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            marginTop: '4px'
          },
          listbox: {
            fontFamily: 'system-ui'
          },
          noOptions: {
            fontFamily: 'system-ui'
          },
        }
      },
      MuiFormControl: {
        defaultProps: {
          sx: { width: '100%' }
        }
      },
      MuiOutlinedInput: {
        defaultProps: {
          sx: {
            '& fieldset': {
              '& legend': {
                marginBottom: 0, width: 0
              }
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'system-ui'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'system-ui'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'system-ui'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            minWidth: '31px !important'
          }
        }
      },
      MuiPagination: {
        defaultProps: {
          dir: isRtl ? 'rtl' : 'ltr',
        }
      },
    };
  
    return theme;
  };

export const DropdownSxObject = {
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      '& legend': {
        marginBottom: 0, width: 0
      }
    }
  },
  '& .MuiFormControl-root': { marginTop: '4px' }
};

export const GlobalOverlayCircularProgress = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  margin: '-25px 0 0 -25px',
};

export const Card = styled('div')(({ theme }) => ({
  padding: '3% 10%',
  border: '1px solid',
  borderColor: theme.palette.colors.neutral[300],
  borderRadius: '0.375rem'
}));

export const CardTextContainer = styled('div')(({ theme }) => ({
  padding: '1rem',
  border: '1px solid',
  borderColor: theme.palette.colors.neutral[300],
  borderRadius: '0.375rem'
}));

export const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'system-ui',
  color: theme.palette.colors.neutral[50],
  marginBottom: '0.5rem'
}));

export const StyledParagraph = styled('p')(({ theme }) => ({
  fontFamily: 'system-ui',
  color: theme.palette.colors.neutral[50]
})) as typeof Typography;

export const StyledInputLabel = styled('label')(({ theme }) => ({
  fontFamily: 'system-ui',
  color: theme.palette.colors.neutral[50],
}));

export const StyledGridCellContent = styled('div')(() => ({
  minWidth: '31px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export const StyledDatePickerLabel = styled('label')(({ theme }) => ({
  fontFamily: 'system-ui',
  color: theme.palette.colors.neutral[50],
  marginBottom: '6px'
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root.MuiDrawer-paper': {
    padding: '1rem',
    backgroundColor: theme.palette.colors.background.dark,
  }
}));

export const InnerTableSxObject = {
  '&.MuiDataGrid-root .MuiDataGrid-cell': {
    fontSize: '14px',
    paddingTop: '15px',
    paddingBottom: '15px'
  }
};

export const ChartSxObject = {
  // change all labels fontFamily shown on both xAxis and yAxis
  '& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel': { fontFamily: 'system-ui' },
};

export const ChartTooltipLabel = styled('label')(({ theme }) => ({
  fontFamily: 'system-ui',
  color: theme.palette.colors.neutral[50],
}));

export const ChartLegendLabel = styled('label')(({ theme }) => ({
  fontFamily: 'system-ui',
  fontSize: '12px',
  color: theme.palette.colors.neutral[50],
}));

export const DatePickerSxObject = {
  '& .PrivatePickersYear-yearButton': { fontFamily: 'system-ui' },
  '& .MuiPickersCalendarHeader-labelContainer': { fontFamily: 'system-ui' },
  '& .MuiTypography-root.MuiDayPicker-weekDayLabel': { fontFamily: 'system-ui' },
  '& .MuiButtonBase-root.MuiPickersDay-root': { fontFamily: 'system-ui' }
};

export const DropdownListItemLongContentSxObject = { 
  '& li': { 
    minHeight: '48px !important', 
    padding: '9px !important', 
    height: 'max-content !important' 
  },
};