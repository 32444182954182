import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { IEquipmentDetailInfoProps } from './IEquipmentDetailInfoProps';
import { EquipmentFormModel } from '../../models/equipment';
import { Grid } from '@mui/material';
import { Dropdown, DropdownOption, Radio, StyledSearchableDropdownProps, TextField } from '@ui-components/ui-library';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { AuthenticatedUser } from '../../../users/models/user';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const EquipmentDetailInfo = (props: IEquipmentDetailInfoProps): JSX.Element => {
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const { readonly, equipmentStatusOptions } = props;
    const { t } = useTranslation();

    const { values: equipment, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext() as FormikProps<EquipmentFormModel>;

    const onIsConnectedChange = (e: any) => {
        setFieldValue('equipmentDetail.connectivityDetail.isConnected', e);
    };

    //#region Dropdown and Radio options and values
    const equipmentStatusDropdownOptions = equipmentStatusOptions.map(o => { return { id: o.id, label: t(o.description), value: o.id }; });
    const equipmentStatusValueOption = equipmentStatusDropdownOptions.find(o => o.id === equipment.equipmentDetail.equipmentStatusId);

    const connectivityRadioOptions = [{ label: t('Yes'), value: 'true', disabled: readonly }, { label: t('No'), value: 'false', disabled: readonly }];
    //#endregion

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <DatePicker
                        id={'equipmentDetail.lastMaintenanceDate'}
                        inputFormat={currentUser.preferences.dateFormat}
                        onChange={value => { setFieldTouched('equipmentDetail.lastMaintenanceDate'); setFieldValue('equipmentDetail.lastMaintenanceDate', value ? moment(value).startOf('d').toDate() : undefined); }}
                        value={equipment.equipmentDetail.lastMaintenanceDate}
                        disableFuture
                        disabled={readonly}
                        actions={['today', 'clear']}
                        title={t('Last Maintenance Date')}
                    />
                    <ErrorMessage name={'equipmentDetail.lastMaintenanceDate'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'equipmentDetail.equipmentStatusId'}
                        label={t('Machine Status')}
                        name={'equipmentDetail.equipmentStatusId'}
                        variant={'searchable'}
                        options={equipmentStatusDropdownOptions ? equipmentStatusDropdownOptions : []}
                        value={equipmentStatusValueOption ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => setFieldValue('equipmentDetail.equipmentStatusId', item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        optionListStyles={{ fontFamily: 'system-ui' }}
                        required={true}
                        disabled={readonly}
                        greyedOutLabelOnDisabled
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'equipmentDetail.equipmentStatusId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Radio
                        options={connectivityRadioOptions}
                        value={equipment.equipmentDetail.connectivityDetail.isConnected.toString()}
                        direction='row'
                        onChange={(e) => onIsConnectedChange(e)}
                        sx={{ '& .MuiTypography-root': { fontFamily: 'system-ui' } }}
                        title={t('Connectivity')}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Estimated Working Hours (Per Year)')}
                        id={'equipmentDetail.estimatedWorkingHoursPerYear'}
                        name={'equipmentDetail.estimatedWorkingHoursPerYear'}
                        value={equipment.equipmentDetail.estimatedWorkingHoursPerYear}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 4 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        inputRestriction='numeric'
                        disabled={readonly}
                    />
                    <ErrorMessage name={'equipmentDetail.estimatedWorkingHoursPerYear'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};
