import { ActionResult, FailActionResult, api } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { PersonOfInterestVersion } from '../models/personOfInterestVersionModel';

class PeopleOfInterestVersionService {
    async GetPeopleOfInterestVersionsForCustomer(customerId: number): Promise<ActionResult<PersonOfInterestVersion[]>> {
        const url = `/peopleOfInterestVersions/GetPeopleOfInterestVersionsForCustomer/${customerId}`;
        const response = await api.get<PersonOfInterestVersion[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;

    }
}

export const peopleOfInterestVersionService = new PeopleOfInterestVersionService();