import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { EquipmentFormModel, getDefaultEquipmentModel } from '../../models/equipment';
import { equipmentService } from '../../services/EquipmentService';
import { BrandViewModel } from '../../../models/brand/brandModel';
import { brandService } from '../../../services/BrandService';
import { userCanEditEquipments } from '../../../stores/user/usersSlice';
import { EquipmentStatusOption } from '../../models/equipmentStatus';
import { equipmentStatusService } from '../../services/EquipmentStatusService';
import { mapEquipmentToCreateModel, mapEquipmentToUpdateModel } from '../../models/equipment';
import { Box, Grid } from '@mui/material';
import { Dropdown, StyledSearchableDropdownProps, DropdownOption, Modal, Stepper } from '@ui-components/ui-library';
import { Button } from '@ui-components/ui-library';
import { EquipmentCategory } from '../../models/equipmentCategory';
import { EquipmentType } from '../../models/equipmentType';
import { equipmentTypeService } from '../../../services/EquipmentTypeService';
import { EquipmentTypeOtherForm } from '../equipmentTypeOtherForm/EquipmentTypeOtherForm';
import { EquipmentTypeCompressorForm } from '../equipmentTypeCompressorForm/EquipmentTypeCompressorForm';
import { EquipmentTypeAirTreatmentForm } from '../equipmentTypeAirTreatmentForm/EquipmentTypeAirTreatmentForm';
import { EquipmentCategories, EquipmentCategoryLabels, getEquipmentCategoryLabel } from '../../../shared/enums/EquipmentCategories';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { IEquipmentFormContainerProps, EquipmentFormContainerStep } from './IEquipmentFormContainerProps';
import { ParentOrganizationOptionModel } from '../../models/customer';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const EquipmentFormContainer = (props: IEquipmentFormContainerProps): JSX.Element => {
    const { formIsOpen, handleClose, equipmentId, getEquipments } = props;
    const isNewForm = equipmentId === undefined;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const canEditEquipments = useAppSelector(state => userCanEditEquipments(state));

    const [equipment, setEquipment] = useState<EquipmentFormModel>(getDefaultEquipmentModel());
    const [brandOptions, setBrandOptions] = useState<BrandViewModel[]>([]);
    const [equipmentStatusOptions, setEquipmentStatusOptions] = useState<EquipmentStatusOption[]>([]);
    const [equipmentCategoryOptions, setEquipmentCategoryOptions] = useState<EquipmentCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<EquipmentCategory | undefined>(undefined);
    const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<EquipmentType[]>([]);
    const [distributorOrganizationOptions, setDistributorOrganizationOptions] = useState<ParentOrganizationOptionModel[]>([]);
    const [formIsReadonly, setFormIsReadonly] = useState<boolean>(true);
    const [isEquipmentLinked, setIsEquipmentLinked] = useState<boolean>(false);
    const [step, setStep] = useState<number>(EquipmentFormContainerStep.EQUIPMENTGENERALINFO);

    const getTypesForCategory = async (categoryId: number) => {
        const equipmentTypeResponse = await equipmentTypeService.GetEquipmentTypesForCategory(categoryId);

        if (equipmentTypeResponse.isSuccess) {
            if (equipmentTypeResponse.result) {
                setEquipmentTypeOptions(equipmentTypeResponse.result);
            }
        } else {
            dispatch(showErrors(equipmentTypeResponse?.errorModel?.errors));
        }
    };

    const getBrandOptions = async () => {
        if (!selectedCategory?.id && selectedCategory?.id !== 0) {
            return;
        }

        const brandsResponse = await brandService.GetBrandsByEquipmentCategory(selectedCategory.id);

        if (brandsResponse.isSuccess) {
            if (brandsResponse.result) {
                setBrandOptions(brandsResponse.result);
            }
        } else {
            dispatch(showErrors(brandsResponse.errorModel?.errors));
        }
    };

    const getEquipmentStatusOptions = async () => {
        const equipmentOptionsResponse = await equipmentStatusService.GetEquipmentStatusOptions();

        if (equipmentOptionsResponse.isSuccess) {
            setEquipmentStatusOptions(orderBy(equipmentOptionsResponse.result!, es => es.description));
        }
    };

    const getDistributorOrganizationOptions = async () => {
        const distributorOrganizationOptionsResponse = await equipmentService.GetDistributorOrganizationsForEquipment();

        if (distributorOrganizationOptionsResponse.isSuccess) {
            if (distributorOrganizationOptionsResponse.result) {
                const orderedOptions = orderBy(distributorOrganizationOptionsResponse.result, dis => dis.name);
                setDistributorOrganizationOptions(orderedOptions);
            } else {
                setDistributorOrganizationOptions([]);
            }
        } else {
            dispatch(showErrors(distributorOrganizationOptionsResponse.errorModel?.errors));
        }
    };

    const handleFormSubmit = async (equipmentModel: EquipmentFormModel) => {
        dispatch(setIsLoading(true));
        if (!selectedCategory?.id && selectedCategory?.id !== 0) {
            dispatch(setIsLoading(false));
            return;
        }

        const startupDate = equipmentModel.startupDate;
        const lastMaintenanceDate = equipmentModel.equipmentDetail.lastMaintenanceDate;

        if (isNewForm) {
            const createModel = mapEquipmentToCreateModel({ ...equipmentModel, equipmentCategory: selectedCategory?.id, startupDate, equipmentDetail: { ...equipmentModel.equipmentDetail, lastMaintenanceDate } });
            await equipmentService.CreateEquipment(createModel).then(async (resp) => {
                if (resp.isSuccess) {
                    dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                    await getEquipments();
                } else {
                    dispatch(showErrors(resp.errorModel?.errors));
                }
            });

        } else {
            const updateModel = mapEquipmentToUpdateModel({ ...equipmentModel, startupDate, equipmentDetail: { ...equipmentModel.equipmentDetail, lastMaintenanceDate } });
            await equipmentService.UpdateEquipment(updateModel).then(async (resp) => {
                if (resp.isSuccess) {
                    dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                    await getEquipments();
                } else {
                    dispatch(showErrors(resp.errorModel?.errors));
                }
            });
        }
        dispatch(setIsLoading(false));
        handleClose();
    };

    const getEquipment = async () => {
        if (equipmentId) {
            dispatch(setIsLoading(true));
            const equipmentResponse = await equipmentService.GetEquipmentById(equipmentId);

            if (equipmentResponse.isSuccess) {
                let _equipment: EquipmentFormModel | null = equipmentResponse.result;

                setEquipment(_equipment!);
                setIsEquipmentLinked(equipmentResponse.result?.distributorOrganizationId != undefined);
            }
            else {
                dispatch(showErrors(equipmentResponse.errorModel?.errors));
            }

            dispatch(setIsLoading(false));
        }
    };

    const onEquipmentCategoryChange = async (selectedCategory: DropdownOption | undefined) => {
        setSelectedCategory(equipmentCategoryOptions.find(o => o.id === selectedCategory?.id));

        if (selectedCategory) {
            dispatch(setIsLoading(true));
            getTypesForCategory(Number(selectedCategory.id)).then(() => {
                dispatch(setIsLoading(false));
            });
        }
    };

    const equipmentCategoryDropdownOptions: DropdownOption[] = Object.entries(EquipmentCategoryLabels)
        .map(([id, value]) => {
            return { id: +id, label: t(value), value: value };
        });

    const equipmentCategoryValueOption = equipmentCategoryDropdownOptions.find(o => o.id === selectedCategory?.id);

    const renderCompressorForm = selectedCategory && selectedCategory.description === t(getEquipmentCategoryLabel(EquipmentCategories.Compressor));

    const renderOtherForm = selectedCategory && selectedCategory.description === t(getEquipmentCategoryLabel(EquipmentCategories.Other));

    const renderAirTreatmentForm = selectedCategory && selectedCategory.description === t(getEquipmentCategoryLabel(EquipmentCategories.AirTreatment));

    const steps: any = [{ label: t('General Info') }, { label: t('Equipment details') }];

    useEffect(() => {
        if (!isNewForm) {
            getEquipment();
        } else {
            setFormIsReadonly(false);
            dispatch(setIsLoading(false));
        }
    }, [isNewForm]);

    useEffect(() => {
        dispatch(setIsLoading(true));
        setEquipmentCategoryOptions(equipmentCategoryDropdownOptions.map(s => ({ id: +s.id, description: t(s.label as string) })));
        Promise.all([getEquipmentStatusOptions(), getDistributorOrganizationOptions() ]).then(() => {
            dispatch(setIsLoading(false));
        });
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            dispatch(setIsLoading(true));
            getBrandOptions().then(() => {
                dispatch(setIsLoading(false));
            });
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (isNewForm || equipment.id < 1) {
            return;
        }

        getTypesForCategory(equipment.equipmentCategory);
    }, [isNewForm, equipment.id]);

    useEffect(() => {
        if (isNewForm || equipment.id < 1 || equipmentCategoryOptions.length === 0) {
            return;
        }

        const existingCategory = equipmentCategoryOptions.find(ec => ec.id === equipment.equipmentCategory);
        setSelectedCategory(existingCategory);
    }, [isNewForm, equipment.id, equipmentCategoryOptions]);

    return (
        <>
            <Modal
                title={t('EquipmentFormTitle')}
                open={formIsOpen}
                onClose={handleClose}
                maxWidth='lg'
                scroll='body'
                titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
                sx={{ '.MuiPaper-root': { width: '100%' } }}
            >
                <Box>
                    {formIsReadonly && canEditEquipments &&
                        <Box flex={1} display={'flex'} justifyContent='flex-start'>
                            <Button
                                id='edit'
                                variant='primary'
                                label={t('Edit')}
                                onClick={() => setFormIsReadonly(false)}
                            />
                        </Box>
                    }

                </Box>

                <Box sx={{ padding: '20px 0' }}>
                    <Stepper
                        steps={steps}
                        activeStep={step}
                    />
                </Box>

                <Box sx={theme => ({ [theme.breakpoints.up('md')]: { minHeight: '250px' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' })}>
                    {
                        step === EquipmentFormContainerStep.EQUIPMENTGENERALINFO &&
                        <Box mb={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Dropdown<StyledSearchableDropdownProps>
                                        label={t('Equipment Category')}
                                        variant={'searchable'}
                                        options={equipmentCategoryDropdownOptions}
                                        value={equipmentCategoryValueOption ?? { id: '', label: '', value: '' }}
                                        onChange={(e, item) => onEquipmentCategoryChange(item as DropdownOption)}
                                        optionListStyles={{ fontFamily: 'system-ui' }}
                                        disabled={formIsReadonly || !isNewForm}
                                        required={true}
                                        greyedOutLabelOnDisabled
                                        noOptionsText={t(NO_OPTIONS_TEXT)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    }

                    {
                        renderOtherForm &&
                        <EquipmentTypeOtherForm
                            equipment={equipment}
                            isNewForm={isNewForm}
                            readonly={formIsReadonly}
                            isEquipmentLinked={isEquipmentLinked}
                            equipmentTypeOptions={equipmentTypeOptions}
                            distributorOrganizationOptions={distributorOrganizationOptions}
                            activeStep={step}
                            handleFormSubmit={handleFormSubmit}
                            handleBack={() => setStep(EquipmentFormContainerStep.EQUIPMENTGENERALINFO)}
                            handleClose={handleClose}
                            handleNext={() => setStep(EquipmentFormContainerStep.EQUIPMENTDETAILS)}
                        />
                    }

                    {
                        renderCompressorForm &&
                        <EquipmentTypeCompressorForm
                            equipment={equipment}
                            readonly={formIsReadonly}
                            isNewForm={isNewForm}
                            isEquipmentLinked={isEquipmentLinked}
                            equipmentTypeOptions={equipmentTypeOptions}
                            distributorOrganizationOptions={distributorOrganizationOptions}
                            brandOptions={brandOptions}
                            activeStep={step}
                            equipmentStatusOptions={equipmentStatusOptions}
                            handleFormSubmit={handleFormSubmit}
                            handleBack={() => setStep(EquipmentFormContainerStep.EQUIPMENTGENERALINFO)}
                            handleClose={handleClose}
                            handleNext={() => setStep(EquipmentFormContainerStep.EQUIPMENTDETAILS)}
                        />
                    }
                    {
                        renderAirTreatmentForm &&
                        <EquipmentTypeAirTreatmentForm
                            equipment={equipment}
                            readonly={formIsReadonly}
                            isNewForm={isNewForm}
                            isEquipmentLinked={isEquipmentLinked}
                            equipmentTypeOptions={equipmentTypeOptions}
                            distributorOrganizationOptions={distributorOrganizationOptions}
                            brandOptions={brandOptions}
                            equipmentStatusOptions={equipmentStatusOptions}
                            activeStep={step}
                            handleFormSubmit={handleFormSubmit}
                            handleBack={() => setStep(EquipmentFormContainerStep.EQUIPMENTGENERALINFO)}
                            handleClose={handleClose}
                            handleNext={() => setStep(EquipmentFormContainerStep.EQUIPMENTDETAILS)}
                        />
                    }

                    {
                        !renderOtherForm && !renderCompressorForm && !renderAirTreatmentForm &&
                        <Box>
                            <SaveSection
                                handleCancel={handleClose}
                            />
                        </Box>
                    }

                </Box>
            </Modal>
        </>
    );
};