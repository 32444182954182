export type InstalledBaseCountByCategoryViewModel = {
    airTreatmentCountCreatedByCustomerCenters: number;
    airTreatmentCountCreatedByDistributors: number;
    airTreatmentCountCreatedByOthers: number;
    compressorsCountCreatedByCustomerCenters: number;
    compressorsCountCreatedByDistributors: number;
    compressorsCountCreatedByOthers: number;
    othersCountCreatedByCustomerCenters: number;
    othersCountCreatedByDistributors: number;
    othersCountCreatedByOthers: number;
};

export const getDefaultInstalledBaseCountByCategory = (): InstalledBaseCountByCategoryViewModel => {
    return {
        airTreatmentCountCreatedByCustomerCenters: 0,
        airTreatmentCountCreatedByDistributors: 0,
        airTreatmentCountCreatedByOthers: 0,
        compressorsCountCreatedByCustomerCenters: 0,
        compressorsCountCreatedByDistributors: 0,
        compressorsCountCreatedByOthers: 0,
        othersCountCreatedByCustomerCenters: 0,
        othersCountCreatedByDistributors: 0,
        othersCountCreatedByOthers: 0
    };
};