import React from 'react';
import { CircularProgress } from '@mui/material';
import styles from './Overlay.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import { GlobalOverlayCircularProgress } from '../../shared/utils/sxStyleObjects';

export const Overlay = (): JSX.Element => {
    const loadingMessage: string = useAppSelector(state => state.Layout.loadingMessage);
    const isLoading: boolean = useAppSelector(state => state.Layout.isLoading);

    const { t } = useTranslation();

    if (!isLoading) {
        return <></>;
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.loading_message}>{t(loadingMessage)}</div>

            <CircularProgress sx={GlobalOverlayCircularProgress} />
        </div>
    );
};
