import * as Yup from 'yup';
import { RequiredMessage, ShortValueMessage } from '../../shared/utils/validationMessages';
import { CustomerFormModel } from '../models/customerModel';

export const customerFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required(RequiredMessage)
        .min(2, ShortValueMessage),
    distributorOrganizationId: Yup.number().required(RequiredMessage).min(1, RequiredMessage),

    customerAddress: Yup.object().shape({
        countryId: Yup.number()
            .min(1, RequiredMessage),
        city: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        postalCode: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        addressLine1: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        hasStates: Yup.boolean(),
        state: Yup.string().nullable(true).when('hasStates', {
            is: true,
            then: Yup.string().nullable(true).required(RequiredMessage)
        }),
    }),

    customerDetail: Yup.object().shape({
        customerNumber: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        phoneNumber: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
    }),
});

const customerFormGeneralInfoValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required()
        .min(2),
    distributorOrganizationId: Yup.number().required().min(1),

    customerAddress: Yup.object().shape({
        countryId: Yup.number()
            .min(1),
        city: Yup.string()
            .trim()
            .required()
            .min(2),
        postalCode: Yup.string()
            .trim()
            .required()
            .min(2),
        addressLine1: Yup.string()
            .trim()
            .required()
            .min(2),
        state: Yup.string().nullable(true).when('hasStates', {
            is: true,
            then: Yup.string().nullable(true).required(RequiredMessage)
        }),
    }),
});

export const customerGeneralInfoIsValid = (customer: CustomerFormModel): boolean => {
    return customerFormGeneralInfoValidationSchema.isValidSync(customer);
};