import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Alert } from '@mui/material';
import { StyledTitle } from '../../../components/form/title/Title';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { TechnicianModel, getTechicianDropdownOptions } from '../../../users/models/technicianModel';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ServiceOrderViewModel } from '../../../serviceOrders/models/serviceOrderModel';
import { DateTimePicker } from '../../../components/form/dateTimePicker/DateTimePicker';
import { AuthenticatedUser } from '../../../users/models/user';
import { ServicePlanningFormModel } from '../../models/servicePlanningModel';
import { PlanningType, getPlanningTypeLabel } from '../../models/planningType';
import moment from 'moment-timezone';

interface IPlanningTimeSelectionStepProps {
    isNewForm: boolean;
    technicians: TechnicianModel[];
    notPlannedServiceOrders: ServiceOrderViewModel[];
    onFormClose(): void;
}

export const PlanningTimeSelectionStep = (props: IPlanningTimeSelectionStepProps) => {
    const { t } = useTranslation();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const { isNewForm, technicians, notPlannedServiceOrders } = props;

    const {
        values: servicePlanning,
        handleBlur,
        setFieldValue,
    } = useFormikContext<ServicePlanningFormModel>();

    const [filteredTechnicians, setFilteredTechnicians] = useState<TechnicianModel[]>(technicians);

    const serviceOrderOptions : DropdownOption[] = useMemo(() => {
        const options = notPlannedServiceOrders;
        const dropDownOption = options.map(o => ({ id: o.id, label: o.number, value: o.id }));
        if (!isNewForm && servicePlanning.serviceOrderId) {
            dropDownOption.push({
                id: servicePlanning.serviceOrderId,
                label: servicePlanning.serviceOrderNumber,
                value: servicePlanning.serviceOrderId,
            });
        }

        return dropDownOption;
    }, [notPlannedServiceOrders]);

    const selectedServiceOrderOption = serviceOrderOptions.find(o => o.id === servicePlanning.serviceOrderId);

    const techniciansOptions: DropdownOption[] = useMemo(() => {
        return getTechicianDropdownOptions(filteredTechnicians);
    }, [filteredTechnicians]);    
    const selectedTechinicians: DropdownOption[] = techniciansOptions.filter(t => servicePlanning.assigneeIds.includes(+t.id));

    const handleServiceOrderChange = (value: DropdownOption | null) => {
        if (value) {
            const selectedServiceOrder = notPlannedServiceOrders.find(so => so.id === value.id);
            if (selectedServiceOrder) {
                const endDateToSet = selectedServiceOrder.estimatedHours
                    ? moment(servicePlanning.start).add(selectedServiceOrder.estimatedHours, 'h').toDate()
                    : servicePlanning.end;
                setFieldValue('serviceOrderId', selectedServiceOrder.id);
                setFieldValue('estimatedWorkHours', selectedServiceOrder.estimatedHours);
                setFieldValue('end', endDateToSet);
                
                setFilteredTechnicians([...filteredTechnicians.filter(t => t.organizationIds.includes(selectedServiceOrder.organizationId))]);
                setFieldValue('assigneeIds', filteredTechnicians);
            }
        } else {
            setFieldValue('serviceOrderId', undefined);
            setFieldValue('estimatedWorkHours', undefined);
            setFieldValue('end', moment().add(1, 'h').toDate());
            setFilteredTechnicians(technicians);
            setFieldValue('assigneeIds', []);
        }
    };

    const handleMultiSelectChange = (items: DropdownOption[] | DropdownOption) => {
        if (items !== null) {
            if (Array.isArray(items)) {
                const uniqueSelectedIds = [...new Set(items.map(item => item.id as number))];
                setFieldValue('assigneeIds', uniqueSelectedIds);
            } else if (typeof items === 'object') {
                const selectedId = items.id as number;
                setFieldValue('assigneeIds', [selectedId]);
            }
        } else {
            setFieldValue('assigneeIds', []);
        }
    };

    const handleStartDateChange = (value: Date) => {
        let endDateToSet = moment(value).toDate();
        if (servicePlanning.estimatedWorkHours) {
            endDateToSet = moment(endDateToSet).add(servicePlanning.estimatedWorkHours, 'h').toDate();
        } else {
            endDateToSet = moment(endDateToSet).add(1, 'h').toDate();
        }

        setFieldValue('start', value);
        setFieldValue('end', endDateToSet);
    };

    const handleEndDateChange = (value: Date) => {
        const currentStartDate: Date = moment(servicePlanning.start).toDate();

        if (value && currentStartDate >= value) {
            const startDateToSet = moment(value).add(-1, 'h').toDate();
            setFieldValue('start', startDateToSet);
            setFieldValue('end', value);
        } else {
            setFieldValue('end', value);
        }
    };

    const shouldDisplayWarningMessage = servicePlanning.type === PlanningType.Service
        ? servicePlanning.visitReportIds.length === 0 && moment(servicePlanning.start) < moment()
        : moment(servicePlanning.start) < moment();

    useEffect(() => {
        if (servicePlanning.serviceOrderId && servicePlanning.type === PlanningType.Service) {
            if (!isNewForm) {
                setFilteredTechnicians(technicians.filter(t => t.organizationIds.includes(servicePlanning.distributorOrganziationId!)));
            } else {
                const selectedServiceOrder = notPlannedServiceOrders.find(so => so.id === servicePlanning.serviceOrderId);
                setFilteredTechnicians(technicians.filter(t => t.organizationIds.includes(selectedServiceOrder?.organizationId!)));
            }
        } else {
            setFilteredTechnicians(technicians);
        }
    }, [servicePlanning.serviceOrderId]);  

    return (
        <>
            <StyledTitle mt='5%' variant='h3'>
                {`${t('{{x}} Planning', { x: t(getPlanningTypeLabel(servicePlanning.type)) })}`}
            </StyledTitle>
            <Grid container spacing={2}>
                {servicePlanning.type === PlanningType.Service && (
                    <Grid item xs={12}>
                        <Dropdown<StyledSearchableDropdownProps>
                            label={t('Service Order')}
                            variant={'searchable'}
                            name={'serviceOrderId'}
                            id={'serviceOrderId'}
                            options={serviceOrderOptions}
                            value={selectedServiceOrderOption ?? { id: 0, label: '', value: 0 }}
                            onChange={(_, item: any) => handleServiceOrderChange(item)}
                            onBlur={handleBlur}
                            disabled={!isNewForm || servicePlanning.visitReportIds.length > 0}
                            greyedOutLabelOnDisabled
                            usePopper
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Dropdown<StyledSearchableDropdownProps>
                        label={t('Technicians')}
                        variant={'searchable'}
                        options={techniciansOptions}
                        value={servicePlanning.type === PlanningType.Service ? selectedTechinicians : selectedTechinicians[0] || { id: 0, label: '', value: 0 }}
                        onChange={(e, items) => handleMultiSelectChange(Array.isArray(items) ? items as DropdownOption[] : items as DropdownOption)}
                        onBlur={handleBlur}
                        disabled={technicians.length === 0 || servicePlanning.visitReportIds.length > 0}
                        greyedOutLabelOnDisabled
                        multiple={servicePlanning.type === PlanningType.Service}
                        usePopper
                    />
                </Grid>

                {shouldDisplayWarningMessage && (
                    <Grid item xs={12}>
                        <Alert icon={false} severity="warning">
                            {t('You\'ve selected a date in the past.')}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={6}>
                    <DateTimePicker
                        title={t('Start')}
                        value={servicePlanning.start}
                        onChange={(value) => handleStartDateChange(value as Date)}
                        inputFormat={`${currentUser.preferences.dateFormat} ${currentUser.preferences.timeFormat}`}
                        disabled={servicePlanning.visitReportIds.length > 0}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker
                        title={t('End')}
                        value={servicePlanning.end}
                        onChange={(value) => handleEndDateChange(value as Date)}
                        inputFormat={`${currentUser.preferences.dateFormat} ${currentUser.preferences.timeFormat}`}
                        disabled={!!servicePlanning.estimatedWorkHours}
                    />
                </Grid>
            </Grid>
        </>
    );
};