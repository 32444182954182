import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.dark.css';
import './shared/styles/dev-extreme-custom.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n/config';
import { store } from './stores/store';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, AccountInfo } from '@azure/msal-browser';
import { AppEnvironment } from './shared/configurations/AppEnvironment';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_LICENSE_KEY } from './shared/utils/constants';
import { CookiesProvider } from 'react-cookie';

const baseUrl: any = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');
export const msalInstance = new PublicClientApplication(AppEnvironment.get().msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account: AccountInfo | null = payload.account;

    msalInstance.setActiveAccount(account);
  }
});

// Setting the MUI License key
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

ReactDOM.render(
  <React.Suspense fallback={<>Loading...</>}>
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
          <App />
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  </React.Suspense>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
