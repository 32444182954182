import React from 'react';
import { GridColumns, GridValidRowModel, GridRowClassNameParams, GridRowIdGetter, GridApi } from '@mui/x-data-grid-pro';
import { DataGrid } from '@ui-components/ui-library';
import { Box } from '@mui/material';
import { InnerTableSxObject } from '../../shared/utils/sxStyleObjects';

interface DataGridFormProps extends React.ComponentProps<typeof DataGrid> {
	columns: GridColumns<GridValidRowModel>;
	rows: GridValidRowModel[];
	apiRef?: React.MutableRefObject<GridApi>;
	isLoading?: boolean;
	getRowClassName?: (params: GridRowClassNameParams<GridValidRowModel>) => string;
	noRowsMessage?: string;
	getRowId?: GridRowIdGetter<GridValidRowModel>;
}

export const DataGridForm = (props: DataGridFormProps): JSX.Element => {
	const CustomNoRowsOverlay = () => {
		return (
			<Box mt={2} ml={1}>{props.noRowsMessage}</Box>
		);
	};

	return (
		<DataGrid
			apiRef={props.apiRef}
			rows={props.rows}
			columns={props.columns}
			loading={props.isLoading}
			autoHeight
			containerHeight='auto'
			disableColumnMenu={true}
			getRowHeight={() => 'auto'}
			hideFooter
			getRowId={props.getRowId}
			components={{ NoRowsOverlay: CustomNoRowsOverlay }}
			sx={InnerTableSxObject}
		/>
	);
};
