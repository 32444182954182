import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { mapUserFormValuesToCreateModel, mapUserFormValuesToUpdateModel, UserFormValues } from '../../models/user';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { UserExternalIdentities } from '../userExternalIdentitiesSection/UserExternalIdentities';
import { UserGeneralInfo } from '../userGeneralInfo/UserGeneralInfo';
import { Routes } from '../../../routes';
import { UserRoleOrganizationSection } from '../userRoleOrganizationSection/UserRoleOrganizationSection';
import { userService } from '../../services/UserService';
import { userGeneralInfoIsValid, validateForm } from '../../services/UserUtility';
import { useTranslation } from 'react-i18next';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { Modal, Stepper } from '@ui-components/ui-library';
import { IUserFormProps, UserFormStep } from './IUserFormProps';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { BrandViewModel } from '../../../models/brand/brandModel';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { brandService } from '../../../services/BrandService';
import { organizationService } from '../../../organizations/services/OrganizationService';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import './UserForm.scss';
import { CountryFormModel } from '../../../organizations/models/countryModel';

export const UserForm = (props: IUserFormProps) => {
    const { selectedUser, handleClose, getUsers, roleOptions } = props;
    
    const countryOptions: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);

    const dispatch = useAppDispatch();
    const isNewForm = selectedUser.id === 0;
    const formIsReadonly = !isNewForm && !selectedUser.canBeModifiedByUser;
    const history = useHistory();
    const { t } = useTranslation();

    const [roleEditingRows, setRoleEditingRows] = useState({});
    const [brandOptions, setBrandOptions] = useState<{ organizationBrands: BrandViewModel[] }[]>([]);
    const [organizationOptions, setOrganizationOptions] = useState<{ roleOrganizations: OrganizationOptionModel[] }[]>([]);
    const [step, setStep] = useState<number>(UserFormStep.GENERALINFO);

    const updateRoleEditningRows = (roleId: number, isEdited: boolean) => {
        setRoleEditingRows({ ...roleEditingRows, ...{ [`${roleId}`]: isEdited } });
    };

    const getBrandsForOrganization = async (organizationId: number, index: number) => {
        const brandsResponse = await brandService.GetBrandsForOrganization(organizationId);
        if (brandsResponse.isSuccess) {
            if (brandsResponse.result) {
                const updatedBrandOptions = brandOptions;
                updatedBrandOptions[index] = { organizationBrands: brandsResponse.result };
                setBrandOptions(updatedBrandOptions);
            } else {
                const updatedBrandOptions = brandOptions;
                updatedBrandOptions[index] = { organizationBrands: [] };
                setBrandOptions(updatedBrandOptions);
            }
        } else {
            dispatch(showErrors(brandsResponse.errorModel?.errors));
        }
    };

    const updateOrganizationOptions = async (roleId: any, index: number): Promise<number | undefined> => {
        const updatedBrandOptions = brandOptions;
        updatedBrandOptions[index] = { organizationBrands: [] };
        setBrandOptions(updatedBrandOptions);
        const organizationOptionsResponse = await organizationService.GetOrganizationOptionsForRole(roleId);

        if (organizationOptionsResponse.isSuccess) {
            if (organizationOptionsResponse.result) {
                const updatedRoleOrganizationOptions = organizationOptions;
                updatedRoleOrganizationOptions[index] = { roleOrganizations: organizationOptionsResponse.result };
                setOrganizationOptions(updatedRoleOrganizationOptions);
                if (organizationOptionsResponse.result.length === 1) {
                    await getBrandsForOrganization(organizationOptionsResponse.result[0].id, index);

                    return organizationOptionsResponse.result[0].id;
                }
            } else {
                setOrganizationOptions([]);
            }
        } else {
            dispatch(showErrors(organizationOptionsResponse.errorModel?.errors));
        }

        return undefined;
    };

    const handleFormSubmit = async (userValues: UserFormValues) => {
        dispatch(setIsLoading(true));
        if (isNewForm) {
            const createUserModel = mapUserFormValuesToCreateModel(userValues);
            const createUserResponse = await userService.CreateUser(createUserModel);
            if (createUserResponse.isSuccess) {
                history.push(Routes.users.getPath());
                dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                await getUsers();
            } else {
                dispatch(showErrors(createUserResponse?.errorModel?.errors));
            }
        } else {
            const updateUserModel = mapUserFormValuesToUpdateModel(userValues);
            const updateUserResponse = await userService.UpdateUser(updateUserModel);
            if (updateUserResponse.isSuccess) {
                history.push(Routes.users.getPath());
                dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                await getUsers();
            } else {
                dispatch(showErrors(updateUserResponse?.errorModel?.errors));
            }
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (!isNewForm) {
            return;
        }

        updateRoleEditningRows(0, true);
    }, []);

    return (
        <>
            <Modal
                title={isNewForm ? t('New user') : t('Update user')}
                open={true}
                onClose={handleClose}
                maxWidth='lg'
                scroll='body'
                titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
            >
                {
                    <Box>
                        <Box sx={{ padding: '20px 0' }}>
                            <Stepper
                                steps={[
                                    { label: t('General Info') },
                                    { label: t('External Identities, Role and Organization') }
                                ]}
                                activeStep={step}
                            />
                        </Box>
                        <Formik
                            initialValues={selectedUser}
                            validate={(values) => validateForm(values, roleOptions)}
                            enableReinitialize
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, dirty, isSubmitting, isValid, handleSubmit }) => (
                                <Form>
                                    <Box display={'flex'} flexDirection={'column'}>
                                        <Box flex={'1 1 auto'}>
                                            {
                                                step === UserFormStep.GENERALINFO &&
                                                <UserGeneralInfo
                                                    formIsReadonly={formIsReadonly}
                                                    countries={countryOptions}
                                                />
                                            }
                                            {
                                                step === UserFormStep.ROLEORGANIZATIONS &&
                                                <Box sx={theme => ({ [theme.breakpoints.up('lg')]: { width: '900px' } })}>
                                                    <UserExternalIdentities formIsReadonly={formIsReadonly} />
                                                    <UserRoleOrganizationSection
                                                        formIsReadonly={formIsReadonly}
                                                        roleOptions={roleOptions}
                                                        brandOptions={brandOptions}
                                                        organizationOptions={organizationOptions}
                                                        roleEditingRows={roleEditingRows}
                                                        updateRoleEditningRows={updateRoleEditningRows}
                                                        updateBrandOptions={getBrandsForOrganization}
                                                        updateOrganizationOptions={updateOrganizationOptions}
                                                    />
                                                </Box>
                                            }
                                        </Box>
                                        <Box flex={'0 1 auto'}>
                                            {
                                                step === UserFormStep.GENERALINFO && !formIsReadonly &&
                                                <SaveSection
                                                    validateNext={() => userGeneralInfoIsValid(values)}
                                                    handleCancel={handleClose}
                                                    handleNext={() => setStep(UserFormStep.ROLEORGANIZATIONS)}
                                                />
                                            }

                                            {
                                                step === UserFormStep.GENERALINFO && formIsReadonly &&
                                                <SaveSection
                                                    handleCancel={handleClose}
                                                    handleNext={() => setStep(UserFormStep.ROLEORGANIZATIONS)}
                                                />
                                            }

                                            {
                                                step === UserFormStep.ROLEORGANIZATIONS && !formIsReadonly &&
                                                <SaveSection
                                                    handleBack={() => setStep(UserFormStep.GENERALINFO)}
                                                    handleClose={handleClose}
                                                    handleSubmit={handleSubmit}
                                                    validateSubmit={() => dirty && isValid && !isSubmitting}
                                                />
                                            }

                                            {
                                                step === UserFormStep.ROLEORGANIZATIONS && formIsReadonly &&
                                                <SaveSection
                                                    handleBack={() => setStep(UserFormStep.GENERALINFO)}
                                                    handleClose={handleClose}
                                                />
                                            }
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                }
            </Modal>
        </>
    );
};
