
export type ImportFileViewModel = {
    fileIdentifier: string;
    originalFileName: string;
    createdOn: Date;
    processStatusMessage: string;
    rowCount: number;
    processedRows: number;
    customerImportRecords: ImportRecordViewModel[]
}

export type ImportRecordViewModel = {
    recordId: number;
    name: string;
    processStatus: ImportProcessStatuses,
    errors: ImportErrorViewModel[],
}

export type ImportErrorViewModel = {
    id: number;
    errorMessage: string;
    customerImportRecordId: number;
}

export enum ImportFileStatusMessage {
    Success = 'File upload was sucessful',
    Failed = 'Unable to process file',
    Pending = 'File is beign processed'
}

export enum ImportProcessStatuses {
    Pending = 0,
    Error = 1,
    Success = 2,
}