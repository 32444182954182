import React from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { UsePreferencesPartial } from '../../models/userPreferences';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from '../../models/user';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Button, Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { Grid } from '@mui/material';
import { IUserPreferenceFormProps } from './IUserPreferenceFormProps';
import { languages } from '../../../i18n/i18nConstants';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const UserPreferenceForm = ({ timeZones }: IUserPreferenceFormProps): JSX.Element => {
    const { values: userPreferences, handleBlur, setFieldValue, isSubmitting, dirty, isValid } = useFormikContext() as FormikProps<UsePreferencesPartial>;
    const { t } = useTranslation();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const handleTimeZoneChange = async (item: DropdownOption) => {
        let timeZone = currentUser.preferences.timeZone;
        let timeZoneIana = currentUser.preferences.timeZoneIana;

        if (item) {
            const timeZoneObject = timeZones.find(t => t.text === item.value);

            if (timeZoneObject) {
                timeZone = timeZoneObject.text;

                if (!timeZoneObject.utc.includes(timeZoneIana)) {
                    timeZoneIana = timeZoneObject.utc[0];
                }
            }
        }

        await setFieldValue('timeZone', timeZone);
        await setFieldValue('timeZoneIana', timeZoneIana);
    };

    const languageChoices: DropdownOption[] = Object.keys(languages).map((key, index) => (
        {
            id: index,
            label: key.toUpperCase(),
            value: index,
        }
    ));

    const timeZoneChoices: DropdownOption[] = timeZones.map(t => (
        {
            id: t.text,
            label: t.text,
            value: t.text,
        }
    ));

    const timeZoneByUserPreferences = timeZones.find(t => t.text === userPreferences.timeZone);
    const timeZoneValueOption = timeZoneChoices.find(ch => ch.value == timeZoneByUserPreferences?.text);

    return (
        <>
            <Grid container spacing={2} maxWidth={'max-content'}>

                <Grid item xs={12}>
                    <Dropdown<StyledSearchableDropdownProps>
                        label={t('Choose your language')}
                        greyedOutLabelOnDisabled
                        variant={'searchable'}
                        value={languageChoices.find(obj => obj.value === userPreferences.language) || { id: '', label: '', value: '' }}
                        options={languageChoices}
                        onBlur={handleBlur}
                        onChange={(_, item: any) => setFieldValue('language', item ? (item as DropdownOption).value : 0)}
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Dropdown<StyledSearchableDropdownProps>
                        label={t('Choose your time zone')}
                        greyedOutLabelOnDisabled
                        variant={'searchable'}
                        value={timeZoneValueOption || { id: '', label: '', value: '' }}
                        options={timeZoneChoices}
                        onBlur={handleBlur}
                        onChange={(_, item: any) => handleTimeZoneChange(item as DropdownOption)}
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                        optionListStyles={{ '& li': { minHeight: '50px !important', height: 'max-content !important' } }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        id='submit'
                        type={'submit'}
                        variant='primary'
                        label={t('Save')}
                        disabled={isSubmitting || !dirty || !isValid}
                    />
                </Grid>

            </Grid>
        </>
    );
};
