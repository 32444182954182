import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { ActivityTypeTypeOptionModel } from '../models/activityTypeModel';

class ActivityTypeService {
    async GetActivityTypeOptions(): Promise<ActionResult<ActivityTypeTypeOptionModel[]>> {
        const url = '/ActivityTypes/GetActivityTypeOptions';
        const response = await api.get<ActivityTypeTypeOptionModel[]>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }
}

export const activityTypeService = new ActivityTypeService();