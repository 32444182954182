export type EquipmentTrafficLightViewModel = {
    underValidContractCount: number;
    servicedInTheLastYearCount: number;
    notServicedInTheLastYearCount: number;
}

export const getDefaultEquipmentTrafficLightData = (): EquipmentTrafficLightViewModel => {
    return {
        underValidContractCount: 0,
        servicedInTheLastYearCount: 0,
        notServicedInTheLastYearCount: 0,
    };
};