
export type UserPermissions = {
    canViewEquipments: boolean;
    canEditEquipments: boolean,
    canEditEquipmentMainInfo: boolean,
    canEditEquipmentDetailInfo: boolean,
    canEditEquipmentContractInformation: boolean;
    canViewServiceOrders: boolean;
    canCreateServiceOrders: boolean;
    canEditServiceOrders: boolean;
    canViewOrganizations: boolean;
    canViewUsers: boolean;
    canCreateUsers: boolean;
    canViewCustomers: boolean;
    canCreateCustomers: boolean;
    canViewServicePlannings: boolean;
    canViewServiceExecutons: boolean;
    canViewStartupServiceExecutions: boolean;
    canEditServiceExecutions: boolean;

    canFilterByEquipmentType: boolean;
    canFilterByBrandType: boolean;
    canFilterByBrand: boolean;
    canFilterByOrganization: boolean;
    canFilterByCountry: boolean;
    canFilterTrafficLightByCountry: boolean;
    canFilterByInstalledBaseCode: boolean;
    canFilterTrafficLightByBrandType: boolean;
    canFilterTrafficLightByBrand: boolean;
    canFilterByCustomerCenter: boolean;
    canFilterByDistributorOrganization: boolean;

    canViewKpis: boolean;
    canViewServiceOrderCreationKpi: boolean;
    canViewCustomerCreationKpi: boolean;
    canViewInstalledBaseDashboard: boolean;
    canViewOnboardedDistributorsKpi: boolean;
    canViewTrafficLightKpi: boolean;
    canViewLeadsKpi: boolean;
};

export const getDefaultPermissions = (): UserPermissions => {
    return {
        canViewEquipments: false,
        canEditEquipments: false,
        canEditEquipmentMainInfo: false,
        canEditEquipmentDetailInfo: false,
        canEditEquipmentContractInformation: false,
        canViewServiceOrders: false,
        canCreateServiceOrders: false,
        canEditServiceOrders: false,
        canViewOrganizations: false,
        canViewUsers: false,
        canCreateUsers: false,
        canViewCustomers: false,
        canCreateCustomers: false,
        canViewServicePlannings: false,
        canViewServiceExecutons: false,
        canViewStartupServiceExecutions: false,
        canEditServiceExecutions: false,

        canViewKpis: false,
        canFilterByEquipmentType: false,
        canFilterByBrandType: false,
        canFilterByBrand: false,
        canFilterByOrganization: false,
        canFilterByCountry: false,
        canFilterTrafficLightByCountry: false,
        canFilterByInstalledBaseCode: false,
        canFilterTrafficLightByBrandType: false,
        canFilterTrafficLightByBrand: false,
        canFilterByCustomerCenter: false,
        canFilterByDistributorOrganization: false,
        canViewServiceOrderCreationKpi: false,
        canViewCustomerCreationKpi: false,
        canViewInstalledBaseDashboard: false,

        canViewOnboardedDistributorsKpi: false,
        canViewTrafficLightKpi: false,
        canViewLeadsKpi: false,
    };
};
