import React, { useMemo, useState } from 'react';
import { Icon } from '@ui-components/ui-library';
import { GridRenderCellParams, GridEnrichedColDef, GridRowParams, getGridDateOperators } from '@mui/x-data-grid-pro';
import { IServiceOrdersTableProps } from './IServiceOrdersTableProps';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ServiceOrderViewModel } from '../../../serviceOrders/models/serviceOrderModel';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';
import { serviceOrderService } from '../../../serviceOrders/services/ServiceOrderService';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { StyledGridCellContent } from '../../../shared/utils/sxStyleObjects';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { ServiceOrderForm } from '../../../serviceOrders/components/serviceOrderForm/ServiceOrderForm';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import moment from 'moment-timezone';
import { FilterDatePicker } from '../../../components/filterDatePicker/FilterDatePicker';
import { serviceOrderNumberComparator } from '../../../serviceOrders/services/ServiceOrderUtility';
import { i18n } from '../../../i18n/config';

export const ServiceOrdersTable = (props: IServiceOrdersTableProps): JSX.Element => {
    const { serviceOrders, updateNotPlannedServiceOrders, getServiceOrders } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);
    const [selectedServiceOrder, setSelectedServiceOrder] = useState<ServiceOrderViewModel | undefined>(undefined);
    const [serviceOrderFormIsOpen, setServiceOrderFormIsOpen] = useState<boolean>(false);

    const createdOnBodyTemplate = (params: GridRenderCellParams<ServiceOrderViewModel>) => {
        const formatedDate = moment(params.row.createdOn).format(currentUser.preferences.dateFormat);

        return (
            <StyledGridCellContent>{formatedDate}</StyledGridCellContent>
        );
    };

    const handleServiceOrderDeletion = async () => {
        dispatch(setIsLoading(true));

        if (selectedServiceOrder) {
            var response = await serviceOrderService.DeleteServiceOrder(selectedServiceOrder.id);

            if (response && response.isSuccess) {
                dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                updateNotPlannedServiceOrders(selectedServiceOrder.id);
            } else {
                dispatch(showErrors(response.errorModel?.errors));
            }
        } else {
            dispatch(showErrors([t('Please select a service order.')]));
        }

        setDeleteDialogIsOpen(false);
        dispatch(setIsLoading(false));
    };

    const handleOpenDeleteDialog = (serviceOrder: ServiceOrderViewModel) => {
        setSelectedServiceOrder(serviceOrder);
        setDeleteDialogIsOpen(true);
    };

    const openServiceOrderForm = (serviceOrder: ServiceOrderViewModel) => {
        setSelectedServiceOrder(serviceOrder);
        setServiceOrderFormIsOpen(true);
    };

    const closeServiceOrderForm = () => {
        setSelectedServiceOrder(undefined);
        setServiceOrderFormIsOpen(false);
    };

    const getColumns = (): GridEnrichedColDef[] => {
        let columns: GridEnrichedColDef[] = [
            {
                field: 'createdOn',
                headerName: t('Created on'),
                description: t('Created on'),
                renderCell: createdOnBodyTemplate,
                type: 'date',
                sortable: true,
                filterable: true,
                pinnable: false,
                flex: 2,
                filterOperators: getGridDateOperators()
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? FilterDatePicker
                            : undefined,
                        InputComponentProps: { inputFormat: currentUser.preferences.dateFormat }
                    })),
            },
            { field: 'number', headerName: t('Service Order Number'), sortable: true, sortComparator: serviceOrderNumberComparator, filterable: true, pinnable: false, flex: 3, description: t('Service Order Number') },
            { field: 'companyName', headerName: t('Customer Name'), sortable: true, filterable: true, pinnable: false, flex: 3, description: t('Customer Name') },
            {
                field: 'estimatedHours',
                headerName: t('Estimated Job Hours'),
                description: t('Estimated Job Hours'),
                type: 'number',
                sortable: true,
                filterable: true,
                pinnable: false,
                flex: 3,
                headerAlign: 'left',
                align: 'left'
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                description: t('Actions'),
                flex: 1,
                getActions: (params: GridRowParams<ServiceOrderViewModel>) => [
                    <DataGridActionOverview
                        key={'edit'}
                        icon={<Icon type='visibility' />}
                        label={t('View')}
                        onClick={() => openServiceOrderForm(params.row)}
                        showInMenu
                    />,
                    <DataGridActionOverview
                        key={'delete'}
                        icon={<Icon type='delete' />}
                        label={t('Delete')}
                        onClick={() => handleOpenDeleteDialog(params.row)}
                        showInMenu
                    />
                ]
            },
        ];

        return columns;
    };

    const memoizedColumns = useMemo(() => getColumns(), [i18n.language]);

    return (
        <>
            <DataGridOverview
                rows={serviceOrders}
                columns={memoizedColumns}
                pagination
                pageSize={10}
                containeraAutoHeight
            />
            <ConfirmationDialog
                open={deleteDialogIsOpen}
                onClose={() => setDeleteDialogIsOpen(false)}
                onConfirm={async () => await handleServiceOrderDeletion()}
                message={t('Are you sure you want to remove {{serviceOrderNumber}}?', { serviceOrderNumber: selectedServiceOrder?.number })}
            />

            {serviceOrderFormIsOpen && selectedServiceOrder &&
                <ServiceOrderForm
                    formIsOpen={serviceOrderFormIsOpen}
                    handleClose={closeServiceOrderForm}
                    serviceOrderId={selectedServiceOrder.id}
                    getServiceOrders={getServiceOrders}
                />
            }
        </>
    );
};

