import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { EquipmentTrafficLightFilterModel, getDefaultEquipmentTrafficLightFilterModel } from '../../models/EquipmentTrafficLightFilterModel';
import { Restricted } from '../../../components/elements/restricted/Restricted';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { ALL_TEXT, NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { EquipmentType } from '../../../equipments/models/equipmentType';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { BrandViewModel } from '../../../models/brand/brandModel';
import { BrandTypeViewModel } from '../../../models/brand/brandTypeModel';
import { CountryFormModel } from '../../../organizations/models/countryModel';
import { InstalledBaseViewModel } from '../../../models/installedBase/installedBaseViewModel';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { isEqual, sortBy } from 'lodash';
import { dashboardService } from '../../../services/DashboardService';
import { EquipmentTrafficLightViewModel, getDefaultEquipmentTrafficLightData } from '../../../models/dashboard/EquipmentTrafficLightViewModel';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { showErrors } from '../../../stores/alert/alertSlice';
import { DropdownListItemLongContentSxObject } from '../../../shared/utils/sxStyleObjects';
import { EquipmentCategories } from '../../../shared/enums/EquipmentCategories';
import { userIsGlobalAdmin, userIsLocalAdmin } from '../../../stores/user/usersSlice';

const StyledTrafficBoxRow = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '1em'
})) as typeof Box;

const StyledTrafficRowLabel = styled('span')(() => ({
    fontSize: '18px'
}));

export const EquipmentTrafficLightDashboard = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useState<EquipmentTrafficLightFilterModel>(getDefaultEquipmentTrafficLightFilterModel());
    const [notAppliedFilters, setNotAppliedFilters] = useState<any>({});

    const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));
	const isLocalAdmin: boolean = useAppSelector(state => userIsLocalAdmin(state));

    const equipmentTypeOptions: EquipmentType[] = useAppSelector(state => state.Dashboard.equipmentTypeOptions);
    const brandOptions: BrandViewModel[] = useAppSelector(state => state.Dashboard.brandOptions);
    const brandTypeOptions: BrandTypeViewModel[] = useAppSelector(state => state.Dashboard.brandTypeOptions);
    const countryOptions: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);
    const installedBaseOptions: InstalledBaseViewModel[] = useAppSelector(state => state.Dashboard.installedBaseOptions);
    const organizationOptions: OrganizationOptionModel[] = useAppSelector(state => state.Dashboard.organizationOptions);
    const customerCenterOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.CustomerCenter), [organizationOptions]);
    const distributorOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.Distributor), [organizationOptions]);

    const [trafficLightData, setTrafficLightData] = useState<EquipmentTrafficLightViewModel>(getDefaultEquipmentTrafficLightData());
    const [selectedValues, setSelectedValues] = useState<any>({});

    const getEquipmentTrafficLightKpiData = async (filters: EquipmentTrafficLightFilterModel) => {
        dispatch(setIsLoading(true));

        const trafficLightDataResponse = await dashboardService.GetEquipmentTrafficLightData(filters);

        if (trafficLightDataResponse.isSuccess) {
            setTrafficLightData(trafficLightDataResponse.result!);
        } else {
            dispatch(showErrors(trafficLightDataResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const handleFilterChange = (propName: keyof EquipmentTrafficLightFilterModel, value: any): void => {
        setNotAppliedFilters({ ...notAppliedFilters, [propName]: value });
    };

    const getSelectedDropdownOption = (propName: keyof EquipmentTrafficLightFilterModel, optionsCollection: any[]): DropdownOption | null | undefined => {
        if ((!appliedFilters[propName as keyof typeof appliedFilters] && !notAppliedFilters[propName as keyof typeof notAppliedFilters]) ||
            (propName in notAppliedFilters && notAppliedFilters[propName as keyof typeof notAppliedFilters] === undefined)) {
            return null;
        }

        if (notAppliedFilters[propName as keyof typeof notAppliedFilters]) {
            return optionsCollection.find(et => et.id === notAppliedFilters[propName as keyof typeof notAppliedFilters]);
        } else if (appliedFilters[propName as keyof typeof appliedFilters]) {
            return optionsCollection.find(o => o.id === appliedFilters[propName as keyof typeof appliedFilters]);
        }
    };

    const getSelectedMultipleDropdownOption = (propName: keyof EquipmentTrafficLightFilterModel, optionsCollection: any[]): DropdownOption[] | undefined => {
        if ((!appliedFilters[propName as keyof typeof appliedFilters] && !notAppliedFilters[propName as keyof typeof notAppliedFilters]) ||
            (propName in notAppliedFilters && notAppliedFilters[propName as keyof typeof notAppliedFilters] === undefined)) {
            return [];
        }

        if (notAppliedFilters[propName as keyof typeof notAppliedFilters]) {
            return optionsCollection.filter(et => notAppliedFilters[propName as keyof typeof notAppliedFilters].includes(et.id));
        } else if (appliedFilters[propName as keyof typeof appliedFilters] && Array.isArray(appliedFilters[propName as keyof typeof appliedFilters])) {
            return optionsCollection.filter(o => (appliedFilters[propName as keyof typeof appliedFilters] as Array<any>).includes(o.id));
        }

        return [];
    };

    const handleEquipmentTypeChange = (items: DropdownOption[] | null) => {
        if (items && items.length > 0) {            
            const values = items.map(i => i.value);
            const childrenInstalledBases = installedBaseOptions.filter(ibo => values.includes(ibo.equipmentTypeId));
            const currentInstalledBase = notAppliedFilters['installedBaseId'] || appliedFilters['installedBaseId'];
            const shouldClearInstalledBase: boolean = currentInstalledBase &&
                !childrenInstalledBases.some(co => co.id === currentInstalledBase);

            if (shouldClearInstalledBase) {
                setNotAppliedFilters({ ...notAppliedFilters, equipmentTypeIds: sortBy(values), installedBaseId: undefined });
            } else {
                setNotAppliedFilters({ ...notAppliedFilters, equipmentTypeIds: sortBy(values) });
            }

            setSelectedValues({ ...selectedValues, equipmentTypeIds: values });
        } else {
            setNotAppliedFilters({ ...notAppliedFilters, equipmentTypeIds: undefined });
            setSelectedValues({ ...selectedValues, equipmentTypeIds: null });
        }
    };

    const handleBrandTypeChange = (value: number | undefined) => {
        if (value) {
            const childrenBrands = brandOptions.filter(ibo => ibo.brandTypeId === value);
            const currentBrand = notAppliedFilters['brandId'] || appliedFilters['brandId'];
            const shouldClearBrand: boolean = currentBrand &&
                !childrenBrands.some(co => co.id === currentBrand);

            if (shouldClearBrand) {
                setNotAppliedFilters({ ...notAppliedFilters, brandTypeId: value, brandId: undefined });
            } else {
                setNotAppliedFilters({ ...notAppliedFilters, brandTypeId: value });
            }
        } else {
            setNotAppliedFilters({ ...notAppliedFilters, brandTypeId: undefined });
        }

        setSelectedValues({ ...selectedValues, brandTypeId: value ? value : null });
    };

    const handleCustomerCenterChange = (value: number | undefined) => {
        if (value) {
            const childrenOrganizations = organizationOptions.filter(oo => oo.parentOrganizationId === value);
            const currentSelectedDistributorId = notAppliedFilters['distributorOrganizationId'] || appliedFilters['distributorOrganizationId'];
            const shouldClearDistributor: boolean = currentSelectedDistributorId &&
                !childrenOrganizations.some(co => co.id === currentSelectedDistributorId);

            if (shouldClearDistributor) {
                setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value, distributorOrganizationId: undefined });
            } else {
                setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value });
            }

        } else {
            setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: undefined });
        }

        setSelectedValues({ ...selectedValues, customerCenterId: value ? value : null });
    };

    const handleDistributorOrganizationChange = (value: number | undefined) => {
        if (value) {
            const selectedDistributor = distributorOptions.find(o => o.id === value);
            const customerCenterOrganizations = customerCenterOptions.filter(cc => cc.id === selectedDistributor?.parentOrganizationId);

            const currentCustomerCenterId = notAppliedFilters['customerCenterId'] || appliedFilters['customerCenterId'];
            const shouldClearCustomerCenter: boolean = currentCustomerCenterId &&
                !customerCenterOrganizations.some(cc => cc.id === currentCustomerCenterId);

            if (shouldClearCustomerCenter) {
                setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: value, customerCenterId: undefined });
            } else {
                setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: value });
            }

        } else {
            setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: undefined });
        }

        setSelectedValues({ ...selectedValues, distributorOrganizationId: value ? value : null });
    };

    const closeFilterModal = () => {
        setFilterModalIsOpen(false);
        setNotAppliedFilters({});
        setSelectedValues({ 
            ...selectedValues, 
            customerCenterId: appliedFilters['customerCenterId'], 
            distributorOrganizationId: appliedFilters['distributorOrganizationId'],
            equipmentTypeId: appliedFilters['equipmentTypeIds'],
            brandTypeId: appliedFilters['brandTypeId'],
        });
    };

    const applyButtonIsDisabled = (): boolean => {
        return Object.keys(notAppliedFilters).length === 0 || !Object.keys(notAppliedFilters).some(naf =>
            !isEqual(appliedFilters[naf as keyof typeof appliedFilters], notAppliedFilters[naf]));
    };

    const applyFilters = async (): Promise<void> => {
        setAppliedFilters({ ...appliedFilters, ...notAppliedFilters });
        closeFilterModal();
        getEquipmentTrafficLightKpiData({ ...appliedFilters, ...notAppliedFilters });
    };

    const clearFilters = async () => {
        await getEquipmentTrafficLightKpiData(getDefaultEquipmentTrafficLightFilterModel());
        setAppliedFilters(getDefaultEquipmentTrafficLightFilterModel());
        setSelectedValues({});
    };

    const selectableFilters = (({ equipmentTypeIds, brandId, brandTypeId, customerCenterId, distributorOrganizationId, countryId, installedBaseId }) => ({ equipmentTypeIds, brandId, brandTypeId, customerCenterId, distributorOrganizationId, countryId, installedBaseId }))(appliedFilters);
    const hasAppliedFilters: boolean = Object.keys(selectableFilters).some(of => appliedFilters[of as keyof typeof appliedFilters]);

    useEffect(() => {
        getEquipmentTrafficLightKpiData(getDefaultEquipmentTrafficLightFilterModel());
    }, []);

    const equipmentTypeDropdownOptions = useMemo(() => equipmentTypeOptions.filter(e => e.equipmentCategory !== EquipmentCategories.Other).map(et => ({ id: et.id, label: t(et.description), value: et.id })), [equipmentTypeOptions]);
    const brandTypeDropdownOptions = useMemo(() => brandTypeOptions.map(b => ({ id: b.id, label: b.description, value: b.id })), [brandTypeOptions]);
    
    const countryDropdownOptions = useMemo(() => {
        if (isGlobalAdmin) {
            return countryOptions.filter(c => c.isCustomerCenterCountry).map(c => ({ id: c.id, label: c.description, value: c.id }));
        }

        if (isLocalAdmin) {
            return countryOptions.filter(c => c.isDistributorOrganizationCountry).map(c => ({ id: c.id, label: c.description, value: c.id }));
        }

        return [];
    }, [countryOptions, isGlobalAdmin, isLocalAdmin]);
    
    const brandDropdownOptions = useMemo(() => {
        if (brandOptions && brandOptions.length > 0) {
            const brandTypeIsSelected: boolean = notAppliedFilters['brandTypeId'] !== undefined ||
                (appliedFilters['brandTypeId'] !== undefined && !('brandTypeId' in notAppliedFilters));

            if (brandTypeIsSelected) {
                if (notAppliedFilters['brandTypeId'] !== undefined) {
                    return brandOptions
                        .filter(dop => dop.brandTypeId === notAppliedFilters['brandTypeId'])
                        .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
                } else if (appliedFilters['brandTypeId'] !== undefined) {
                    return brandOptions
                        .filter(dop => dop.brandTypeId === appliedFilters['brandTypeId'])
                        .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
                }
            }

            return brandOptions.map(et => ({ id: et.id, label: t(et.name), value: et.id }));
        }

        return [];

    }, [brandOptions, selectedValues['brandTypeId']]);
    
    const installedBaseDropdownOptions = useMemo(() => {
        if (installedBaseOptions && installedBaseOptions.length > 0) {
            const equipmentTypeIsSelected: boolean = notAppliedFilters['equipmentTypeIds'] !== undefined ||
                (appliedFilters['equipmentTypeIds'] !== undefined && !('equipmentTypeIds' in notAppliedFilters));

            if (equipmentTypeIsSelected) {
                if (notAppliedFilters['equipmentTypeIds'] !== undefined) {
                    return installedBaseOptions
                        .filter(dop => notAppliedFilters['equipmentTypeIds'].includes(dop.equipmentTypeId))
                        .map(et => ({ id: et.id, label: t(et.description), value: et.id }));
                } else if (appliedFilters['equipmentTypeIds'] !== undefined) {
                    return installedBaseOptions
                        .filter(dop => appliedFilters['equipmentTypeIds']?.includes(dop.equipmentTypeId))
                        .map(et => ({ id: et.id, label: t(et.description), value: et.id }));
                }
            }

            return installedBaseOptions.map(et => ({ id: et.id, label: t(et.description), value: et.id }));
        }

        return [];

    }, [installedBaseOptions, selectedValues['equipmentTypeIds'], appliedFilters.equipmentTypeIds, notAppliedFilters['equipmentTypeIds']]); 

    const customerCenterDropdownOptions = useMemo(() => {
        if (customerCenterOptions && customerCenterOptions.length > 0) {
            const distributorIsSelected: boolean = notAppliedFilters['distributorOrganizationId'] !== undefined ||
                (appliedFilters['distributorOrganizationId'] !== undefined && !('distributorOrganizationId' in notAppliedFilters));
            
            if (distributorIsSelected) {
                if (notAppliedFilters['distributorOrganizationId'] !== undefined) {
                    const selectedDistributor = distributorOptions.find(dop => dop.id === notAppliedFilters['distributorOrganizationId']);

                    return customerCenterOptions
                        .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
                        .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
                } else if (appliedFilters['distributorOrganizationId'] !== undefined) {
                    const selectedDistributor = distributorOptions.find(dop => dop.id === appliedFilters['distributorOrganizationId']);

                    return customerCenterOptions
                        .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
                        .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
                }
            }

            return customerCenterOptions.map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        }

        return [];
    }, [customerCenterOptions, selectedValues['distributorOrganizationId']]);

    const distributorDropdownOptions = useMemo(() => {
        if (distributorOptions && distributorOptions.length > 0) {
            const customerCenterIsSelected: boolean = notAppliedFilters['customerCenterId'] !== undefined ||
                (appliedFilters['customerCenterId'] !== undefined && !('customerCenterId' in notAppliedFilters));

            if (customerCenterIsSelected) {
                if (notAppliedFilters['customerCenterId'] !== undefined) {
                    return distributorOptions
                        .filter(dop => dop.parentOrganizationId === notAppliedFilters['customerCenterId'])
                        .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
                } else if (appliedFilters['customerCenterId'] !== undefined) {
                    return distributorOptions
                        .filter(dop => dop.parentOrganizationId === appliedFilters['customerCenterId'])
                        .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
                }
            }

            return distributorOptions.map(et => ({ id: et.id, label: t(et.name), value: et.id }));
        }

        return [];
    }, [distributorOptions, selectedValues['customerCenterId']]);

    return (
        <>
            <Box>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box fontSize={18} flex={2} textAlign={'left'}>{t('Traffic Light')}</Box>

                    <Tooltip
                        title={t('Filter')}
                    >
                        <IconButton id={'filter'} onClick={() => setFilterModalIsOpen(true)}>
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>

                    {
                        hasAppliedFilters &&
                        <Tooltip
                            title={t('Clear filters')}
                        >
                            <IconButton id={'clear_filters'} onClick={clearFilters}>
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>

                <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={'1rem'}>
                    <StyledTrafficBoxRow>
                        <FiberSmartRecordIcon sx={{ fontSize: '50px', color: '#43B02A' }} />
                        <StyledTrafficRowLabel>
                            {
                                trafficLightData.underValidContractCount === 1 ?
                                t('{{underValidContract}} machine under contract', { underValidContract: trafficLightData.underValidContractCount }) :
                                t('{{underValidContract}} machines under contract', { underValidContract: trafficLightData.underValidContractCount })
                            }
                        </StyledTrafficRowLabel>
                    </StyledTrafficBoxRow>

                    <StyledTrafficBoxRow>
                        <FiberSmartRecordIcon sx={{ fontSize: '50px', color: '#e8c120' }} />
                        <StyledTrafficRowLabel>
                            {
                                trafficLightData.servicedInTheLastYearCount === 1 ?
                                    t('{{servicedInLastYear}} machine serviced in the last 12 months', { servicedInLastYear: trafficLightData.servicedInTheLastYearCount }) :
                                    t('{{servicedInLastYear}} machines serviced in the last 12 months', { servicedInLastYear: trafficLightData.servicedInTheLastYearCount })
                            }
                        </StyledTrafficRowLabel>
                    </StyledTrafficBoxRow>

                    <StyledTrafficBoxRow>
                        <FiberSmartRecordIcon sx={{ fontSize: '50px', color: '#C8102E' }} />
                        <StyledTrafficRowLabel>
                            {
                                trafficLightData.notServicedInTheLastYearCount === 1 ?
                                t('{{notServicedInOneYear}} machine without any contract or service in the last 12 months', { notServicedInOneYear: trafficLightData.notServicedInTheLastYearCount }) :
                                t('{{notServicedInOneYear}} machines without any contract or service in the last 12 months', { notServicedInOneYear: trafficLightData.notServicedInTheLastYearCount })
                            }
                        </StyledTrafficRowLabel>
                    </StyledTrafficBoxRow>
                </Box>
            </Box>

            <FilterModal
                isOpen={filterModalIsOpen}
                disableApply={applyButtonIsDisabled()}
                onClose={closeFilterModal}
                onApply={applyFilters}
            >
                <Grid container spacing={2}>
                    <Restricted to={'canFilterByEquipmentType'}>
                        <Grid item xs={12} md={12}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Equipment Type')}
                                options={equipmentTypeDropdownOptions}
                                value={getSelectedMultipleDropdownOption('equipmentTypeIds', equipmentTypeDropdownOptions)}
                                onChange={(e, items) => handleEquipmentTypeChange(items ? items as DropdownOption[] : null)}
                                variant='searchable'
                                multiple
                                usePopper
                                disabled={equipmentTypeDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterTrafficLightByBrandType'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Brand Type')}
                                options={brandTypeDropdownOptions}
                                value={getSelectedDropdownOption('brandTypeId', brandTypeDropdownOptions)}
                                onChange={(e, item) => handleBrandTypeChange(item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={brandTypeDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterTrafficLightByBrand'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Brand')}
                                options={brandDropdownOptions}
                                value={getSelectedDropdownOption('brandId', brandDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('brandId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={brandDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByCustomerCenter'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Customer Center')}
                                options={customerCenterDropdownOptions}
                                value={getSelectedDropdownOption('customerCenterId', customerCenterDropdownOptions)}
                                onChange={(e, item) => handleCustomerCenterChange(item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={customerCenterDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByDistributorOrganization'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Distributor Organization')}
                                options={distributorDropdownOptions}
                                value={getSelectedDropdownOption('distributorOrganizationId', distributorDropdownOptions)}
                                onChange={(e, item) => handleDistributorOrganizationChange(item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={distributorDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                optionListStyles={DropdownListItemLongContentSxObject}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterTrafficLightByCountry'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Country')}
                                options={countryDropdownOptions}
                                value={getSelectedDropdownOption('countryId', countryDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('countryId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={countryDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByInstalledBaseCode'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Installed Base Code')}
                                options={installedBaseDropdownOptions}
                                value={getSelectedDropdownOption('installedBaseId', installedBaseDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('installedBaseId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={installedBaseDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                </Grid>
            </FilterModal>
        </>
    );
};