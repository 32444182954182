import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { styled } from '@mui/material/styles';
import { Box, Container, Paper, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setCurrentUser, userCanViewKPIs, userLoaded } from '../../stores/user/usersSlice';
import { NavMenu } from './NavMenu';
import { showErrors, showSuccess } from '../../stores/alert/alertSlice';
import { useTranslation } from 'react-i18next';
import { userService } from '../../users/services/UserService';
import { AuthenticatedUser } from '../../users/models/user';
import { changeApplicationMomentLocale, getLanguageCodeFromBrowserLanguage, isMobileDevice } from '../../shared/utils/Utils';
import { useSnackbar } from '../../hooks/useSnackbar';
import type { } from '@mui/x-data-grid-pro/themeAugmentation';
import { Overlay } from '../overlay/Overlay';
import { getDefaultPreferences, Language, localesByLanguage } from '../../users/models/userPreferences';
import { i18n } from '../../i18n/config';
import { RoleType } from '../../shared/enums/RoleType';
import { brandService } from '../../services/BrandService';
import { countryService } from '../../services/CountryService';
import { equipmentTypeService } from '../../services/EquipmentTypeService';
import { insallBassesService } from '../../services/InstalledBasesService';
import { organizationService } from '../../organizations/services/OrganizationService';
import { setBrandOptions, setBrandTypeOptions, setCountryOptions, setEquipmentTypeOptions, setInstalledBaseOptions, setOrganizationOptions } from '../../stores/dashboard/dashboardSlice';
import { CookiesBanner } from '../cookiesBanner/CookiesBanner';
import { useLocation } from 'react-router-dom';
import { useCookie } from '../../hooks/useCookie';
import moment from 'moment-timezone';
import { setIsLoading } from '../../stores/layout/layoutSlice';
import { TermsAndConditionsModal } from '../form/termsAndConditions/TermsAndConditionsModal';
import { COOKIES_CONSENT_NAME, USER_SET_PREFERED_LANGUAGE_COOKIE_NAME, TERMS_AND_CONDITIONS_COOKIE_NAME, USER_LANGUAGE_IS_SET } from '../../shared/utils/constants';
import { gatewayService } from '../../services/GatewayService';
import { TermsAndConditionsViewModel } from '../form/termsAndConditions/termsAndConditionsModel';
import { languages, rtlLanguages } from '../../i18n/i18nConstants';
import { RoleActions } from '../../shared/enums/RoleActions';
import { SUCCESS_MESSAGE } from '../../shared/utils/notificationMessages';
import { SelectLanguageDialog } from '../selectLanguageDialog/SelectLanguageDialog';

import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/tr';
import 'moment/locale/ar-ly';
import { Footer } from './Footer';

const PageContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
}));

export const Layout = (props: any) => {
  const [SnackbarMessage, isVisible, getSnackbarMessage] = useSnackbar();
  const message = useAppSelector(state => state.Alert.message);
  const successMessage: string | undefined = useAppSelector(state => state.Alert.successMessage);
  const errorMessages: string[] | undefined = useAppSelector(state => state.Alert.errorMessages);
  const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
  const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
  const canViewKPIs: boolean = useAppSelector(state => userCanViewKPIs(state));

  const [termsAndConditionsModalIsOpen, setTermsAndConditionsModalIsOpen] = useState<boolean | undefined>(undefined);
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditionsViewModel[]>([]);

  const [browserLanguage, setBrowserLanguage] = useState<string>();
  const [selectPreferedLanguageDialogIsOpen, setSelectPreferedLanguageDialogIsOpen] = useState<boolean>(false);

  const cookiesNames = [
    TERMS_AND_CONDITIONS_COOKIE_NAME,
    COOKIES_CONSENT_NAME,
    USER_SET_PREFERED_LANGUAGE_COOKIE_NAME
  ];

  const [setCookieValue, getCookieValue] = useCookie(cookiesNames);

  const location = useLocation();
  const [showCookiesBanner, setShowCookiesBanner] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const cookiesConsentVersion = process.env.REACT_APP_COOKIE_VERSION;

  const loadDashboardFilterOptions = async () => {
    try {
      const [brandOptionsResponse, brandTypeOptionsResponse, countryOptionsResponse, equipmentTypeOptionsResponse, installedBaseOptionsResponse, organizationOptionsResponse] = await Promise.all([
        await brandService.GetBrandOptions(),
        await brandService.GetBrandTypes(),
        await countryService.GetAllCountries(),
        await equipmentTypeService.GetAllEquipmentTypes(),
        await insallBassesService.GetInstalledBaseOptions(),
        await organizationService.GetOrganizationOptions(),
      ]);

      dispatch(setBrandOptions(orderBy(brandOptionsResponse.result!, b => b.name)));
      dispatch(setBrandTypeOptions(orderBy(brandTypeOptionsResponse.result!, bt => bt.description)));
      dispatch(setCountryOptions(orderBy(countryOptionsResponse.result!, c => c.description)));
      dispatch(setEquipmentTypeOptions(orderBy(equipmentTypeOptionsResponse.result!, et => et.description)));
      dispatch(setInstalledBaseOptions(orderBy(installedBaseOptionsResponse.result!, ib => ib.description)));
      dispatch(setOrganizationOptions(orderBy(organizationOptionsResponse.result!, o => o.name)));
    } catch (error: any) {
      dispatch(showErrors([error.message]));
    }
  };

  const submitTermsAndConditionsConsent = () => {
    setCookieValue(TERMS_AND_CONDITIONS_COOKIE_NAME, termsAndConditions[0].Version);
    setTermsAndConditionsModalIsOpen(false);
  };

  const userHasServiceExecutionPermission = (user: AuthenticatedUser, action: RoleActions): boolean => {
    const combinedPermissions = user.userOrganizationRoles
      .filter(uor => uor.isActive)
      .map(uor => uor.serviceExecutionPermissions)
      .reduce((acc, role) => acc | role, RoleActions.None);

    return (combinedPermissions & action) === action;
  };

  useEffect(() => {
    userService.GetAuthenticatedUser().then((response) => {
      if (response.isSuccess && response.result) {
        const userIsGlobalAdmin = response.result.userOrganizationRoles
          .some(r => r.role === RoleType.GlobalAdministrator && r.isActive);

        const userIsLocalAdmin = response.result.userOrganizationRoles
          .some(r => r.role === RoleType.LocalAdministrator && r.isActive);

        const userIsDistributorOwner = response.result.userOrganizationRoles
          .some(r => r.role === RoleType.DistributorOwner && r.isActive);

        const userIsPlanner = response.result.userOrganizationRoles
          .some(r => r.role === RoleType.Planner && r.isActive);

        const userIsTechnician = response.result.userOrganizationRoles
          .some(r => r.role === RoleType.Technician && r.isActive);

        const canViewStartupServiceExecutions =
          (userIsGlobalAdmin || userIsLocalAdmin) && userHasServiceExecutionPermission(response.result, RoleActions.Read);
        
        const localeByLanguage = localesByLanguage[response.result.preferences.language];
        
        moment.tz.setDefault(response.result.preferences.timeZoneIana);        
        changeApplicationMomentLocale(localeByLanguage);

        dispatch(setCurrentUser({
          ...response.result,
          preferences: {
            ...getDefaultPreferences(),
            language: response.result.preferences?.language,
            timeZone: response.result.preferences?.timeZone,
            timeZoneIana: response.result.preferences?.timeZoneIana,
            momentLocale: localeByLanguage
          },
          permissions: {
            canViewEquipments: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canEditEquipments: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canEditEquipmentMainInfo: userIsGlobalAdmin || userIsLocalAdmin,
            canEditEquipmentDetailInfo: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canEditEquipmentContractInformation: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner,
            canViewServiceOrders: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner,
            canCreateServiceOrders: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner,
            canEditServiceOrders: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner,
            canViewOrganizations: userIsGlobalAdmin || userIsLocalAdmin,
            canViewUsers: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canCreateUsers: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canViewCustomers: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner,
            canCreateCustomers: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner,
            canViewServicePlannings: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner || userIsTechnician,
            canViewServiceExecutons: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner || userIsTechnician,
            canViewStartupServiceExecutions: canViewStartupServiceExecutions,
            canEditServiceExecutions: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner || userIsTechnician,

            canFilterByEquipmentType: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canFilterByBrandType: userIsGlobalAdmin || userIsLocalAdmin,
            canFilterByBrand: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canFilterByOrganization: userIsGlobalAdmin || userIsLocalAdmin,
            canFilterByCountry: userIsGlobalAdmin,
            canFilterTrafficLightByCountry: userIsGlobalAdmin || userIsLocalAdmin,
            canFilterByInstalledBaseCode: userIsGlobalAdmin || userIsLocalAdmin,

            canFilterTrafficLightByBrandType: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canFilterTrafficLightByBrand: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canFilterByCustomerCenter: userIsGlobalAdmin,
            canFilterByDistributorOrganization: userIsGlobalAdmin || userIsLocalAdmin,

            canViewKpis: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canViewServiceOrderCreationKpi: userIsGlobalAdmin || userIsLocalAdmin,
            canViewCustomerCreationKpi: userIsGlobalAdmin,
            canViewInstalledBaseDashboard: userIsGlobalAdmin || userIsLocalAdmin,
            canViewOnboardedDistributorsKpi: userIsGlobalAdmin,
            canViewTrafficLightKpi: userIsGlobalAdmin || userIsLocalAdmin || userIsDistributorOwner || userIsPlanner,
            canViewLeadsKpi: userIsGlobalAdmin || userIsDistributorOwner || userIsPlanner,
          },
          isOnMobileDevice: isMobileDevice(),
        }));
      } else {
        dispatch(showErrors(response?.errorModel?.errors));
      }
    });
  }, []);

  // in Chrome browser cookies cannot have an expiration date more than 400 days in the future
  // if cookie exists, update it to set newer expiration date
  useEffect(() => {
    const cookieConsent = getCookieValue(COOKIES_CONSENT_NAME);

    if (cookieConsent) {
      setCookieValue(COOKIES_CONSENT_NAME, cookieConsent);
    }
  }, []);

  useEffect(() => {
    if (!userIsLoaded) {
      return;
    }

    changeApplicationLanguage(Language[currentUser.preferences.language]);
  }, [currentUser.preferences.language, userIsLoaded]);

  useEffect(() => {
    // check browser language only after the T&C process is finished 
    // T&C process is finished when the T&C modal doesn't need to be displayed or is closed by the user
    if (!userIsLoaded || termsAndConditionsModalIsOpen === undefined || termsAndConditionsModalIsOpen) {
      return;
    }

    const userLanguage = Language[currentUser.preferences.language];
    const userLanguageIsSetCookie = getCookieValue(USER_SET_PREFERED_LANGUAGE_COOKIE_NAME);
    const browserLanguageCode = getLanguageCodeFromBrowserLanguage();

    if (userLanguageIsSetCookie === undefined && browserLanguageCode !== userLanguage && Object.values(Language).includes(browserLanguageCode)) {
      setBrowserLanguage(browserLanguageCode);
      setSelectPreferedLanguageDialogIsOpen(true);
    }

    // in Chrome browser cookies cannot have an expiration date more than 400 days in the future
    // if cookie exists, update it to set newer expiration date
    if (userLanguageIsSetCookie !== undefined) {
      setCookieValue(USER_SET_PREFERED_LANGUAGE_COOKIE_NAME, userLanguageIsSetCookie);
    }

    i18n.loadLanguages(Object.values(languages));
  }, [userIsLoaded, termsAndConditionsModalIsOpen]);

  const handleUseBrowserLanguage = async () => {
    if (browserLanguage) {
      dispatch(setIsLoading(true));

      const updateBody = { userId: currentUser.id, language: Language[browserLanguage as keyof typeof Language] };
      const response = await userService.UpdateUserLanguage(updateBody);

      if (response.isSuccess) {
        changeApplicationLanguage(browserLanguage);
        changeApplicationMomentLocale(localesByLanguage[Language[browserLanguage as keyof typeof Language]]);

        dispatch(setCurrentUser({
          ...currentUser,
          preferences: { ...currentUser.preferences, language: Language[browserLanguage as keyof typeof Language] }
        }));

        setCookieValue(USER_SET_PREFERED_LANGUAGE_COOKIE_NAME, USER_LANGUAGE_IS_SET);
        setSelectPreferedLanguageDialogIsOpen(false);

        dispatch(showSuccess(t(SUCCESS_MESSAGE)));
      } else {
        dispatch(showErrors(['Error while saving user language']));
      }

      dispatch(setIsLoading(false));
    }
  };

  const handleUsePreferenceLanguage = () => {
    setCookieValue(USER_SET_PREFERED_LANGUAGE_COOKIE_NAME, USER_LANGUAGE_IS_SET);
    setSelectPreferedLanguageDialogIsOpen(false);
  };

  const changeApplicationLanguage = (language: string) => {
    i18n.changeLanguage(language);
    
    const direction = Object.values(rtlLanguages).includes(language) ? 'rtl' : 'ltr';
    document.getElementsByTagName('html')[0].setAttribute('dir', direction);
  };

  const getTermsAndConditions = async () => {
    const termsAndConditionsResponse = await gatewayService.getTermsAndConditions();

    if (termsAndConditionsResponse.isSuccess) {
      if (termsAndConditionsResponse.result) {
        setTermsAndConditions(termsAndConditionsResponse.result!);

        const version = termsAndConditionsResponse.result[0].Version;
        const cookieTermsAndConditionsConsent = getCookieValue(TERMS_AND_CONDITIONS_COOKIE_NAME);

        setTermsAndConditionsModalIsOpen(!cookieTermsAndConditionsConsent || cookieTermsAndConditionsConsent !== version);
      }
    }
  };

  useEffect(() => {
    dispatch(setIsLoading(true));

    getTermsAndConditions().then(() => {
      dispatch(setIsLoading(false));
    });
  }, []);

  useEffect(() => {
    const cookieConsent = getCookieValue(COOKIES_CONSENT_NAME);

    if (!cookieConsent || cookieConsent !== cookiesConsentVersion && !selectPreferedLanguageDialogIsOpen) {
      setShowCookiesBanner(true);
    }
  }, [location]);

  useEffect(() => {
    if (errorMessages && errorMessages.length) {
      getSnackbarMessage('error', t('Error'), errorMessages.join('\r\n'));
    }
  }, [errorMessages]);

  useEffect(() => {
    if (message) {
      if (typeof message === 'string') {
        getSnackbarMessage('info', message, '');
      } else {
        getSnackbarMessage(message.severity, message.summary, message.detail);
      }
    }
  }, [message]);


  useEffect(() => {
    if (successMessage) {
      getSnackbarMessage('success', successMessage, '');
    }
    dispatch(showSuccess(undefined));
  }, [successMessage]);

  useEffect(() => {
    if (!canViewKPIs) {
      return;
    }

    dispatch(setIsLoading(true));

    loadDashboardFilterOptions().then(() => {
      dispatch(setIsLoading(false));
    });
  }, [canViewKPIs]);

  return (
    <>
      {
        showCookiesBanner && <CookiesBanner handleClose={() => setShowCookiesBanner(false)} />
      }

      <Overlay />

      <PageContainer>
        {
          isVisible && <SnackbarMessage />
        }
        {
          userIsLoaded &&
          <>            
            <Box display={'flex'} height={'100vh'} overflow={'hidden'} flexDirection={'column'}>
              <NavMenu />
              <Box flexGrow={1} overflow={'auto'} >
                <Container className='main_view' maxWidth="xl">
                  {props.children}
                </Container>
              </Box>
              <Footer />
            </Box>           
          </>
        }
      </PageContainer>

      {
        <SelectLanguageDialog
          open={userIsLoaded && selectPreferedLanguageDialogIsOpen}
          browserLanguage={browserLanguage}
          preferenceLanguage={Language[currentUser.preferences.language]}
          onClose={() => setSelectPreferedLanguageDialogIsOpen(false)}
          onSelectBrowserLanguage={handleUseBrowserLanguage}
          onSelectPreferenceLanguage={handleUsePreferenceLanguage}
        />
      }

      {
        userIsLoaded && termsAndConditionsModalIsOpen &&
        <TermsAndConditionsModal
          handleClose={() => setTermsAndConditionsModalIsOpen(false)}
          handleSubmit={submitTermsAndConditionsConsent}
          termsAndConditions={termsAndConditions}
        />
      }
    </>
  );
};