import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { ServiceOrderTypeOptionModel } from '../models/serviceOrderTypeOptionModel';

class ServiceOrderTypeService {
    async GetServiceOrderTypeOptions(): Promise<ActionResult<ServiceOrderTypeOptionModel[]>> {
        const url = '/serviceOrderTypes/GetServiceOrderTypeOptions';
        const response = await api.get<ServiceOrderTypeOptionModel[]>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }
}

export const serviceOrderTypeService = new ServiceOrderTypeService();