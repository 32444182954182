import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IServiceOrderRelationDataProps } from './IServiceOrderRelationDataProps';
import { TextField, Dropdown, StyledDefaultDropdownProps } from '@ui-components/ui-library';
import { Box, Grid } from '@mui/material';
import { StyledTitle } from '../../../components/form/title/Title';
import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import { PersonOfInterestVersion } from '../../models/personOfInterestVersionModel';
import { ServiceOrderFormModel } from '../../models/serviceOrderModel';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ServiceOrderPersonOfInterest } from '../serviceOrderPersonOfInterest/ServiceOrderPersonOfInterest';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const ServiceOrderRelationData = (props: IServiceOrderRelationDataProps): JSX.Element => {
	const { t } = useTranslation();

	const isLoading: boolean = useAppSelector(state => state.Layout.isLoading);

	const { values: serviceOrder, handleChange, handleBlur, setFieldValue } = useFormikContext() as FormikProps<ServiceOrderFormModel>;

	const { customerRelatedData, readonly, peopleOfInterestVersionOptions, personOfInterestForCreation, handlePersonOfInterestForCreationChange, validatePersonOfInterestForCreation } = props;

	const preSelectPersonOfInterest = async () => {
		if (peopleOfInterestVersionOptions.length === 1) {
			setFieldValue('personOfInterestVersionId', peopleOfInterestVersionOptions[0].id);
		}
		else {
			const defaultPoi = peopleOfInterestVersionOptions.find(poi => poi.isDefault);
			setFieldValue('personOfInterestVersionId', defaultPoi?.id);
		}
	};

	const poiOptions = peopleOfInterestVersionOptions.map(p => {
		const shouldShowVersion = peopleOfInterestVersionOptions.filter(poiv => poiv.personOfInterestId === p.personOfInterestId).length > 1;
		return {
			id: p.id,
			label: `${p.firstName} ${p.lastName} ${p.isDefault ? t('[default]') : ''} ${shouldShowVersion ? 'v' + p.version : ''}`,
			value: p.id
		};
	});

	const companyAddress = [
		customerRelatedData.companyAddress.addressLine1,
		customerRelatedData.companyAddress.postalCode,
		customerRelatedData.companyAddress.city,
		customerRelatedData.companyAddress.country
	].join(', ');

	useEffect(() => {
		if (serviceOrder.personOfInterestVersionId > 0) {
			return;
		}

		preSelectPersonOfInterest();
	}, [peopleOfInterestVersionOptions]);

	const selectedPersonOfInterest: PersonOfInterestVersion | undefined = peopleOfInterestVersionOptions.find(e => e.id === serviceOrder.personOfInterestVersionId);

	return (
		<Grid item xs={12}>
			<Box pt={3} display={'flex'} justifyContent={'start'}>
				<StyledTitle variant='h4'>{t('Company Information')}</StyledTitle>
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<TextField
						label={t('Name of Company')}
						value={customerRelatedData.nameOfCompany}
						disabled={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						label={t('Address of Company')}
						value={companyAddress}
						multiline
						minRows={3}
						disabled
					/>
				</Grid>
			</Grid>

			<Box width={'100%'} mt={2}>
				{
					peopleOfInterestVersionOptions.length === 0 && !isLoading &&
					<ServiceOrderPersonOfInterest
						personOfInterest={personOfInterestForCreation}
						handlePersonOfInterestChange={handlePersonOfInterestForCreationChange}
						validatePersonOfInterest={validatePersonOfInterestForCreation}
					/>
				}
			</Box>

			<>
				{
					peopleOfInterestVersionOptions.length > 0 &&
					<>
						<Box display={'flex'} justifyContent={'start'}>
							<StyledTitle variant='h4'>{t('Person of Interest')}</StyledTitle>
						</Box>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Dropdown<StyledDefaultDropdownProps>
									id={'personOfInterestVersionId'}
									name={'personOfInterestVersionId'}
									options={poiOptions}
									value={serviceOrder.personOfInterestVersionId ?? ''}
									onChange={handleChange}
									onBlur={handleBlur}
									disabled={readonly}
									noOptionsText={t(NO_OPTIONS_TEXT)}
								/>
								<ErrorMessage name={'personOfInterestVersionId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
							</Grid>
						</Grid>

						<Grid container spacing={2} pt={1}>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									label={t('First Name')}
									value={selectedPersonOfInterest?.firstName}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									label={t('Last Name')}
									value={selectedPersonOfInterest?.lastName}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									label={t('Email')}
									value={selectedPersonOfInterest?.emailAddress}
									disabled={true}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									label={t('Phone Number')}
									value={selectedPersonOfInterest?.phoneNumber}
									disabled={true}
								/>
							</Grid>
						</Grid>
					</>
				}
			</>
		</Grid>
	);
};
