import { RoleType } from '../../shared/enums/RoleType';
import { isBlank, isValidEmail, lengthIsLessThanTwoChars } from '../../shared/utils/Utils';
import { InvalidEmailMessage, RequiredBrandMessage, RequiredMessage, ShortValueMessage } from '../../shared/utils/validationMessages';
import { RoleToAssign, UserFormValues } from '../models/user';
import * as Yup from 'yup';

export const validateForm = (values: UserFormValues, roleOptions: RoleToAssign[]) => {
    const errors: any = {};
    let isValid: boolean = true;

    if (lengthIsLessThanTwoChars(values.firstName)) {
        if (isBlank(values.firstName)) {
            errors.firstName = RequiredMessage;
        } else {
            errors.firstName = ShortValueMessage;
        }
        isValid = false;
    }

    if (lengthIsLessThanTwoChars(values.lastName)) {
        if (isBlank(values.lastName)) {
            errors.lastName = RequiredMessage;
        } else {
            errors.lastName = ShortValueMessage;
        }
        isValid = false;
    }

    if (isBlank(values.email) || !isValidEmail(values.email)) {
        errors.email = InvalidEmailMessage;
        isValid = false;
    }
    errors.externalIdentities = [];

    for (let index = 0; index < values.externalIdentities.length; index++) {
        if (lengthIsLessThanTwoChars(values.externalIdentities[index].identifier)) {
            errors.externalIdentities[index] = {};
            if (isBlank(values.externalIdentities[index].identifier)) {
                errors.externalIdentities[index].identifier = RequiredMessage;
            } else {
                errors.externalIdentities[index].identifier = ShortValueMessage;
            }
            isValid = false;
        }
    }

    errors.userAdditionalInformation = {};

    if (values.userAdditionalInformation.countryId < 1) {
        errors.userAdditionalInformation.countryId = RequiredMessage;
        isValid = false;
    }

    if (lengthIsLessThanTwoChars(values.userAdditionalInformation.phoneNumber)) {
        if (isBlank(values.userAdditionalInformation.phoneNumber)) {
            errors.userAdditionalInformation.phoneNumber = RequiredMessage;
        } else {
            errors.userAdditionalInformation.phoneNumber = ShortValueMessage;
        }
        isValid = false;
    }

    if (lengthIsLessThanTwoChars(values.userAdditionalInformation.city)) {
        if (isBlank(values.userAdditionalInformation.city)) {
            errors.userAdditionalInformation.city = RequiredMessage;
        } else {
            errors.userAdditionalInformation.city = ShortValueMessage;
        }
        isValid = false;
    }

    if (values.userOrganizationRoles.length < 1) {
        errors.userOrganizationRoles = RequiredMessage;
        isValid = false;
    }

    errors.userOrganizationRoles = [];

    for (let index = 0; index < values.userOrganizationRoles.length; index++) {
        const userOrganizationRole = values.userOrganizationRoles[index];
        errors.userOrganizationRoles[index] = {};

        if (userOrganizationRole.roleId < 1) {
            errors.userOrganizationRoles[index].roleId = RequiredMessage;
            isValid = false;
        }

        const selectedRole = roleOptions.find(ro => ro.id === userOrganizationRole.roleId);
        if (selectedRole && selectedRole.key !== RoleType.GlobalAdministrator) {
            if (!userOrganizationRole.organizationId || userOrganizationRole.organizationId < 1) {
                errors.userOrganizationRoles[index].organizationId = RequiredMessage;
                isValid = false;
            }
        }

        if (selectedRole && (selectedRole.key === RoleType.LocalAdministrator || selectedRole.key === RoleType.Technician)) {
            if (values.userOrganizationRoles[index].brandIds.length === 0) {
                errors.userOrganizationRoles[index].brandIds = RequiredBrandMessage;
                isValid = false;
            }
        }
    }

    if (isValid) {
        return {};
    }

    return errors;
};

const userFormGeneralInfoValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .trim()
        .required()
        .min(2),
    lastName: Yup.string()
        .trim()
        .required()
        .min(2),
    email: Yup.string()
        .trim()
        .email()
        .required()
        .min(2),

    userAdditionalInformation: Yup.object().shape({
        countryId: Yup.number()
            .min(1),
        city: Yup.string()
            .trim()
            .required()
            .min(2),
        phoneNumber: Yup.string()
            .trim()
            .required()
            .min(2),
    }),
});

export const userGeneralInfoIsValid = (user: UserFormValues): boolean => {
    return userFormGeneralInfoValidationSchema.isValidSync(user);
};

export const userPreferenceValidationSchema = Yup.object().shape({
    language: Yup.string().required(),
    timeZone: Yup.string().required()
});