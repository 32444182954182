import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import './NavMenu.css';
import { Routes } from '../../routes';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import {
	userCanViewCustomers, userCanViewServiceOrders, userCanViewEquipments, userCanViewOrganizations, userCanViewUsers,
	userCanViewServicePlannings, userCanViewServiceExecutions, userCanBulkImportCustomers, userCanViewStartupServiceExecutions
} from '../../stores/user/usersSlice';
import { AppBar, Box, Container, IconButton, Toolbar, Typography, Menu } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavMenuItem } from './NavMenuItem';
import { Link as LinkRouter } from 'react-router-dom';
import Link from '@mui/material/Link';
import { UserMenu } from '@ui-components/ui-library';
import { AuthenticatedUser } from '../../users/models/user';
import { truncate } from '../../shared/utils/Utils';
import { UserMenuActionType } from '../../shared/enums/UserMenuActionType';
import { msalInstance } from '../..';

const NavBar = styled(AppBar)(({ theme }) => ({
	backgroundColor: theme.palette.colors.neutral[500],
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
	'& .MuiMenu-paper': {
		backgroundColor: theme.palette.colors.neutral[500],
	}
}));

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export const NavMenu = () => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
	const userCanAccessEquipmentView = useAppSelector(state => userCanViewEquipments(state));
	const userCanAccessOrganizationsView = useAppSelector(state => userCanViewOrganizations(state));
	const userCanAccessUsersView = useAppSelector(state => userCanViewUsers(state));
	const userCanAccessCustomersView = useAppSelector(state => userCanViewCustomers(state));
	const userCanAccessServiceOrdersView = useAppSelector(state => userCanViewServiceOrders(state));
	const userCanAccessServicePlanningView = useAppSelector(state => userCanViewServicePlannings(state));
	const userCanAccessServiceExecutionView = useAppSelector(state => userCanViewServiceExecutions(state));
	const userCanAccessServiceExecutionStartupView = useAppSelector(state => userCanViewStartupServiceExecutions(state));
	const userCanImportCustomers = useAppSelector(state => userCanBulkImportCustomers(state));
	const [anchorElCustomer, setAnchorElCustomer] = useState<null | HTMLElement>(null);
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [selected, setSelected] = useState<any>(location.pathname);
	const customerDropdownIsOpen = Boolean(anchorElCustomer);

	useEffect(() => {
		setSelected(location.pathname);
	}, [location.pathname]);

	const handleDropdownCustomer = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorElCustomer(event.currentTarget);
	};

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseCustomer = () => {
		setAnchorElCustomer(null);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleClickMenuItem = (id: string, link: any) => {
		setSelected(id);
		if (link)
			history.push(link);
		handleCloseNavMenu();
	};

	const handleProfileMenuDropdown = async (actionType: UserMenuActionType): Promise<void> => {
		switch (actionType) {
			case UserMenuActionType.SETTINGS:
				history.push(Routes.userPreferences.getPath());
				break;
			case UserMenuActionType.LOGOUT:
				await msalInstance.logoutRedirect();
				await msalInstance.logoutPopup();
				break;
			default:
				break;
		}
	};

	return (
		<>
			<NavBar position='fixed'>
				<Container maxWidth='xl'>
					<Toolbar disableGutters>
						<Box sx={{ flexGrow: 1 }}>
							<Box display={'flex'} alignItems={'center'}>
								<Link component={LinkRouter} to="/" sx={{ textDecoration: 'none' }}>
									<Typography variant="h3" sx={{ mr: 2, fontFamily: 'inherit', color: 'inherit', textDecoration: 'none' }}>
										My Service Manager
									</Typography>
								</Link>
							</Box>
						</Box>
						<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
							<Box display={'flex'}>
								<NavMenuItem
									id={Routes.home.pattern}
									label={t('Home')}
									selected={selected === Routes.home.pattern}
									handleClick={handleClickMenuItem}
									link={Routes.home.getPath()}
								/>
								{userCanAccessUsersView &&
									<NavMenuItem
										id={Routes.users.pattern}
										label={t('Users')}
										selected={selected === Routes.users.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.users.getPath()}
									/>
								}
								{userCanAccessOrganizationsView &&
									<NavMenuItem
										id={Routes.organizations.pattern}
										label={t('Organizations')}
										selected={selected === Routes.organizations.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.organizations.getPath()}
									/>
								}
								{(userCanAccessCustomersView && userCanImportCustomers) &&
									<>
										<NavMenuItem
											id={Routes.customers.pattern}
											label={t('Customers')}
											selected={
												selected === Routes.customers.pattern ||
												selected === Routes.customersUpload.pattern ||
												selected === Routes.customerUploads.pattern
											}
											handleDropdown={handleDropdownCustomer}
											dropdownState={anchorElCustomer === null ? 'inactive' : 'active'}
										/>
										<StyledMenu
											anchorEl={anchorElCustomer}
											open={customerDropdownIsOpen}
											onClose={handleCloseCustomer}
										>
											<NavMenuItem
												label={t('Overview')}
												link={Routes.customers.getPath()}
												selected={selected === Routes.customers.pattern}
												handleSelect={() => {
													history.push(Routes.customers.getPath());
													setAnchorElCustomer(null);
												}}
											/>
											<NavMenuItem
												label={t('Upload customers')}
												link={Routes.customersUpload.getPath()}
												selected={selected === Routes.customersUpload.pattern}
												handleSelect={() => {
													history.push(Routes.customersUpload.getPath());
													setAnchorElCustomer(null);
												}}
											/>
											<NavMenuItem
												label={t('My uploads')}
												link={Routes.customerUploads.getPath()}
												selected={selected === Routes.customerUploads.pattern}
												handleSelect={() => {
													history.push(Routes.customerUploads.getPath());
													setAnchorElCustomer(null);
												}}
											/>
										</StyledMenu>
									</>
								}
								{(userCanAccessCustomersView && !userCanImportCustomers) &&
									<NavMenuItem
										id={Routes.customers.pattern}
										label={t('Customers')}
										selected={selected === Routes.customers.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.customers.getPath()}
									/>
								}
								{userCanAccessEquipmentView &&
									<NavMenuItem
										id={Routes.equipments.pattern}
										label={t('EquipmentOverviewTitle')}
										selected={selected === Routes.equipments.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.equipments.getPath()}
									/>
								}
								{userCanAccessServiceOrdersView &&
									<NavMenuItem
										id={Routes.serviceOrders.pattern}
										label={t('Service Orders')}
										selected={selected === Routes.serviceOrders.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.serviceOrders.getPath()}
									/>
								}
								{userCanAccessServicePlanningView &&
									<NavMenuItem
										id={Routes.servicePlanning.pattern}
										label={t('Service Planning')}
										selected={selected === Routes.servicePlanning.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.servicePlanning.getPath()}
									/>
								}
								{userCanAccessServiceExecutionView &&
									<NavMenuItem
										id={Routes.serviceExecution.pattern}
										label={t('Service Reports')}
										selected={selected === Routes.serviceExecution.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.serviceExecution.getPath()}
									/>
								}

								{
									userCanAccessServiceExecutionStartupView &&
									<NavMenuItem
										id={Routes.startupServiceExecution.pattern}
										label={t('Startup Service Reports')}
										selected={selected === Routes.startupServiceExecution.pattern}
										handleClick={handleClickMenuItem}
										link={Routes.startupServiceExecution.getPath()}
									/>
								}

								<UserMenu
									email={truncate(currentUser.email, 30)}
									initials={`${currentUser.firstName[0]}${currentUser.lastName[0]}`}
									items={[
										{
											icon: 'settings',
											id: UserMenuActionType.SETTINGS,
											label: t('My settings'),
										},

										{
											icon: 'logout',
											id: UserMenuActionType.LOGOUT,
											label: t('Log out'),
										},
									]}
									name={`${currentUser.firstName} ${currentUser.lastName}`}
									onClick={(id) => handleProfileMenuDropdown(id)}
								/>

							</Box>
						</Box>
						<Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size="large"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
							<StyledMenu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
								keepMounted
								transformOrigin={{ vertical: 'top', horizontal: 'left' }}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{ display: { xs: 'block', md: 'none' } }}
							>
								<NavMenuItem 
									label={t('Home')} 
									id={Routes.home.pattern} 
									link={Routes.home.getPath()} 
									handleClick={handleClickMenuItem} 
									selected={selected === Routes.home.pattern} 
								/>
								{userCanAccessUsersView &&
									<NavMenuItem 
										label={t('Users')} 
										id={Routes.users.pattern} 
										link={Routes.users.getPath()} 
										handleClick={handleClickMenuItem} 
										selected={selected === Routes.users.pattern}
									/>
								}
								{userCanAccessOrganizationsView &&
									<NavMenuItem 
										label={t('Organizations')} 
										id={Routes.organizations.pattern}
										link={Routes.organizations.getPath()} 
										handleClick={handleClickMenuItem}
										selected={selected === Routes.organizations.pattern}
									/>
								}
								{(userCanAccessCustomersView && userCanImportCustomers) &&
									<NavMenuItem
										label={t('Customers')}
										id={Routes.customers.pattern}
										handleDropdown={handleDropdownCustomer}
										dropdownState={anchorElCustomer === null ? 'inactive' : 'active'}
										selected={selected === Routes.customers.pattern ||
											selected === Routes.customersUpload.pattern ||
											selected === Routes.customerUploads.pattern}
									/>
								}
								{(userCanAccessCustomersView && !userCanImportCustomers) &&
									<NavMenuItem
										label={t('Customers')}
										id={Routes.customers.pattern}
										link={Routes.customers.getPath()}
										handleClick={handleClickMenuItem}
										selected={selected === Routes.customers.pattern}
									/>
								}
								{userCanAccessEquipmentView &&
									<NavMenuItem 
										label={t('EquipmentOverviewTitle')} 
										id={Routes.equipments.pattern}
										link={Routes.equipments.getPath()} 
										handleClick={handleClickMenuItem}
										selected={selected === Routes.equipments.pattern}
									/>
								}
								{userCanAccessServiceOrdersView &&
									<NavMenuItem 
										label={t('Service Orders')} 
										id={Routes.serviceOrders.pattern}
										link={Routes.serviceOrders.getPath()}
										handleClick={handleClickMenuItem}
										selected={selected === Routes.serviceOrders.pattern}
									/>
								}
								{userCanAccessServicePlanningView &&
									<NavMenuItem 
										label={t('Service Planning')}
										id={Routes.servicePlanning.pattern}
										link={Routes.servicePlanning.getPath()}
										handleClick={handleClickMenuItem}
										selected={selected === Routes.servicePlanning.pattern}
									/>
								}
								{userCanAccessServiceExecutionView &&
									<NavMenuItem 
										label={t('Service Reports')}
										id={Routes.serviceExecution.pattern}
										link={Routes.serviceExecution.getPath()}
										handleClick={handleClickMenuItem}
										selected={selected === Routes.serviceExecution.pattern}
									/>
								}
								{userCanAccessServiceExecutionStartupView &&
									<NavMenuItem
										label={t('Startup Service Reports')}
										id={Routes.startupServiceExecution.pattern}
										link={Routes.startupServiceExecution.getPath()}
										handleClick={handleClickMenuItem}
										selected={selected === Routes.startupServiceExecution.pattern}
									/>
								}

								<UserMenu
									email={currentUser.email}
									initials={`${currentUser.firstName[0]}${currentUser.lastName[0]}`}
									items={[
										{
											icon: 'settings',
											id: UserMenuActionType.SETTINGS,
											label: t('My settings'),
										},

										{
											icon: 'logout',
											id: UserMenuActionType.LOGOUT,
											label: t('Log out'),
										},
									]}
									name={`${currentUser.firstName} ${currentUser.lastName}`}
									onClick={(id) => handleProfileMenuDropdown(id)}
								/>

							</StyledMenu>
						</Box>
					</Toolbar>
				</Container>
			</NavBar>
			<Offset />
		</>
	);
};
