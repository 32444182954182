import React from 'react';
import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import { Grid } from '@mui/material';
import { Dropdown, Radio, StyledSearchableDropdownProps, TextField, DropdownOption } from '@ui-components/ui-library';
import { IEquipmentTypeCompressorSectionProps } from './IEquipmentTypeCompressorSectionProps';
import { useTranslation } from 'react-i18next';
import { EquipmentFormModel } from '../../models/equipment';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { insallBassesService } from '../../../services/InstalledBasesService';
import { userCanSeeInstalledBaseCode, userLoaded } from '../../../stores/user/usersSlice';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import moment from 'moment-timezone';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const EquipmentTypeCompressorSection = (props: IEquipmentTypeCompressorSectionProps): JSX.Element => {
    const { readonly, brandOptions } = props;

    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
    const userCanSeeEquipmentInstalledBaseCode = useAppSelector(state => userCanSeeInstalledBaseCode(state));

    const { t } = useTranslation();
    const { values: equipment, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext() as FormikProps<EquipmentFormModel>;

    const determineInstalledBaseCode = async (equipmentTypeId: number, size: number, inverterDrive: boolean | undefined) => {
        if (equipmentTypeId > 0) {
            const installedBaseResponse =
                await insallBassesService.GetInstalledBaseForEquipmentValues(equipmentTypeId, size, inverterDrive);
            if (installedBaseResponse.isSuccess) {
                if (installedBaseResponse.result && installedBaseResponse.result.description) {
                    setFieldValue('installedBaseCode', installedBaseResponse.result.description);
                } else {
                    setFieldValue('installedBaseCode', '');
                }
            } else {
                setFieldValue('installedBaseCode', '');
            }
        } else {
            setFieldValue('installedBaseCode', '');
        }
    };

    const onSizeBlur = async (e: React.FocusEvent<HTMLInputElement> | undefined) => {
        handleBlur(e);
        if (!equipment.size) {
            return;
        }
        await determineInstalledBaseCode(equipment.equipmentTypeId, equipment.size, equipment.inverterDrive);
    };

    const onInverterDriveChange = async (e: any) => {
        setFieldValue('inverterDrive', e);
        if (!equipment.size) {
            return;
        }
        await determineInstalledBaseCode(equipment.equipmentTypeId, equipment.size, e);
    };

    const brandDropdownOptions = brandOptions.map(o => { return { id: o.id, label: o.name, value: o.id }; });
    const brandValueOption = brandDropdownOptions.find(o => o.id === equipment.brandId);

    const inverterDriveRadioOptions = [{ label: t('Yes'), value: 'true', disabled: readonly }, { label: t('No'), value: 'false', disabled: readonly }];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'brandId'}
                        label={t('Brand')}
                        name={'brandId'}
                        variant={'searchable'}
                        options={brandDropdownOptions ? brandDropdownOptions : []}
                        value={brandValueOption ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => setFieldValue('brandId', item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        optionListStyles={{ fontFamily: 'system-ui' }}
                        required={true}
                        disabled={readonly}
                        greyedOutLabelOnDisabled
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'brandId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Size (KW)')}
                        id={'size'}
                        name={'size'}
                        value={equipment.size}
                        onChange={handleChange}
                        onBlur={(e) => onSizeBlur(e as React.FocusEvent<HTMLInputElement>)}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 10 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        inputRestriction='decimal'
                        decimalRestrictionMaxDecimalPlacesBefore={6}
                        decimalRestrictionMaxDecimalPlacesAfter={3}
                        disabled={readonly}
                        required
                    />
                    <ErrorMessage name={'size'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DatePicker
                        id={'estimatedProductionDate'}
                        inputFormat={'YYYY'} 
                        value={equipment.estimatedProductionDate}
                        onChange={value => {
                            setFieldTouched('estimatedProductionDate');

                            // setFieldValue should be last action in order for the validation to work
                            if (value) {
                                // Not sure if this will be the final way of storing the data.
                                const changeValueString = String(value);
                                const selectedDate = moment(changeValueString).toDate();
                                const firstDay = moment(selectedDate).startOf('year').toDate();
                                setFieldValue('estimatedProductionDate', firstDay);
                            } else {
                                setFieldValue('estimatedProductionDate', undefined);
                            }
                        }}
                        disableFuture
                        disabled={readonly}
                        views={['year']}
                        actions={['clear']}
                        title={t('Production Year')}
                    />                    
                    <ErrorMessage name={'estimatedProductionDate'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DatePicker
                        id={'startupDate'}
                        value={equipment.startupDate}
                        // setFieldValue should be last action in order for the validation to work
                        onChange={value => { setFieldTouched('startupDate'); setFieldValue('startupDate', value ? moment(value).startOf('d').toDate() : undefined); }}
                        inputFormat={currentUser.preferences.dateFormat}
                        disableFuture
                        disabled={readonly}
                        actions={['today', 'clear']}
                        title={t('Startup Date')}
                    />
                    <ErrorMessage name={'startupDate'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Radio
                        options={inverterDriveRadioOptions}
                        value={equipment.inverterDrive ? equipment.inverterDrive.toString() : 'false'}
                        direction='row'
                        onChange={(e) => onInverterDriveChange(e)}
                        sx={{ '& .MuiTypography-root': { fontFamily: 'system-ui' } }}
                        title={t('Inverter Drive')}
                    />
                </Grid>

                {userIsLoaded && userCanSeeEquipmentInstalledBaseCode ?
                    (
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('Installed Base Code')}
                                id={'installedBaseCode'}
                                name={'installedBaseCode'}
                                value={equipment.installedBaseCode}
                                sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                                disabled
                            />
                            <ErrorMessage name={'installedBaseCode'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                        </Grid>
                    )
                    :
                    (
                        <Grid xs={12} md={6} />
                    )
                }
            </Grid>
        </>
    );
};
