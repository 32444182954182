import React from 'react';
import { GridColumns, GridValidRowModel, GridGroupingColDefOverride, GridLocaleText, GridRowsProp, DataGridPro, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { DataGrid } from '@ui-components/ui-library';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import { userLanguageIsRtl } from '../../stores/user/usersSlice';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
	border: 'none',

	'.MuiDataGrid-columnHeaders': {
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.colors.neutral[500] : '#fafafa',
		borderRadius: '11px 11px 0px 0px',
		border: 'none',
	},
	'.MuiDataGrid-columnHeaderTitle': {
		fontSize: '12px',
		lineHeight: '18px',
		letterSpacing: '0.06em',
		textTransform: 'uppercase',
		color: theme.palette.text.primary,
	},
	'.MuiDataGrid-columnHeaderTitleContainerContent': {
		'& .MuiCheckbox-root': {
			color: theme.palette.colors.neutral[100],
		},
	},
	'.MuiDataGrid-cell': {
		fontSize: '16px',
		lineHeight: '24px',
		color: theme.palette.text.primary,
		border: 'none',
		'& .MuiCheckbox-root': {
			color: theme.palette.colors.neutral[100],
		},
		'& .MuiCheckbox-root.Mui-checked': {
			color: theme.palette.primary.main,
		},
	},
	'.MuiDataGrid-cell--editable': {
		// eslint-disable-next-line quotes, max-len
		cursor: `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="20" width="20"%3E%3Cpath fill="%23fff" d="M4.25 15.75h1.229l7-7-1.229-1.229-7 6Zm11.938-8.208-3.73-3.73 1.021-1.02q.521-.521 1.24-.521t1.239.521l1.25 1.25q.5.5.5 1.239 0 .74-.5 1.24Zm-1.23 1.229L6.229 17.5H2.5v-3.729l8.729-8.729Zm-3.083-.625-.625-.625 1.229 1.229Z"/%3E%3C/svg%3E'), pointer`,
	},
	'.MuiDataGrid-row': {
		':hover': {
			backgroundColor: 'unset',
		},
		border: 'none',
		borderBottom: `1px solid ${theme.palette.colors.alpha[200]}`,
		'&.Mui-selected': {
			backgroundColor: 'unset',
			':hover': {
				backgroundColor: 'unset',
			},
		},
	},
	'.MuiDataGrid-footerContainer': {
		border: 'none',
		color: theme.palette.text.primary,
	},
})) as typeof DataGridPro;

interface DataGridTreeProps extends React.ComponentProps<typeof DataGrid> {
	columns: GridColumns<GridValidRowModel>;
	rows: GridRowsProp;
	gridKey?: number;
	isLoading?: boolean;
	pageSize?: number;
	getRowClassName?: (params: GridRowClassNameParams<GridValidRowModel>) => string;
	clearFilters?: boolean | undefined;
	containeraAutoHeight?: boolean | undefined;
	groupingColDef?: GridGroupingColDefOverride<GridValidRowModel>
}

export const DataGridTree = (props: DataGridTreeProps): JSX.Element => {
	const { t } = useTranslation();

	const defaultLocaleText: Partial<GridLocaleText> = {
		// Root
		noRowsLabel: t('No rows'),
		noResultsOverlayLabel: t('No results found.'),

		// Columns selector toolbar button text
		toolbarColumns: t('Columns'),
		toolbarColumnsLabel: ('Select columns'),

		// Filters toolbar button text
		toolbarFilters: t('Filters'),
		toolbarFiltersLabel: t('Show filters'),
		toolbarFiltersTooltipHide: t('Hide filters'),
		toolbarFiltersTooltipShow: t('Show filters'),
		toolbarFiltersTooltipActive: (count) =>
			count !== 1 ? t('{{filters_count}} active filters', { filters_count: count }) : t('{{filters_count}} active filter', { filters_count: count }),

		// Quick filter toolbar field
		toolbarQuickFilterPlaceholder: t('Search…'),
		toolbarQuickFilterLabel: t('Search'),
		toolbarQuickFilterDeleteIconLabel: t('Clear'),

		// Export selector toolbar button text
		toolbarExport: t('Export'),
		toolbarExportLabel: t('Export'),
		toolbarExportCSV: t('Download as CSV'),
		toolbarExportPrint: t('Print'),
		toolbarExportExcel: t('Download as Excel'),

		// Columns panel text
		columnsPanelTextFieldLabel: t('Find column'),
		columnsPanelTextFieldPlaceholder: t('Column title'),
		columnsPanelDragIconLabel: t('Reorder column'),
		columnsPanelShowAllButton: t('Show all'),
		columnsPanelHideAllButton: t('Hide all'),

		// Filter panel text
		filterPanelAddFilter: t('Add filter'),
		filterPanelDeleteIconLabel: t('Delete'),
		filterPanelOperators: t('Operator'),
		filterPanelOperatorAnd: t('And'),
		filterPanelOperatorOr: t('Or'),
		filterPanelColumns: t('Columns'),
		filterPanelInputLabel: t('Value'),
		filterPanelInputPlaceholder: t('Filter value'),

		// Filter operators text
		filterOperatorContains: t('contains'),
		filterOperatorEquals: t('equals'),
		filterOperatorStartsWith: t('starts with'),
		filterOperatorEndsWith: t('ends with'),
		filterOperatorIs: t('is'),
		filterOperatorNot: t('is not'),
		filterOperatorAfter: t('is after'),
		filterOperatorOnOrAfter: t('is on or after'),
		filterOperatorBefore: t('is before'),
		filterOperatorOnOrBefore: t('is on or before'),
		filterOperatorIsEmpty: t('is empty'),
		filterOperatorIsNotEmpty: t('is not empty'),
		filterOperatorIsAnyOf: t('is any of'),

		// Filter values text
		filterValueAny: t('any'),
		filterValueTrue: t('true'),
		filterValueFalse: t('false'),

		// Column menu text
		columnMenuLabel: t('Menu'),
		columnMenuShowColumns: t('Show columns'),
		columnMenuFilter: t('Filter'),
		columnMenuHideColumn: t('Hide column'),
		columnMenuUnsort: t('Unsort'),
		columnMenuSortAsc: t('Sort by ASC'),
		columnMenuSortDesc: t('Sort by DESC'),

		// Column header text
		columnHeaderFiltersTooltipActive: (count) =>
			count !== 1 ? t('{{filters_count}} active filters', { filters_count: count }) : t('{{filters_count}} active filter', { filters_count: count }),
		columnHeaderFiltersLabel: t('Show filters'),
		columnHeaderSortIconLabel: t('Sort'),

		// Rows selected footer text
		footerRowSelected: (count) =>
			count !== 1
				? t('{{rows_count}} rows selected', { rows_count: count })
				: t('{{rows_count}} row selected', { rows_count: count }),

		// Total row amount footer text
		footerTotalRows: t('Total Rows:'),

		// Total visible row amount footer text
		footerTotalVisibleRows: (visibleCount, totalCount) =>
			t('{{visible_count}} of {{total_count}}', { visible_count: visibleCount, total_count: totalCount }),

		// Checkbox selection text
		checkboxSelectionHeaderName: t('Checkbox selection'),
		checkboxSelectionSelectAllRows: t('Select all rows'),
		checkboxSelectionUnselectAllRows: t('Unselect all rows'),
		checkboxSelectionSelectRow: t('Select row'),
		checkboxSelectionUnselectRow: t('Unselect row'),

		// Boolean cell text
		booleanCellTrueLabel: t('yes'),
		booleanCellFalseLabel: t('no'),

		// Actions cell more text
		actionsCellMore: t('more'),

		// Column pinning text
		pinToLeft: t('Pin to left'),
		pinToRight: t('Pin to right'),
		unpin: t('Unpin'),

		// Tree Data
		treeDataGroupingHeaderName: t('Group'),
		treeDataExpand: t('see children'),
		treeDataCollapse: t('hide children'),

		// Grouping columns
		groupingColumnHeaderName: t('Group'),
		groupColumn: (name) => t('Group by {{column_name}}', { column_name: name }),
		unGroupColumn: (name) => t('Stop grouping by {{column_name}}', { column_name: name }),

		// Master/detail
		detailPanelToggle: t('Detail panel toggle'),
		expandDetailPanel: t('Expand'),
		collapseDetailPanel: t('Collapse'),

		// Used core components translation keys
		MuiTablePagination: {
			labelDisplayedRows: ({ from, to, count }) =>
				t('{{from_count}}–{{to_count}} of {{total_count}}', { from_count: from, to_count: to, total_count: count }),
			nextIconButtonProps: {
				title: t('Go to next page')
			},
			backIconButtonProps: {
				title: t('Go to previous page')
			}
		},

		// Row reordering text
		rowReorderingHeaderName: t('Row reordering'),

		// Aggregation
		aggregationMenuItemHeader: t('Aggregation'),
		aggregationFunctionLabelSum: t('sum'),
		aggregationFunctionLabelAvg: t('avg'),
		aggregationFunctionLabelMin: t('min'),
		aggregationFunctionLabelMax: t('max'),
		aggregationFunctionLabelSize: t('size'),
	};

	const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));

	return (
		<>
			<div style={{ height: props.containerHeight ? props.containerHeight : '100vh', width: '100%' }}>
				<StyledDataGridPro
					treeData
					getTreeDataPath={(row) => row.path}
					rows={props.rows}
					columns={props.columns}
					loading={props.isLoading}
					groupingColDef={props.groupingColDef}
					pagination
					pageSize={props.pageSize ?? 15}
					getRowHeight={() => 'auto'}
					autoHeight
					getRowClassName={props.getRowClassName}
					disableSelectionOnClick
					onPageChange={() => window.scrollTo(0, 0)}
					componentsProps={{
						columnMenu: {
							sx: {
								'& .MuiMenuItem-root': { fontFamily: 'system-ui', fontSize: '14px' },
							}
						},
						filterPanel: {
							sx: {
								'& .MuiFormControl-root': { width: 'auto !important' },
								'& .MuiFormLabel-root.MuiInputLabel-root': { fontFamily: 'system-ui !important' },
								'& .MuiInputBase-root': { fontFamily: 'system-ui !important', fontSize: '15px' },
								'& .MuiButtonBase-root.MuiButton-root': { fontFamily: 'system-ui !important' },
							}
						},
						columnsPanel: {
							sx: {
								'& .MuiTypography-root': { fontSize: '15px' },
							}
						},
						footer: {
							sx: {
								'& .MuiTablePagination-displayedRows': { fontFamily: 'system-ui', my: 'auto' },
								'& .MuiTablePagination-actions .MuiButtonBase-root .MuiSvgIcon-root': { transform: shouldUseRtl && 'rotate(180deg)' }
							}
						}
					}}
					sx={{
						'&.MuiDataGrid-root .MuiDataGrid-cell': { py: '10px' },
					}}

					localeText={{
						...defaultLocaleText,
						...props.localeText
					}}
				/>
			</div>
		</>
	);
};