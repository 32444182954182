import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, ChipType, Button, SearchBar, Icon, Modal } from '@ui-components/ui-library';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Box, Grid } from '@mui/material';
import { debounce } from 'lodash';
import { GridRowClassNameParams, GridColDef, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import styles from './OrganizationsOverview.module.scss';
import { OrganizationFormModel, OrganizationViewModel, ParentOrganizationOptionModel, getDefaultOrganization } from './../../models/organizationModel';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { organizationService } from '../../services/OrganizationService';
import { userCanViewOrganizations, userIsGlobalAdmin, userIsLocalAdmin, userLoaded } from '../../../stores/user/usersSlice';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { OrganizationForm } from '../organizationForm/OrganizationForm';
import { OrganizationType, getOrganizationTypeLabel } from '../../../shared/enums/OrganizationType';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { CountryFormModel } from '../../models/countryModel';
import { BrandViewModel } from '../../../models/brand/brandModel';
import { ErpTypeModel } from '../../models/erpTypeModel';
import { getDefaultAddress } from '../../models/addressModel';
import { getDefaultDistributorDetails } from '../../models/distributorDetailModel';
import { getDefaultCustomerCenterDetail } from '../../models/customerCenterDetailModel';
import { erpTypeService } from '../../services/ErpTypeService';
import { i18n } from '../../../i18n/config';

export const OrganizationsOverview = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));
	const isLocalAdmin: boolean = useAppSelector(state => userIsLocalAdmin(state));
	const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
	const userCanAccessOrganizationsView = useAppSelector(state => userCanViewOrganizations(state));
	const { t } = useTranslation();

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [selectedOrganization, setSelectedOrganization] = useState<OrganizationViewModel | undefined>(undefined);
	const [toggleStatusDialogVisible, setToggleStatusDialogVisible] = useState<boolean>(false);
	const [organizations, setOrganizations] = useState<OrganizationViewModel[]>([]);
	const [organizationsFiltered, setOrganizationsFiltered] = useState<OrganizationViewModel[]>([]);
	const [clearGridFilters, setClearGridFilters] = useState<boolean | undefined>(undefined);
	
	const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
	const [selectedFormOrganization, setSelectedFormOrganization] = useState<OrganizationFormModel | undefined>(undefined);
	const [selectedType, setSelectedType] = useState<OrganizationType | undefined>(undefined);
    const [erpTypeOptions, setErpTypeOptions] = useState<ErpTypeModel[]>([]);
	const [parentOrganizationOptions, setParentOrganizationOptions] = useState<ParentOrganizationOptionModel[]>([]);

	const brandOptions: BrandViewModel[] = useAppSelector(state => state.Dashboard.brandOptions);
	const countryOptions: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);

	const isDistributorType: boolean = selectedType === OrganizationType.Distributor;
    const isCustomerCenterType: boolean = selectedType === OrganizationType.CustomerCenter;

	useEffect(() => {
		if (!userIsLoaded) {
			return;
		}

		if (userCanAccessOrganizationsView) {
			initFilters();
			getOrganizations();
		} else {
			dispatch(setIsLoading(false));
		}
	}, [userIsLoaded, userCanAccessOrganizationsView]);

	const debouncedFilterOrganizations = useCallback(
        debounce((value: string) => {
			if (organizations.length > 0) {
				let _organizationsFiltered = [...organizations];
				_organizationsFiltered = _organizationsFiltered.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value.toLowerCase())));
				setOrganizationsFiltered(_organizationsFiltered);
			}
        }, 500), [organizations]
    );

	useEffect(() => {
        return () => debouncedFilterOrganizations.cancel();
    }, [debouncedFilterOrganizations]);
	
	useEffect(() => {		
		debouncedFilterOrganizations(globalFilterValue);
	}, [globalFilterValue]);

	const getOrganizations = async () => {
		dispatch(setIsLoading(true));
		const organizationsResponse = await organizationService.GetOrganizations();

		if (organizationsResponse.isSuccess && organizationsResponse.result) {
			setOrganizations(organizationsResponse.result);
			setOrganizationsFiltered(organizationsResponse.result);
		}
		if (organizationsResponse.errorModel) {
			dispatch(showErrors(organizationsResponse.errorModel.errors));
		}
		dispatch(setIsLoading(false));
	};

	const initFilters = () => {
		setGlobalFilterValue('');
		setOrganizationsFiltered(organizations);
	};

	const onGlobalFilterChange = (value: string) => {
		setGlobalFilterValue(value);
	};

	const clearFilters = () => {
		initFilters();
		setOrganizationsFiltered(organizations);
		setClearGridFilters(!clearGridFilters);
	};

	const renderHeader = (): JSX.Element => {
		return (
			<Box display='flex' justifyContent='space-between' marginBottom={2}>
				{
					(isGlobalAdmin || isLocalAdmin) &&
					<Button
						id='new-distributor'
						onClick={handleCreate}
						variant='primary'
						label={t('New distributor')}
						startIcon='add'
					/>
				}
				<SearchBar
					variant='default'
					onChange={(value) => onGlobalFilterChange(value)}
					value={globalFilterValue}
					placeholder={t('Search')}
				/>
				<Button
					id='clear-filter'
					onClick={clearFilters}
					variant='secondary'
					label={t('Clear')}
					startIcon='filter_list_off'
				/>
			</Box>
		);
	};

	const showToggleStatusDialog = (organizationToToggle: OrganizationViewModel) => {
		setSelectedOrganization(organizationToToggle);
		setToggleStatusDialogVisible(true);
	};

	const hideToggleStatusDialog = () => {
		setSelectedOrganization(undefined);
		setToggleStatusDialogVisible(false);
	};
	
	const toggleOrganizationStatus = async () => {
		if (!selectedOrganization) {
			dispatch(showErrors([t('Please select an Organization')]));
			return;
		}

		const result = selectedOrganization.isActive ?
			await organizationService.DeactivateOrganization(selectedOrganization.id) :
			await organizationService.ActivateOrganization(selectedOrganization.id);

		if (result.isSuccess) {
			dispatch(showSuccess(t(SUCCESS_MESSAGE)));

			const newOrganizationsState = organizations.map(org => {
				if (org.id === selectedOrganization.id) {
					return { ...org, isActive: !selectedOrganization.isActive };
				}

				return org;
			});

			const newOrganizationsFilteredState = organizationsFiltered.map(org => {
				if (org.id === selectedOrganization.id) {
					return { ...org, isActive: !selectedOrganization.isActive };
				}

				return org;
			});

			setOrganizations(newOrganizationsState);
			setOrganizationsFiltered(newOrganizationsFilteredState);
			setSelectedOrganization(undefined);
		} else {
			if (result?.errorModel) {
				dispatch(showErrors(result.errorModel?.errors));
			}
		}
	};

	const onToggleStatusConfirm = async () => {
		dispatch(setIsLoading(true));
		await toggleOrganizationStatus();
		hideToggleStatusDialog();
		dispatch(setIsLoading(false));
	};

	const brandsBodyTemplate = (params: GridRenderCellParams<OrganizationViewModel>) => {
		if (!params.row.brands)
			return '';

		return (
			<Grid container gap={1}>
				{params.row.brands.map((brand: string) => {
					return (
						<Grid item xs={12} key={brand} maxWidth={'100%'}>
							<Chip type={ChipType.LIGHTBLUE} label={brand} />
						</Grid>
					);
				})}
			</Grid>
		);
	};

	const getTranslatedOrganizations = () => {
		const result = organizationsFiltered.map(o => ({ ...o, organizationType: t(getOrganizationTypeLabel(o.organizationType)) }));

		return result;
	};

	const rowClassName = (rowData: GridValidRowModel) => {
		return rowData.isActive ? '' : styles.inactive;
	};

	const getColumns = (): GridEnrichedColDef[] => {
		let columns: GridColDef[] = [
			{ field: 'name', headerName: t('Name'), sortable: true, filterable: true, pinnable: false, flex: 3, description: t('Name') },
			{ field: 'country', headerName: t('Country'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Country') },
		];

		const actionsColumn: GridEnrichedColDef[] = [
			{ field: 'brands', headerName: t('Brands'), renderCell: brandsBodyTemplate, sortable: false, filterable: true, pinnable: false, flex: 4, description: t('Brands') },
			{ field: 'famCode', headerName: t('Fam Code'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Fam Code') },
			{
				field: 'actions',
				type: 'actions',
				headerName: t('Actions'),
				flex: 1,
				description: t('Actions'),
				getActions: (params: GridRowParams<OrganizationViewModel>) => [
					<DataGridActionOverview
						key={'edit'}
						icon={<Icon type='edit' />}
						label={t('Edit')}
						onClick={() => handleEdit(params.row.id, params.row.organizationType)}
						showInMenu
					/>,
					<DataGridActionOverview
						key={params.row.isActive ? 'Deactivate' : 'Activate'}
						icon={<PowerSettingsNewIcon />}
						label={params.row.isActive ? t('Deactivate') : t('Activate')}
						onClick={() => showToggleStatusDialog(params.row as OrganizationViewModel)}
						showInMenu
					/>
				]
			},
		];

		if (isGlobalAdmin) {
			const globalAdministratorColumns: GridColDef[] = [
				{ field: 'organizationType', headerName: t('Organization Type'), sortable: true, filterable: true, pinnable: false, flex: 3, description: t('Organization Type') },
			];
			columns = [...columns, ...globalAdministratorColumns, ...actionsColumn];
		} else if (isLocalAdmin) {
			columns = [...columns, ...actionsColumn];
		}

		return columns;
	};

	const memoizedColumns = useMemo(() => getColumns(), [erpTypeOptions, countryOptions, parentOrganizationOptions, i18n.language]);

	const handleCreate = () => {
		const organizationType = OrganizationType.Distributor;

		setSelectedType(organizationType);
		setSelectedFormOrganization(getDefaultOrganization());

		dispatch(setIsLoading(true));
		Promise.all([getErpTypes(), getParentOrganizationOptions(organizationType)]).then(() => {			
			setFormIsOpen(true);
			dispatch(setIsLoading(false));
		});
	};

	const handleEdit = (id: number, translatedType: any) => {
		const organizationType: OrganizationType | undefined =
			t(getOrganizationTypeLabel(OrganizationType.Distributor)) === translatedType ? OrganizationType.Distributor :
				t(getOrganizationTypeLabel(OrganizationType.CustomerCenter)) === translatedType ? OrganizationType.CustomerCenter : undefined;
		
		setSelectedType(organizationType);
		
		dispatch(setIsLoading(true));
		Promise.all([getErpTypes(), getParentOrganizationOptions(organizationType), fetchOrganization(id)]).then(() => {
			setFormIsOpen(true);
			dispatch(setIsLoading(false));
		});
	};

	const handleCloseFormDialog = () => {
		setFormIsOpen(false);
		setSelectedFormOrganization(undefined);
		setSelectedType(undefined);
	};

	const fetchOrganization = async (id: number) => {
        if (id) {
            const organizationResponse = await organizationService.GetOrganizationById(id);

            if (organizationResponse.isSuccess && organizationResponse.result) {
                if (!organizationResponse.result.address)
                    organizationResponse.result.address = getDefaultAddress();
                if (isDistributorType && !organizationResponse.result.distributorDetail) {
                    organizationResponse.result.distributorDetail = getDefaultDistributorDetails();
                }
                if (isCustomerCenterType && !organizationResponse.result.customerCenterDetail) {
                    organizationResponse.result.customerCenterDetail = getDefaultCustomerCenterDetail();
                }
                setSelectedFormOrganization(organizationResponse.result);
            }
            else {
                dispatch(showErrors(organizationResponse.errorModel?.errors));
            }
        }
    };

    const getErpTypes = async () => {
		if (erpTypeOptions.length === 0) {
			const erpTypesResponse = await erpTypeService.GetErpTypes();

			if (erpTypesResponse.isSuccess) {
				setErpTypeOptions(erpTypesResponse.result!);
			}
		}
    };

	const getParentOrganizationOptions = async (organizationType: OrganizationType | undefined) => {
		if (organizationType !== null && organizationType !== OrganizationType.CustomerCenter && parentOrganizationOptions.length === 0) {
			const parentOrganizationOptionsResponse = await organizationService.GetParentOrganizationOptions();
			if (parentOrganizationOptionsResponse.isSuccess && parentOrganizationOptionsResponse.result) {
				setParentOrganizationOptions(parentOrganizationOptionsResponse.result.sort((a, b) => a.name.localeCompare(b.name)));
			}
		}
    };

	return (
		<>
			{
				userIsLoaded && userCanAccessOrganizationsView &&
				<>
					<Box>
						{renderHeader()}
					</Box>
					<DataGridOverview
						rows={getTranslatedOrganizations()}
						columns={memoizedColumns}
						pagination
						clearFilters={clearGridFilters}
						getRowClassName={(params: GridRowClassNameParams<GridValidRowModel>) => rowClassName(params.row)}
					/>
				</>
			}
			{
				toggleStatusDialogVisible &&
				<ConfirmationDialog
					open={toggleStatusDialogVisible}
					message={t(`Are you sure you want to ${selectedOrganization?.isActive ? 'deactivate' : 'activate'} this organization?`)}
					onConfirm={onToggleStatusConfirm}
					onClose={hideToggleStatusDialog}
				/>
			}

			<Modal
				title={isDistributorType ? t('Distributor Organization')
					: isCustomerCenterType ? t('Customer Center Organization') : t('Organization')}
				open={formIsOpen}
				onClose={handleCloseFormDialog}
				maxWidth='lg'
				scroll='body'
				titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
				sx={{ '.MuiPaper-root': { width: '100%' } }}
			>
				{
					selectedFormOrganization && selectedType &&
					<OrganizationForm
						organization={selectedFormOrganization!}
						organizationType={selectedType!}
						brands={brandOptions}
						erpTypes={erpTypeOptions}
						countries={countryOptions}
						parentOrganizations={parentOrganizationOptions}
						handleClose={handleCloseFormDialog}
						getOrganizations={getOrganizations}
					/>
				}													
			</Modal>
		</>
	);
};
