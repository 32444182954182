import React from 'react';
import { IServiceOrderDisplayGeneralInfoProps } from './IServiceOrderDisplayGeneralInfoProps';
import { TextField } from '@ui-components/ui-library';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ServiceOrderDisplayGeneralInfo.scss';

export const ServiceOrderDisplayGeneralInfo = (props: IServiceOrderDisplayGeneralInfoProps): JSX.Element => {

    const { serviceOrder } = props;

    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Service Order Number')}
                        value={serviceOrder.number}
                        disabled
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Type of Service Order')}
                        value={t(serviceOrder.serviceOrderDetail.serviceOrderType)}
                        disabled
                    />
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Service Order ID')}
                        value={serviceOrder.externalId}
                        disabled
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Is Machine Operating?')}
                        value={serviceOrder.serviceOrderDetail.isEquipmentOperating ? t('Yes') : t('No')}
                        disabled
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                     <TextField
                        label={t('Estimated Job Hours')}
                        value={serviceOrder.estimatedWorkHours}
                        disabled
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id='descriptionOfOrderMultiline'
                        label={t('Description of Order')}
                        value={serviceOrder.serviceOrderDetail.description}
                        disabled
                        multiline={true}
                        minRows={2}
                    />
                </Grid>
            </Grid>
        </>
    );
};
