
export type DistributorDetailsModel = {
    id: number;
    erpTypeId: number;
    erpCustomerNumber: string;
    phoneNumber: string;
};

export const getDefaultDistributorDetails = (): DistributorDetailsModel => {
    return {
        id: 0,
        erpTypeId: 0,
        erpCustomerNumber: '',
        phoneNumber: '',
    };
};
