
export type CustomerCenterDetailModel = {
    id: number;
    famCode: string;
    organizationId: number;
};

export const getDefaultCustomerCenterDetail = (): CustomerCenterDetailModel => {
    return {
        id: 0,
        famCode: '',
        organizationId: 0,
    };
};
