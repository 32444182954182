import { IServiceOrderPlanningOptimization } from '../components/serviceOrderPlanningOptimization/IServiceOrderPlanningOptimizationProps';
import { getDefaultPersonOfInterestVersion, PersonOfInterestVersion } from './personOfInterestVersionModel';
import { PlannedPartModel } from './plannedPartModel';
import { getDefaultServiceOrderDetail, ServiceOrderDetailDisplayModel, ServiceOrderDetailModel } from './serviceOrderDetailModel';
import moment from 'moment-timezone';

export type ServiceOrderViewModel = {
    externalId: string | undefined;
    id: number;
    createdOn: Date;
    number: string;
    companyName: string;
    estimatedHours: number | undefined;
    status: string;
    organizationId: number;
    dateOfVisit: Date | undefined;
    isReportGenerated: boolean;
    distributorOrganization?: string;
};

export type ServiceOrderDisplayModel = {
    externalId: string | undefined;
    id: number;
    createdOn: Date;
    number: string;
    serialNumber: string;
    model: string;
    brand: string;
    customerName: string;
    customerCity: string;
    customerPostalCode: string;
    customerStreet: string;
    customerCountry: string;
    estimatedWorkHours: number | undefined;
    personOfInterestVersionId: number;
    personOfInterestVersion: PersonOfInterestVersion;
    serviceOrderDetail: ServiceOrderDetailDisplayModel;
    plannedParts: PlannedPartModel[];
}

export type ServiceOrderFormModel = {
    externalId: string | undefined;
    id: number;
    createdOn: Date;
    lastModifiedOn: Date | undefined;
    number: string;
    equipmentId: number;
    isEquipmentLinkedToCustomer: boolean | undefined;
    visitReportGenerated: boolean;
    personOfInterestVersionId: number;
    personOfInterestVersion: PersonOfInterestVersion;
    serviceOrderDetail: ServiceOrderDetailModel;
    plannedParts: PlannedPartModel[];
    planningOptimization: IServiceOrderPlanningOptimization;
};

export const getDefaultServiceOrder = (): ServiceOrderFormModel => {
    return {
        externalId: undefined,
        id: 0,
        createdOn: moment().toDate(),
        lastModifiedOn: undefined,
        number: '',
        equipmentId: 0,
        isEquipmentLinkedToCustomer: undefined,
        visitReportGenerated: false,
        personOfInterestVersionId: 0,
        personOfInterestVersion: getDefaultPersonOfInterestVersion(),
        serviceOrderDetail: getDefaultServiceOrderDetail(),
        plannedParts: [],
        planningOptimization: getDefaultPlanningOptimization()
    };
};

export type ServiceOrderCreateModel = {
    externalId: string | undefined;
    equipmentId: number;
    personOfInterestVersionId: number;
    description: string;
    isEquipmentOperating: boolean;
    serviceOrderTypeId: number;
    plannedParts: {
        name: string;
        number: string;
        quantity: number;
        description: string;
    }[];
    planningOptimizations: {
        estimatedWorkHours: number | undefined;
    };
};

export const mapServiceOrderToCreateModel = (serviceOrder: ServiceOrderFormModel): ServiceOrderCreateModel => {
    return {
        externalId: serviceOrder.externalId,
        equipmentId: serviceOrder.equipmentId,
        personOfInterestVersionId: serviceOrder.personOfInterestVersionId,
        description: serviceOrder.serviceOrderDetail.description,
        isEquipmentOperating: serviceOrder.serviceOrderDetail.isEquipmentOperating,
        serviceOrderTypeId: serviceOrder.serviceOrderDetail.serviceOrderTypeId,
        plannedParts: serviceOrder.plannedParts?.map(pp => (
            {
                name: pp.name,
                number: pp.number,
                quantity: pp.quantity,
                description: pp.description,
            }
        )),
        planningOptimizations: {
            estimatedWorkHours: serviceOrder.planningOptimization.estimatedWorkHours
        }
    };
};

export type ServiceOrderUpdateModel = {
    externalId: string | undefined;
    serviceOrderId: number;
    equipmentId: number;
    personOfInterestVersionId: number;
    description: string;
    isEquipmentOperating: boolean;
    serviceOrderTypeId: number;
    plannedParts: {
        id: number;
        name: string;
        number: string;
        quantity: number;
        toCreate?: boolean;
        toUpdate?: boolean;
        toDelete?: boolean;
    }[];
    planningOptimizations: {
        estimatedWorkHours: number | undefined;
    };
};

export const mapServiceOrderToUpdateModel = (serviceOrder: ServiceOrderFormModel): ServiceOrderUpdateModel => {
    return {
        externalId: serviceOrder.externalId,
        serviceOrderId: serviceOrder.id,
        equipmentId: serviceOrder.equipmentId,
        personOfInterestVersionId: serviceOrder.personOfInterestVersionId,
        description: serviceOrder.serviceOrderDetail.description,
        isEquipmentOperating: serviceOrder.serviceOrderDetail.isEquipmentOperating,
        serviceOrderTypeId: serviceOrder.serviceOrderDetail.serviceOrderTypeId,
        plannedParts: serviceOrder.plannedParts?.map(pp => (
            {
                id: pp.id,
                name: pp.name,
                number: pp.number,
                quantity: pp.quantity,
                toCreate: pp.toCreate,
                toUpdate: pp.toUpdate,
                toDelete: pp.toDelete
            }
        )),
        planningOptimizations: {
            estimatedWorkHours: serviceOrder.planningOptimization.estimatedWorkHours
        }
    };
};

export const getDefaultPlanningOptimization = (): IServiceOrderPlanningOptimization => {
    return {
        estimatedWorkHours: undefined
    };
};