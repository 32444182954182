import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { Alert, Box, Grid } from '@mui/material';
import { Button, Modal, Stepper } from '@ui-components/ui-library';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getDefaultServiceOrder, mapServiceOrderToCreateModel, mapServiceOrderToUpdateModel, ServiceOrderFormModel } from '../../models/serviceOrderModel';
import { serviceOrderCustomerInfoIsValid, serviceOrderGeneralInfoIsValid, serviceOrderValidationSchema } from '../../services/ServiceOrderUtility';
import { serviceOrderService } from '../../services/ServiceOrderService';
import { ServiceOrderGeneralInfo } from '../serviceOrderGeneralInfo/ServiceOrderGeneralInfo';
import { ServiceOrderParts } from '../serviceOrderParts/ServiceOrderParts';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { userCanCreateServiceOrders, userCanEditServiceOrders, userLoaded } from '../../../stores/user/usersSlice';
import { PlannedPartModel } from '../../models/plannedPartModel';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import moment from 'moment-timezone';
import { IServiceOrderFormProps, ServiceOrderFormStep } from './IServiceOrderFormProps';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { styled } from '@mui/material/styles';
import { AuthenticatedUser } from '../../../users/models/user';
import { CustomerRelatedDataModel } from '../../models/equipmentRelatedDataModel';
import { customerService } from '../../../customers/services/CustomerService';
import { ServiceOrderRelationData } from '../serviceOrderRelationData/ServiceOrderRelationData';
import { ServiceOrderEquipmentInfo } from '../serviceOrderEquipmentInfo/ServiceOrderEquipmentInfo';
import { ServiceOrderPlanningOptimization } from '../serviceOrderPlanningOptimization/ServiceOrderPlanningOptimization';
import { PersonOfInterestVersion } from '../../models/personOfInterestVersionModel';
import { peopleOfInterestVersionService } from '../../services/PeopleOfInterestVersionService';
import { ServiceOrderTypeOptionModel } from '../../models/serviceOrderTypeOptionModel';
import { EquipmentOptionModel } from '../../../equipments/models/equipmentOptionModel';
import { serviceOrderTypeService } from '../../services/ServiceOrderTypeService';
import { equipmentService } from '../../../equipments/services/EquipmentService';
import { getDefaultPersonOfInterest, mapPersonOfInterestToCreateModel, PersonOfInterestModel } from '../../../customers/models/personOfInterestModel';
import { isValidEmail, lengthIsLessThanTwoChars } from '../../../shared/utils/Utils';
import { ActionResult } from '../../../shared/utils/api';
import { peopleOfInterestService } from '../../../customers/services/PeopleOfInterestService';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { isEqual } from 'lodash';
import { CountryFormModel } from '../../../organizations/models/countryModel';

const StyledLabel = styled('p')({
	fontSize: '14px',
	color: '#fff',
	marginBottom: '6px'
});

export const ServiceOrderForm = (props: IServiceOrderFormProps): JSX.Element => {
	const { formIsOpen, handleClose, serviceOrderId, getServiceOrders } = props;
	const isNewForm = serviceOrderId === undefined;
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
	const canCreateServiceOrders = useAppSelector(state => userCanCreateServiceOrders(state));
	const canEditServiceOrders = useAppSelector(state => userCanEditServiceOrders(state));

	const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
	const countries: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);

	const [serviceOrder, setServiceOrder] = useState<ServiceOrderFormModel>(getDefaultServiceOrder());
	const [customerRelatedData, setCustomerRelatedData] = useState<CustomerRelatedDataModel | undefined>(undefined);
	const [peopleOfInterestVersionOptions, setPeopleOfInterestVersionOptions] = useState<PersonOfInterestVersion[]>([]);
	const [personOfInterestForCreation, setPersonOfInterestForCreation] = useState<PersonOfInterestModel>(getDefaultPersonOfInterest());
	const [serviceOrderTypeOptions, setServiceOrderTypeOptions] = useState<ServiceOrderTypeOptionModel[]>([]);
	const [equipmentOptions, setEquipmentOptions] = useState<EquipmentOptionModel[]>([]);
	const [formIsReadonly, setFormIsReadonly] = useState<boolean>(true);
	const [step, setStep] = useState<number>(ServiceOrderFormStep.GENERALINFO);

	const [partsToCreateIds, setPartsToCreateIds] = useState<number[]>([]);
	const [partsToUpdateIds, setPartsToUpdateIds] = useState<number[]>([]);
	const [partsToDeleteIds, setPartsToDeleteIds] = useState<number[]>([]);
	const [partsToDelete, setPartsToDelete] = useState<PlannedPartModel[]>([]);

	const markPartAsCreated = (id: number) => {
		setPartsToCreateIds([...partsToCreateIds, id]);
	};

	const markPartAsUpdated = (partsUpdated: PlannedPartModel[]) => {
		const idsToAdd: number[] = [];
		const idsToRemove: number[] = [];

		partsUpdated.forEach(partUpdated => {
			if (isPlannedPartInitialForm(partUpdated)) {
				idsToRemove.push(partUpdated.id);
			} else {
				if (!partsToCreateIds.includes(partUpdated.id)) {
					idsToAdd.push(partUpdated.id);
				}
			}
		});

		let _updated = [...partsToUpdateIds];

		_updated = _updated.filter(u => !idsToRemove.includes(u));

		idsToAdd.forEach(id => {
			if (!_updated.includes(id)) {
				_updated.push(id);
			}
		});

		setPartsToUpdateIds(_updated);
	};

	const markPartAsDeleted = (partToDelete: PlannedPartModel) => {
		if (!partsToCreateIds.includes(partToDelete.id)) {
			setPartsToDeleteIds([...partsToDeleteIds, partToDelete.id]);
			setPartsToDelete([...partsToDelete, partToDelete]);
		} else {
			setPartsToCreateIds([...partsToCreateIds.filter(pId => pId !== partToDelete.id)]);
		}
	};

	const isPlannedPartInitialForm = (partUpdated: PlannedPartModel): boolean => {
		const partInitial = serviceOrder.plannedParts.find(p => p.id === partUpdated.id);

		if (!partInitial) {
			return false;
		}

		return (isEqual(partInitial, partUpdated));
	};

	const handlePersonOfInterestForCreationChange = (propName: keyof PersonOfInterestModel, value: any) => {
		setPersonOfInterestForCreation({ ...personOfInterestForCreation, [propName]: value });
	};

	const getServiceOrder = async () => {
		if (serviceOrderId) {
			const serviceOrderResponse = await serviceOrderService.GetServiceOrderById(serviceOrderId);
			if (serviceOrderResponse.isSuccess) {
				setServiceOrder(serviceOrderResponse.result!);
			} else {
				dispatch(showErrors(serviceOrderResponse?.errorModel?.errors));
			}
		}
	};

	const getServiceOrderTypeOptions = async () => {
		const serviceOrderTypeOptionsResponse = await serviceOrderTypeService.GetServiceOrderTypeOptions();
		if (serviceOrderTypeOptionsResponse.isSuccess) {
			const orderedServiceOrderTypeOptions = orderBy(serviceOrderTypeOptionsResponse.result!, sot => sot.description);
			setServiceOrderTypeOptions(orderedServiceOrderTypeOptions);
		}
	};

	const getEquipmentOptions = async () => {
		const equipmentOptionsResponse = await equipmentService.GetEquipmentOptions();
		if (equipmentOptionsResponse.isSuccess) {
			const orderedEquipmentOptions = orderBy(equipmentOptionsResponse.result!, eo => eo.serialNumber);
			setEquipmentOptions(orderedEquipmentOptions);
		}
	};

	const getPeopleOfInterestVersionOptions = async (): Promise<PersonOfInterestVersion[] | undefined> => {
		if (customerRelatedData && customerRelatedData.id) {
			const peopleOfInterestResponse = await peopleOfInterestVersionService.GetPeopleOfInterestVersionsForCustomer(customerRelatedData.id);

			if (peopleOfInterestResponse.isSuccess && peopleOfInterestResponse.result) {
				let lastVersionsOfPeopleOfInterest = peopleOfInterestResponse.result;
				if (!isNewForm) {
					const existingPersonOfInterestIsInOptions = peopleOfInterestResponse.result.some(poi => poi.id === serviceOrder.personOfInterestVersion.id);

					if (!existingPersonOfInterestIsInOptions) {
						lastVersionsOfPeopleOfInterest = [...lastVersionsOfPeopleOfInterest, serviceOrder.personOfInterestVersion];
					}
				}

				return lastVersionsOfPeopleOfInterest;
			} else {
				dispatch(showErrors(peopleOfInterestResponse.errorModel?.errors));
			}
		}
	};

	const loadPeopleOfInterestVersionOptions = async () => {
		dispatch(setIsLoading(true));

		const lastVersionsOfPeopleOfInterest = await getPeopleOfInterestVersionOptions();

		if (lastVersionsOfPeopleOfInterest) {
			setPeopleOfInterestVersionOptions(lastVersionsOfPeopleOfInterest);
		} else {
			setPeopleOfInterestVersionOptions([]);
		}

		dispatch(setIsLoading(false));
	};

	const getCustomerRelatedData = async (equipmentId: number) => {
		dispatch(setIsLoading(true));
		const customerRelatedDataResponse = await customerService.GetCustomerRelatedData(equipmentId);
		if (customerRelatedDataResponse.isSuccess) {
			setCustomerRelatedData(customerRelatedDataResponse.result!);
		}
		dispatch(setIsLoading(false));
	};

	const clearCustomerRelatedData = async () => {
		setCustomerRelatedData(undefined);
	};

	const clearPersonOfInterestForCreation = () => {
		setPersonOfInterestForCreation(getDefaultPersonOfInterest());
	};

	const handlePeopleOfInterestVersionOptions = () => {
		let lastVersionsOfPeopleOfInterest = peopleOfInterestVersionOptions;
		const existingPersonOfInterestIsInOptions = peopleOfInterestVersionOptions.some(poi => poi.id === serviceOrder.personOfInterestVersion.id);

		if (!existingPersonOfInterestIsInOptions) {
			lastVersionsOfPeopleOfInterest = [...lastVersionsOfPeopleOfInterest, serviceOrder.personOfInterestVersion];
			setPeopleOfInterestVersionOptions(lastVersionsOfPeopleOfInterest);
		}
	};

	const handleFormSubmit = async (currentServiceOrderState: ServiceOrderFormModel) => {
		dispatch(setIsLoading(true));

		if (isNewForm) {
			if (peopleOfInterestVersionOptions.length === 0) {
				const createPersonOfInterestActionResponse = await createPersonOfInterest();

				if (createPersonOfInterestActionResponse) {
					if (createPersonOfInterestActionResponse.isSuccess && createPersonOfInterestActionResponse.result) {
						const createdPersonOfInterestVersionId = await getCreatedPersonOfInterestVersionId(createPersonOfInterestActionResponse.result);

						if (createdPersonOfInterestVersionId) {
							currentServiceOrderState.personOfInterestVersionId = createdPersonOfInterestVersionId;
						}
					} else {
						dispatch(showErrors(createPersonOfInterestActionResponse.errorModel?.errors));
						dispatch(setIsLoading(false));
						return;
					}
				}
			}

			const createModel = mapServiceOrderToCreateModel(currentServiceOrderState);
			const createResponse = await serviceOrderService.CreateServiceOrder(createModel);
			if (createResponse && createResponse.isSuccess) {
				await getServiceOrders();
				dispatch(showSuccess(t(SUCCESS_MESSAGE)));
				handleClose();

			} else {
				dispatch(showErrors(createResponse?.errorModel?.errors));
			}
		} else {
			const plannedParts = getProcessedParts(currentServiceOrderState.plannedParts);
			currentServiceOrderState.plannedParts = plannedParts;

			const updateModel = mapServiceOrderToUpdateModel(currentServiceOrderState);
			const updateResponse = await serviceOrderService.UpdateServiceOrder(updateModel);

			if (updateResponse && updateResponse.isSuccess) {
				await getServiceOrders();
				dispatch(showSuccess(t(SUCCESS_MESSAGE)));
				handleClose();
			} else {
				dispatch(showErrors(updateResponse?.errorModel?.errors));
			}
		}
		dispatch(setIsLoading(false));
	};

	const getProcessedParts = (plannedParts: PlannedPartModel[]): PlannedPartModel[] => {
		const _partsUpdated = plannedParts.map(pp => {
			if (partsToCreateIds.find(pcId => pcId === pp.id)) {
				return { ...pp, id: 0, toCreate: true };
			} else if (partsToUpdateIds.find(puId => puId === pp.id)) {
				return { ...pp, toUpdate: true };
			}

			return pp;
		});

		partsToDelete.forEach(pd => {
			pd.toDelete = true;
			_partsUpdated.push(pd);
		});

		return _partsUpdated;
	};

	const createPersonOfInterest = async (): Promise<ActionResult<number> | undefined> => {
		let createResponse = undefined;

		if (customerRelatedData) {
			const personOfInterestToCreate = { ...personOfInterestForCreation, isDefault: true };
			const createModel = mapPersonOfInterestToCreateModel(personOfInterestToCreate, customerRelatedData.id);
			createResponse = await peopleOfInterestService.AddPersonOfInterest(createModel);
		}

		return createResponse;
	};

	const getCreatedPersonOfInterestVersionId = async (createdPersonOfInterestId: number): Promise<number | undefined> => {
		const peopleOfInterestVersions = await getPeopleOfInterestVersionOptions();

		if (peopleOfInterestVersions) {
			const personCountry = countries.find(c => c.id === personOfInterestForCreation.countryId);

			if (personCountry) {
				const personCreatedVersion = peopleOfInterestVersions.find(poi => poi.personOfInterestId === createdPersonOfInterestId &&
					poi.firstName === personOfInterestForCreation.firstName &&
					poi.lastName === personOfInterestForCreation.lastName &&
					poi.emailAddress === personOfInterestForCreation.emailAddress &&
					poi.phoneNumber === personOfInterestForCreation.phoneNumber &&
					poi.phoneNumberPrefix === personCountry.phonePrefix
				);

				if (personCreatedVersion) {
					return personCreatedVersion.id;
				}
			}
		}
	};

	useEffect(() => {
		if (!userIsLoaded || !isNewForm) {
			return;
		}

		if (!canCreateServiceOrders) {
			dispatch(setIsLoading(false));
		}
	}, [userIsLoaded, isNewForm, canCreateServiceOrders]);

	useEffect(() => {
		dispatch(setIsLoading(true));
		if (!userIsLoaded) {
			return;
		}

		Promise.all([getServiceOrderTypeOptions(), getEquipmentOptions()]).then(() => {
			dispatch(setIsLoading(false));
		});

		if (!isNewForm) {
			getServiceOrder().then(() => {
				dispatch(setIsLoading(false));
			});
		} else {
			setFormIsReadonly(false);
		}
	}, [isNewForm, userIsLoaded]);

	useEffect(() => {
		if (customerRelatedData)
			return;
		if (!isNewForm && serviceOrder.equipmentId) {
			getCustomerRelatedData(serviceOrder.equipmentId);
		}
	}, [isNewForm, serviceOrder.equipmentId]);

	useEffect(() => {
		if (!customerRelatedData) {
			return;
		}

		loadPeopleOfInterestVersionOptions();
	}, [customerRelatedData]);

	useEffect(() => {
		if (isNewForm) {
			return;
		}

		handlePeopleOfInterestVersionOptions();

	}, [isNewForm]);

	if (isNewForm && userIsLoaded && !canCreateServiceOrders) {
		return (
			<></>
		);
	}

	if (!isNewForm && serviceOrder.id === 0) {
		return (
			<></>
		);
	}

	const steps: { label: string, optional?: boolean }[] = [
		{ label: t('General Info') },
		{ label: t('Customer Information') },
		{ label: t('Equipment Information') },
	];

	const plannedPartsAreValid = (plannedParts: PlannedPartModel[]): boolean => !plannedParts.some(pp => !pp.name.trim() || !pp.number.trim() || !pp.quantity || pp.quantity <= 0);

	const personOfInterestForCreationIsValid = (): boolean => {
		const isValid = !lengthIsLessThanTwoChars(personOfInterestForCreation.firstName) &&
			!lengthIsLessThanTwoChars(personOfInterestForCreation.lastName) &&
			isValidEmail(personOfInterestForCreation.emailAddress) &&
			!lengthIsLessThanTwoChars(personOfInterestForCreation.role) &&
			personOfInterestForCreation.countryId > 0 &&
			!lengthIsLessThanTwoChars(personOfInterestForCreation.phoneNumber);

		return isValid;
	};

	const validatePersonOfInterest = (serviceOrderCurrentState: ServiceOrderFormModel): boolean => {
		if (peopleOfInterestVersionOptions.length === 0) {
			return personOfInterestForCreationIsValid();
		} else {
			return serviceOrderCustomerInfoIsValid(serviceOrderCurrentState);
		}
	};

	return (
		<>
			<Modal
				title={t('Service Order')}
				open={formIsOpen}
				onClose={handleClose}
				maxWidth='lg'
				scroll='body'
				titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
				sx={{ '.MuiPaper-root': { width: '100%' } }}
			>
				<Box>
					{
						formIsReadonly && canEditServiceOrders && !serviceOrder.visitReportGenerated &&
						<Box sx={{ gridColumnStart: '1', gridColumnEnd: '1' }}>
							<Button
								id={'edit-service-order'}
								type={'button'}
								label={t('Edit')}
								onClick={() => setFormIsReadonly(false)}
							/>
						</Box>
					}
					{
						serviceOrder.visitReportGenerated &&
						<Box marginBottom={'2rem'}>
							<Alert
								severity='warning'
								sx={{ display: 'flex', justifyContent: 'center' }}
							>
								{t('Service order can\'t be modified')}
							</Alert>
						</Box>
					}
				</Box>
				<Box sx={{ padding: '20px 0' }}>
					<Stepper
						steps={steps}
						activeStep={step}
					/>
				</Box>
				<Formik
					initialValues={serviceOrder}
					validationSchema={serviceOrderValidationSchema}
					enableReinitialize
					onSubmit={handleFormSubmit}
				>
					{({ values, dirty, isSubmitting, isValid, handleSubmit }) => (

						<Form>

							{
								!isNewForm &&
								<Grid item xs={12} mt={1} mb={2}>
									<Grid container>
										<Grid item xs={12} md={4}>
											<StyledLabel>{t('Status')}</StyledLabel>
											<StyledLabel>{t(serviceOrder.serviceOrderDetail.status)}</StyledLabel>
										</Grid>

										<Grid item xs={12} md={4}>
											<StyledLabel>{t('Created on')}</StyledLabel>
											<StyledLabel>{moment(serviceOrder.createdOn).format(currentUser.preferences.dateFormat)}</StyledLabel>
										</Grid>

										<Grid item xs={12} md={4}>
											<StyledLabel>{t('Last Modified on')}</StyledLabel>
											{
												serviceOrder.lastModifiedOn &&
												<StyledLabel>{moment(serviceOrder.lastModifiedOn).format(currentUser.preferences.dateFormat)}</StyledLabel>
											}
										</Grid>
									</Grid>
								</Grid>
							}

							{
								step === ServiceOrderFormStep.GENERALINFO &&
								<ServiceOrderGeneralInfo
									readonly={formIsReadonly}
									activeStep={step}
									serviceOrderTypeOptions={serviceOrderTypeOptions}
									equipmentOptions={equipmentOptions}
									getCustomerRelatedData={getCustomerRelatedData}
									clearCustomerRelatedData={clearCustomerRelatedData}
									clearPersonOfInterestForCreation={clearPersonOfInterestForCreation}
								/>
							}

							{
								step === ServiceOrderFormStep.GENERALINFO &&
								<SaveSection
									validateNext={() => serviceOrderGeneralInfoIsValid(values)}
									handleCancel={handleClose}
									handleNext={() => setStep(ServiceOrderFormStep.CUSTOMERINFO)}
								/>
							}

							{
								step === ServiceOrderFormStep.CUSTOMERINFO && customerRelatedData &&
								<ServiceOrderRelationData
									readonly={formIsReadonly}
									customerRelatedData={customerRelatedData}
									peopleOfInterestVersionOptions={peopleOfInterestVersionOptions}
									personOfInterestForCreation={personOfInterestForCreation}
									handlePersonOfInterestForCreationChange={handlePersonOfInterestForCreationChange}
									validatePersonOfInterestForCreation={() => personOfInterestForCreationIsValid()}
								/>
							}
							{
								step === ServiceOrderFormStep.CUSTOMERINFO &&
								<SaveSection
									validateNext={isNewForm ?
										() => validatePersonOfInterest(values) :
										() => serviceOrderCustomerInfoIsValid(values)}
									handleBack={() => setStep(ServiceOrderFormStep.GENERALINFO)}
									handleNext={() => setStep(ServiceOrderFormStep.EQUIPMENTINFO)}
								/>
							}

							{
								step === ServiceOrderFormStep.EQUIPMENTINFO && customerRelatedData &&
								<>
									<ServiceOrderEquipmentInfo
										customerRelatedData={customerRelatedData}
									/>

									<ServiceOrderPlanningOptimization readonly={formIsReadonly} />

									<ServiceOrderParts
										readonly={formIsReadonly}
										markPartAsCreated={markPartAsCreated}
										markPartAsUpdated={markPartAsUpdated}
										markPartAsDeleted={markPartAsDeleted}
									/>
								</>
							}
							{
								step === ServiceOrderFormStep.EQUIPMENTINFO && !formIsReadonly &&
								<SaveSection
									handleBack={() => setStep(ServiceOrderFormStep.CUSTOMERINFO)}
									validateSubmit={isNewForm ?
										() => {
											return validatePersonOfInterest(values) &&
												plannedPartsAreValid(values.plannedParts) &&
												dirty && isValid && !isSubmitting;
										} :
										() => {
											return plannedPartsAreValid(values.plannedParts) &&
												dirty && isValid && !isSubmitting;
										}
									}
									handleSubmit={handleSubmit}
								/>
							}

							{
								step === ServiceOrderFormStep.EQUIPMENTINFO && formIsReadonly &&
								<SaveSection
									handleBack={() => setStep(ServiceOrderFormStep.CUSTOMERINFO)}
								/>
							}

						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
};