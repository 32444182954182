export type PersonOfInterestVersion = {
    id: number;
    personOfInterestId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumberPrefix: string;
    phoneNumber: string;
    isDefault: boolean;
    version: number;
}

export const getDefaultPersonOfInterestVersion = (): PersonOfInterestVersion => {
    return {
        id: 0,
        personOfInterestId: 0,
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumberPrefix: '',
        phoneNumber: '',
        isDefault: false,
        version: 0,
    };
};