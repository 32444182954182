import React from 'react';
import { useTranslation } from 'react-i18next';
import { IServiceOrderPlanningOptimizationProps } from './IServiceOrderPlanningOptimizationProps';
import { FormikProps, useFormikContext } from 'formik';
import { ServiceOrderFormModel } from '../../models/serviceOrderModel';
import { estimatedWorkHoursShouldBeDisabled } from '../../services/ServiceOrderUtility';
import { TextField } from '@ui-components/ui-library';
import { Grid } from '@mui/material';

export const ServiceOrderPlanningOptimization = (props: IServiceOrderPlanningOptimizationProps): JSX.Element => {
	const { t } = useTranslation();
	const { readonly } = props;

	const { values: serviceOrder, handleChange, handleBlur } = useFormikContext() as FormikProps<ServiceOrderFormModel>;

	return (
		<>
			<Grid container spacing={2} mt={2}>
				<Grid item xs={12} md={6}>
					<TextField
						label={t('Estimated Job Hours')}
						id={'planningOptimization.estimatedWorkHours'}
						name={'planningOptimization.estimatedWorkHours'}
						value={serviceOrder.planningOptimization.estimatedWorkHours ?? ''}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled={readonly || estimatedWorkHoursShouldBeDisabled(serviceOrder)}
						inputRestriction={'numeric'}
						numericRestrictionAllowNegative={false}
						inputProps={{ maxLength: 5 }}
					/>
				</Grid>
			</Grid>
		</>
	);
};