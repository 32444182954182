export enum PlanningType {
    Service,
    Vacation,
    Holiday,
    SickLeave,
    Training,
    Other,
}

export const planningTypeLabels: Record<PlanningType, string> = {
    [PlanningType.Service]: 'Service',
    [PlanningType.Vacation]: 'Vacation',
    [PlanningType.Holiday]: 'Holiday',
    [PlanningType.SickLeave]: 'Sick Leave',
    [PlanningType.Training]: 'Training',
    [PlanningType.Other]: 'Other',
};

export const getPlanningTypeLabel = (type: PlanningType): string => {
    return planningTypeLabels[type];
};