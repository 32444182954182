import React from 'react';
import { EquipmentTypeAirTreatmentSectionProps } from './EquipmentTypeAirTreatmentSectionProps';
import { Grid } from '@mui/material';
import { Dropdown, StyledSearchableDropdownProps, TextField, DropdownOption } from '@ui-components/ui-library';
import { useTranslation } from 'react-i18next';
import { EquipmentFormModel } from '../../models/equipment';
import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import { insallBassesService } from '../../../services/InstalledBasesService';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { userCanSeeInstalledBaseCode, userLoaded } from '../../../stores/user/usersSlice';
import { AuthenticatedUser } from '../../../users/models/user';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import moment from 'moment-timezone';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const EquipmentTypeAirTreatmentSection = (props: EquipmentTypeAirTreatmentSectionProps): JSX.Element => {
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const { t } = useTranslation();

    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
    const userCanSeeEquipmentInstalledBaseCode = useAppSelector(state => userCanSeeInstalledBaseCode(state));

    const { readonly, brandOptions, equipmentStatusOptions } = props;

    const { values: equipment, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext() as FormikProps<EquipmentFormModel>;

    const determineInstalledBaseCode = async (equipmentTypeId: number, flow: number, inverterDrive: boolean | undefined) => {
        if (equipmentTypeId > 0) {
            const installedBaseResponse =
                await insallBassesService.GetInstalledBaseForEquipmentValues(equipmentTypeId, flow, inverterDrive);
            if (installedBaseResponse.isSuccess) {
                if (installedBaseResponse.result && installedBaseResponse.result.description) {
                    setFieldValue('installedBaseCode', installedBaseResponse.result.description);
                } else {
                    setFieldValue('installedBaseCode', '');
                }
            } else {
                setFieldValue('installedBaseCode', '');
            }
        } else {
            setFieldValue('installedBaseCode', '');
        }
    };

    const onFlowBlur = async (e: React.FocusEvent<HTMLInputElement> | undefined) => {
        handleBlur(e);
        if (!equipment.flow) {
            return;
        }
        await determineInstalledBaseCode(equipment.equipmentTypeId, equipment.flow, equipment.inverterDrive);
    };

    const onInverterDriveChange = async (e: any) => {
        setFieldValue('inverterDrive', e);
        if (!equipment.flow) {
            return;
        }
        await determineInstalledBaseCode(equipment.equipmentTypeId, equipment.flow, e);
    };

    const equipmentStatusDropdownOptions = equipmentStatusOptions.map(o => { return { id: o.id, label: t(o.description), value: o.id }; });
    const equipmentStatusValueOption = equipmentStatusDropdownOptions.find(o => o.id === equipment.equipmentDetail.equipmentStatusId);

    const brandDropdownOptions = brandOptions.map(o => { return { id: o.id, label: o.name, value: o.id }; });
    const brandValueOption = brandDropdownOptions.find(o => o.id === equipment.brandId);

    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'brandId'}
                        label={t('Brand')}
                        name={'brandId'}
                        variant={'searchable'}
                        options={brandDropdownOptions}
                        value={brandValueOption ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => setFieldValue('brandId', item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        optionListStyles={{ fontFamily: 'system-ui' }}
                        required={true}
                        disabled={readonly}
                        greyedOutLabelOnDisabled
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'brandId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>


                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Flow (l/s)')}
                        id={'flow'}
                        name={'flow'}
                        value={equipment.flow}
                        onChange={handleChange}
                        onBlur={(e) => onFlowBlur(e as React.FocusEvent<HTMLInputElement>)}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 10 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        inputRestriction='decimal'
                        decimalRestrictionMaxDecimalPlacesBefore={6}
                        decimalRestrictionMaxDecimalPlacesAfter={3}
                        disabled={readonly}
                        required
                    />
                    <ErrorMessage name={'flow'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DatePicker
                        id={'estimatedProductionDate'}
                        value={equipment.estimatedProductionDate}
                        onChange={value => {
                            setFieldTouched('estimatedProductionDate');

                            // setFieldValue should be last action in order for the validation to work
                            if (value) {
                                // Not sure if this will be the final way of storing the data.
                                const changeValueString = String(value);
                                const selectedDate = moment(changeValueString).toDate();
                                const firstDay = moment(selectedDate).startOf('year').toDate();
                                setFieldValue('estimatedProductionDate', firstDay);
                            } else {
                                setFieldValue('estimatedProductionDate', undefined);
                            }
                        }}
                        inputFormat={'YYYY'}
                        disableFuture
                        disabled={readonly}
                        actions={['clear']}
                        views={['year']}
                        title={t('Production Year')}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Kilowatt')}
                        id={'size'}
                        name={'size'}
                        value={equipment.size}
                        onChange={handleChange}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 10 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        inputRestriction='decimal'
                        decimalRestrictionMaxDecimalPlacesBefore={6}
                        decimalRestrictionMaxDecimalPlacesAfter={3}
                        disabled={readonly}
                    />
                    <ErrorMessage name={'size'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                {userIsLoaded && userCanSeeEquipmentInstalledBaseCode ?
                    (
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('Installed Base Code')}
                                id={'installedBaseCode'}
                                name={'installedBaseCode'}
                                value={equipment.installedBaseCode}
                                onChange={(e) => onInverterDriveChange(e)}
                                sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                                disabled
                            />
                            <ErrorMessage name={'installedBaseCode'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                        </Grid>
                    )
                    :
                    (
                        <Grid xs={12} md={6}>
                        </Grid>
                    )
                }

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Type of gas')}
                        id={'typeOfGas'}
                        name={'typeOfGas'}
                        value={equipment.typeOfGas}
                        onChange={handleChange}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 75 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        disabled={readonly}
                    />
                    <ErrorMessage name={'typeOfGas'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DatePicker
                        id={'lastMaintenanceDate'}
                        value={equipment.equipmentDetail.lastMaintenanceDate}
                        // setFieldValue should be last action in order for the validation to work
                        onChange={value => { setFieldTouched('equipmentDetail.lastMaintenanceDate'); setFieldValue('equipmentDetail.lastMaintenanceDate', value ? moment(value).startOf('d').toDate() : undefined); }}
                        inputFormat={currentUser.preferences.dateFormat}
                        disableFuture
                        disabled={readonly}
                        actions={['today', 'clear']}
                        title={t('Last Maintenance Date')}
                    />
                    <ErrorMessage name={'equipmentDetail.lastMaintenanceDate'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'equipmentDetail.equipmentStatusId'}
                        label={t('Machine Status')}
                        name={'equipmentDetail.equipmentStatusId'}
                        variant={'searchable'}
                        options={equipmentStatusDropdownOptions}
                        value={equipmentStatusValueOption ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => setFieldValue('equipmentDetail.equipmentStatusId', item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        optionListStyles={{ fontFamily: 'system-ui' }}
                        required={true}
                        disabled={readonly}
                        greyedOutLabelOnDisabled
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'equipmentDetail.equipmentStatusId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};