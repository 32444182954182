import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTranslation } from 'react-i18next';
import { ServiceOrderStatusesViewModel, getDefaultServiceOrderStatuses } from '../../../models/dashboard/ServiceOrderStatusesViewModel';
import { dashboardService } from '../../../services/DashboardService';
import { ChartSxObject } from '../../../shared/utils/sxStyleObjects';
import { CustomChartTooltipContent } from '../customChartTooltipContent/CustomChartTooltipContent';
import { truncateStringWithEllipsis } from '../../../shared/utils/Utils';
import { showErrors } from '../../../stores/alert/alertSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { ChartsAxisContentProps } from '@mui/x-charts';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';

export const ServiceOrderByStatusDashboard = (): JSX.Element => {
    
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));

    const [serviceOrderStatuses, setServiceOrderStatuses] = useState<ServiceOrderStatusesViewModel>(getDefaultServiceOrderStatuses());

    const getServiceOrderStatusesCount = async () => {
        const serviceOrderStatusesResponse = await dashboardService.GetServiceOrderStatusesCount();
        if (serviceOrderStatusesResponse.isSuccess) {
            setServiceOrderStatuses(serviceOrderStatusesResponse.result!);
        } else {
            dispatch(showErrors(serviceOrderStatusesResponse?.errorModel?.errors));
        }
    };

    useEffect(() => {
        getServiceOrderStatusesCount();
    }, []);

    const labels = [t('To be assigned'), t('Assigned'), t('In Progress'), t('Completed')];

    return (
        <>
            <Box dir={'ltr'}>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'left'} fontSize={18}
                    minHeight={'40px'} alignItems={'center'} dir={shouldUseRtl ? 'rtl' : 'ltr'}>
                    {t('Service orders KPI')}
                </Box>

                <BarChart
                    xAxis={[
                        {
                            id: 'serviceOrderStatuses',
                            data: labels,
                            scaleType: 'band',
                            tickLabelInterval: () => true,
                            valueFormatter: (val) => truncateStringWithEllipsis(val, 10, shouldUseRtl)
                        },
                    ]}
                    series={[
                        {
                            data: [
                                serviceOrderStatuses.ToBeAssigned || 0,
                                serviceOrderStatuses.Assigned || 0,
                                serviceOrderStatuses.IN_PROGRESS || 0,
                                serviceOrderStatuses.Completed || 0,
                            ],
                        },
                    ]}
                    tooltip={{ trigger: 'axis' }}
                    slots={{

                        axisContent: (props: ChartsAxisContentProps) =>
                            <CustomChartTooltipContent
                                axisValue={props.axisValue}
                                series={props.series}
                                dataIndex={props.dataIndex}
                            />
                    }}
                    sx={ChartSxObject}
                />
            </Box>
        </>
    );
};
