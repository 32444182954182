
export interface IEquipmentFormContainerProps {
	formIsOpen: boolean;
	equipmentId: number | undefined;
	handleClose: () => void;
	getEquipments(): Promise<void>;
}

export enum EquipmentFormContainerStep {
	EQUIPMENTGENERALINFO = 0,
	EQUIPMENTDETAILS = 1,
}