import { DropdownOption } from '@ui-components/ui-library';

export type TechnicianModel = {
    id: number;
    name: string;
    organizationIds: number[];
}

export const getTechicianDropdownOptions = (technicians: TechnicianModel[]): DropdownOption[] => {
    const dropDownOption = technicians.map(o => ({ id: o.id, label: o.name, value: o.id }));
    return dropDownOption;
};