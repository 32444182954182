
export type ServiceOrderStatusesViewModel = {
    ToBeAssigned: number,
    Assigned: number,
    IN_PROGRESS: number,
    Completed: number,
}

export const getDefaultServiceOrderStatuses = (): ServiceOrderStatusesViewModel => {
    return {
        ToBeAssigned: 0,
        Assigned: 0,
        IN_PROGRESS: 0,
        Completed: 0,
    };
};
