import * as Yup from 'yup';
import { RequiredMessage, ShortValueMessage } from '../../shared/utils/validationMessages';
import { ServiceOrderFormModel } from '../models/serviceOrderModel';
import { stringNumericComparator } from '../../shared/utils/Utils';

export const serviceOrderValidationSchema = Yup.object().shape({
    externalId: Yup.string().optional().max(100),
    number: Yup.string().optional(),
    equipmentId: Yup.number().required(RequiredMessage).min(1, RequiredMessage),
    isEquipmentLinkedToCustomer: Yup.boolean().nullable(true).when('equipmentId', {
        is: (equipmentId: number) => equipmentId !== 0,
        then: Yup.boolean().oneOf([true])
    }),

    serviceOrderDetail: Yup.object().shape({
        description: Yup.string()
        .trim()
        .required(RequiredMessage)
        .min(2, ShortValueMessage),
        isEquipmentOperating: Yup.boolean(),
        serviceOrderTypeId: Yup.number()
        .min(1, RequiredMessage),
        serviceOrderStatusId: Yup.number().optional()
    }),
    
    planningOptimization: Yup.object().shape({
        estimatedWorkHours: Yup.number().optional()
    }),    
    
    personOfInterestVersionId: Yup.number().when('id', {
        is: 0,
        then: Yup.number().optional().min(1, RequiredMessage),
        otherwise: Yup.number().required(RequiredMessage).min(1, RequiredMessage),
    }),
});

const serviceOrderGeneralInfoValidationSchema = Yup.object().shape({
    number: Yup.string().optional(),
    equipmentId: Yup.number().required().min(1),
    serviceOrderDetail: Yup.object().shape({
        description: Yup.string()
            .trim()
            .required()
            .min(2),
        isEquipmentOperating: Yup.boolean(),
        serviceOrderTypeId: Yup.number()
            .min(1),
    }),
    isEquipmentLinkedToCustomer: Yup.boolean().nullable(true).when('equipmentId', {
        is: (equipmentId: number) => equipmentId !== 0,
        then: Yup.boolean().oneOf([true])
    }),
});

const serviceOrderCustomerInfoValidationSchema = Yup.object().shape({
    personOfInterestVersionId: Yup.number().required().min(1)
});

export const serviceOrderGeneralInfoIsValid = (serviceOrder: ServiceOrderFormModel): boolean => {
    return serviceOrderGeneralInfoValidationSchema.isValidSync(serviceOrder);
};

export const serviceOrderCustomerInfoIsValid = (serviceOrder: ServiceOrderFormModel): boolean => {
    return serviceOrderCustomerInfoValidationSchema.isValidSync(serviceOrder);
};

export const estimatedWorkHoursShouldBeDisabled = (serviceOrder: ServiceOrderFormModel) => {
    return serviceOrder.serviceOrderDetail.status === 'Assigned';
};

export const serviceOrderNumberComparator = (v1: string, v2: string) => {
    const regexp = /SO(\d+)-(\d+)/;

    const [, distributorId1, number1] = regexp.exec(v1) || [];
    const [, distributorId2, number2] = regexp.exec(v2) || [];

    let compareResult = stringNumericComparator(distributorId1, distributorId2);

    if (compareResult === 0) {
        return stringNumericComparator(number1, number2);
    }
    
    return compareResult;
};