import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps, TextField } from '@ui-components/ui-library';
import { IDistributorDetailProps } from './IDistributorDetailsProps';
import { Box, Grid } from '@mui/material';
import { StyledTitle } from '../../../components/form/title/Title';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const DistributorDetails = (props: IDistributorDetailProps): JSX.Element => {
    const { isNewForm, erpTypes, countries } = props;
    const { t } = useTranslation();
    const { values: organization, handleChange, handleBlur, setFieldValue } = useFormikContext() as any;

    const onPhonePrefixChange = (value: any) => {
        setFieldValue('address.countryId', value);
    };

    const onErpTypeChange = (value: any) => {
        setFieldValue('distributorDetail.erpTypeId', value);
    };

    const disableNonEditableFields = () => {
        if (!isNewForm) {
            if (organization.distributorDetail?.id != 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return organization.distributorDetail == undefined;
        }
    };

    const erpOptions = erpTypes?.map(ro => ({ id: ro.id, label: t(ro.key), value: ro.id }));
    const selectedErpType = erpOptions?.find(obj => obj.id === organization.distributorDetail.erpTypeId);

    const phonePrefixOptions = orderBy(countries, c => c.phonePrefix)?.map(b => {
        return { id: b.id, label: `${b.phonePrefix} (${b.description})`, value: b.id };
    });
    const selectedPhonePrefix = phonePrefixOptions?.find(obj => obj.id === organization.address?.countryId);

    return (
        <>
            <Box pt={5} display={'flex'} justifyContent={'space-between'}>
                <StyledTitle variant='h4'>{t('Company details')}</StyledTitle>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'distributorDetail.erpTypeId'}
                        label={t('ERP Type')}
                        name={'distributorDetail.erpTypeId'}
                        options={erpOptions ? erpOptions : []}
                        value={selectedErpType ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => onErpTypeChange(item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        required={true}
                        disabled={disableNonEditableFields()}
                        greyedOutLabelOnDisabled
                        variant='searchable'
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'distributorDetail.erpTypeId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Distributor Number')}
                        id={'distributorDetail.erpCustomerNumber'}
                        name={'distributorDetail.erpCustomerNumber'}
                        value={organization.distributorDetail?.erpCustomerNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableNonEditableFields()}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                    />
                    <ErrorMessage name={'distributorDetail.erpCustomerNumber'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'address.countryId'}
                        label={t('Phone Number Prefix')} 
                        name={'address.countryId'}
                        options={phonePrefixOptions ?? []}
                        value={selectedPhonePrefix ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => onPhonePrefixChange(item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        required={true}
                        greyedOutLabelOnDisabled
                        variant='searchable'
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'address.countryId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Phone Number')}
                        id={'distributorDetail.phoneNumber'}
                        name={'distributorDetail.phoneNumber'}
                        value={organization.distributorDetail?.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                    />
                    <ErrorMessage name={'distributorDetail.phoneNumber'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};
