import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, ListItemButton, ListItemButtonProps, ListItemText, ListItemTextProps } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Link as LinkRouter } from 'react-router-dom';
import './NavMenu.css';

interface IStyledListItemText extends ListItemTextProps {
	selected?: boolean;
}

interface IStyledListItemButton extends ListItemButtonProps {
	selected?: boolean;
}

const StyledListItemButton = styled(ListItemButton)<IStyledListItemButton>
	(({ theme, selected }) => ({
		paddingLeft: '8px',
		paddingRight: '8px',
		'& .MuiListItemText-root': {
			'& .MuiListItemText-primary': {
				color: selected ? theme.palette.colors.neutral[50] : theme.palette.colors.neutral[100],
			}
		},
		'&.Mui-selected': {
			backgroundColor: 'unset',
			color: theme.palette.colors.neutral[50],
			':hover': {
				backgroundColor: 'unset',
			}
		},
		':hover': {
			backgroundColor: 'unset',
			'& .MuiListItemText-root': {
				'& .MuiListItemText-primary': {
					color: theme.palette.colors.neutral[50],
				}
			},
		},
	}));

const StyledListItemText = styled(ListItemText)<IStyledListItemText>
	(({ theme, selected }) => ({
		marginLeft: '8px',
		marginRight: '8px',
		'.MuiListItemText-primary': {
			fontSize: 'inherit',
			lineHeight: '16px',
			fontFamily: 'inherit',
			fontWeight: selected ? 600 : 400,
			color: selected ? theme.palette.colors.neutral[50] : theme.palette.colors.neutral[100],
		}
	}));

export interface NavMenuItemProps {
	label: string;
	id?: string;
	link?: any;
	selected?: boolean;
	handleClick?: (id: string, link?: any) => void;
	handleDropdown?: (e: React.MouseEvent<HTMLDivElement>) => void;
	handleSelect?: () => void;
	dropdownState?: 'active' | 'inactive';
}
export const NavMenuItem = (props: NavMenuItemProps): JSX.Element => {
	const handleClick = () => {
		if (props.handleClick && props.id) {
			props.handleClick(props.id, props.link);
		}
	};

	return (
		<>
			{(props.link || props.handleSelect) &&
				<StyledListItemButton
					id={props.id}
					selected={props.selected}
					onClick={props.handleSelect ? props.handleSelect : handleClick}
				>
					<Link
						component={LinkRouter}
						to={props.link}
						onClick={props.handleSelect ? props.handleSelect : handleClick}
						underline='none'
					>
						<StyledListItemText>
							{props.label}
						</StyledListItemText>
					</Link>
				</StyledListItemButton>
			}
			{(props.handleDropdown) &&
				<StyledListItemButton
					id={props.id}
					selected={props.selected}
					onClick={
						props.handleDropdown
					}
				>
					<StyledListItemText>
						{props.label}
						{
							props.dropdownState === 'inactive' ?
								<ArrowDropDownIcon
									sx={{
										verticalAlign: 'text-bottom',
										'&.MuiSvgIcon-root': { height: '0.7em', width: '0.7em' }
									}}
								/> :
								<ArrowDropUpIcon
									sx={{
										verticalAlign: 'text-bottom',
										'&.MuiSvgIcon-root': { height: '0.7em', width: '0.7em' }
									}}
								/>
						}
					</StyledListItemText>
				</StyledListItemButton>
			}
		</>
	);
};
