import React from 'react';
import { useTranslation } from 'react-i18next';
import { IServiceOrderDisplayCustomerInfoProps } from './IServiceOrderDisplayCustomerInfoProps';
import { TextField } from '@ui-components/ui-library';
import { Box, Grid } from '@mui/material';
import { StyledTitle } from '../../../components/form/title/Title';
import './ServiceOrderDisplayCustomerInfo.scss';

export const ServiceOrderDisplayCustomerInfo = (props: IServiceOrderDisplayCustomerInfoProps): JSX.Element => {

	const { serviceOrder } = props;

	const { t } = useTranslation();

	const companyAddress = [
		serviceOrder.customerStreet,
		serviceOrder.customerPostalCode,
		serviceOrder.customerCity,
		serviceOrder.customerCountry
	].join(', ');

	return (
		<>
			<Grid item xs={12}>
				<Box pt={3} display={'flex'} justifyContent={'start'}>
					<StyledTitle variant='h4'>{t('Company Information')}</StyledTitle>
				</Box>
				<Grid container spacing={2}>
					
					<Grid item xs={12} md={6}>
						<TextField
							label={t('Name of Company')}
							value={serviceOrder.customerName}
							disabled={true}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<TextField
							id={'addressOfCompanyMultiline'}
							label={t('Address of Company')}
							value={companyAddress}
							multiline
							minRows={3}
							disabled
						/>
					</Grid>
				</Grid>


				<Box pt={5} display={'flex'} justifyContent={'start'}>
					<StyledTitle variant='h4'>{t('Person of Interest')}</StyledTitle>
				</Box>

				<Grid container spacing={2} pt={1}>

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label={t('First Name')}
							value={serviceOrder.personOfInterestVersion.firstName}
							disabled={true}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label={t('Last Name')}
							value={serviceOrder.personOfInterestVersion.lastName}
							disabled={true}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label={t('Email')}
							value={serviceOrder.personOfInterestVersion.emailAddress}
							disabled={true}
						/>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<TextField
							label={t('Phone Number')}
							value={`${serviceOrder.personOfInterestVersion.phoneNumberPrefix}${serviceOrder.personOfInterestVersion.phoneNumber}`}
							disabled={true}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
