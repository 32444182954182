export type ServiceExecutionWorkCommentModel = {
    description: string;
};

export type ServiceExecutionWorkCommentCreateModel = {
    visitReportId: number;
    workComments: string;
};

export const getDefaultServiceExecutionWorkComment = (): ServiceExecutionWorkCommentModel => {
    return {
        description: '',
    };
};