import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPeopleOfInterestProps } from './IPeopleOfInterestProps';
import styles from './PeopleOfInterest.module.scss';
import { getDefaultPersonOfInterest, PersonOfInterestModel } from '../../models/personOfInterestModel';
import { StyledTitle } from '../../../components/form/title/Title';
import { Button, DataGrid, Icon } from '@ui-components/ui-library';
import { Box } from '@mui/material';
import { GridEnrichedColDef, GridRenderCellParams, GridRowClassNameParams, GridRowParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { InnerTableSxObject, StyledGridCellContent } from '../../../shared/utils/sxStyleObjects';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';

export const PeopleOfInterest = (props: IPeopleOfInterestProps): JSX.Element => {
	const { t } = useTranslation();
	const [selectedPersonOfInterest, setSelectedPersonOfInterest] = useState<PersonOfInterestModel | undefined>(undefined);
	const [removePersonOfInterestDialogIsOpen, setRemovePersonOfInterestDialogIsOpen] = useState<boolean>(false);


	const { peopleOfInterest, countries, onAddEditPerson, onSetPersonAsDefault, onRemovePerson } = props;

	const peopleOfInterestWithTranslatedRoles = peopleOfInterest.map(poi => ({ ...poi, role: t(poi.role) }));

	const onPersonOfInterestRemove = async () => {
		if (selectedPersonOfInterest) {
			onRemovePerson(selectedPersonOfInterest?.id);
			setRemovePersonOfInterestDialogIsOpen(false);
		}
	};

	const onSetAsDefaultClick = async (personOfInterestId: number) => {
		onSetPersonAsDefault(personOfInterestId);
	};

	const onAddEditPersonOfInterest = (personOfInterestId: number | undefined) => {
		if (personOfInterestId) {
			const personOfInterestToSet = peopleOfInterest.find(poi => poi.id === personOfInterestId);
			setSelectedPersonOfInterest(personOfInterestToSet);
			onAddEditPerson(personOfInterestToSet);
		} else {
			setSelectedPersonOfInterest(getDefaultPersonOfInterest());
			onAddEditPerson(getDefaultPersonOfInterest());
		}
	};

	const hideRemovePersonOfInterestDialog = () => {
		setRemovePersonOfInterestDialogIsOpen(false);
		setSelectedPersonOfInterest(undefined);
	};

	const onRemovePersonOfInterestClick = (personOfInterestId: number) => {
		const personOfInterestToBeRemoved = peopleOfInterest.find(poi => poi.id === personOfInterestId);
		setSelectedPersonOfInterest(personOfInterestToBeRemoved);
		setRemovePersonOfInterestDialogIsOpen(true);
	};

	const phoneNumberBodyTemplate = (params: GridRenderCellParams) => {
		const countryOption = countries.find(c => c.id === params.row.countryId);
		return (
			<StyledGridCellContent>{`${countryOption?.phonePrefix} ${params.row.phoneNumber}`}</StyledGridCellContent>
		);
	};

	const getRowClassName = (rowData: GridValidRowModel): string => {
		return rowData.isDefault ? styles.default_poi_row : '';
	};

	const columns: GridEnrichedColDef[] = [
		{ field: 'firstName', headerName: t('First Name'), disableExport: true, sortable: false, flex: 2 },
		{ field: 'lastName', headerName: t('Last Name'), disableExport: true, sortable: false, flex: 2 },
		{ field: 'emailAddress', headerName: t('Email'), disableExport: true, sortable: false, flex: 2 },
		{ field: 'role', headerName: t('Role'), disableExport: true, sortable: false, flex: 2 },
		{ field: 'phoneNumber', headerName: t('Phone Number'), renderCell: phoneNumberBodyTemplate, disableExport: true, sortable: false, flex: 2 },
		{
			field: 'actions',
			type: 'actions',
			headerName: t('Actions'),
			flex: 1,
			getActions: (params: GridRowParams<PersonOfInterestModel>) => {
				let actionsArray = [];
				actionsArray.push(
					<DataGridActionOverview
						key={'edit'}
						icon={<Icon type='edit' />}
						label={t('Edit')}
						onClick={() => onAddEditPersonOfInterest(params.row.id)}
						showInMenu
					/>
				);
				if (!params.row.isDefault) {
					actionsArray.push(
						<DataGridActionOverview
							key={'default'}
							icon={<Icon type='check' />}
							label={t('Set as Default')}
							onClick={() => onSetAsDefaultClick(params.row.id)}
							showInMenu
						/>,
						<DataGridActionOverview
							key={'activate'}
							icon={<Icon type='delete' />}
							label={t('Delete')}
							onClick={() => onRemovePersonOfInterestClick(params.row.id)}
							showInMenu
						/>
					);
				}
				return actionsArray;
			},
		},
	];

	return (
		<>
			<Box mt={'2rem'} p={'1%'} border={'1px solid lightgray'}>
				<Box display={'flex'} flexDirection='row' justifyContent='space-between' alignItems='center' marginBottom='1%'>
					<StyledTitle variant={'h4'}>{t('People of Interest')}</StyledTitle>
					<Button
						id='add'
						label={t('Add')}
						startIcon={'add'}
						aria-label={'Add'}
						onClick={() => onAddEditPersonOfInterest(undefined)}
						type={'button'} />
				</Box>

				<DataGrid
					rows={peopleOfInterestWithTranslatedRoles}
					columns={columns}
					autoHeight
					containerHeight='auto'
					getRowHeight={() => 'auto'}
					disableColumnMenu={true}
					hideFooter
					getRowClassName={(params: GridRowClassNameParams<GridValidRowModel>) => getRowClassName(params.row)}
					sx={InnerTableSxObject}
				/>
				{
					removePersonOfInterestDialogIsOpen && selectedPersonOfInterest &&
					<ConfirmationDialog
						open={removePersonOfInterestDialogIsOpen}
						message={t('Are you sure you want to remove {{firstName}} {{lastName}}', { firstName: selectedPersonOfInterest.firstName, lastName: selectedPersonOfInterest.lastName })}
						onConfirm={onPersonOfInterestRemove}
						onClose={hideRemovePersonOfInterestDialog}
					/>
				}
			</Box>
		</>
	);
};