
export type ServiceOrderDetailModel = {
    id: number;
    description: string;
    isEquipmentOperating: boolean;
    serviceOrderTypeId: number;
    serviceOrderStatusId: number;
    status: string;
};

export type ServiceOrderDetailDisplayModel = {
    id: number;
    description: string;
    isEquipmentOperating: boolean;
    serviceOrderType: string;
}

export const getDefaultServiceOrderDetail = (): ServiceOrderDetailModel => {
    return {
        id: 0,
        description: '',
        isEquipmentOperating: false,
        serviceOrderTypeId: 0,
        serviceOrderStatusId: 0,
        status: '',
    };
};