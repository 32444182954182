import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';

export const LinkText = (props: React.PropsWithChildren<LinkProps>) => {
  return (
    <Link component={LinkRouter} to={props.href || ''} {...props}>
      <span>{props.children}</span>
    </Link>
  );
}; 