export const namespaces = {
    common: 'common',
};

export const ltrLanguages = {
    en: 'en',
    nl: 'nl',
    it: 'it',
    pt: 'pt',
    fr: 'fr',
    tr: 'tr',
    es: 'es',
};

export const rtlLanguages = {
    ar: 'ar',
};

export const languages = { ...ltrLanguages, ...rtlLanguages };