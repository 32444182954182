import { EquipmentType } from '../equipments/models/equipmentType';
import { ActionResult, api } from '../shared/utils/api';

class EquipmentTypeService {
    async GetEquipmentTypesForCategory(categoryId: number): Promise<ActionResult<EquipmentType[]>> {
        const url = `/equipmentTypes/GetEquipmentTypesForCategory/${categoryId}`;

        const response = await api.get<EquipmentType[]>(url);

        return response;
    }

    async GetAllEquipmentTypes(): Promise<ActionResult<EquipmentType[]>> {
        const url = '/equipmentTypes/GetAllEquipmentTypes/';

        const response = await api.get<EquipmentType[]>(url);

        return response;
    }
}

export const equipmentTypeService = new EquipmentTypeService(); 