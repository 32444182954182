import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentsOverviewProps } from './IEquipmentsOverviewProps';
import { EquipmentViewModel } from '../../models/equipment';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { Button, Icon, SearchBar } from '@ui-components/ui-library';
import { GridEnrichedColDef, GridRowParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';
import { getEquipmentCategoryLabel } from '../../../shared/enums/EquipmentCategories';
import { EquipmentFormContainer } from '../equipmentFormContainer/EquipmentFormContainer';
import { userCanViewCustomers, userIsGlobalAdmin, userIsLocalAdmin } from '../../../stores/user/usersSlice';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { i18n } from '../../../i18n/config';

export const EquipmentsOverview = (props: IEquipmentsOverviewProps): JSX.Element => {
    const { equipments, getEquipments } = props;
    const { t } = useTranslation();
    
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [clearGridFilters, setClearGridFilters] = useState<boolean | undefined>(undefined);
    const [equipmentsFiltered, setEquipmentsFiltered] = useState<EquipmentViewModel[]>(equipments);
    const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
    
    const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));
    const isLocalAdmin: boolean = useAppSelector(state => userIsLocalAdmin(state));
    const userCanViewCustomerColumn = useAppSelector(state => userCanViewCustomers(state));

    useEffect(() => {
        if (equipments.length > 0) {
            setEquipmentsFiltered(equipments);
        }        
    }, [equipments]);

    const debouncedFilterEquipments = useCallback(
        debounce((value: string) => {
			if (equipments.length > 0) {
				let _equipmentsFiltered = [...equipments];
                if (globalFilterValue !== undefined) {
                    _equipmentsFiltered = _equipmentsFiltered.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value.toLowerCase())));
                }
				setEquipmentsFiltered(_equipmentsFiltered);
			}
        }, 500), [equipments]
    );

    useEffect(() => {
        return () => debouncedFilterEquipments.cancel();
    }, [debouncedFilterEquipments]);

    useEffect(() => {
        debouncedFilterEquipments(globalFilterValue);
    }, [globalFilterValue]);

    const onGlobalFilterChange = (value: string) => {
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setGlobalFilterValue('');
        setEquipmentsFiltered(equipments);
    };

    const clearFilters = () => {
        initFilters();
        setClearGridFilters(!clearGridFilters);
    };

    const handleEdit = (id: number) => {
        setSelectedId(id);
        setFormIsOpen(true);
    };

    const handleCloseFormDialog = () => {
        setSelectedId(undefined);
        setFormIsOpen(false);
    };

    const renderHeader = (): JSX.Element => {
        return (
            <Box display='flex' justifyContent='space-between' marginBottom={2}>
                <Button
                    id='new-equipment'
                    variant='primary'
                    label={t('New Equipment')}
                    startIcon='add'
                    onClick={() => setFormIsOpen(true)}
                />

                <SearchBar
                    variant='default'
                    onChange={(value) => onGlobalFilterChange(value)}
                    timeoutOnChange={300}
                    value={globalFilterValue}
                    placeholder={t('Search')}
                />

                <Button
                    id='clear-filter'
                    variant='secondary'
                    label={t('Clear')}
                    startIcon='filter_list_off'
                    onClick={clearFilters}
                />
            </Box>
        );
    };

    const translatedEquipments = equipmentsFiltered.map(e => ({ ...e, equipmentCategory: t(getEquipmentCategoryLabel(e.equipmentCategory)) })) as GridValidRowModel[];

    const getColumns = (): GridEnrichedColDef[] => {
        const columns: GridEnrichedColDef[] = [
            { field: 'serialNumber', headerName: t('Serial Number'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Serial Number') },
            isGlobalAdmin && { field: 'customerCenter', headerName: t('Customer Center'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Customer Center') },
            (isLocalAdmin || isGlobalAdmin) &&  { field: 'distributorOrganization', headerName: t('Distributor Organization'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Distributor Organization') },
            userCanViewCustomerColumn && { field: 'customerName', headerName: t('Customer Name'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Customer Name') },
            (isLocalAdmin || isGlobalAdmin) && { field: 'country', headerName: t('Country'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Country') },
            { field: 'model', headerName: t('Model'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('Model') },
            { field: 'equipmentCategory', headerName: t('Equipment Category'), sortable: true, filterable: true, pinnable: false, flex: 3, description: t('Equipment Category') },
            { field: 'brand', headerName: t('Brand'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('Brand') },
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                flex: 1,
                description: t('Actions'),
                getActions: (params: GridRowParams<EquipmentViewModel>) => {
                    let actionsArray = [];

                    actionsArray.push(
                        <DataGridActionOverview
                            key={'view'}
                            icon={<Icon type='visibility' />}
                            label={t('View')}
                            onClick={() => handleEdit(params.row.id)}
                            showInMenu
                        />
                    );

                    return actionsArray;
                },
            },
        ].filter(Boolean) as GridEnrichedColDef[];

        return columns;
    };
    
    const memoizedColumns = useMemo(() => getColumns(), [i18n.language]);

    return (
        <>
            <Box>
                {renderHeader()}
            </Box>
            <DataGridOverview
                rows={translatedEquipments}
                columns={memoizedColumns}
                pagination
                clearFilters={clearGridFilters}
            />
            {formIsOpen &&
                <EquipmentFormContainer
                    formIsOpen={formIsOpen}
                    handleClose={handleCloseFormDialog}
                    equipmentId={selectedId}
                    getEquipments={getEquipments}
                />
            }
        </>
    );
};
