import React from 'react';
import styles from './CookiesBanner.module.scss';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Button } from '@ui-components/ui-library';
import { COOKIES_CONSENT_NAME } from '../../shared/utils/constants';
import { useCookie } from '../../hooks/useCookie';

export interface CookiesBannerProps {
    handleClose: () => void;
}

export const CookiesBanner = (props: CookiesBannerProps): JSX.Element => {
    const { t } = useTranslation();

    const { handleClose } = props;

    const [setCookieValue, getCookieValue, removeCookieValue] = useCookie([COOKIES_CONSENT_NAME]);

    const cookiesConsentVersion = process.env.REACT_APP_COOKIE_VERSION;

    const onAccept = () => {
        const cookieConsent = getCookieValue(COOKIES_CONSENT_NAME);
        if (!cookieConsent || cookieConsent !== cookiesConsentVersion) {
            setCookieValue(COOKIES_CONSENT_NAME, cookiesConsentVersion);
        }

        handleClose();
    };

    const onDecline = () => {
        if (getCookieValue(COOKIES_CONSENT_NAME)) {
            removeCookieValue(COOKIES_CONSENT_NAME);
        }
        
        handleClose();
    };

    return (
        <Box className={styles.cookies_container}>
            <Box className={styles.cookies_inner}>
                <Box className={styles.cookies_description}>
                    {/* I don't know if this text will remain like this so I haven't added it to the translation files yes */}
                    <Typography variant={'h3'}>{t('Our website uses cookies to provide your browsing experience and relevant information.')}</Typography>
                </Box>
                <Box className={styles.cookies_actions}>
                    <Button
                        id={'accept'}
                        key={'accept'}
                        variant={'primary'}
                        label={t('Accept')}
                        onClick={onAccept}
                        sx={{ marginRight: '1rem' }}
                    />

                    <Button
                        id={'decline'}
                        key={'decline'}
                        variant={'secondary'}
                        label={t('Decline')}
                        onClick={onDecline}
                    />
                </Box>
            </Box>
        </Box>
    );
};