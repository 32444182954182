export enum EquipmentCategories {
    Compressor = 0,
    AirTreatment = 1,
    Other = 2,
}

export const EquipmentCategoryLabels: Record<EquipmentCategories, string> = {
    [EquipmentCategories.Compressor]: 'Compressor',
    [EquipmentCategories.AirTreatment]: 'Air Treatment',
    [EquipmentCategories.Other]: 'Other',
};

export const getEquipmentCategoryLabel = (category: EquipmentCategories): string => {
    return EquipmentCategoryLabels[category];
};