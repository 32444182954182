import { BrandViewModel } from '../models/brand/brandModel';
import { BrandTypeViewModel } from '../models/brand/brandTypeModel';
import { ActionResult, api } from '../shared/utils/api';

class BrandService {
    async GetBrandOptions(): Promise<ActionResult<BrandViewModel[]>> {
        const url = '/brands';
        return await api.get<BrandViewModel[]>(url);
    }

    async GetBrandsForOrganization(organizationId: number): Promise<ActionResult<BrandViewModel[]>> {
        const url = `/brands/GetBrandsForOrganization/${organizationId}`;
        return await api.get<BrandViewModel[]>(url);
    }

    async GetBrandsByEquipmentCategory(categoryId: number): Promise<ActionResult<BrandViewModel[]>> {
        const url = `/brands/GetBrandsByEquipmentCategory/${categoryId}`;
        return await api.get<BrandViewModel[]>(url);
    }

    async GetBrandTypes(): Promise<ActionResult<BrandTypeViewModel[]>> {
        const url = '/brands/BrandTypes';
        return await api.get<BrandTypeViewModel[]>(url);
    }
}

export const brandService = new BrandService();