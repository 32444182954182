import React from 'react';
import { ICustomerDetailsSectionProps } from './ICustomerDetailsSectionProps';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Title } from '../../../components/form/title/Title';
import { TextField } from '@ui-components/ui-library';

export const CustomerDetailsSection = (props: ICustomerDetailsSectionProps): JSX.Element => {
    const { t } = useTranslation();
    const { showHeader, serviceExecution } = props;

    return (
        <>
            <Box marginTop={'1rem'}>
                {
                    showHeader && <Title variant={'h3'} text={t('Customer details')} align='left' />
                }

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('Customer name')}
                            value={serviceExecution.customerDetail.name}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                </Grid>

                <Box marginTop='2rem'>
                    <Title variant='h4' align='left' text={t('Person of Interest')}></Title>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t('First Name')}
                            value={serviceExecution.customerDetail.personOfInterestFirstName}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t('Last Name')}
                            value={serviceExecution.customerDetail.personOfInterestLastName}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label={t('Phone Number')}
                            value={`(${serviceExecution.customerDetail.personOfInterestPhoneNumberPrefix})${serviceExecution.customerDetail.personOfInterestPhoneNumber}`}
                            disabled
                        />
                    </Grid>
                </Grid>

                <Box marginTop='2rem'>
                    <Title variant='h4' align='left' text={t('Customer Address')}></Title>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('Country')}
                            value={serviceExecution.customerDetail.country}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('City')}
                            value={serviceExecution.customerDetail.city}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('Street')}
                            value={serviceExecution.customerDetail.street}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('Postal Code')}
                            value={serviceExecution.customerDetail.postalCode}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
