import React from 'react';
import { DataGridAction } from '@ui-components/ui-library';

export const DataGridActionOverview = (props: any): JSX.Element => {
	return (
		<DataGridAction
			{...props}
			nonce={undefined}
			sx={{ fontFamily: 'system-ui', fontSize: '15px' }}
		/>
	);
};