import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import styles from './Home.module.scss';
import { DashboardContainer } from '../dashboard/components/dashboardContainer/DashboardContainer';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import { Restricted } from './elements/restricted/Restricted';

// Should I rename the component to Dashboard or we will leave it for the sprint when we are going to separete the client app?
export const Home = () => {
    const { t } = useTranslation();

    return (
        <AuthenticatedTemplate>
            <div className={styles.home_wrapper}>
                <Restricted to={'canViewKpis'}>
                    <DashboardContainer />
                </Restricted>
            </div>
        </AuthenticatedTemplate>
    );
};
