import { ActionResult, api } from '../../shared/utils/api';
import { EndCustomerOptionModel } from '../models/EndCustomerOptionModel';

class EquipmentCustomerService {
    async GetCustomersForEquipment(distributorOrganizationId: number): Promise<ActionResult<EndCustomerOptionModel[]>> {
        const url = `/equipmentCustomer/GetEndCustomersForEquipment/${distributorOrganizationId}`;

        return await api.get<EndCustomerOptionModel[]>(url);
    }
}

export const equipmentCustomerService = new EquipmentCustomerService();