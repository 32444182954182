import moment from 'moment-timezone';

export type PlannedPartModel = {
    id: number;
    serviceOrderId?: number;
    name: string;
    number: string;
    quantity: number;
    description: string;
    toCreate?: boolean;
    toUpdate?: boolean;
    toDelete?: boolean;
}

export const getDefaultPlannedPart = (): PlannedPartModel => {
    return {
        id: moment().valueOf(),
        serviceOrderId: 0,
        name: '',
        number: '',
        quantity: 0,
        description: '',
    };
};