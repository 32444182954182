import moment from 'moment-timezone';

export type InstalledBaseFilterModel = {
    from: Date | undefined;
    to: Date | undefined;
    customerCenterId: number | undefined;
    distributorOrganizationId: number | undefined;
}

export const getDefaultInstalledBaseFilterModel = (): InstalledBaseFilterModel => {
    return {
        from: moment().add(-1, 'y').startOf('d').toDate(),
        to: moment().endOf('d').toDate(),
        customerCenterId: undefined,
        distributorOrganizationId: undefined,
    };
};

export const getOnloadInstallBaseFilterModel = (): InstalledBaseFilterModel => {
    return {
        from: undefined,
        to: undefined,
        customerCenterId: undefined,
        distributorOrganizationId: undefined,
    };
};
