import React, { useEffect, useState } from 'react';
import { range } from 'lodash';
import { BarChart } from '@mui/x-charts/BarChart';
import { ServiceOrdersFilterModel } from '../../models/ServiceOrdersFilterModel';
import { dashboardService } from '../../../services/DashboardService';
import { ChartSxObject } from '../../../shared/utils/sxStyleObjects';
import moment from 'moment-timezone';
import { showErrors } from '../../../stores/alert/alertSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';

interface IServiceOrderCreationYearViewProps {
    appliedFilters: ServiceOrdersFilterModel;
}

export const ServiceOrderCreationYearView = (props: IServiceOrderCreationYearViewProps): JSX.Element => {
    const dispatch = useAppDispatch();
    
    const { appliedFilters } = props;
    const yearsRange = range(moment().year() - 9, moment().year());

    const [serviceOrdersByYear, setServiceOrdersByYear] = useState<any>(Object.assign({}, ...yearsRange.map(key => ({ [key]: 0 }))));

    const getServiceOrdersCountForYearRange = async (filters: any) => {
        // Currently we are always getting current year as an End year
        const serviceOrdersForYearRangeResponse = await dashboardService.ServiceOrdersCountForYearRange({ ...filters, year: moment().year() });
        if (serviceOrdersForYearRangeResponse.isSuccess) {
            setServiceOrdersByYear(serviceOrdersForYearRangeResponse.result!);
        } else {
            dispatch(showErrors(serviceOrdersForYearRangeResponse?.errorModel?.errors));
        }
    };

    useEffect(() => {
        getServiceOrdersCountForYearRange(appliedFilters);
    }, [appliedFilters]);

    const labels = Object.keys(serviceOrdersByYear);

    return (
        <>
            <BarChart
                xAxis={[
                    {
                        id: 'serviceOrdersByYear',
                        data: labels,
                        scaleType: 'band',
                        tickLabelInterval: () => true,
                        max: moment().year(),
                        min: moment().year() - 9
                    },
                ]}
                series={[
                    {
                        data: Object.keys(serviceOrdersByYear).map(k => serviceOrdersByYear[k]),
                    },
                ]}
                sx={ChartSxObject}
            />
        </>
    );
};
