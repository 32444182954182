import * as Yup from 'yup';
import { MaximumCharacterMessage, RequiredMessage } from '../../shared/utils/validationMessages';
import { ServiceExecutionFormModel } from '../models/serviceExecutionModel';
import { ServiceExecutionEquipmentDetailModel } from '../models/serviceExecutionEquipmentDetailModel';
import { ServiceExecutionWorkCommentModel } from '../models/serviceExecutionWorkCommentModel';
import { ServiceExecutionFutureRecommendationModel } from '../models/serviceExecutionFutureRecommendationModel';

export const workCommentMaximumLength = 4000;
export const futureRecommendationMaximumLength = 4000;

export const serviceExecutionValidationSchema = Yup.object().shape({
    equipmentDetail: Yup.object().shape({
        runningHours: Yup.number().required(RequiredMessage).min(0),
        loadedHours: Yup.number().nullable().moreThan(0).transform((_, val) => (val !== '' ? Number(val) : undefined)),
    }),
    workComment: Yup.object().shape({
        description: Yup.string().trim().max(workCommentMaximumLength, MaximumCharacterMessage(workCommentMaximumLength)).required(RequiredMessage),
    }),
    futureRecommendation: Yup.object().shape({
        description: Yup.string().max(futureRecommendationMaximumLength, MaximumCharacterMessage(futureRecommendationMaximumLength)).notRequired(),
    }),
    serviceExecutionSigner: Yup.object().shape({
        firstName: Yup.string().trim().required(RequiredMessage),
        lastName: Yup.string().trim().required(RequiredMessage),
        signature: Yup.string().trim().required(RequiredMessage),
    }),
});

export const serviceExecutionEquipmentDetailValidation = Yup.object().shape({
    runningHours: Yup.number().required(RequiredMessage).min(0),
    loadedHours: Yup.number().nullable().moreThan(0).transform((_, val) => (val !== '' ? Number(val) : undefined)),
});

export const serviceExecutionWorkCommentValidation = Yup.object().shape({
    description: Yup.string().trim().max(workCommentMaximumLength, MaximumCharacterMessage(workCommentMaximumLength)).required(RequiredMessage),
});

export const serviceExecutionFutureRecommendationValidation = Yup.object().shape({
    description: Yup.string().max(futureRecommendationMaximumLength, MaximumCharacterMessage(futureRecommendationMaximumLength)).notRequired(),
});

export const serviceExecutionEquipmentDetailsIsValid = (equipmentDetail: ServiceExecutionEquipmentDetailModel): boolean => {
    return serviceExecutionEquipmentDetailValidation.isValidSync(equipmentDetail);
};

export const serviceExecutionWorkCommentIsValid = (workComment: ServiceExecutionWorkCommentModel): boolean => {
    return serviceExecutionWorkCommentValidation.isValidSync(workComment);
};

export const serviceExecutionRecommendationIsValid = (recommendation: ServiceExecutionFutureRecommendationModel): boolean => {
    return serviceExecutionFutureRecommendationValidation.isValidSync(recommendation);
};

export const serviceExecutionIsValid = (serviceExecution: ServiceExecutionFormModel): boolean => {
    return serviceExecutionValidationSchema.isValidSync(serviceExecution);
};