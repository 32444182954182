import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { ServicePlanningCreateModel, ServicePlanningFormModel, ServicePlanningUpdateModel } from '../models/servicePlanningModel';

class ServicePlanningService {
    async GetAllServicePlannings(fromDateString: string, tillDateString: string): Promise<ActionResult<ServicePlanningFormModel[]>> {
        const url = `/servicePlannings/GetAllServicePlannings?fromDateString=${fromDateString}&tillDateString=${tillDateString}`;
        const response = await api.get<ServicePlanningFormModel[]>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async CreateServicePlanning(createModel: ServicePlanningCreateModel): Promise<ActionResult<number>> {
        const url = '/servicePlannings/CreateServicePlanning';

        const response = await api.post<number>(url, JSON.stringify(createModel));
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async UpdateServicePlanning(updateModel: ServicePlanningUpdateModel): Promise<ActionResult<void>> {
        const url = '/servicePlannings/UpdateServicePlanning';

        const response = await api.put<void>(url, JSON.stringify(updateModel));

        return response;
    }

    async CancelServicePlanning(servicePlanningId: number): Promise<ActionResult<void>> {
        const url = `/servicePlannings/CancelServicePlanning/${servicePlanningId}`;

        const response = await api.put<void>(url, servicePlanningId);

        return response;
    }
}

export const servicePlanningService = new ServicePlanningService();