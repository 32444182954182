import { UserGridModel } from './user';

export type UserOverviewPagedList = {
    items: UserGridModel[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}

export const getDefaultUserOverviewPagedList = (): UserOverviewPagedList => {
    return {
        items: [],
        totalCount: 0,
        pageNumber: 1,
        pageSize: 15,
    };
};
