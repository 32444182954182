import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { IOrganizationGeneralInfoProps } from './IOrganizationGeneralInfoProps';
import { Grid } from '@mui/material';
import { StyledSearchableDropdownProps, TextField, Dropdown, DropdownOption } from '@ui-components/ui-library';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { validateForm as validateInitialValues } from '../../../shared/utils/Utils';

export const OrganizationGeneralInfo = (props: IOrganizationGeneralInfoProps): JSX.Element => {
    const { isNewForm, type, brands, parentOrganizations } = props;
    const isDistributorType: boolean = type === OrganizationType.Distributor;
    const { values: organization, handleChange, handleBlur, setFieldValue, validateForm, setFieldTouched } = useFormikContext() as any;
    const { t } = useTranslation();
    
    const handleMultiSelectChange = (items: DropdownOption[] | null) => {
        let values = [];
        if ((items as any).length > 0) {
            values = (items as any).map((i: any) => i.value);
        }

        setFieldValue('organizationBrandIds', values);
    };

    const brandOptions = brands.map(b => {
        return { id: b.id, label: b.name, value: b.id };
    });

    const brandValues = organization.organizationBrandIds;

    const selectedBrands = brandOptions.filter(obj => brandValues.includes(obj.id));

    const parentOptions = parentOrganizations.map(b => {
        return { id: b.id, label: b.name, value: b.id };
    });

    const selectedParent = parentOptions?.find(obj => obj.id === organization.parentOrganizationId);

    useEffect(() => {
        if (!isNewForm) {
            validateInitialValues(validateForm, setFieldTouched);
        }
    }, [isNewForm]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Name')}
                        id={'name'}
                        name={'name'}
                        value={organization.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                    />
                    <ErrorMessage name={'name'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        label={t('Brands')}
                        id={'organizationBrandIds'}
                        name={'organizationBrandIds'}
                        options={brandOptions}
                        value={selectedBrands}
                        onChange={(e, items) => handleMultiSelectChange(items ? items as DropdownOption[] : null)}
                        onBlur={handleBlur}
                        required={true}
                        variant='searchable'
                        greyedOutLabelOnDisabled
                        multiple
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'organizationBrandIds'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                {
                    isDistributorType &&
                    <Grid item xs={12} md={6}>
                        <Dropdown<StyledSearchableDropdownProps>
                            label={
                                isDistributorType ?
                                    t('Customer Center Organization') :
                                    t('Parent Organization')
                            }
                            name={'parentOrganizationId'}
                            id={'parentOrganizationId'}
                            options={parentOptions ?? []}
                            value={selectedParent ?? { id: '', label: '', value: '' }}
                            onChange={(e, item) => setFieldValue('parentOrganizationId', item ? (item as DropdownOption).value : 0)}
                            onBlur={handleBlur}
                            disabled={!isNewForm}
                            required={true}
                            greyedOutLabelOnDisabled
                            variant='searchable'
                            usePopper
                            noOptionsText={t(NO_OPTIONS_TEXT)}
                        />
                        <ErrorMessage name={'parentOrganizationId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                    </Grid>
                }
            </Grid>
        </>
    );
};
