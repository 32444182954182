import React, { useState, useEffect, useMemo } from 'react';
import styles from './LeadsDashboard.module.scss';
import { isEqual, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LeadsFilterModel, getDefaultLeadsFilterModel } from '../../models/LeadsFilterModel';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TuneIcon from '@mui/icons-material/Tune';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import IconButton from '@mui/material/IconButton';
import { dashboardService } from '../../../services/DashboardService';
import { LeadsKpiViewModel, getDefaultLeadsKpiViewModel } from '../../../models/dashboard/LeadsKpiViewModel';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { showErrors } from '../../../stores/alert/alertSlice';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { Grid } from '@mui/material';
import { Restricted } from '../../../components/elements/restricted/Restricted';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { ALL_TEXT, NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { EquipmentType } from '../../../equipments/models/equipmentType';
import { BrandViewModel } from '../../../models/brand/brandModel';
import { BrandTypeViewModel } from '../../../models/brand/brandTypeModel';
import { InstalledBaseViewModel } from '../../../models/installedBase/installedBaseViewModel';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { EquipmentCategories } from '../../../shared/enums/EquipmentCategories';
import { DropdownListItemLongContentSxObject } from '../../../shared/utils/sxStyleObjects';
import { CountryFormModel } from '../../../organizations/models/countryModel';

export const LeadsDashboard = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const allEquipmentTypeOptions: EquipmentType[] = useAppSelector(state => state.Dashboard.equipmentTypeOptions);
    const brandOptions: BrandViewModel[] = useAppSelector(state => state.Dashboard.brandOptions);
    const brandTypeOptions: BrandTypeViewModel[] = useAppSelector(state => state.Dashboard.brandTypeOptions);
    const countryOptions: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);
    const installedBaseOptions: InstalledBaseViewModel[] = useAppSelector(state => state.Dashboard.installedBaseOptions);
    const organizationOptions: OrganizationOptionModel[] = useAppSelector(state => state.Dashboard.organizationOptions);
    const customerCenterOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.CustomerCenter), [organizationOptions]);
    const distributorOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.Distributor), [organizationOptions]);
    const equipmentTypeOptions = useMemo(() => allEquipmentTypeOptions.filter(et => et.equipmentCategory !== EquipmentCategories.Other), [allEquipmentTypeOptions]);

    const [leads, setLeads] = useState<LeadsKpiViewModel>(getDefaultLeadsKpiViewModel());
    const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useState<LeadsFilterModel>(getDefaultLeadsFilterModel());
    const [notAppliedFilters, setNotAppliedFilters] = useState<Partial<LeadsFilterModel>>({});

    const getLeads = async () => {
        const leadsResponse = await dashboardService.LeadsKpi(appliedFilters);

        if (leadsResponse.isSuccess) {
            setLeads(leadsResponse.result!);
        } else {
            dispatch(showErrors(leadsResponse.errorModel?.errors));
        }
    };

    const applyButtonIsDisabled = (): boolean => {
        return Object.keys(notAppliedFilters).length === 0 || Object.keys(notAppliedFilters).every(naf =>
            isEqual(appliedFilters[naf as keyof typeof appliedFilters], notAppliedFilters[naf as keyof typeof notAppliedFilters]));
    };

    const hasAppliedFilters: boolean = !isEqual(appliedFilters, getDefaultLeadsFilterModel());

    const handleFilterChange = (fieldName: keyof LeadsFilterModel, value: any) => {
        setNotAppliedFilters({ ...notAppliedFilters, [fieldName]: value });
    };

    const handleEquipmentTypeChange = (value: number[] | undefined) => {
        if (value && value.length > 0) {
            let selectedInstalledBaseCode: InstalledBaseViewModel | undefined = undefined;
            if ('installedBaseId' in notAppliedFilters && notAppliedFilters.installedBaseId) {
                selectedInstalledBaseCode = installedBaseOptions.find(ib => ib.id === notAppliedFilters.installedBaseId);
            } else if (appliedFilters.installedBaseId) {
                selectedInstalledBaseCode = installedBaseOptions.find(ib => ib.id === appliedFilters.installedBaseId);
            }

            const shouldClearInstalledBaseCode: boolean = selectedInstalledBaseCode !== undefined &&
                !value.some(etid => etid === selectedInstalledBaseCode?.equipmentTypeId);
            
            if (shouldClearInstalledBaseCode) {
                setNotAppliedFilters({...notAppliedFilters, equipmentTypeIds: sortBy(value), installedBaseId: undefined});
                return;
            }
        }

        setNotAppliedFilters({...notAppliedFilters, equipmentTypeIds: sortBy(value)});
    };

    const applyFilters = async (): Promise<void> => {
        setAppliedFilters({ ...appliedFilters, ...notAppliedFilters });
        closeFilterModal();
    };

    const clearFilters = () => {
        setAppliedFilters(getDefaultLeadsFilterModel());
        setNotAppliedFilters({});
    };

    const closeFilterModal = () => {
        setFilterModalIsOpen(false);
        setNotAppliedFilters({});
    };

    useEffect(() => {
        getLeads();
    }, [appliedFilters]);

    const brandTypeDropdownOptions = useMemo(() => {
        if (notAppliedFilters.brandId) {
            return brandTypeOptions
                .filter(bt => bt.id === brandOptions.find(b => b.id === notAppliedFilters.brandId)?.brandTypeId)
                .map(b => ({ id: b.id, label: b.description, value: b.id }));
        } else if (appliedFilters.brandId) {
            return brandTypeOptions
                .filter(bt => bt.id === brandOptions.find(b => b.id === appliedFilters.brandId)?.brandTypeId)
                .map(b => ({ id: b.id, label: b.description, value: b.id }));
        }

        return brandTypeOptions.map(b => ({ id: b.id, label: b.description, value: b.id }));
    }, [brandOptions, brandTypeOptions, notAppliedFilters.brandId, appliedFilters.brandId]);

    const brandDropdownOptions = useMemo(() => {
        if (notAppliedFilters.brandTypeId) {
            return brandOptions
                .filter(b => b.brandTypeId === notAppliedFilters.brandTypeId)
                .map(b => ({ id: b.id, label: b.name, value: b.id }));
        } else if (appliedFilters.brandTypeId) {
            return brandOptions
                .filter(b => b.brandTypeId === appliedFilters.brandTypeId)
                .map(b => ({ id: b.id, label: b.name, value: b.id }));
        }

        return brandOptions.map(b => ({ id: b.id, label: b.name, value: b.id }));
    }, [brandOptions, notAppliedFilters.brandTypeId, appliedFilters.brandTypeId]);

    const equipmentTypeDropdownOptions = useMemo(() => {
        return equipmentTypeOptions.map(et => ({ id: et.id, label: t(et.description), value: et.id }));
    }, [equipmentTypeOptions]);

    const customerCenterDropdownOptions = useMemo(() => {
        if (notAppliedFilters.distributorOrganizationId) {
            return customerCenterOptions
                .filter(cc => cc.id === distributorOptions.find(d => d.id === notAppliedFilters.distributorOrganizationId)?.parentOrganizationId)
                .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        } else if (appliedFilters.distributorOrganizationId) {
            return customerCenterOptions
                .filter(cc => cc.id === distributorOptions.find(d => d.id === appliedFilters.distributorOrganizationId)?.parentOrganizationId)
                .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        }

        return customerCenterOptions.map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
    }, [distributorOptions, customerCenterOptions, notAppliedFilters.distributorOrganizationId, appliedFilters.distributorOrganizationId]);


    const distributorDropdownOptions = useMemo(() => {
        if (notAppliedFilters.customerCenterId) {
            return distributorOptions
                .filter(d => d.parentOrganizationId === notAppliedFilters.customerCenterId)
                .map(d => ({ id: d.id, label: d.name, value: d.id }));
        } else if (appliedFilters.customerCenterId) {
            return distributorOptions
                .filter(d => d.parentOrganizationId === appliedFilters.customerCenterId)
                .map(d => ({ id: d.id, label: d.name, value: d.id }));
        }

        return distributorOptions.map(d => ({ id: d.id, label: d.name, value: d.id }));
    }, [distributorOptions, notAppliedFilters.customerCenterId, appliedFilters.customerCenterId]);

    const installedBaseDropdownOptions = useMemo(() => {
        if (notAppliedFilters.equipmentTypeIds && notAppliedFilters.equipmentTypeIds.length > 0) {
            return installedBaseOptions
                .filter(ib => notAppliedFilters.equipmentTypeIds?.some(et => et === ib.equipmentTypeId))
                .map(ib => ({ id: ib.id, label: ib.description, value: ib.id }));
        } else if (!('equipmentTypeIds' in notAppliedFilters) && appliedFilters.equipmentTypeIds && appliedFilters.equipmentTypeIds.length > 0) {
            return installedBaseOptions
                .filter(ib => appliedFilters.equipmentTypeIds.some(et => et === ib.equipmentTypeId))
                .map(ib => ({ id: ib.id, label: ib.description, value: ib.id }));
        }

        return installedBaseOptions.map(ib => ({ id: ib.id, label: ib.description, value: ib.id }));
    }, [installedBaseOptions, notAppliedFilters.equipmentTypeIds, appliedFilters.equipmentTypeIds]);

    const countryDropdownOptions = useMemo(() => countryOptions.map(c => ({ id: c.id, label: c.description, value: c.id })), [countryOptions]);

    return (
        <>
            <Box>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box fontSize={18} flex={2} textAlign={'left'}>{t('Leads')}</Box>

                    <Tooltip
                        title={t('Filter')}
                    >
                        <IconButton id={'filter'} onClick={() => setFilterModalIsOpen(true)}>
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>

                    {
                        hasAppliedFilters &&
                        <Tooltip
                            title={t('Clear filters')}
                        >
                            <IconButton id={'clear_filters'} onClick={clearFilters}>
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                <div className={styles.leads_box}>
                    <div className={`${styles.leads_box_row} ${styles.leads_success}`}>
                        <div>{t('Contracts to Renew')}</div>
                        <div>{leads.contractToRenew}</div>
                    </div>

                    <div className={`${styles.leads_box_row} ${styles.leads_info}`}>
                        <div>{t('Priorities to call')}</div>
                        <div>{leads.prioritiesToCall}</div>
                    </div>

                    <div className={`${styles.leads_box_row} ${styles.leads_error}`}>
                        <div>{t('Potential New Business')}</div>
                        <div>{leads.potentialNewBusiness}</div>
                    </div>
                </div>

            </Box>

            <FilterModal
                isOpen={filterModalIsOpen}
                onClose={closeFilterModal}
                onApply={applyFilters}
                disableApply={applyButtonIsDisabled()}
            >
                <Grid container spacing={2}>
                    <Restricted to={'canFilterByEquipmentType'}>
                        <Grid item xs={12} md={12}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Equipment Type')}
                                options={equipmentTypeDropdownOptions}
                                value={
                                    'equipmentTypeIds' in notAppliedFilters ?
                                        equipmentTypeDropdownOptions.filter(et => notAppliedFilters.equipmentTypeIds?.some(etid => etid === et.id)) || null :
                                        equipmentTypeDropdownOptions.filter(et => appliedFilters.equipmentTypeIds.some(etid => etid === et.id)) || null
                                }
                                onChange={(_, items) => handleEquipmentTypeChange(items ? (items as any).map((i: any) => i.id) : undefined)}
                                variant='searchable'
                                multiple
                                usePopper
                                disabled={equipmentTypeDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByBrandType'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Brand Type')}
                                options={brandTypeDropdownOptions}
                                value={'brandTypeId' in notAppliedFilters ?
                                    brandTypeDropdownOptions.find(bt => bt.id === notAppliedFilters.brandTypeId) || null :
                                    brandTypeDropdownOptions.find(bt => bt.id === appliedFilters.brandTypeId) || null
                                }
                                onChange={(_, item) => handleFilterChange('brandTypeId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={brandTypeDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByBrand'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Brand')}
                                options={brandDropdownOptions}
                                value={'brandId' in notAppliedFilters ?
                                    brandDropdownOptions.find(b => b.id === notAppliedFilters.brandId) || null :
                                    brandDropdownOptions.find(b => b.id === appliedFilters.brandId) || null
                                }
                                onChange={(_, item) => handleFilterChange('brandId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={brandDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByCustomerCenter'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Customer Center')}
                                options={customerCenterDropdownOptions}
                                value={'customerCenterId' in notAppliedFilters ?
                                    customerCenterDropdownOptions.find(cc => cc.id === notAppliedFilters.customerCenterId) || null :
                                    customerCenterDropdownOptions.find(cc => cc.id === appliedFilters.customerCenterId) || null
                                }
                                onChange={(_, item) => handleFilterChange('customerCenterId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={customerCenterDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByDistributorOrganization'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Distributor Organization')}
                                options={distributorDropdownOptions}
                                value={'distributorOrganizationId' in notAppliedFilters ?
                                    distributorDropdownOptions.find(d => d.id === notAppliedFilters.distributorOrganizationId) || null :
                                    distributorDropdownOptions.find(d => d.id === appliedFilters.distributorOrganizationId) || null
                                }
                                onChange={(_, item) => handleFilterChange('distributorOrganizationId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={distributorDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                optionListStyles={DropdownListItemLongContentSxObject}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByCountry'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Country')}
                                options={countryDropdownOptions}
                                value={'countryId' in notAppliedFilters ?
                                    countryDropdownOptions.find(c => c.id === notAppliedFilters.countryId) || null :
                                    countryDropdownOptions.find(c => c.id === appliedFilters.countryId) || null
                                }
                                onChange={(_, item) => handleFilterChange('countryId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={countryDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByInstalledBaseCode'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Installed Base Code')}
                                options={installedBaseDropdownOptions}
                                value={'installedBaseId' in notAppliedFilters ?
                                    installedBaseDropdownOptions.find(ib => ib.id === notAppliedFilters.installedBaseId) || null :
                                    installedBaseDropdownOptions.find(ib => ib.id === appliedFilters.installedBaseId) || null
                                }
                                onChange={(_, item) => handleFilterChange('installedBaseId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={installedBaseDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                </Grid>
            </FilterModal>
        </>
    );
};
