import moment from 'moment-timezone';

export type ServiceOrdersFilterModel = {
    year: number;
    customerCenterId: number | undefined;
    distributorOrganizationId: number | undefined;
    userOffset: number;
}

export const getDefaultServiceOrdersFilterModel = (): ServiceOrdersFilterModel => {
    return {
        year: moment().year(),
        customerCenterId: undefined,
        distributorOrganizationId: undefined,
        userOffset: moment().utcOffset(),
    };
};