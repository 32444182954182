import React from 'react';
import moment from 'moment-timezone';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppSelector } from '../../../hooks/reduxHooks';

export const TimeCell = (props: any): JSX.Element => {
  const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
  const { text } = props.itemData;

  return (
    <div>
      {
        !text || moment(text, ['h:m a', 'H:m']).format(currentUser.preferences.hourFormat)
      }
    </div>
  );
};
