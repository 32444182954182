import moment from 'moment-timezone';

export type OneToOneFilterModel = {
    userCurrentDate: Date;
    userDateBeforeOneYear: Date;
    equipmentTypeId: number | undefined;
    brandId: number | undefined;
    brandTypeId: number | undefined;
    organizationId: number | undefined;
    countryId: number | undefined;
    installedBaseId: number | undefined;
    userOffset: number;
}

export const getDefaultOneToOneFilterModel = (): OneToOneFilterModel => {
    return {
        userCurrentDate: moment().endOf('d').toDate(),
        userDateBeforeOneYear: moment().add(-1, 'y').startOf('d').toDate(),
        equipmentTypeId: undefined,
        brandId: undefined,
        brandTypeId: undefined,
        organizationId: undefined,
        countryId: undefined,
        installedBaseId: undefined,
        userOffset: moment().utcOffset(),
    };
};
