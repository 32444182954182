import React, { useRef, useState } from 'react';
import { IMobileSchedulerOverviewProps } from './IMobileSchedulerOverviewProps';
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import styles from './MobileSchedulerOverview.module.scss';
import { styled } from '@mui/material/styles';
import { Box, Tooltip, IconButton, Dialog, DialogContent, Typography, DialogActions } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Icon } from '@ui-components/ui-library';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { TechnicianModel } from '../../../users/models/technicianModel';
import { TimeCell } from '../schedulerOverview/TimeCell';
import { ServicePlanningFormModel } from '../../models/servicePlanningModel';
import moment from 'moment-timezone';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { serviceExecutionService } from '../../../serviceExecutions/services/ServiceExecutionService';
import { userCanCancelServices, userCanCreateServicePlannings, userLanguageIsRtl } from '../../../stores/user/usersSlice';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { servicePlanningService } from '../../services/servicePlanningService';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { PlanningType, getPlanningTypeLabel } from '../../models/planningType';
import { AppointmentRenderedEvent } from 'devextreme/ui/scheduler';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';

const StyledFlexboxRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.colors.neutral[200]}`,
}));

const TooltipWrapper = styled(Box)(({ theme }) => ({
    color: theme.palette.colors.neutral[50],
    padding: '0 10px',
}));

export const MobileSchedulerOverview = (props: IMobileSchedulerOverviewProps): JSX.Element => {
    const { servicePlannings, technicians, currentMinDate, currentMaxDate,
        onDateRangeChange, openPlanningForm, refreshPlannings, openServiceOrderForm, onServiceCancellationHandler, openServiceExecutionForm, reloadPlannings } = props;

    const schedulerRef = useRef<Scheduler>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const userCanCreateServicePlanning: boolean = useAppSelector(state => userCanCreateServicePlannings(state));
    const userCanCancelService: boolean = useAppSelector(state => userCanCancelServices(state));
    const shouldUseRtlTheme: boolean = useAppSelector(state => userLanguageIsRtl(state));

    const [generationReportPromptOpen, setGenerationReportPromptOpen] = useState<boolean>(false);
    const [cancellationPromptOpen, setCancellationPromptOpen] = useState<boolean>(false);

    const views = {
        day: t('Day'),
        week: t('Week'),
        workWeek: t('Work week'),
        month: t('Month')
    };
    const [currentView, setCurrentView] = useState<string>(views.day);

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const appointments = servicePlannings.map(sp => (
        {
            id: sp.id,
            text: sp.customer,
            description: sp.serviceOrderNumber,
            startDate: sp.start,
            endDate: sp.end,
            userId: sp.assigneeIds,
        }
    ));

    const getUsersData = () => {
        const result: { text: string, id: number, color: string }[] = [];
        technicians.forEach((t: TechnicianModel) => {
            result.push({
                text: t.name,
                id: t.id,
                color: currentUser.preferences.schedulerPreferences.userColor,
            });
        });

        return result;
    };

    const groups: string[] = ['userId'];

    const handleRangeChange = (e: Date) => {
        setIsDisabled(true);
        if (e < currentMinDate || e > currentMaxDate) {
            onDateRangeChange(moment(e).toDate());
        }
        setIsDisabled(false);
    };

    const renderTimeCell = (itemData: any) => <TimeCell itemData={itemData} />;

    const openPlanning = (planningId: number) => {
        if (userCanCreateServicePlanning) {
            openPlanningForm(planningId);
        }

        schedulerRef.current?.instance.hideAppointmentTooltip();
    };

    const onReportConformationClick = async (servicePlanningId: number | undefined) => {
        dispatch(setIsLoading(true));
        if (servicePlanningId) {
            const serviceExecutionCreateResponse = await serviceExecutionService.CreateServiceExecution({ servicePlanningId: servicePlanningId });
            if (serviceExecutionCreateResponse.isSuccess) {
                openServiceExecutionForm(serviceExecutionCreateResponse.result!);
                schedulerRef.current?.instance.hideAppointmentTooltip();
                await reloadPlannings();
            } else {
                dispatch(showErrors(serviceExecutionCreateResponse?.errorModel?.errors));
            }
        }
        setGenerationReportPromptOpen(false);
        dispatch(setIsLoading(false));
    };

    const onReportButtonClick = async (visitReportId: number | undefined) => {
        if (visitReportId)
            openServiceExecutionForm(visitReportId);
        else
            setGenerationReportPromptOpen(true);

        schedulerRef.current?.instance.hideAppointmentTooltip();
    };

    const onCancelButtonClick = async (servicePlanningId: number | undefined) => {
        dispatch(setIsLoading(true));
        if (servicePlanningId) {
            const cancelResponse = await servicePlanningService.CancelServicePlanning(servicePlanningId);
            if (cancelResponse.isSuccess) {
                dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                onServiceCancellationHandler(servicePlanningId);
                schedulerRef.current?.instance.hideAppointmentTooltip();
            } else {
                dispatch(showErrors(cancelResponse?.errorModel?.errors));
            }
        }
        setCancellationPromptOpen(false);
        dispatch(setIsLoading(false));
    };

    const handleAppointmentDoubleClick = (event: any) => {
        event.cancel = true;
        const appointmentData = event.targetedAppointmentData;
        openPlanning(appointmentData.id);
    };

    const renderAppointment = (model: any) => {
        const { targetedAppointmentData } = model.data;
        const servicePlanningInfo = servicePlannings.find(sp => sp.id === targetedAppointmentData?.id);

        if (!servicePlanningInfo)
            return (<div></div>);

        const startDate = moment(targetedAppointmentData?.startDate);
        const endDate = moment(targetedAppointmentData?.endDate);

        const isMultiDay = !startDate.isSame(endDate, 'day');

        const timeFormat = isMultiDay ? currentUser.preferences.dateFormat : currentUser.preferences.timeFormat;

        switch (servicePlanningInfo.type) {
            case PlanningType.Service:
                return (
                    <div className={styles.service_appointment}>
                        <div><strong>{servicePlanningInfo.customer}</strong></div>
                        <div>
                            <span>{servicePlanningInfo.serviceOrderNumber}</span>
                        </div>
                        <div>
                            <span>{isMultiDay ? startDate.format(timeFormat) : startDate.format(timeFormat) + ' - ' + endDate.format(timeFormat)}</span>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className={styles.service_appointment}>
                        <div><strong>{t(getPlanningTypeLabel(servicePlanningInfo.type))}</strong></div>
                        <div>
                            <span>{startDate.format(timeFormat) + ' - ' + endDate.format(timeFormat)}</span>
                        </div>
                    </div>
                );
        }
    };


    const setAppointmentStyle = (event: AppointmentRenderedEvent) => {
        const { targetedAppointmentData, appointmentElement } = event;
        const servicePlanningInfo = servicePlannings.find(sp => sp.id === targetedAppointmentData?.id);

        if (!servicePlanningInfo) {
            return null;
        }

        const appointmentColor = setAppointmentColor(servicePlanningInfo.type);

        appointmentElement.style.backgroundColor = appointmentColor;
    };

    const setAppointmentColor = (type: PlanningType) => {
        const colorMap = {
            [PlanningType.Service]: 'rgb(106, 149, 217)',
            [PlanningType.Vacation]: '#F68D76',
            [PlanningType.Holiday]: '#FFC000',
            [PlanningType.SickLeave]: '#F26522',
            [PlanningType.Training]: '#0066B3',
            [PlanningType.Other]: '#6D2077',
        };

        return colorMap[type] || '';
    };


    const renderTooltip = (e: any): JSX.Element => {
        const servicePlanning: ServicePlanningFormModel | undefined = servicePlannings.find(a => a.id === e.data.appointmentData.id);

        return (
            <>
                {servicePlanning && (
                    <TooltipWrapper onClick={(e) => e.stopPropagation()}>
                        <StyledFlexboxRow>
                            {
                                userCanCreateServicePlanning && servicePlanning && servicePlanning.visitReportIds.length === 0 &&
                                <Tooltip
                                    title={t('Edit')}
                                    componentsProps={{
                                        popper: {
                                            sx: { zIndex: '5000' },
                                        }
                                    }}
                                >
                                    <IconButton aria-label="edit"
                                        onClick={() => {
                                            if (servicePlanning.id)
                                                openPlanning(servicePlanning.id);
                                        }}>
                                        <Icon type='edit' />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                userCanCancelService && servicePlanning && servicePlanning.visitReportIds.length === 0 && (
                                    <Tooltip
                                        title={t('Cancel')}
                                        componentsProps={{
                                            popper: {
                                                sx: { zIndex: '5000' },
                                            }
                                        }}
                                    >
                                        <IconButton aria-label="undo"
                                            onClick={() => {
                                                schedulerRef.current?.instance.hideAppointmentTooltip();
                                                setCancellationPromptOpen(true);
                                            }}>
                                            <Icon type='undo' />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                            {
                                cancellationPromptOpen &&
                                <ConfirmationDialog
                                    open={cancellationPromptOpen}
                                    onClose={() => setCancellationPromptOpen(false)}
                                    onConfirm={async () => {
                                        setCancellationPromptOpen(false);
                                        onCancelButtonClick(servicePlanning.id);
                                    }}
                                    message={t('Are you sure you want to cancel {{serviceOrderNumber}}?', { serviceOrderNumber: servicePlanning.type === PlanningType.Service ? servicePlanning.serviceOrderNumber : t(getPlanningTypeLabel(servicePlanning.type)) })}
                                />
                            }
                            {
                                servicePlanning.type === PlanningType.Service &&
                                <Tooltip
                                    title={servicePlanning?.visitReportIds[0] ? t('Go to Report') : t('Generate Report')}
                                    componentsProps={{
                                        popper: {
                                            sx: { zIndex: '5000' },
                                        }
                                    }}
                                >
                                    <IconButton aria-label='visit'
                                        onClick={() => onReportButtonClick(servicePlanning?.visitReportIds[0])}>
                                        <InsertDriveFileOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            }


                            {
                                generationReportPromptOpen &&
                                <Dialog
                                    open={generationReportPromptOpen}
                                    onClose={() => setGenerationReportPromptOpen(false)}
                                >
                                    <DialogContent>
                                        <Typography variant='body1' sx={{ fontFamily: 'system-ui' }}>
                                            {t('Please confirm report generation for {{serviceOrderNumber}}?', { serviceOrderNumber: servicePlanning?.serviceOrderNumber })}
                                        </Typography>
                                        <Typography style={{ marginTop: '2%' }} variant='body2' sx={{ fontFamily: 'system-ui' }}>
                                            {t('Note that once generated, the service cannot be cancelled.')}
                                        </Typography>
                                    </DialogContent>
                                    <DialogActions sx={{ paddingBottom: '1rem' }}>
                                        <Button id='confirm-deactivate' label={t('Confirm')} startIcon='check' variant='primary' onClick={async () => onReportConformationClick(servicePlanning?.id)} />
                                        <Button id='cancel-deactivate' label={t('Cancel')} startIcon='cancel' variant='secondary' onClick={() => setGenerationReportPromptOpen(false)} />
                                    </DialogActions>
                                </Dialog>
                            }

                            {
                                servicePlanning.type === PlanningType.Service &&
                                <Tooltip
                                    title={t('Service Order Details')}
                                    componentsProps={{
                                        popper: {
                                            sx: { zIndex: '5000' },
                                        }
                                    }}
                                >
                                    <IconButton aria-label='details'
                                        onClick={() => {
                                            openServiceOrderForm(servicePlanning?.serviceOrderId);
                                            schedulerRef.current?.instance.hideAppointmentTooltip();
                                        }
                                        }>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            }

                        </StyledFlexboxRow>
                        <StyledFlexboxRow pb={'5%'} pt={'5%'}>{servicePlanning?.customer}</StyledFlexboxRow>

                        <StyledFlexboxRow mt={'.5rem'} mb={'.5rem'} borderBottom={'none !important'}>
                            <Box>{servicePlanning.type === PlanningType.Service ? servicePlanning.serviceOrderNumber : t(getPlanningTypeLabel(servicePlanning.type))}</Box>
                            <Box ml={'.6rem'} display={'flex'} alignItems={'center'}>
                                <Icon type={'access_time'} />
                                {' '}
                                <span>
                                    {moment(servicePlanning.start).format(currentUser.preferences.timeFormat)}
                                    {' - '}
                                </span>
                                <span>
                                    {moment(servicePlanning.end).format(currentUser.preferences.timeFormat)}
                                </span>
                            </Box>
                        </StyledFlexboxRow>
                    </TooltipWrapper >
                )}
            </ >
        );
    };

    const onOptionChanged = (e: any) => {
        if(e.name === 'currentView') {
            setCurrentView(e.value);
        }
    };

    const customizeDateNavigatorText = (e: any) => {
        if(currentView === views.day || currentView === views.month) {
            return e.text;
        }
         
        if (currentView === views.workWeek || currentView === views.week) {
            if (shouldUseRtlTheme) {
                const textParts = e.text.split(' ');
                textParts[0] = textParts[0].split('-').reverse().join('-');

                return textParts.join(' ');
            }
            
            return e.text;
        }
        
        return '';
    };

    return (
        <>
            <Box mt={1}>
                <Scheduler
                    rtlEnabled={shouldUseRtlTheme}
                    ref={schedulerRef}
                    timeZone={currentUser.preferences.timeZoneIana}
                    dataSource={appointments}
                    defaultCurrentView="day"
                    defaultCurrentDate={moment().toDate()}
                    groups={groups}
                    cellDuration={currentUser.preferences.schedulerPreferences.cellDuration}
                    firstDayOfWeek={currentUser.preferences.schedulerPreferences.firstDayOfWeek}
                    startDayHour={currentUser.preferences.schedulerPreferences.startLongDayHour}
                    endDayHour={currentUser.preferences.schedulerPreferences.endLongDayHour}
                    editing={false}
                    onCurrentDateChange={(e) => handleRangeChange(e)}
                    onAppointmentDblClick={handleAppointmentDoubleClick}
                    onAppointmentFormOpening={(e) => e.cancel = true}
                    appointmentTooltipComponent={renderTooltip}
                    appointmentComponent={renderAppointment}
                    onAppointmentRendered={setAppointmentStyle}
                    onCurrentViewChange={refreshPlannings}
                    useDropDownViewSwitcher={true}
                    adaptivityEnabled={true}
                    onOptionChanged={onOptionChanged}
                    customizeDateNavigatorText={customizeDateNavigatorText}
                    disabled={isDisabled}
                >

                    <View
                        name={views.day}
                        type="day"
                        cellDuration={60}
                        intervalCount={1}
                        timeCellRender={renderTimeCell}
                    />

                    <View
                        name={views.workWeek}
                        type="workWeek"
                        groupOrientation="horizontal"
                        cellDuration={60}
                        intervalCount={1}
                        startDayHour={currentUser.preferences.schedulerPreferences.startShortDayHour}
                        endDayHour={currentUser.preferences.schedulerPreferences.endShortDayHour}
                        timeCellRender={renderTimeCell}
                    />

                    <View
                        name={views.week}
                        type="week"
                        groupOrientation="horizontal"
                        cellDuration={60}
                        intervalCount={1}
                        timeCellRender={renderTimeCell}
                    />

                    <View
                        name={views.month}
                        type="month"
                        groupOrientation="horizontal"
                        cellDuration={60}
                        intervalCount={1}
                    />

                    <Resource
                        fieldExpr="userId"
                        allowMultiple={true}
                        dataSource={getUsersData()}
                        label={t('Technicians')}
                    />
                </Scheduler>
            </Box>
        </>
    );
};
