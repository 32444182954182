import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LinkText } from '../linkText/LinkText';
import { Routes } from '../../routes';
import { Button } from '@ui-components/ui-library';

export interface ISelectLanguageDialogProps {
    open: boolean;
    onClose: () => void;
    onSelectPreferenceLanguage: () => void;
    onSelectBrowserLanguage: () => void;
    browserLanguage: string | undefined;
    preferenceLanguage: string;
}

const StyledDialogText = styled(Typography)(({ theme }) => ({
	fontVariant: 'body1',
    fontSize: '16px',
    fontFamily: 'system-ui'
}));

export const SelectLanguageDialog = (props: ISelectLanguageDialogProps): JSX.Element => {
    const { open, onClose, onSelectPreferenceLanguage, onSelectBrowserLanguage, browserLanguage, preferenceLanguage } = props;
    const { t } = useTranslation();
    
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth='md'>
                <DialogTitle style={{fontSize: '18px'}}>
                {t('Your browser language ({{browserLanguage}}) is different from the language in your preferences ({{preferencesLanguage}})', 
                    { browserLanguage: browserLanguage?.toUpperCase(), preferencesLanguage: preferenceLanguage.toUpperCase() })}
                </DialogTitle>
                <DialogContent>
                    <StyledDialogText>
                        {t('In what language do you like to continue using the application?')}
                    </StyledDialogText>
                    <StyledDialogText>
                        <Trans 
                            i18nKey="ViewYourCurrentSettingsInMySettings" 
                            t={t}
                            components={{
                                lnk: <LinkText href={Routes.userPreferences.getPath()} onClick={() => onClose()} />
                            }}
                        />
                    </StyledDialogText>
                </DialogContent>
                <DialogActions sx={{ paddingBottom: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        id='use-browser-language'
                        label={t('Use {{language}}', { language: browserLanguage?.toUpperCase() })}
                        variant='primary'
                        type={'button'}
                        onClick={onSelectBrowserLanguage}
                    />
                    <Button
                        id='use-preferences-language'
                        label={t('Use {{language}}', { language: preferenceLanguage.toUpperCase() })}
                        variant='primary'
                        type={'button'}
                        onClick={onSelectPreferenceLanguage}
                    />
                </DialogActions>
            </Dialog>
        </>
    )
}