
export type CustomerAddressFormModel = {
    id: number;
    city: string;
    postalCode: string;
    addressLine1: string;
    countryId: number;
    hasStates: boolean;
    state: string | null;
};

export const getDefaultCustomerAddress = (): CustomerAddressFormModel => {
    return {
        id: 0,
        city: '',
        postalCode: '',
        addressLine1: '',
        countryId: 0,
        hasStates: false,
        state: null,
    };
};