
export type LeadsKpiViewModel = {
    contractToRenew: number;
    contractToRenewIds: number[];
    prioritiesToCall: number;
    prioritiesToCallIds: number[];
    potentialNewBusiness: number;
    potentialNewBusinessIds: number[];
};

export const getDefaultLeadsKpiViewModel = (): LeadsKpiViewModel => {
    return {
        contractToRenew: 0,
        contractToRenewIds: [],
        prioritiesToCall: 0,
        prioritiesToCallIds: [],
        potentialNewBusiness: 0,
        potentialNewBusinessIds: [],
    };
};
