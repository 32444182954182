import React from 'react';
import { Alert, Box } from '@mui/material';
import { PersonOfInterestForm } from '../../../customers/components/personOfInterestForm/PersonOfInterestForm';
import { CountryFormModel } from '../../../organizations/models/countryModel';
import { IServiceOrderPersonOfInterestProps } from './IServiceOrderPersonOfInterestProps';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';

export const ServiceOrderPersonOfInterest = (props: IServiceOrderPersonOfInterestProps): JSX.Element => {
	const { t } = useTranslation();
	const { handlePersonOfInterestChange, personOfInterest, validatePersonOfInterest } = props;

	const countries: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);
	
	return (
		<>
			{
				!validatePersonOfInterest() &&
				<Alert icon={false} severity="warning">
					{t('Section is missing required information.')}
				</Alert>
			}

			<Box mt={2} p={1} border={'1px solid'}>
				<PersonOfInterestForm
					personOfInterest={personOfInterest}
					countries={countries}
					onPersonOfInterestChange={handlePersonOfInterestChange}
				/>
			</Box>
		</>
	);
};