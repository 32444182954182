
export const RequiredMessage: string = 'Field is required!';

export const ShortValueMessage: string = 'Value is too short!';

export const InvalidNumberMessage: string = 'Value must be a valid number!';

export const NumberTooBigMessage: string = 'Value is too big!';

export const InvalidDateMessage: string = 'Value must be a valid date!';

export const InvalidEmailMessage: string = 'Field must be a valid email!';

export const RequiredBrandMessage: string = 'Brand is required for this role!';

export const NoResultMessage: string = 'Failed to load data!';

export const MaximumCharacterMessage = (count: number): string => `Maximum character limit reached. Please shorten your input to ${count} characters or less.`;

export const EquipmentValidationMessages = {
    noCustomer: 'You must select an Equipment that is connected to a Customer!',
    contractStartDateAfterExpirationDate: 'Contract start date must be at least one day before the expiration date.',
    maxCharacters: 'Maximum characters must be at most 200 characters.',
    startDateBeforeYearOfProduction: 'Cannot select startup date that is before the year of production.',
    productionYearAfterStartupDate: 'Cannot select production year that is after the startup date.',
    startDateBeforeMaintananceDate: 'Cannot select startup date that is after the last maintenance date.',
    productionYearAfterMaintananceDate: 'Cannot select production year that is after the maintenance date.',
    maintenanceDateBeforeProductionOrStartup: 'Cannot select maintenance date that is before the production year or before the startup date of the machine.',
};

export const StateRequiredForCountryGeneralInfoStep = 'State field is required for selected country (step General Info).';