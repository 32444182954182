export type ServiceExecutionFutureRecommendationModel = {
    description: string;
};

export type ServiceExecutionFutureRecommendationCreateModel = {
    visitReportId: number;
    description: string,
};

export const getDefaultServiceExecutionFutureRecommendation = (): ServiceExecutionFutureRecommendationModel => {
    return {
        description: '',
    };
};