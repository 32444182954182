import React from 'react';
import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IEquipmentTypeOtherSectionProps } from './IEquipmentTypeOtherSectionProps';
import { Grid } from '@mui/material';
import { TextField } from '@ui-components/ui-library';
import { EquipmentFormModel } from '../../models/equipment';
import './EquipmentTypeOtherSection.scss';

export const EquipmentTypeOtherSection = (props: IEquipmentTypeOtherSectionProps): JSX.Element => {
    const { t } = useTranslation();
    
    const { readonly } = props;

    const { values: equipment, handleChange, handleBlur } = useFormikContext() as FormikProps<EquipmentFormModel>;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Brand')}
                        id={'brand'}
                        name={'textBrand'}
                        value={equipment.textBrand}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 50 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        required
                        disabled={readonly}
                    />
                    <ErrorMessage name={'textBrand'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Description')}
                        id={'description'}
                        name={'description'}
                        value={equipment.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 200 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%', height: 'auto' }}
                        multiline
                        minRows={5}
                        disabled={readonly}
                    />
                    <ErrorMessage name={'description'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};
