import React from 'react';
import { Form, Formik } from 'formik';
import { Card } from '../../../shared/utils/sxStyleObjects';
import { EquipmentGeneralInfo } from '../equipmentGeneralInfo/EquipmentGeneralInfo';
import { EquipmentCustomerInfo } from '../equipmentCustomerInfo/EquipmentCustomerInfo';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { airTreatmentEquipmentFormValidationSchema, equipmentGeneralInfoIsValid } from '../../services/EquipmentUtility';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { userCanEditEquipmentContractInfo, userCanEditEquipmentCustomerInfo } from '../../../stores/user/usersSlice';
import { EquipmentTypeAirTreatmentFormProps } from './EquipmentTypeAirTreatmentFormProps';
import { EquipmentTypeAirTreatmentSection } from '../equipmentTypeAirTreatmentSection/EquipmentTypeAirTreatmentSection';
import { EquipmentCategories } from '../../../shared/enums/EquipmentCategories';
import { EquipmentFormContainerStep } from '../equipmentFormContainer/IEquipmentFormContainerProps';

export const EquipmentTypeAirTreatmentForm = (props: EquipmentTypeAirTreatmentFormProps): JSX.Element => {
    const { equipment, readonly,
        isEquipmentLinked, isNewForm, equipmentTypeOptions, brandOptions,
        equipmentStatusOptions, distributorOrganizationOptions, handleFormSubmit,
        activeStep, handleBack, handleClose, handleNext } = props;

    const canEditEquipmentCustomerInfo: boolean = useAppSelector(state => userCanEditEquipmentCustomerInfo(state));
    const canEditContractInfo: boolean = useAppSelector(state => userCanEditEquipmentContractInfo(state));

    return (
        <>
            <Formik
                initialValues={equipment}
                validationSchema={airTreatmentEquipmentFormValidationSchema}
                enableReinitialize
                onSubmit={handleFormSubmit}
            >
                {({ values, dirty, isSubmitting, isValid, handleSubmit }) => (

                    <Form>
                        {
                            activeStep === EquipmentFormContainerStep.EQUIPMENTGENERALINFO &&
                            <>
                                <Card>
                                    <EquipmentGeneralInfo
                                        equipmentCategory={EquipmentCategories.AirTreatment}
                                        equipmentTypeOptions={equipmentTypeOptions}
                                        distributorOrganizationOptions={distributorOrganizationOptions}
                                        contractSectionEditable={!readonly && canEditContractInfo}
                                        readonly={readonly}
                                        isNewForm={isNewForm}
                                        isEquipmentLinked={isEquipmentLinked}
                                    />
                                </Card>

                                <SaveSection
                                    validateNext={() => equipmentGeneralInfoIsValid(values)}
                                    handleCancel={handleClose}
                                    handleNext={handleNext}
                                />
                            </>
                        }

                        {
                            activeStep === EquipmentFormContainerStep.EQUIPMENTDETAILS &&
                            <Card>
                                <EquipmentTypeAirTreatmentSection
                                    brandOptions={brandOptions}
                                    readonly={readonly}
                                    isNewForm={isNewForm}
                                    equipmentStatusOptions={equipmentStatusOptions}
                                />
                            </Card>
                        }

                        {
                            activeStep === EquipmentFormContainerStep.EQUIPMENTDETAILS && canEditEquipmentCustomerInfo &&
                            <Card>
                                <EquipmentCustomerInfo readonly={readonly} />
                            </Card>
                        }

                        {
                            activeStep === EquipmentFormContainerStep.EQUIPMENTDETAILS &&
                            <SaveSection
                                handleBack={handleBack}
                                handleSubmit={handleSubmit}
                                validateSubmit={() => dirty && isValid && !isSubmitting}
                            />
                        }
                    </Form>
                )}
            </Formik>
        </>
    );
};