import { EquipmentType } from '../../equipments/models/equipmentType';
import { BrandViewModel } from '../../models/brand/brandModel';
import { BrandTypeViewModel } from '../../models/brand/brandTypeModel';
import { InstalledBaseViewModel } from '../../models/installedBase/installedBaseViewModel';
import { CountryFormModel } from '../../organizations/models/countryModel';
import { OrganizationOptionModel } from '../../organizations/models/organizationModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDashboardState {
    countryOptions: CountryFormModel[];
    brandOptions: BrandViewModel[];
    brandTypeOptions: BrandTypeViewModel[];
    equipmentTypeOptions: EquipmentType[];
    installedBaseOptions: InstalledBaseViewModel[];
    organizationOptions: OrganizationOptionModel[];
}

const initialState: IDashboardState = {
    countryOptions: [],
    brandOptions: [],
    brandTypeOptions: [],
    equipmentTypeOptions: [],
    installedBaseOptions: [],
    organizationOptions: [],
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setCountryOptions: (state, action: PayloadAction<CountryFormModel[]>) => {
            state.countryOptions = action.payload;
        },

        setBrandOptions: (state, action: PayloadAction<BrandViewModel[]>) => {
            state.brandOptions = action.payload;
        },

        setBrandTypeOptions: (state, action: PayloadAction<BrandTypeViewModel[]>) => {
            state.brandTypeOptions = action.payload;
        },

        setEquipmentTypeOptions: (state, action: PayloadAction<EquipmentType[]>) => {
            state.equipmentTypeOptions = action.payload;
        },

        setInstalledBaseOptions: (state, action: PayloadAction<InstalledBaseViewModel[]>) => {
            state.installedBaseOptions = action.payload;
        },

        setOrganizationOptions: (state, action: PayloadAction<OrganizationOptionModel[]>) => {
            state.organizationOptions = action.payload;
        },
    },
});

// Export Actions
export const { setCountryOptions, setBrandOptions, setBrandTypeOptions, setEquipmentTypeOptions,
    setInstalledBaseOptions, setOrganizationOptions } = dashboardSlice.actions;

export default dashboardSlice.reducer;