import React from 'react';
import { useTranslation } from 'react-i18next';
import { IServiceOrderDisplayPartsProps } from './IServiceOrderDisplayPartsProps';
import { Box } from '@mui/material';
import { StyledTitle } from '../../../components/form/title/Title';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';

export const ServiceOrderDisplayParts = (props: IServiceOrderDisplayPartsProps): JSX.Element => {

    const { serviceOrder } = props;

    const { t } = useTranslation();

    return (
        <>
            <Box pt={3} display={'flex'} justifyContent={'start'}>
                <StyledTitle variant='h4'>{t('Parts to be used')}</StyledTitle>
            </Box>

            <DataGridOverview
                columns={[
                    {
                        field: 'name',
                        flex: 1,
                        headerName: t('Name'),
                        sortable: false,
                        filterable: false,
                        disableReorder: true,
                        resizable: false,
                        disableColumnMenu: true,
                    },
                    {
                        field: 'number',
                        flex: 1,
                        headerName: t('Number'),
                        sortable: false,
                        filterable: false,
                        disableReorder: true,
                        resizable: false,
                        disableColumnMenu: true,
                    },
                    {
                        field: 'quantity',
                        flex: 1,
                        headerName: t('Quantity'),
                        sortable: false,
                        filterable: false,
                        disableReorder: true,
                        resizable: false,
                        disableColumnMenu: true,
                    },
                ]}
                rows={serviceOrder.plannedParts}
                hideFooterRowCount={true}
                containeraAutoHeight={true}
                hideFooterSelectedRowCount={true}
                pagination={false}
                localeText={{ noRowsLabel: t('No parts') }}
            />
        </>
    );
};
