import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext, ErrorMessage } from 'formik';
import { StyledSearchableDropdownProps, TextField, Dropdown, DropdownOption } from '@ui-components/ui-library';
import { Grid } from '@mui/material';
import { ICustomerGeneralInfoProps } from './ICustomerGeneralInfoProps';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { validateForm as validateInitialValues } from '../../../shared/utils/Utils';

export const CustomerGeneralInfo = (props: ICustomerGeneralInfoProps): JSX.Element => {
    const { isNewForm, distributorOrganizations } = props;
    const { values: customer, handleChange, handleBlur, setFieldValue, validateForm, setFieldTouched } = useFormikContext() as any;
    const { t } = useTranslation();

    useEffect(() => {
        if (distributorOrganizations.length === 1) {
            setFieldValue('distributorOrganizationId', distributorOrganizations[0].id);
        }
    }, []);

    const distOrgOptions = distributorOrganizations.map(b => {
        return { id: b.id, label: b.name, value: b.id };
    });
    const selectedDistOrg = distOrgOptions?.find(obj => obj.id === customer.distributorOrganizationId);

    useEffect(() => {
        if (!isNewForm) {
            validateInitialValues(validateForm, setFieldTouched);
        }
    }, [isNewForm]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Name')}
                        id={'name'}
                        name={'name'}
                        value={customer.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 200 }}
                        required={true}
                    />
                    <ErrorMessage name={'name'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'distributorOrganizationId'}
                        label={t('Distributor Organization')}
                        name={'distributorOrganizationId'}
                        value={selectedDistOrg ?? { id: '', label: '', value: '' }}
                        options={distOrgOptions}
                        onChange={(e, item) => setFieldValue('distributorOrganizationId', item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        disabled={!isNewForm}
                        required={true}
                        variant='searchable'
                        greyedOutLabelOnDisabled
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'distributorOrganizationId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};
