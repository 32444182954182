import { TermsAndConditionsViewModel } from "../components/form/termsAndConditions/termsAndConditionsModel";
import { ActionResult, api } from "../shared/utils/api";

class GatewayService {
    async getTermsAndConditions(): Promise<ActionResult<TermsAndConditionsViewModel[]>> {
        const url = '/Compliance/TermsAndConditions';
        return await api.gatewayGet<TermsAndConditionsViewModel[]>(url);
    }
}    

export const gatewayService = new GatewayService();