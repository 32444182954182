import { CountryFormModel } from '../organizations/models/countryModel';
import { ActionResult, api } from '../shared/utils/api';

class CountryService {
    async GetAllCountries(): Promise<ActionResult<CountryFormModel[]>> {
        const url = '/countries/organizations';
        return await api.get<CountryFormModel[]>(url);
    }
}

export const countryService = new CountryService();