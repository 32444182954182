import React from 'react';
import moment from 'moment-timezone';
import { IDatePickerActionBarProps } from './IDatePickerActionBarProps';
import { Button, DialogActions } from '@mui/material';
import { WrapperVariantContext, useLocaleText } from '@mui/x-date-pickers/internals';

export const DatePickerActionBar = (props: IDatePickerActionBarProps) => {
    const { onAccept, onClear, onCancel, onSetToday, actions, minDate, maxDate } = props;

    const wrapperVariant = React.useContext(WrapperVariantContext);
    const localeText = useLocaleText();

    const actionsArray =
        typeof actions === 'function' ? actions(wrapperVariant) : actions;

    if (!actionsArray || actionsArray.length === 0) {
        return null;
    }

    const todayButtonIsDisabled = (minDate && moment(minDate) > moment()) || (maxDate && moment(maxDate) < moment());

    const actionButtons = actionsArray.map((actionType) => {
        switch (actionType) {
            case 'clear':
                return (
                    <Button key={actionType} onClick={onClear}>{localeText.clearButtonLabel}</Button>
                );
            case 'cancel':
                return (
                    <Button key={actionType} onClick={onCancel}>{localeText.cancelButtonLabel}</Button>
                );
            case 'accept':
                return (
                    <Button key={actionType} onClick={onAccept}>{localeText.okButtonLabel}</Button>
                );
            case 'today':
                return (
                    <Button key={actionType} onClick={onSetToday} disabled={todayButtonIsDisabled}>{localeText.todayButtonLabel}</Button>
                );
            default:
                return null;
        }
    });

    return (
        <>
            <DialogActions>
                {actionButtons}
            </DialogActions>
        </>
    );
};