import React from 'react';
import { useTranslation } from 'react-i18next';
import { IServiceOrderEquipmentInfoProps } from './IServiceOrderEquipmentInfoProps';
import { TextField } from '@ui-components/ui-library';
import { Box, Grid } from '@mui/material';
import { StyledTitle } from '../../../components/form/title/Title';

export const ServiceOrderEquipmentInfo = (props: IServiceOrderEquipmentInfoProps): JSX.Element => {
	const { t } = useTranslation();

	const { customerRelatedData } = props;

	return (
		<Grid item xs={12}>	
			{ 
				<Box width={'100%'}>
					<Box pt={5} display={'flex'} justifyContent={'start'}>
						<StyledTitle variant='h4'>{t('Equipment Information')}</StyledTitle>
					</Box>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<TextField
								label={t('Model')}
								value={customerRelatedData.modelName}
								disabled={true}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label={t('Brand')}
								value={customerRelatedData.brandName}
								disabled={true}
							/>
						</Grid>
					</Grid>
				</Box>
			}
		</Grid>
	);
};
