import * as yup from 'yup';
import { PlanningType } from '../models/planningType';
import { ServicePlanningFormModel } from '../models/servicePlanningModel';

export const planningValidationSchema = yup.object().shape({
    id: yup.number(),
    serviceOrderNumber: yup.string(),
    customer: yup.string(),
    assigneeIds: yup.array().of(yup.number()).min(1).required('Assignee IDs are required'),
    start: yup.date().required('Start date is required'),
    end: yup
        .date()
        .when('estimatedWorkHours', (estimatedWorkHours, schema) => {
            return estimatedWorkHours ? schema.required('End date is required if estimated work hours are provided') : schema;
        }),
    type: yup.string().oneOf(Object.keys(PlanningType)).required('Type is required'),
    estimatedWorkHours: yup.number(),
    visitReportIds: yup.array().of(yup.number()),
    serviceOrderId: yup.number().when('type', {
        is: 0 || '0' || PlanningType.Service || PlanningType[PlanningType.Service],
        then: yup.number().min(1).required('Service Order ID is required when type is Service'),
        otherwise: yup.number().optional(),
    })
});

const servicePlanningTypeStepValidationSchema = yup.object().shape({
    type: yup.string().oneOf(Object.keys(PlanningType)).required(),
});

export const servicePlanningTypeStepIsValid = (servicePlanning: ServicePlanningFormModel): boolean => {
    return servicePlanningTypeStepValidationSchema.isValidSync(servicePlanning);
};