import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { showErrors } from '../../../stores/alert/alertSlice';
import { equipmentService } from '../../services/EquipmentService';
import { EquipmentsOverview } from '../equipmentsOverview/EquipmentsOverview';
import { userCanViewEquipments, userLoaded } from '../../../stores/user/usersSlice';
import { EquipmentViewModel } from '../../models/equipment';
import { setIsLoading } from '../../../stores/layout/layoutSlice';

export const EquipmentsOverviewContainer = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
    const userCanAccessEquipmentView = useAppSelector(state => userCanViewEquipments(state));

    const [equipments, setEquipments] = useState<EquipmentViewModel[]>([]);

    const getEquipments = async () => {
        dispatch(setIsLoading(true));
        const equipmentsResponse = await equipmentService.GetEquipments();
        if (equipmentsResponse.isSuccess) {
            setEquipments(equipmentsResponse.result!);
        } else {
            dispatch(showErrors(equipmentsResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (!userIsLoaded) {
            return;
        }

        if (userCanAccessEquipmentView) {
            getEquipments();
        } else {
            dispatch(setIsLoading(false));
        }
    }, [userIsLoaded, userCanAccessEquipmentView]);

    return (
        <>
            {
                userIsLoaded && userCanAccessEquipmentView &&
                <EquipmentsOverview
                    equipments={equipments}
                    getEquipments={getEquipments}
                />
            }
        </>
    );
};
