import moment from 'moment-timezone';

export type EquipmentTrafficLightFilterModel = {
    userCurrentDate: Date;
    userDateBeforeOneYear: Date;
    equipmentTypeIds: number[] | undefined;
    brandId: number | undefined;
    brandTypeId: number | undefined;
    customerCenterId: number | undefined;
    distributorOrganizationId: number | undefined;
    countryId: number | undefined;
    installedBaseId: number | undefined;
    userOffset: number;
}

export const getDefaultEquipmentTrafficLightFilterModel = (): EquipmentTrafficLightFilterModel => {
    return {
        userCurrentDate: moment().endOf('d').toDate(),
        userDateBeforeOneYear: moment().add(-1, 'y').startOf('d').toDate(),
        equipmentTypeIds: undefined,
        brandId: undefined,
        brandTypeId: undefined,
        customerCenterId: undefined,
        distributorOrganizationId: undefined,
        countryId: undefined,
        installedBaseId: undefined,
        userOffset: moment().utcOffset(),
    };
};
