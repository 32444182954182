import axios, { AxiosResponse, AxiosError } from 'axios';
import { msalInstance } from '../../index';
import { AppEnvironment } from '../configurations/AppEnvironment';
import { ErrorModel } from '../models/ErrorModel';
import { isNullOrUndefined } from '../utils/Utils';
import qs from 'qs';

const get = async <T>(url: string, filters?: any): Promise<ActionResult<T>> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;
    try {
        const queryParams = filters ? { ...filters } : undefined;

        if (filters) {
            Object.keys(queryParams).forEach((key) => {
                if (queryParams[key] === undefined) {
                    delete queryParams[key];
                }
            });
        }

        const response: AxiosResponse<any, any> = await axios.get(`${AppEnvironment.get().baseUrl}${url}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: queryParams,                
                paramsSerializer: params => qs.stringify(params)
            });

        return new SuccessActionResult(response.data);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorModel = error?.response?.data as ErrorModel;

            return new FailActionResult(errorModel);
        }
        return new FailActionResult();
    }
};

const gatewayGet = async <T>(url: string): Promise<ActionResult<T>> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;
    try {
        const response: AxiosResponse<any, any> = await axios.get(`${AppEnvironment.get().newBaseUrl}${url}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

        return new SuccessActionResult(response.data);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorModel = error?.response?.data as ErrorModel;

            return new FailActionResult(errorModel);
        }
        return new FailActionResult();
    }
};

const post = async <T>(url: string, jsonModel: string): Promise<ActionResult<T>> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;
    try {
        const response: AxiosResponse<any, any> = await axios.post(`${AppEnvironment.get().baseUrl}${url}`, jsonModel,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

        return new SuccessActionResult(response.data);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorModel = error?.response?.data as ErrorModel;

            return new FailActionResult(errorModel);
        }
        return new FailActionResult();
    }
};

const filePost = async<T>(url: string, attachmentFile: File, additionalProps: { key: string, value: any }[]): Promise<ActionResult<T>> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;

    try {
        const formData = new FormData();
        formData.append('attachmentFile', attachmentFile);
        additionalProps.forEach(p => {
            formData.append(p.key, p.value);
        });
        const response: AxiosResponse<any, any> = await axios.post(`${AppEnvironment.get().baseUrl}${url}`, formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': `${attachmentFile.type}`,
                },
            });

        return new SuccessActionResult(response.data);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorModel = error?.response?.data as ErrorModel;

            return new FailActionResult(errorModel);
        }
        return new FailActionResult();
    }
};

const put = async <T>(url: string, jsonModel: string | number | {}): Promise<ActionResult<T>> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;
    try {
        const response: AxiosResponse<any, any> = await axios.put(`${AppEnvironment.get().baseUrl}${url}`, jsonModel,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

        return new SuccessActionResult(response.data);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorModel = error?.response?.data as ErrorModel;

            return new FailActionResult(errorModel);
        }
        return new FailActionResult();
    }

};

const filePut = async<T>(url: string, attachmentFile: File, additionalProps: { key: string, value: any }[]): Promise<ActionResult<T>> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;

    try {
        const formData = new FormData();
        formData.append('attachmentFile', attachmentFile);
        additionalProps.forEach(p => {
            formData.append(p.key, p.value);
        });
        const response: AxiosResponse<any, any> = await axios.put(`${AppEnvironment.get().baseUrl}${url}`, formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': `${attachmentFile.type}`,
                },
            });

        return new SuccessActionResult(response.data);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorModel = error?.response?.data as ErrorModel;

            return new FailActionResult(errorModel);
        }
        return new FailActionResult();
    }
};

const patch = async <T>(url: string, jsonModel: string): Promise<T> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;
    const response: AxiosResponse<any, any> = await axios.patch(`${AppEnvironment.get().baseUrl}${url}`, jsonModel,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

    return response.data;
};

const deleteAction = async <T>(url: string): Promise<ActionResult<T>> => {
    const token: string = (await msalInstance.acquireTokenSilent(AppEnvironment.get().loginRequest)).accessToken;
    try {
        const response: AxiosResponse<any, any> = await axios.delete(`${AppEnvironment.get().baseUrl}${url}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

        return new SuccessActionResult(response.data);
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorModel = error?.response?.data as ErrorModel;

            return new FailActionResult(errorModel);
        }
        return new FailActionResult();
    }
};

export const api = {
    get: async <T>(url: string, filters?: any) => await get<T>(url, filters),
    gatewayGet: async <T>(url: string) => await gatewayGet<T>(url),
    post: async <T>(url: string, jsonModel: string) => await post<T>(url, jsonModel),
    filePost: async <T>(url: string, attachmentFile: File, additionalProps: { key: string, value: any }[]) => await filePost<T>(url, attachmentFile, additionalProps),
    put: async <T>(url: string, jsonModel: string | number | {}) => await put<T>(url, jsonModel),
    filePut: async <T>(url: string, attachmentFile: File, additionalProps: { key: string, value: any }[]) => await filePut<T>(url, attachmentFile, additionalProps),
    patch: async <T>(url: string, jsonModel: string) => await patch<T>(url, jsonModel),
    delete: async <T>(url: string) => await deleteAction<T>(url),
};

export class ActionResult<T> {
    private _isSuccess: boolean;

    private _result: T | null;
    private _errorModel: ErrorModel | null;

    constructor(isSuccess: boolean, result?: T, errorModel?: ErrorModel) {
        this._isSuccess = isSuccess;

        this._result = isNullOrUndefined(result) ? null : <T>result;
        this._errorModel = isNullOrUndefined(errorModel) ? null : <ErrorModel>errorModel;
    }

    get isSuccess(): boolean {
        return this._isSuccess;
    }

    get result(): T | null {
        return this._result;
    }

    get errorModel(): ErrorModel | null {
        return this._errorModel;
    }
}

export class SuccessActionResult<T> extends ActionResult<T> {
    constructor(result: T) {
        super(true, result);
    }
}

export class FailActionResult<T> extends ActionResult<T> {
    constructor(errorModel?: ErrorModel) {
        super(false, undefined, errorModel);
    }
}