import { api, ActionResult } from '../../shared/utils/api';
import { OrganizationFormModel, DistributorOrganizationCreateModel, DistributorOrganizationUpdateModel, CustomerCenterOrganizationUpdateModel, OrganizationViewModel, OrganizationOptionModel, ParentOrganizationOptionModel } from '../models/organizationModel';

class OrganizationService {
    async GetOrganizations(): Promise<ActionResult<OrganizationViewModel[]>> {
        const url = '/organizations';
        return await api.get<OrganizationViewModel[]>(url);
    }

    async GetParentOrganizationOptions(): Promise<ActionResult<ParentOrganizationOptionModel[]>> {
        const url = '/organizations/GetParentOrganizationOptions';
        return await api.get<ParentOrganizationOptionModel[]>(url);
    }

    async GetOrganizationOptionsForRole(roleId: number): Promise<ActionResult<OrganizationOptionModel[]>> {
        const url = `/organizations/GetOrganizationOptionsForRole/${roleId}`;
        return await api.get<OrganizationOptionModel[]>(url);
    }

    async GetOrganizationOptions(): Promise<ActionResult<OrganizationOptionModel[]>> {
        const url = '/organizations/GetOrganizationOptions';
        return await api.get<OrganizationOptionModel[]>(url);
    }

    async GetOrganizationById(id: number): Promise<ActionResult<OrganizationFormModel>> {
        const url = `/Organizations/${id}`;
        return await api.get<OrganizationFormModel>(url);
    }
    async DeactivateOrganization(id: number): Promise<ActionResult<void>> {
        const url = `/Organizations/${id}`;

        return await api.delete(url);
    }

    async ActivateOrganization(id: number): Promise<ActionResult<void>> {
        const url = `/Organizations/${id}`;

        return await api.put(url, {});
    }

    async CreateDistributorOrganization(createModel: DistributorOrganizationCreateModel): Promise<ActionResult<number>> {
        const url = '/organizations/CreateDistributorOrganization';

        return await api.post<number>(url, JSON.stringify(createModel));
    }

    async UpdateDistributorOrganization(updateModel: DistributorOrganizationUpdateModel): Promise<ActionResult<void>> {
        const url = '/organizations/UpdateDistributorOrganization';

        return await api.put(url, JSON.stringify(updateModel));
    }

    async UpdateCustomerCenterOrganization(updateModel: CustomerCenterOrganizationUpdateModel): Promise<ActionResult<void>> {
        const url = '/organizations/UpdateCustomerCenterOrganization';

        return await api.put(url, JSON.stringify(updateModel));
    }
}

export const organizationService = new OrganizationService();