
export type ServiceExecutionSigner = {
    firstName: string;
    lastName: string;
    signature: string | undefined;
}

export const getDefaultServiceExecutionSigner = (): ServiceExecutionSigner => {
    return {
        firstName: '',
        lastName: '',
        signature: undefined,
    };
};