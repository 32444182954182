import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { UnitOptionModel } from '../models/unitOptionModel';

class UnitService {
    async GetUnitOptionsForUnitCategory(unitCategoryId: number): Promise<ActionResult<UnitOptionModel[]>> {
        const url = `/Units/GetUnitsForUnitCategory/${unitCategoryId}`;
        const response = await api.get<UnitOptionModel[]>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }
}

export const unitService = new UnitService();