import React, { useEffect, useState } from 'react';
import { getDefaultServicePlanning, ServicePlanningFormModel } from '../../models/servicePlanningModel';
import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Box } from '@mui/material';
import { Button } from '@ui-components/ui-library';
import { servicePlanningService } from '../../services/servicePlanningService';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { showErrors } from '../../../stores/alert/alertSlice';
import { TechnicianModel } from '../../../users/models/technicianModel';
import { userService } from '../../../users/services/UserService';
import { userCanCreateServicePlannings, userCanViewServiceOrders, userCanViewServicePlannings, userLoaded } from '../../../stores/user/usersSlice';
import { useTranslation } from 'react-i18next';
import { ServiceOrderDisplayModel, ServiceOrderViewModel } from '../../../serviceOrders/models/serviceOrderModel';
import { serviceOrderService } from '../../../serviceOrders/services/ServiceOrderService';
import { ServiceOrdersTable } from '../serviceOrdersTable/ServiceOrdersTable';
import { SchedulerOverview } from '../schedulerOverview/SchedulerOverview';
import { MobileSchedulerOverview } from '../mobileSchedulerOverview/MobileSchedulerOverview';
import { SHEDULER_NUMBER_OF_DAYS_RANGE } from '../../../shared/utils/constants';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { ServiceOrderDisplayForm } from '../serviceOrderDisplayForm/ServiceOrderDisplayForm';
import { PlanningForm } from '../planningForm/PlanningForm';
import { ServiceExecutionForm } from '../../../serviceExecutions/components/serviceExecutionForm/ServiceExecutionForm';
import moment from 'moment-timezone';
import { SchedulerMessageProvider } from '../../services/schedulerMessageProvider';
import { AuthenticatedUser } from '../../../users/models/user';

const SchedulerContainer = styled('div')({
    '@media (min-width:1600px)': {
        margin: '0 -5% 1rem -5% ',
    },
    '@media (min-width:1900px)': {
        margin: '0 -12% 1rem -12%',
    },
});

export const ServicePlanningOverviewContainer = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
    const userCanAccessServicePlanningView = useAppSelector(state => userCanViewServicePlannings(state));
    const userCanAccessServiceOrdersView = useAppSelector(state => userCanViewServiceOrders(state));
    const userCanCreateServicePlanning: boolean = useAppSelector(state => userCanCreateServicePlannings(state));
    const userIsOnMobileDevice: boolean = useAppSelector(state => state.User.currentUser.isOnMobileDevice);
    const isLoading: boolean = useAppSelector(state => state.Layout.isLoading);

    const [servicePlannings, setServicePlannings] = useState<ServicePlanningFormModel[]>([]);
    const [technicians, setTechnicians] = useState<TechnicianModel[]>([]);
    const [notPlannedServiceOrders, setNotPlannedServiceOrders] = useState<ServiceOrderViewModel[]>([]);
    const [currentMinDate, setCurrentMinDate] = useState<Date>(moment().toDate());
    const [currentMaxDate, setCurrentMaxDate] = useState<Date>(moment().toDate());
    const [planningFormIsOpen, setPlanningFormIsOpen] = useState<boolean>(false);
    const [serviceOrderFormIsOpen, setServiceOrderFormIsOpen] = useState<boolean>(false);

    const [serviceExecutionFormIsOpen, setServiceExecutionFormIsOpen] = useState<boolean>(false);
    const [selectedServiceExcutionId, setSelectedServiceExecutionId] = useState<number | undefined>(undefined);

    const [selectedServiceOrder, setSelectedServiceOrder] = useState<ServiceOrderDisplayModel | undefined>(undefined);
    const [selectedPlanning, setSelectedPlanning] = useState<ServicePlanningFormModel>(getDefaultServicePlanning());

    const getServicePlannings = async (from: Date | undefined) => {
        dispatch(setIsLoading(true));
        let fromDateString;
        let toDateString;

        if (from) {
            const fromDate: Date = moment(from).add(-SHEDULER_NUMBER_OF_DAYS_RANGE, 'd').toDate();
            const toDate: Date = moment(from).add(SHEDULER_NUMBER_OF_DAYS_RANGE, 'd').toDate();
            fromDateString = moment(fromDate).format('YYYY-MM-DD');
            toDateString = moment(toDate).format('YYYY-MM-DD');
            setCurrentMinDate(fromDate);
            setCurrentMaxDate(toDate);
        } else {
            const fromDate: Date = moment().add(-SHEDULER_NUMBER_OF_DAYS_RANGE, 'd').toDate();
            const toDate: Date = moment().add(SHEDULER_NUMBER_OF_DAYS_RANGE, 'd').toDate();
            fromDateString = moment(fromDate).format('YYYY-MM-DD');
            toDateString = moment(toDate).format('YYYY-MM-DD');
            setCurrentMinDate(fromDate);
            setCurrentMaxDate(toDate);
        }

        const servicePlanningsResponse = await servicePlanningService.GetAllServicePlannings(fromDateString, toDateString);

        if (servicePlanningsResponse.isSuccess) {
            setServicePlannings(servicePlanningsResponse.result!);
        } else {
            dispatch(showErrors(servicePlanningsResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };


    const refreshPlanningsAndOrders = async () => {
        await getServicePlannings(moment(currentMinDate).add(SHEDULER_NUMBER_OF_DAYS_RANGE, 'd').toDate());
        await getNotPlannedServiceOrders();
    };

    const reloadPlannings = async () => {
        await getServicePlannings(moment(currentMinDate).add(SHEDULER_NUMBER_OF_DAYS_RANGE, 'd').toDate());
    };

    const getTechnicians = async () => {
        const techniciansResponse = await userService.GetTechnicians();
        if (techniciansResponse.isSuccess) {
            setTechnicians(techniciansResponse.result!);
        } else {
            dispatch(showErrors(techniciansResponse?.errorModel?.errors));
        }
    };

    const getNotPlannedServiceOrders = async () => {
        const notPlannedServiceOrdersResponse = await serviceOrderService.GetNotPlannedServiceOrders();
        if (notPlannedServiceOrdersResponse.isSuccess) {
            setNotPlannedServiceOrders(notPlannedServiceOrdersResponse.result!);
        } else {
            dispatch(showErrors(notPlannedServiceOrdersResponse?.errorModel?.errors));
        }
    };

    const openServicePlanningForm = (servicePlanningId: number | undefined) => {
        if (servicePlanningId) {
            const servicePlanning = servicePlannings.find(sp => sp.id === servicePlanningId);
            if (servicePlanning) {
                const _servicePlanning = { ...servicePlanning };

                setSelectedPlanning(_servicePlanning);
                setPlanningFormIsOpen(true);
            }
        } else {
            setSelectedPlanning(getDefaultServicePlanning());
            setPlanningFormIsOpen(true);
        }
    };

    const openServiceOrderForm = async (serviceOrderId: number | undefined) => {

        if (!serviceOrderId) {
            dispatch(showErrors([t('Please select a Service order')]));
            dispatch(setIsLoading(false));
            return;
        }

        dispatch(setIsLoading(true));

        const serviceOrderResponse = await serviceOrderService.DisplayServiceOrderById(serviceOrderId);

        if (serviceOrderResponse.isSuccess) {
            setSelectedServiceOrder(serviceOrderResponse.result!);
            setServiceOrderFormIsOpen(true);
        } else {
            dispatch(showErrors(serviceOrderResponse.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const openServiceExcutionForm = (id: number) => {
        setSelectedServiceExecutionId(id);
        setServiceExecutionFormIsOpen(true);
    };

    const closeServiceExcutionForm = () => {
        setSelectedServiceExecutionId(undefined);
        setServiceExecutionFormIsOpen(false);
    };

    const closeServiceOrderForm = async () => {
        setServiceOrderFormIsOpen(false);
        setSelectedServiceOrder(undefined);
    };

    const closeServicePlanningForm = () => {
        setPlanningFormIsOpen(false);
    };

    const refreshPlannings = () => {
        setServicePlannings([...servicePlannings]);
    };

    useEffect(() => {
        if (!userIsLoaded) {
            return;
        }

        if (userCanAccessServicePlanningView) {
            getServicePlannings(undefined);
            getTechnicians();
        } else {
            dispatch(setIsLoading(false));
            return;
        }

        if (userCanAccessServiceOrdersView) {
            getNotPlannedServiceOrders();
        }

        new SchedulerMessageProvider(currentUser.preferences).setSchedulerMessages();

    }, [userIsLoaded, userCanAccessServicePlanningView, userCanAccessServiceOrdersView]);

    const updateNotPlannedServiceOrders = (usedServiceOrderId: number): void => {
        const updatedNotPlannedServiceOrders = notPlannedServiceOrders.filter(npso => npso.id !== usedServiceOrderId);

        setNotPlannedServiceOrders(updatedNotPlannedServiceOrders);
    };

    const onServiceCancellationHandler = async (servicePlanningId: number): Promise<void> => {
        const updatedPlannings = servicePlannings.filter(sp => sp.id !== servicePlanningId);
        setServicePlannings(updatedPlannings);
        await getNotPlannedServiceOrders();
    };

    return (
        <>
            {
                userCanCreateServicePlanning && technicians.length > 0 &&
                <SchedulerContainer>
                    <Button
                        id={'create'}
                        label={t('Create')}
                        startIcon={'add'}
                        aria-label="Create"
                        onClick={() => openServicePlanningForm(undefined)}
                        sx={{ marginBottom: '1rem' }}
                    />
                </SchedulerContainer>
            }

            {
                userCanCreateServicePlanning && technicians.length === 0 && !isLoading &&
                <Alert severity="info" sx={{ margin: '1rem 0', fontSize: '14px' }}>
                    <AlertTitle>
                        {t('You cannot see the calendar as your organization has no active technician')}
                    </AlertTitle>
                    {t('In order to see the calendar and use all of it\'s features you need to have an active technician under your organizational structure.')}
                </Alert>
            }

            {
                userIsLoaded && userCanAccessServicePlanningView && technicians.length > 0 && !userIsOnMobileDevice &&
                <SchedulerOverview
                    currentMaxDate={currentMaxDate}
                    currentMinDate={currentMinDate}
                    onDateRangeChange={getServicePlannings}
                    technicians={technicians}
                    servicePlannings={servicePlannings}
                    openPlanningForm={openServicePlanningForm}
                    refreshPlannings={refreshPlannings}
                    openServiceOrderForm={openServiceOrderForm}
                    onServiceCancellationHandler={onServiceCancellationHandler}
                    openServiceExecutionForm={openServiceExcutionForm}
                    reloadPlannings={reloadPlannings}
                />
            }

            {
                userIsLoaded && userCanAccessServicePlanningView && technicians.length > 0 && userIsOnMobileDevice &&
                <MobileSchedulerOverview
                    currentMaxDate={currentMaxDate}
                    currentMinDate={currentMinDate}
                    onDateRangeChange={getServicePlannings}
                    technicians={technicians}
                    servicePlannings={servicePlannings}
                    openPlanningForm={openServicePlanningForm}
                    refreshPlannings={refreshPlannings}
                    openServiceOrderForm={openServiceOrderForm}
                    onServiceCancellationHandler={onServiceCancellationHandler}
                    openServiceExecutionForm={openServiceExcutionForm}
                    reloadPlannings={reloadPlannings}
                />
            }

            {
                userIsLoaded && userCanAccessServicePlanningView && planningFormIsOpen &&
                <PlanningForm
                    planning={selectedPlanning}
                    isOpen={planningFormIsOpen}
                    handleClose={closeServicePlanningForm}
                    technicians={technicians}
                    notPlannedServiceOrders={notPlannedServiceOrders}
                    refreshPlannings={refreshPlanningsAndOrders}
                />
            }

            {
                userIsLoaded && userCanAccessServiceOrdersView && technicians.length > 0 &&
                <Box m={'3em 0'}>
                    <ServiceOrdersTable
                        serviceOrders={notPlannedServiceOrders}
                        updateNotPlannedServiceOrders={updateNotPlannedServiceOrders}
                        getServiceOrders={getNotPlannedServiceOrders}
                    />

                </Box>
            }

            {
                selectedServiceOrder && serviceOrderFormIsOpen &&
                <ServiceOrderDisplayForm
                    serviceOrder={selectedServiceOrder}
                    isOpen={serviceOrderFormIsOpen}
                    onClose={closeServiceOrderForm}
                />
            }

            {
                selectedServiceExcutionId && serviceExecutionFormIsOpen &&
                <ServiceExecutionForm
                    formIsOpen={serviceExecutionFormIsOpen}
                    handleClose={closeServiceExcutionForm}
                    serviceExecutionId={selectedServiceExcutionId}
                />
            }
        </>
    );
};