// These environment constants will be moved together with other secrets and credentials into .env files when we R&D the pipeline integration

//#region Environments
export const TEST_URL = 'https://myservicemanagertest.azurewebsites.net';
export const STAGING_URL = 'https://myservicemanagerstg.azurewebsites.net';
export const PRODUCTION_URL = 'https://myservicemanager.com';
//#endregion

export const SHEDULER_NUMBER_OF_DAYS_RANGE: number = 37;

export const MUI_LICENSE_KEY = 'b4db89a160f8663bd3d0bebd5c7b35ebTz05MzU2NSxFPTE3NTU5MDcyMDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

// TODO BT 20230614 Later get this from the API
export const CustomerTemplateFileName = 'MSM_Customers_Import_Template.xlsx';

export const ExportFileNameDateFormat = 'DDMMyyyy';

export const ServerDateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const MAX_FILES_PER_SECTION: number = 4;

export const MAX_CHARACTERS_FOR_FILE_NAME = 64;

export const MAX_DISTRIBUTORS_FOR_CUSTOMER_CREATION_KPI = 5;

export const KPI_GAUGE_COLORS = ['#FF0000', '#FF0C00', '#FF1900','#FF2600', '#FF3300', '#FF3F00', '#FF4C00', '#FF5900', '#FF6600', '#FF7200', '#FF7F00', '#FF8C00', '#FF9900', '#FFA600', '#FFB200', '#FFBF00', '#FFCC00', '#D9FF00', '#B2FF00', '#8CFF00', '#66FF00', '#40FF00', '#19FF00', '#00FF0C', '#00FF33', ];

export const NO_OPTIONS_TEXT = 'No options';

export const ALL_TEXT = 'All';

export const COOKIES_CONSENT_NAME = 'cookies_consent';

export const TERMS_AND_CONDITIONS_COOKIE_NAME = 'termsAndConditionsConsent';

export const USER_SET_PREFERED_LANGUAGE_COOKIE_NAME = 'userSetPreferedLanguage';

export const USER_LANGUAGE_IS_SET = 1;

export const ARABIC_SHORT_MONTHS = [
    'ين',
    'فبر',
    'مار',
    'أبر',
    'ماي',
    'يون',
    'يول',
    'أغس',
    'سبت',
    'أكت',
    'نوف',
    'ديس',
];

export type MSMEnvironment = {
    Environment: string,
    WebsiteUrl: string;
    ApiUrl: string;
    NewApiUrl: string;
    SignInUrl: string;
    ClientId: string;
}

export const PRIVACY_POLICY_LINK = 'https://www.atlascopcogroup.com/en/privacy-policy';

class MSMEnvironmentFactory {
    GetAll(): MSMEnvironment[] {
        const environments: MSMEnvironment[] = [
            {
                Environment: 'Production',
                WebsiteUrl: 'https://myservicemanager.azurewebsites.net',
                ApiUrl: 'https://myservicemanager.azurewebsites.net/api',
                NewApiUrl: 'https://msm-gateway-prd.azurewebsites.net/api',
                ClientId: '23915e6c-6c18-4b07-a8d9-927f4df93a6b',
                SignInUrl: 'https://myservicemanager.azurewebsites.net/signin-oidc',
            },
            {
                Environment: 'Production2',
                WebsiteUrl: 'https://myservicemanager.com',
                ApiUrl: 'https://myservicemanager.com/api',
                NewApiUrl: 'https://msm-gateway-prd.azurewebsites.net/api',
                ClientId: '23915e6c-6c18-4b07-a8d9-927f4df93a6b',
                SignInUrl: 'https://myservicemanager.com/signin-oidc',
            },
            {
                Environment: 'Staging',
                WebsiteUrl: 'https://myservicemanagerstg.azurewebsites.net',
                ApiUrl: 'https://myservicemanagerstg.azurewebsites.net/api',
                NewApiUrl: 'https://msm-gateway-stg.azurewebsites.net/api',
                ClientId: '1759571c-9171-49c0-8004-fe1fafd4423a',
                SignInUrl: 'https://myservicemanagerstg.azurewebsites.net/signin-oidc',
            },
            {
                Environment: 'Staging2',
                WebsiteUrl: 'https://staging.myservicemanager.com',
                ApiUrl: 'https://staging.myservicemanager.com/api',
                NewApiUrl: 'https://msm-gateway-stg.azurewebsites.net/api',
                ClientId: '1759571c-9171-49c0-8004-fe1fafd4423a',
                SignInUrl: 'https://staging.myservicemanager.com/signin-oidc',
            },
            {
                Environment: 'Test',
                WebsiteUrl: 'https://myservicemanagertest.azurewebsites.net',
                ApiUrl: 'https://myservicemanagertest.azurewebsites.net/api',
                NewApiUrl: 'https://msm-gateway-tst.azurewebsites.net/api',
                ClientId: 'b67b3412-bd2a-4947-aee1-08d8416fe490',
                SignInUrl: 'https://myservicemanagertest.azurewebsites.net/signin-oidc',
            },
            {
                Environment: 'Test2',
                WebsiteUrl: 'https://test.myservicemanager.com',
                ApiUrl: 'https://test.myservicemanager.com/api',
                NewApiUrl: 'https://msm-gateway-tst.azurewebsites.net/api',
                ClientId: 'b67b3412-bd2a-4947-aee1-08d8416fe490',
                SignInUrl: 'https://test.myservicemanager.com/signin-oidc',
            },
            {
                Environment: 'Development',
                WebsiteUrl: 'https://localhost:7137',
                ApiUrl: 'https://localhost:7137/api',
                NewApiUrl: 'http://localhost:5224//api',
                ClientId: 'b67b3412-bd2a-4947-aee1-08d8416fe490',
                SignInUrl: 'https://localhost:44445/signin-oidc',
            },
            {
                Environment: 'Development2',
                WebsiteUrl: 'https://localhost:44445',
                ApiUrl: 'https://localhost:7137/api',
                NewApiUrl: 'http://localhost:5224/api',
                ClientId: 'b67b3412-bd2a-4947-aee1-08d8416fe490',
                SignInUrl: 'https://localhost:44445/signin-oidc',
            },
        ];

        return environments;
    }

}

export const msmEnvironmentFactory = new MSMEnvironmentFactory();