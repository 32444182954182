import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { userCanViewServiceExecutions, userIsGlobalAdmin, userLoaded } from '../../../stores/user/usersSlice';
import { ServiceExecutionOverviewRecord } from '../../models/serviceExecutionOverviewModel';
import { serviceExecutionService } from '../../services/ServiceExecutionService';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { orderBy } from 'lodash';
import { Box, Grid } from '@mui/material';
import { debounce } from 'lodash';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';
import { Button, Chip, ChipType, Icon, SearchBar } from '@ui-components/ui-library';
import { GridEnrichedColDef, GridRowParams, GridValidRowModel, GridRenderCellParams, getGridDateOperators } from '@mui/x-data-grid-pro';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { StyledGridCellContent } from '../../../shared/utils/sxStyleObjects';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { ServiceExecutionStatus } from '../../models/serviceExecutionStatus';
import { NotificationCreateModel } from '../../models/notificationCreateModel';
import { notificationService } from '../../services/NotificationService';
import { EmailDialog } from '../../../components/emailDialog/EmailDialog';
import { ServiceExecutionForm } from '../serviceExecutionForm/ServiceExecutionForm';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import moment from 'moment-timezone';
import { FilterDatePicker } from '../../../components/filterDatePicker/FilterDatePicker';
import { serviceOrderNumberComparator } from '../../../serviceOrders/services/ServiceOrderUtility';
import { i18n } from '../../../i18n/config';

export const ServiceExecutionOverview = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const canCreateServiceExecutions = false;
    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
    const userCanAccessServiceExecutionsView = useAppSelector(state => userCanViewServiceExecutions(state));
    const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));

    const [serviceExecutions, setServiceExecutions] = useState<ServiceExecutionOverviewRecord[]>([]);
    const [serviceExecutionsFiltered, setServiceExecutionsFiltered] = useState<ServiceExecutionOverviewRecord[]>([]);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [clearGridFilters, setClearGridFilters] = useState<boolean | undefined>(undefined);

    const [selectedServiceExecution, setSelectedServiceExecution] = useState<ServiceExecutionOverviewRecord | undefined>(undefined);
    const [emailDialogIsVisible, setEmailDialogIsVisible] = useState<boolean>(false);

    const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

    const getServiceExecutions = async () => {
        dispatch(setIsLoading(true));
        const serviceOrdersResponse = await serviceExecutionService.GetServiceExecutions();
        if (serviceOrdersResponse.isSuccess) {
            const orderedServiceExecutions = orderBy(serviceOrdersResponse.result!.visitReports, vr => vr.creationDate, 'desc');
            setServiceExecutions(orderedServiceExecutions);
            setServiceExecutionsFiltered(orderedServiceExecutions);
        } else {
            dispatch(showErrors(serviceOrdersResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const sendServiceExecutionEmail = async (email: string, languageCode: string) => {
        if (!selectedServiceExecution) {
            dispatch(showErrors([t('Please select a record')]));
            return;
        }

        dispatch(setIsLoading(true));
        const notificationCreateModel: NotificationCreateModel = {
            serviceExecutionId: selectedServiceExecution.id,
            email: email,
            languageCode: languageCode,
        };

        const notificationCreateResponse = await notificationService.CreateServiceExecutionNotification(notificationCreateModel);

        if (notificationCreateResponse.isSuccess) {
            setEmailDialogIsVisible(false);
            dispatch(showSuccess(t(SUCCESS_MESSAGE)));
        } else {
            dispatch(showErrors(notificationCreateResponse.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const openEmailDialog = (serviceExecution: ServiceExecutionOverviewRecord) => {
        setSelectedServiceExecution(serviceExecution);
        setEmailDialogIsVisible(true);
    };

    const closeEmailDialog = () => {
        setEmailDialogIsVisible(false);
        setSelectedServiceExecution(undefined);
    };

    const onGlobalFilterChange = (value: string) => {
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setGlobalFilterValue('');
        setServiceExecutionsFiltered([...serviceExecutions]);
    };

    const clearFilters = () => {
        initFilters();
        setClearGridFilters(!clearGridFilters);
    };

    const createdOnBody = (params: GridRenderCellParams<ServiceExecutionOverviewRecord>) => {
        const formatedDate = moment(params.row.creationDate).format(currentUser.preferences.dateFormat);

        return (
            <StyledGridCellContent>{formatedDate}</StyledGridCellContent>
        );
    };


    const technicianBody = (params: GridRenderCellParams<ServiceExecutionOverviewRecord>) => {
        if (params.row.technicians === undefined)
            return;

        return (
            <Grid container gap={1}>
                {params.row.technicians.map((t: string) => {
                    return (
                        <Grid item xs={12} key={t} maxWidth={'100%'}>
                            <Chip type={ChipType.LIGHTBLUE} label={t} />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const handleEdit = (id: number) => {
        setSelectedId(id);
        setFormIsOpen(true);
    };

    const handleCloseFormDialog = () => {
        setSelectedId(undefined);
        setFormIsOpen(false);
    };

    const renderHeader = (): JSX.Element => {
        return (
            <Box display='flex' justifyContent='space-between' marginBottom={2}>
                {
                    canCreateServiceExecutions &&
                    <Button
                        id='new-equipment'
                        variant='primary'
                        label={t('New Service Order')}
                        startIcon='add'
                    />
                }

                <SearchBar
                    variant='default'
                    onChange={(value) => onGlobalFilterChange(value)}
                    timeoutOnChange={300}
                    value={globalFilterValue}
                    placeholder={t('Search')}
                />

                <Box display={'flex'} gap='5px'>
                    <Button
                        id='clear-filter'
                        variant='secondary'
                        label={t('Clear')}
                        startIcon='filter_list_off'
                        onClick={clearFilters}
                    />
                </Box>
            </Box>
        );
    };

    const translatedServiceExecutions = serviceExecutionsFiltered.map(se => ({ ...se, status: t(se.status), id: se.reportId })) as GridValidRowModel[];

    const getColumns = (): GridEnrichedColDef[] => {
        const columns: GridEnrichedColDef[] = [
            {
                field: 'creationDate',
                headerName: t('Created on'),
                description: t('Created on'),
                renderCell: (params: GridRenderCellParams<ServiceExecutionOverviewRecord>) => createdOnBody(params),
                type: 'date',
                sortable: true,
                filterable: true,
                pinnable: false,
                flex: 1.5,
                filterOperators: getGridDateOperators()
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? FilterDatePicker
                            : undefined,
                        InputComponentProps: { inputFormat: currentUser.preferences.dateFormat }
                    })),
            },
            { field: 'serviceOrderNumber', headerName: t('Service Order Number'), sortable: true, sortComparator: serviceOrderNumberComparator, filterable: true, pinnable: false, flex: 1.5, description: t('Service Order Number') },
            { field: 'customerName', headerName: t('Customer name'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Customer name') },
            (isGlobalAdmin) && { field: 'distributorOrganization', headerName: t('Distributor organization'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Distributor organization') },
            { field: 'equipmentSerialNumber', headerName: t('Serial Number'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('Serial Number') },
            {
                field: 'technicians', headerName: t('Technicians'), renderCell: technicianBody, sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Technicians'),
                sortComparator: (v1: string[], v2: string[]) => {
                    return v1.join(', ').localeCompare(v2.join(', '));
                }
            },
            { field: 'status', headerName: t('Status'), sortable: true, filterable: true, pinnable: false, flex: 1, description: t('Status') },
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                description: t('Actions'),
                flex: 1,
                getActions: (params: GridRowParams<ServiceExecutionOverviewRecord>) => {
                    let actionsArray = [];

                    actionsArray.push(
                        <DataGridActionOverview
                            key={'edit'}
                            icon={params.row.status !== t(ServiceExecutionStatus.Completed) ? <Icon type='edit' /> : <Icon type='visibility' />}
                            label={params.row.status !== t(ServiceExecutionStatus.Completed) ? t('Edit') : t('View')}
                            onClick={() => handleEdit(params.row.reportId)}
                            showInMenu
                        />
                    );

                    if (params.row.status === t(ServiceExecutionStatus.Completed)) {
                        actionsArray.push(
                            <DataGridActionOverview
                                key={'email'}
                                icon={<Icon type='email' />}
                                label={t('Send as Email')}
                                onClick={() => openEmailDialog(params.row)}
                                showInMenu
                            />
                        );
                    }

                    return actionsArray;
                },
            },
        ].filter(Boolean) as GridEnrichedColDef[];

        return columns;
    };

    const memoizedColumns = useMemo(() => getColumns(), [i18n.language]);

    const debouncedFilterServiceExecutions = useCallback(
        debounce((value: string) => {
			if (serviceExecutions.length > 0) {
				let _serviceExecutionsFiltered = [...serviceExecutions];
				_serviceExecutionsFiltered = _serviceExecutionsFiltered.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value.toLowerCase())));
				setServiceExecutionsFiltered(_serviceExecutionsFiltered);
			}
        }, 500), [serviceExecutions]
    );

    useEffect(() => {
        return () => debouncedFilterServiceExecutions.cancel();
    }, [debouncedFilterServiceExecutions]);

    useEffect(() => {
        debouncedFilterServiceExecutions(globalFilterValue);
    }, [globalFilterValue]);

    useEffect(() => {
        if (!userIsLoaded) {
            return;
        }

        if (userCanAccessServiceExecutionsView) {
            initFilters();
            getServiceExecutions();
        } else {
            dispatch(setIsLoading(false));
        }
    }, [userIsLoaded, userCanAccessServiceExecutionsView]);

    return (
        <>
            {
                userCanAccessServiceExecutionsView &&
                <>
                    <Box>
                        {renderHeader()}
                    </Box>
                    <DataGridOverview
                        rows={translatedServiceExecutions}
                        columns={memoizedColumns}
                        pagination
                        clearFilters={clearGridFilters}
                    />

                    {formIsOpen &&
                        <ServiceExecutionForm
                            formIsOpen={formIsOpen}
                            handleClose={handleCloseFormDialog}
                            serviceExecutionId={selectedId}
                            getServiceExecutions={getServiceExecutions}
                        />
                    }

                    {
                        emailDialogIsVisible &&
                        <EmailDialog
                            header={t('Send as Email')}
                            visible={emailDialogIsVisible}
                            onClose={closeEmailDialog}
                            onSubmit={sendServiceExecutionEmail}
                        />
                    }
                </>
            }
        </>
    );
};