import { LocalizationProvider, PickersLocaleText } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDatePickerLocalizationProvider {
    children: ReactNode;
}

export const DatePickerLocalizationProvider = ({ children }: IDatePickerLocalizationProvider) => {
    const { t } = useTranslation();
    
    const defaultLocaleText: Partial<PickersLocaleText<any>> = {
        previousMonth: t('Previous month'),
        nextMonth: t('Next month'),
        cancelButtonLabel: t('Cancel'),
        clearButtonLabel: t('Clear'),
        okButtonLabel: t('OK'),
        todayButtonLabel: t('Today')
    };

    return (
        <>
            <LocalizationProvider 
                dateAdapter={AdapterMoment}
                localeText={defaultLocaleText}
            >
                {children}
            </LocalizationProvider>
        </>
    )    
};