import React from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers-pro';
import { Box, InputAdornment } from '@mui/material';
import { DatePickerTextField } from '@ui-components/ui-library';
import { useTheme } from '@mui/material/styles';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { DatePickerLocalizationProvider } from '../../datePickerLocalizationProvider/DatePickerLocalizationProvider';
import { DatePickerSxObject } from '../../../shared/utils/sxStyleObjects';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';

interface DateTimePickerProps {
  title?: string;
  value?: Date;
  onChange: (value: Date | null) => void;
  inputFormat?: string;
  disabled?: boolean;
}

export const DateTimePicker = (props: DateTimePickerProps): JSX.Element => {
  const theme = useTheme();

  const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <Box width={'100%'} display={'flex'} flexDirection={'column'}>
        <DatePickerLocalizationProvider>
          <MuiDateTimePicker            
            value={props.value || null}
            onChange={props.onChange}
            inputFormat={props.inputFormat}
            showDaysOutsideCurrentMonth
            disabled={props.disabled}
            minutesStep={15}
            ampm={false}
            closeOnSelect
            PopperProps={{
              sx: {
                ...DatePickerSxObject,
                '& .MuiPickersArrowSwitcher-root .MuiButtonBase-root': { transform: shouldUseRtl ? 'rotate(180deg)' : '' }
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Calendar />
                </InputAdornment>
              ),
            }}
            componentsProps={{
              actionBar: {
                actions: ['today', 'clear'],
              },
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => {
              inputProps!.placeholder = '';
              inputProps!.readOnly = false;
              inputProps!.onKeyDown = onKeyDown;
              inputProps!.sx = { 
                ...inputProps!.sx,
                '&.MuiInputBase-input': { textAlign: shouldUseRtl ? 'end !important' : 'start' }
              };

              return (
                <DatePickerTextField
                  label={props.title}
                  labelSx={{
                    '&.MuiFormLabel-root.MuiInputLabel-root': {
                      fontSize: '18px !important',
                      ineHeight: '24px',
                      color: props.disabled
                        ? theme.palette.colors.neutral[200]
                        : theme.palette.colors.neutral[50],
                      top: '4px',
                    },
                  }}
                  inputRef={inputRef}
                  inputProps={inputProps}
                  InputProps={InputProps}
                  disabled={props.disabled}
                />
              );
            }}
          />
        </DatePickerLocalizationProvider>
      </Box>
    </>
  );
};
