import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { StyledSearchableDropdownProps, Dropdown, DropdownOption } from '@ui-components/ui-library';
import { BarChart, ChartsAxisContentProps } from '@mui/x-charts';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { ChartSxObject, DropdownListItemLongContentSxObject } from '../../../shared/utils/sxStyleObjects';
import { useTranslation } from 'react-i18next';
import {
  InstalledBaseCountByCategoryViewModel,
  getDefaultInstalledBaseCountByCategory,
} from '../../../models/dashboard/InstalledBaseCountByMonthViewModel';
import { isEqual } from 'lodash';
import TuneIcon from '@mui/icons-material/Tune';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import { dashboardService } from '../../../services/DashboardService';
import { EquipmentCategoryLabels } from '../../../shared/enums/EquipmentCategories';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { AuthenticatedUser } from '../../../users/models/user';
import { InstalledBaseFilterModel, getDefaultInstalledBaseFilterModel, getOnloadInstallBaseFilterModel } from '../../models/InstalledBaseFilterModel';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { ALL_TEXT, NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { Restricted } from '../../../components/elements/restricted/Restricted';
import { userIsGlobalAdmin, userLanguageIsRtl } from '../../../stores/user/usersSlice';
import { showErrors } from '../../../stores/alert/alertSlice';
import { CustomChartTooltipContent } from '../customChartTooltipContent/CustomChartTooltipContent';
import { CustomChartLegendComponent } from '../customChartLegendComponent/CustomChartLegendComponent';
import { setIsLoading } from '../../../stores/layout/layoutSlice';

export const InstalledBaseCreationDashboard = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUser: AuthenticatedUser = useAppSelector((state) => state.User.currentUser);
  const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));
  const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));
  const organizationOptions: OrganizationOptionModel[] = useAppSelector(state => state.Dashboard.organizationOptions);

  const customerCenterOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.CustomerCenter), [organizationOptions]);
  const distributorOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.Distributor), [organizationOptions]);

  const [installedBaseByCategory, setInstalledBaseByCategory] = useState<InstalledBaseCountByCategoryViewModel>(getDefaultInstalledBaseCountByCategory());

  const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<InstalledBaseFilterModel>(getDefaultInstalledBaseFilterModel());
  const [notAppliedFilters, setNotAppliedFilters] = useState<any>({});

  const getInstalledBaseCountByCategory = async (filters: InstalledBaseFilterModel) => {
    dispatch(setIsLoading(true));
    const installedBaseByCategoryReponse = await dashboardService.GetInstalledBaseCountByCategory(filters);

    if (installedBaseByCategoryReponse.isSuccess) {
      setInstalledBaseByCategory(installedBaseByCategoryReponse.result!);
    } else {
      dispatch(showErrors(installedBaseByCategoryReponse?.errorModel?.errors));
    }
    dispatch(setIsLoading(false));
  };

  const closeFilterModal = () => {
    setFilterModalIsOpen(false);
    setNotAppliedFilters({});
  };

  const handleFilterChange = (propName: keyof InstalledBaseFilterModel, value: any): void => {
    setNotAppliedFilters({ ...notAppliedFilters, [propName]: value });
  };

  const handleCustomerCenterChange = (value: number | undefined) => {
    if (value) {
      const childrenOrganizations = organizationOptions.filter(oo => oo.parentOrganizationId === value);
      const currentSelectedDistributorId = notAppliedFilters['distributorOrganizationId'] || appliedFilters['distributorOrganizationId'];
      const shouldClearDistributor: boolean = currentSelectedDistributorId &&
        !childrenOrganizations.some(co => co.id === currentSelectedDistributorId);

      if (shouldClearDistributor) {
        setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value, distributorOrganizationId: undefined });
      } else {
        setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value });
      }

    } else {
      setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: undefined });
    }
  };

  const handleDistributorOrganizationChange = (value: number | undefined) => {
    if (value) {
      const selectedDistributor = distributorOptions.find(o => o.id === value);
      const customerCenterOrganizations = customerCenterOptions.filter(cc => cc.id === selectedDistributor?.parentOrganizationId);

      const currentCustomerCenterId = notAppliedFilters['customerCenterId'] || appliedFilters['customerCenterId'];
      const shouldClearCustomerCenter: boolean = currentCustomerCenterId &&
        !customerCenterOrganizations.some(cc => cc.id === currentCustomerCenterId);

      if (shouldClearCustomerCenter) {
        setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: value, customerCenterId: undefined });
      } else {
        setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: value });
      }

    } else {
      setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: undefined });
    }
  };

  const applyFilters = async (): Promise<void> => {
    setAppliedFilters({ ...appliedFilters, ...notAppliedFilters });
    closeFilterModal();
    await getInstalledBaseCountByCategory({ ...appliedFilters, ...notAppliedFilters });
  };

  const clearFilters = async () => {
    await getInstalledBaseCountByCategory(getOnloadInstallBaseFilterModel());
    setAppliedFilters(getDefaultInstalledBaseFilterModel());
  };

  const getSelectedDropdownOption = (propName: keyof InstalledBaseFilterModel, optionsCollection: any[]): DropdownOption | null | undefined => {
    if ((!appliedFilters[propName as keyof typeof appliedFilters] && !notAppliedFilters[propName as keyof typeof notAppliedFilters]) ||
      (propName in notAppliedFilters && notAppliedFilters[propName as keyof typeof notAppliedFilters] === undefined)) {
      return null;
    }

    if (notAppliedFilters[propName as keyof typeof notAppliedFilters]) {
      return optionsCollection.find(et => et.id === notAppliedFilters[propName as keyof typeof notAppliedFilters]);
    } else if (appliedFilters[propName as keyof typeof appliedFilters]) {
      return optionsCollection.find(o => o.id === appliedFilters[propName as keyof typeof appliedFilters]);
    }
  };

  const applyButtonIsDisabled = (): boolean => {
    const filtersAreNotChanged: boolean = Object.keys(notAppliedFilters).length === 0 || !Object.keys(notAppliedFilters).some(naf =>
      appliedFilters[naf as keyof typeof appliedFilters] instanceof Date ?
        !(moment(appliedFilters[naf as keyof typeof appliedFilters]).isSame(notAppliedFilters[naf], 'day')) :
        !isEqual(appliedFilters[naf as keyof typeof appliedFilters], notAppliedFilters[naf]));

    if (isGlobalAdmin) {
      const customerCenterIsSelected: boolean = notAppliedFilters['customerCenterId'] !== undefined ||
        (appliedFilters['customerCenterId'] !== undefined && !('customerCenterId' in notAppliedFilters));

      const distributorIsSelected: boolean = notAppliedFilters['distributorOrganizationId'] !== undefined ||
        (appliedFilters['distributorOrganizationId'] !== undefined && !('distributorOrganizationId' in notAppliedFilters));

      return filtersAreNotChanged || (!customerCenterIsSelected && !distributorIsSelected);
    }

    return filtersAreNotChanged;
  };

  const hasAppliedFilters: boolean =
    appliedFilters.customerCenterId !== undefined ||
    appliedFilters.distributorOrganizationId !== undefined ||
    !moment(appliedFilters.from).isSame(moment().add(-1, 'y').toDate(), 'day') ||
    !moment(appliedFilters.to).isSame(moment().toDate(), 'day');

  const labels: string[] = Object.entries(EquipmentCategoryLabels).map(
    ([_, value]) => {
      return t(value);
    }
  );

  useEffect(() => {
    getInstalledBaseCountByCategory(getOnloadInstallBaseFilterModel());
  }, []);

  const stackOrder = 'descending';

  const seriesLabels = {
    byDistributors: t('By Distributors'),
    byCustomerCenters: t('By Customer Centers'),
    byOthers: t('By Others'),
  };

  const seriesColors = {
    byDistributors: '#02b2af',
    byCustomerCenters: '#F26522',
    byOthers: '#237bd3',
  };

  const legendData = [
    { label: seriesLabels.byDistributors, color: seriesColors.byDistributors },
    { label: seriesLabels.byCustomerCenters, color: seriesColors.byCustomerCenters },
    { label: seriesLabels.byOthers, color: seriesColors.byOthers }
  ];

  const customerCenterDropdownOptions = useMemo(() => {
    if (customerCenterOptions && customerCenterOptions.length > 0) {
      const distributorIsSelected: boolean = notAppliedFilters['distributorOrganizationId'] !== undefined ||
        (appliedFilters['distributorOrganizationId'] !== undefined && !('distributorOrganizationId' in notAppliedFilters));

      if (distributorIsSelected) {
        if (notAppliedFilters['distributorOrganizationId'] !== undefined) {
          const selectedDistributor = distributorOptions.find(dop => dop.id === notAppliedFilters['distributorOrganizationId']);

          return customerCenterOptions
            .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
            .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        } else if (appliedFilters['distributorOrganizationId'] !== undefined) {
          const selectedDistributor = distributorOptions.find(dop => dop.id === appliedFilters['distributorOrganizationId']);

          return customerCenterOptions
            .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
            .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        }
      }

      return customerCenterOptions.map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
    }

    return [];
  }, [customerCenterOptions, distributorOptions, appliedFilters, notAppliedFilters]);

  const distributorDropdownOptions = useMemo(() => {
    if (distributorOptions && distributorOptions.length > 0) {
      const customerCenterIsSelected: boolean = notAppliedFilters['customerCenterId'] !== undefined ||
        (appliedFilters['customerCenterId'] !== undefined && !('customerCenterId' in notAppliedFilters));

      if (customerCenterIsSelected) {
        if (notAppliedFilters['customerCenterId'] !== undefined) {
          return distributorOptions
            .filter(dop => dop.parentOrganizationId === notAppliedFilters['customerCenterId'])
            .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
        } else if (appliedFilters['customerCenterId'] !== undefined) {
          return distributorOptions
            .filter(dop => dop.parentOrganizationId === appliedFilters['customerCenterId'])
            .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
        }
      }

      return distributorOptions.map(et => ({ id: et.id, label: t(et.name), value: et.id }));
    }

    return [];
  }, [distributorOptions, appliedFilters, notAppliedFilters]);

  return (
    <>
      <Box dir={'ltr'}>
        <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'} dir={shouldUseRtl ? 'rtl' : 'ltr'}>
          <Box fontSize={18} flex={2} textAlign={'left'}>{t('Installed Base creation')}</Box>

          <Tooltip
            title={t('Filter')}
          >
            <IconButton id={'filter'} onClick={() => setFilterModalIsOpen(true)}>
              <TuneIcon />
            </IconButton>
          </Tooltip>

          {
            hasAppliedFilters &&
            <Tooltip
              title={t('Clear filters')}
            >
              <IconButton id={'clear_filters'} onClick={clearFilters}>
                <FilterIcon />
              </IconButton>
            </Tooltip>
          }
        </Box>

        <CustomChartLegendComponent legendData={legendData} />
        <BarChart
          xAxis={[
            {
              id: 'installedBaseByCategory',
              data: labels,
              scaleType: 'band',
              tickLabelInterval: () => true,
            },
          ]}
          series={[
            {
              data: [
                installedBaseByCategory.compressorsCountCreatedByDistributors || 0,
                installedBaseByCategory.airTreatmentCountCreatedByDistributors || 0,
                installedBaseByCategory.othersCountCreatedByDistributors || 0
              ],
              stack: 'A',
              color: seriesColors.byDistributors,
              stackOrder: stackOrder
            },
            {
              data: [
                installedBaseByCategory.compressorsCountCreatedByCustomerCenters || 0,
                installedBaseByCategory.airTreatmentCountCreatedByCustomerCenters || 0,
                installedBaseByCategory.othersCountCreatedByCustomerCenters || 0
              ],
              stack: 'A',
              color: seriesColors.byCustomerCenters,
              stackOrder: stackOrder
            },
            {
              data: [
                installedBaseByCategory.compressorsCountCreatedByOthers || 0,
                installedBaseByCategory.airTreatmentCountCreatedByOthers || 0,
                installedBaseByCategory.othersCountCreatedByOthers || 0
              ],
              stack: 'A',
              color: seriesColors.byOthers,
              stackOrder: stackOrder
            },
          ]}
          sx={ChartSxObject}
          tooltip={{ trigger: 'axis' }}
          slots={{
            axisContent: (props: ChartsAxisContentProps) =>
              <CustomChartTooltipContent
                seriesLabels={Object.values(seriesLabels)}
                axisValue={props.axisValue}
                series={props.series}
                dataIndex={props.dataIndex}
              />
          }}
          slotProps={{
            legend: {
              hidden: true
            }
          }}
          margin={{
            top: 20,
            bottom: 30
          }}
        />
      </Box>

      <FilterModal
        isOpen={filterModalIsOpen}
        disableApply={applyButtonIsDisabled()}
        onClose={closeFilterModal}
        onApply={applyFilters}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <DatePicker
              id={'from'}
              inputFormat={currentUser.preferences.dateFormat}
              onChange={(value) => value ? handleFilterChange('from', moment(value).startOf('d').toDate()) : undefined}
              value={notAppliedFilters['from'] || appliedFilters['from']}
              disableFuture
              actions={['today']}
              title={t('From')}
              maxDate={notAppliedFilters['to'] || appliedFilters['to']}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              id={'to'}
              inputFormat={currentUser.preferences.dateFormat}
              onChange={(value) => value ? handleFilterChange('to', moment(value).endOf('d').toDate()) : undefined}
              value={notAppliedFilters['to'] || appliedFilters['to']}
              disableFuture
              actions={['today']}
              title={t('To')}
              minDate={notAppliedFilters['from'] || appliedFilters['from']}
            />
          </Grid>

          <Restricted to={'canFilterByCustomerCenter'}>
            <Grid item xs={12} md={6}>
              <Dropdown<StyledSearchableDropdownProps>
                label={t('Customer Center')}
                options={customerCenterDropdownOptions}
                value={getSelectedDropdownOption('customerCenterId', customerCenterDropdownOptions)}
                onChange={(e, item) => handleCustomerCenterChange(item ? Number((item as DropdownOption).value) : undefined)}
                variant='searchable'
                usePopper
                disabled={customerCenterDropdownOptions.length === 0}
                noOptionsText={t(NO_OPTIONS_TEXT)}
                placeholder={t(ALL_TEXT)}
              />
            </Grid>
          </Restricted>

          <Grid item xs={12} md={6}>
            <Dropdown<StyledSearchableDropdownProps>
              label={t('Distributor Organization')}
              options={distributorDropdownOptions}
              value={getSelectedDropdownOption('distributorOrganizationId', distributorDropdownOptions)}
              onChange={(e, item) => handleDistributorOrganizationChange(item ? Number((item as DropdownOption).value) : undefined)}
              variant='searchable'
              usePopper
              disabled={distributorDropdownOptions.length === 0}
              noOptionsText={t(NO_OPTIONS_TEXT)}
              optionListStyles={DropdownListItemLongContentSxObject}
              placeholder={t(ALL_TEXT)}
            />
          </Grid>

        </Grid>
      </FilterModal>
    </>
  );
};