import moment from 'moment-timezone';
import { ServiceExecutionActivityFormModel } from './serviceExecutionActivityModel';
import { ServiceExecutionAttachment } from './serviceExecutionAttachment';
import { getDefaultServiceExecutionCustomerDetail, ServiceExecutionCustomerDetailModel } from './serviceExecutionCustomerDetailModel';
import { getDefaultServiceExecutionEquipmentDetail, ServiceExecutionEquipmentDetailModel } from './serviceExecutionEquipmentDetailModel';
import { getDefaultServiceExecutionFutureRecommendation, ServiceExecutionFutureRecommendationModel } from './serviceExecutionFutureRecommendationModel';
import { ServiceExecutionStatus } from './serviceExecutionStatus';
import { getDefaultServiceExecutionWorkComment, ServiceExecutionWorkCommentModel } from './serviceExecutionWorkCommentModel';
import { getDefaultServiceExecutionSigner, ServiceExecutionSigner } from './serviceExecutionSigner';


export type ServiceExecutionFormModel = {
    id: number;
    status: ServiceExecutionStatus;
    createdOn: Date;
    servicePlanningStartDate: Date;
    customerDetail: ServiceExecutionCustomerDetailModel;
    equipmentDetail: ServiceExecutionEquipmentDetailModel;
    workComment: ServiceExecutionWorkCommentModel,
    futureRecommendation: ServiceExecutionFutureRecommendationModel,
    serviceOrderDescription: string,
    serviceExecutionActivities: ServiceExecutionActivityFormModel[];
    serviceExecutionAttachments: ServiceExecutionAttachment[];
    serviceExecutionSigner: ServiceExecutionSigner;
    personOfInterestToggle: boolean;
    completionConformation: boolean;
};

export const getDefaultServiceExecution = (): ServiceExecutionFormModel => {
    return {
        id: 0,
        status: ServiceExecutionStatus.InProgress,
        createdOn: moment().toDate(),
        servicePlanningStartDate: moment().toDate(),
        customerDetail: getDefaultServiceExecutionCustomerDetail(),
        equipmentDetail: getDefaultServiceExecutionEquipmentDetail(),
        workComment: getDefaultServiceExecutionWorkComment(),
        futureRecommendation: getDefaultServiceExecutionFutureRecommendation(),
        serviceOrderDescription: '',
        serviceExecutionActivities: [],
        serviceExecutionAttachments: [],
        serviceExecutionSigner: getDefaultServiceExecutionSigner(),
        personOfInterestToggle: true,
        completionConformation: false,
    };
};

export type ServiceExecutionCreateModel = {
    servicePlanningId: number;
};

export type ServiceEquipmentMeasuredDataModel = {
    visitReportId: number;
    runningHours: number;
    loadedHours: number | undefined;
}

export type ServiceExecutionFinalizationModel = {
    serviceExecutionId: number;
    language: string;
    signerFirstName: string;
    signerLastName: string;
    signatureBase64String: string;
    poiReceiverToggle: boolean;
}