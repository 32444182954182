export interface IServiceOrderFormProps {
	formIsOpen: boolean;
	serviceOrderId: number | undefined;
	handleClose: () => void;
	getServiceOrders(): Promise<void>;
}

export enum ServiceOrderFormStep {
	GENERALINFO = 0,
	CUSTOMERINFO = 1,
	EQUIPMENTINFO = 2,
}