import React, { useEffect, useRef, useState } from 'react';
import { GridColumns, GridValidRowModel, GridFilterModel, GridSortModel, GridLocaleText, GridRowClassNameParams, getDefaultGridFilterModel } from '@mui/x-data-grid-pro';
import { DataGrid } from '@ui-components/ui-library';
import { useTranslation } from 'react-i18next';

interface DataGridOverviewProps extends React.ComponentProps<typeof DataGrid> {
	columns: GridColumns<GridValidRowModel>;
	rows: GridValidRowModel[];
	gridKey?: number;
	isLoading?: boolean;
	pageSize?: number;
	getRowClassName?: (params: GridRowClassNameParams<GridValidRowModel>) => string;
	clearFilters?: boolean | undefined;
	containeraAutoHeight?: boolean | undefined;
}

export const DataGridOverview = (props: DataGridOverviewProps): JSX.Element => {
	const { t } = useTranslation();

	const [filterModel, setFilterModel] = useState<GridFilterModel>();
	const [sortModel, setSortModel] = useState<GridSortModel>();

	const defaultLocaleText: Partial<GridLocaleText> = {
		// Root
		noRowsLabel: t('No rows'),
		noResultsOverlayLabel: t('No results found.'),

		// Columns selector toolbar button text
		toolbarColumns: t('Columns'),
		toolbarColumnsLabel: ('Select columns'),

		// Filters toolbar button text
		toolbarFilters: t('Filters'),
		toolbarFiltersLabel: t('Show filters'),
		toolbarFiltersTooltipHide: t('Hide filters'),
		toolbarFiltersTooltipShow: t('Show filters'),
		toolbarFiltersTooltipActive: (count) =>
			count !== 1 ? t('{{filters_count}} active filters', { filters_count: count }) : t('{{filters_count}} active filter', { filters_count: count }),

		// Quick filter toolbar field
		toolbarQuickFilterPlaceholder: t('Search…'),
		toolbarQuickFilterLabel: t('Search'),
		toolbarQuickFilterDeleteIconLabel: t('Clear'),

		// Export selector toolbar button text
		toolbarExport: t('Export'),
		toolbarExportLabel: t('Export'),
		toolbarExportCSV: t('Download as CSV'),
		toolbarExportPrint: t('Print'),
		toolbarExportExcel: t('Download as Excel'),

		// Columns panel text
		columnsPanelTextFieldLabel: t('Find column'),
		columnsPanelTextFieldPlaceholder: t('Column title'),
		columnsPanelDragIconLabel: t('Reorder column'),
		columnsPanelShowAllButton: t('Show all'),
		columnsPanelHideAllButton: t('Hide all'),

		// Filter panel text
		filterPanelAddFilter: t('Add filter'),
		filterPanelDeleteIconLabel: t('Delete'),
		filterPanelOperators: t('Operator'),
		filterPanelOperatorAnd: t('And'),
		filterPanelOperatorOr: t('Or'),
		filterPanelColumns: t('Columns'),
		filterPanelInputLabel: t('Value'),
		filterPanelInputPlaceholder: t('Filter value'),

		// Filter operators text
		filterOperatorContains: t('contains'),
		filterOperatorEquals: t('equals'),
		filterOperatorStartsWith: t('starts with'),
		filterOperatorEndsWith: t('ends with'),
		filterOperatorIs: t('is'),
		filterOperatorNot: t('is not'),
		filterOperatorAfter: t('is after'),
		filterOperatorOnOrAfter: t('is on or after'),
		filterOperatorBefore: t('is before'),
		filterOperatorOnOrBefore: t('is on or before'),
		filterOperatorIsEmpty: t('is empty'),
		filterOperatorIsNotEmpty: t('is not empty'),
		filterOperatorIsAnyOf: t('is any of'),

		// Filter values text
		filterValueAny: t('any'),
		filterValueTrue: t('true'),
		filterValueFalse: t('false'),

		// Column menu text
		columnMenuLabel: t('Menu'),
		columnMenuShowColumns: t('Show columns'),
		columnMenuFilter: t('Filter'),
		columnMenuHideColumn: t('Hide column'),
		columnMenuUnsort: t('Unsort'),
		columnMenuSortAsc: t('Sort by ASC'),
		columnMenuSortDesc: t('Sort by DESC'),

		// Column header text
		columnHeaderFiltersTooltipActive: (count) =>
			count !== 1 ? t('{{filters_count}} active filters', { filters_count: count }) : t('{{filters_count}} active filter', { filters_count: count }),
		columnHeaderFiltersLabel: t('Show filters'),
		columnHeaderSortIconLabel: t('Sort'),

		// Rows selected footer text
		footerRowSelected: (count) =>
			count !== 1
				? t('{{rows_count}} rows selected', { rows_count: count })
				: t('{{rows_count}} row selected', { rows_count: count }),

		// Total row amount footer text
		footerTotalRows: t('Total Rows:'),

		// Total visible row amount footer text
		footerTotalVisibleRows: (visibleCount, totalCount) =>
			t('{{visible_count}} of {{total_count}}', { visible_count: visibleCount, total_count: totalCount }),

		// Checkbox selection text
		checkboxSelectionHeaderName: t('Checkbox selection'),
		checkboxSelectionSelectAllRows: t('Select all rows'),
		checkboxSelectionUnselectAllRows: t('Unselect all rows'),
		checkboxSelectionSelectRow: t('Select row'),
		checkboxSelectionUnselectRow: t('Unselect row'),

		// Boolean cell text
		booleanCellTrueLabel: t('yes'),
		booleanCellFalseLabel: t('no'),

		// Actions cell more text
		actionsCellMore: t('more'),

		// Column pinning text
		pinToLeft: t('Pin to left'),
		pinToRight: t('Pin to right'),
		unpin: t('Unpin'),

		// Tree Data
		treeDataGroupingHeaderName: t('Group'),
		treeDataExpand: t('see children'),
		treeDataCollapse: t('hide children'),

		// Grouping columns
		groupingColumnHeaderName: t('Group'),
		groupColumn: (name) => t('Group by {{column_name}}', { column_name: name }),
		unGroupColumn: (name) => t('Stop grouping by {{column_name}}', { column_name: name }),

		// Master/detail
		detailPanelToggle: t('Detail panel toggle'),
		expandDetailPanel: t('Expand'),
		collapseDetailPanel: t('Collapse'),

		// Used core components translation keys
		MuiTablePagination: {
			labelDisplayedRows: ({ from, to, count }) =>
				t('{{from_count}}–{{to_count}} of {{total_count}}', { from_count: from, to_count: to, total_count: count }),
			nextIconButtonProps: {
				title: t('Go to next page')
			},
			backIconButtonProps: {
				title: t('Go to previous page')
			}
		},

		// Row reordering text
		rowReorderingHeaderName: t('Row reordering'),

		// Aggregation
		aggregationMenuItemHeader: t('Aggregation'),
		aggregationFunctionLabelSum: t('sum'),
		aggregationFunctionLabelAvg: t('avg'),
		aggregationFunctionLabelMin: t('min'),
		aggregationFunctionLabelMax: t('max'),
		aggregationFunctionLabelSize: t('size'),
	};

	useEffect(() => {
		if (props.clearFilters !== undefined) {
			setFilterModel(getDefaultGridFilterModel());
			setSortModel([]);
		}
	}, [props.clearFilters]);

	return (
		<DataGrid
			rows={props.rows}
			columns={props.columns}
			loading={props.isLoading}
			pagination={props.pagination}
			pageSize={props.pageSize ?? 15}
			getEstimatedRowHeight={() => 60}
			getRowHeight={() => 'auto'}
			autoHeight
			containerHeight={props.containeraAutoHeight ? 'auto' : undefined}
			getRowClassName={props.getRowClassName}
			disableSelectionOnClick
			filterModel={filterModel}
			onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
			sortModel={sortModel}
			onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
			hideFooterSelectedRowCount={props.hideFooterSelectedRowCount}
			hideFooterRowCount={props.hideFooterRowCount}
			onPageChange={() => window.scrollTo(0, 0)}
			componentsProps={{
				panel: {
					sx: { '&.MuiDataGrid-panel': { zIndex: '1000 !important' }},
				},
				columnMenu: {
					sx: {
						'& .MuiMenuItem-root': { fontFamily: 'system-ui', fontSize: '14px' },
					}
				},
				filterPanel: {
					sx: {
						'& .MuiFormControl-root': { width: 'auto !important' },
						'& .MuiFormLabel-root.MuiInputLabel-root': { fontFamily: 'system-ui !important' },
						'& .MuiInputBase-root': { fontFamily: 'system-ui !important', fontSize: '15px' },
						'& .MuiButtonBase-root.MuiButton-root': { fontFamily: 'system-ui !important' },
					}
				},
				columnsPanel: {
					sx: {
						'& .MuiTypography-root': { fontSize: '15px' },
					}
				},
				footer: {
					sx: {
						'& .MuiTablePagination-displayedRows': { fontFamily: 'system-ui', my: 'auto' }
					}
				}
			}}
			sx={{
				'&.MuiDataGrid-root .MuiDataGrid-cell': { py: '15px' },
				'&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': { lineHeight: '18px' },
			}}

			localeText={{
				...defaultLocaleText,
				...props.localeText
			}}
		/>
	);
};