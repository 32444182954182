import React from 'react';
import { useTranslation } from 'react-i18next';
import { IServiceOrderInformationSectionProps } from './IServiceOrderInformationSectionProps';
import { Box, Typography } from '@mui/material';
import { Title } from '../../../components/form/title/Title';
import { CardTextContainer } from '../../../shared/utils/sxStyleObjects';

export const ServiceOderInformationSection = (props: IServiceOrderInformationSectionProps): JSX.Element => {
    const { t } = useTranslation();

    const { serviceExecution, showHeader } = props;

    return (
        <Box marginTop={'1rem'}>
            {
                showHeader && <Title variant='h4' align='left' text={t('Service Order Description')} />
            }
            
            <CardTextContainer>
                <Typography sx={{ fontSize: '14px' }}>
                    {serviceExecution.serviceOrderDescription}
                </Typography>
            </CardTextContainer>
        </Box>
    );
};