
export type AddressFormModel = {
    id: number;
    city: string;
    postalCode: string;
    addressTypeId: number;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    countryId: number;
};

export type AddressTypeFormModel = {
    id: number;
    key: string;
};

export const getDefaultAddress = (): AddressFormModel => {
    return {
        id: 0,
        city: '',
        postalCode: '',
        // Hardcoded address type until decided if to be removed
        addressTypeId: 1,
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        countryId: 0,
    };
};
