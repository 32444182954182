import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { IUploadProgressComponentProps } from './IUploadProgressComponentProps';
import { ImportErrorViewModel, ImportFileStatusMessage, ImportFileViewModel, ImportProcessStatuses, ImportRecordViewModel } from '../../models/ImportFileViewModel';
import styles from './UploadProgressComponent.module.scss';
import { useAppSelector } from '../../hooks/reduxHooks';
import { AuthenticatedUser } from '../../users/models/user';
import { calculatePercentage } from '../../shared/utils/Utils';
import { Chip, ChipType, Icon } from '@ui-components/ui-library';
import { Box, ButtonProps, IconButton, LinearProgress } from '@mui/material';
import { StyledGridCellContent } from '../../shared/utils/sxStyleObjects';
import {
    GridRenderCellParams,
    useGridApiContext,
    GridRowsProp,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
    GridEnrichedColDef,
    GridRowClassNameParams,
    GridValidRowModel
} from '@mui/x-data-grid-pro';
import { DataGridTree } from '../datagrid/DataGridTree';
import { userLanguageIsRtl } from '../../stores/user/usersSlice';

export const isNavigationKey = (key: string) =>
    key === 'Home' ||
    key === 'End' ||
    key.indexOf('Arrow') === 0 ||
    key.indexOf('Page') === 0 ||
    key === ' ';

const CustomGroupingCell = (props: GridRenderCellParams) => {
    const { id, field, rowNode, row } = props;

    const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));

    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleKeyDown: ButtonProps['onKeyDown'] = (event) => {
        if (event.key === ' ') {
            event.stopPropagation();
        }
        if (isNavigationKey(event.key) && !event.shiftKey) {
            apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
        }
    };

    const handleClick: ButtonProps['onClick'] = (event) => {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    const getRecordChip = (processStatus: ImportProcessStatuses): ChipType => {
        switch (processStatus) {
            case ImportProcessStatuses.Success:
                return ChipType.BACKGROUND_GREEN;
            case ImportProcessStatuses.Error:
                return ChipType.BACKGROUND_RED;
            case ImportProcessStatuses.Pending:
                return ChipType.BACKGROUND_YELLOW;
        }
    };

    const generateRecordMessage = (name: string, processStatus: ImportProcessStatuses): string => {
        let message = `Customer: ${name}`;

        if (processStatus === ImportProcessStatuses.Success)
            message = message.concat(' created sucessfully');
        else
            message = message.concat(' is being processed');

        return message;
    };

    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                {row.processedRows !== undefined || filteredDescendantCount > 0 ? (
                    <>
                        <IconButton
                            aria-label="chevron"
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                                marginRight: '0.5rem'
                            }}
                            onClick={handleClick}
                            onKeyDown={handleKeyDown}
                            tabIndex={-1}
                        >
                            <Icon type={rowNode.childrenExpanded ? 'expand_more' : (shouldUseRtl ? 'chevron_left' : 'chevron_right')} />
                        </IconButton>
                        <span>{row.originalFileName}</span>
                    </>
                ) : (
                    <Box className={styles.record_row} key={row.id}>
                        <Box className={styles.chip_container}>
                            <Chip
                                label={`${ImportProcessStatuses[row.customerProcessStatus]}`}
                                type={getRecordChip(row.customerProcessStatus)}
                            />
                        </Box>
                        <Box dir='ltr' className={styles.record_message}>
                            {
                                row.customerProcessStatus === ImportProcessStatuses.Success ||
                                    row.customerProcessStatus === ImportProcessStatuses.Pending
                                    ?
                                    generateRecordMessage(row.customerName, row.customerProcessStatus)
                                    :
                                    row.customerImportErrors?.map((em: { errorMessage: any; }) => em.errorMessage).join(', ')
                            }
                        </Box>

                    </Box>
                )}
            </div>
        </Box>
    );
};

interface Row {
    path: string[];
    id: string;
    createdOn?: Date;
    processedRows?: number;
    rowCount?: number;
    originalFileName: string;
    showDate?: boolean;
    showProgress?: boolean;
    customerName?: string;
    customerProcessStatus?: ImportProcessStatuses;
    customerImportErrors?: ImportErrorViewModel[];
    fileStatusMessage?: string;
    customerImportRecords?: ImportRecordViewModel[];
    applyCustomStyle?: boolean;
}

export const UploadProgressComponent = (props: IUploadProgressComponentProps): JSX.Element => {
    const { importedFiles } = props;
    const { t } = useTranslation();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const [dataRows, setDataRows] = useState<GridRowsProp<Row>>([]);

    useEffect(() => {
        if (importedFiles) {
            let _dataRows: Row[] = [];

            let fileIndex = 0;
            importedFiles.forEach(f => {
                const fileIdentifier = 'file-identifier-' + f.fileIdentifier + '-' + fileIndex;

                const rowTitle: Row = {
                    path: [fileIdentifier],
                    id: fileIdentifier,
                    createdOn: f.createdOn,
                    processedRows: f.processedRows,
                    rowCount: f.rowCount,
                    originalFileName: f.originalFileName,
                    fileStatusMessage: f.processStatusMessage,
                    customerImportRecords: f.customerImportRecords,
                    applyCustomStyle: true,
                    showDate: true,
                    showProgress: true
                };

                _dataRows.push(rowTitle);

                f.customerImportRecords.forEach(r => {
                    const recordId = fileIdentifier + '-record-id-' + r.recordId.toString();

                    const rowExpand: Row = {
                        id: recordId,
                        path: [fileIdentifier, recordId],
                        originalFileName: f.originalFileName,
                        customerName: r.name,
                        customerProcessStatus: r.processStatus,
                        customerImportErrors: r.errors,
                    };

                    _dataRows.push(rowExpand);
                });

                fileIndex += 1;
            });

            setDataRows(_dataRows);
        }

    }, [importedFiles]);

    const getRowClass = (row: GridValidRowModel): string => {
        if (row.applyCustomStyle) {
            const hasErrors = row.fileStatusMessage?.startsWith(ImportFileStatusMessage.Failed) ||
                (row.customerImportRecords.length > 0
                    && row.customerImportRecords.some((cir: { errors: string | any[]; }) => cir.errors && cir.errors.length > 0));
            if (hasErrors) {
                return styles.error_row;
            }

            const allRowsAreProcessed = row.rowCount > 0 && row.processedRows === row.rowCount;
            if (allRowsAreProcessed) {
                return styles.success_row;
            }

            return styles.in_progress_row;
        }

        return '';
    };

    const createdOnBody = (params: GridRenderCellParams<ImportFileViewModel>) => {
        const formatedDate = moment(params.row.createdOn).format(currentUser.preferences.dateFormat);

        return (
            <>
                {params.row.showDate &&
                    <StyledGridCellContent>{formatedDate}</StyledGridCellContent>
                }
            </>
        );
    };

    const progressBody = (params: GridRenderCellParams<ImportFileViewModel>) => {
        const importFile = params.row;

        return (
            <Box marginBottom={'0.5rem'}>
                {importFile.showProgress &&
                    <>
                        <span>{importFile.processedRows} / {importFile.rowCount} {t('Processed')}</span>
                        <LinearProgress
                            variant="determinate"
                            value={calculatePercentage(importFile.processedRows, importFile.rowCount)}
                            className={styles.progress_bar}
                        />
                    </>}
            </Box>
        );
    };

    const columns: GridEnrichedColDef[] = [
        {
            field: 'createdOn', headerName: t('Created on'), sortable: true, filterable: true, pinnable: false, flex: 1,
            renderCell: (params: GridRenderCellParams<ImportFileViewModel>) => createdOnBody(params),
            description: t('Created on')
        },
        {
            field: 'processedRows', headerName: t('Progress'), sortable: false, filterable: false, pinnable: false, flex: 1.5, resizable: false,
            renderCell: (params: GridRenderCellParams<ImportFileViewModel>) => progressBody(params),
            description: t('Progress')
        },
        {
            field: 'fileStatusMessage', headerName: t('Details'), sortable: false, filterable: false, pinnable: false, flex: 1.5, resizable: false,
            description: t('Details')
        },
    ];

    return (
        <>
            <DataGridTree
                rows={dataRows}
                columns={columns}
                groupingColDef={{
                    headerName: t('File Name'),
                    description: t('File Name'),
                    flex: 6,
                    renderCell: (params) => <CustomGroupingCell {...params} />,
                }}
                getRowClassName={(params: GridRowClassNameParams<GridValidRowModel>) => getRowClass(params.row)}
                pagination
                pageSize={15}
            />
        </>
    );
};
