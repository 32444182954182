import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Box } from '@mui/material';
import { mapCustomerCenterOrganizationToUpdateModel, mapDistributorOrganizationToCreateModel, mapDistributorOrganizationToUpdateModel, OrganizationFormModel } from '../../../organizations/models/organizationModel';
import { OrganizationGeneralInfo } from '../organizationGeneralInfo/OrganizationGeneralInfo';
import { AddressSection } from '../../../components/form/addressSection/AddressSection';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../routes';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { DistributorDetails } from '../distributorDetails/DistributorDetails';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { organizationService } from '../../services/OrganizationService';
import { CustomerCenterDetail } from '../customerCenterDetails/CustomerCenterDetail';
import { getOrganizationFormValidationSchema, organizationGeneralInfoIsValid } from '../../services/OrganizationUtility';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { Stepper } from '@ui-components/ui-library';
import { IOrganizationFormProps, OrganizationFormStep } from './IOrganizationFormProps';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';

export const OrganizationForm = (props: IOrganizationFormProps): JSX.Element => {
    const { organization, organizationType, brands, erpTypes, countries, parentOrganizations, handleClose, getOrganizations } = props;
    const isDistributorType: boolean = organizationType === OrganizationType.Distributor;
    const isCustomerCenterType: boolean = organizationType === OrganizationType.CustomerCenter;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const isNewForm = organization.id === 0;
    const [step, setStep] = useState<number>(OrganizationFormStep.GENERALINFO);    

    const handleFormSubmit = async (currentOrganizationState: OrganizationFormModel) => {
        dispatch(setIsLoading(true));
        let actionResponse = undefined;
        if (isNewForm) {
            if (isDistributorType) {
                const createModel = mapDistributorOrganizationToCreateModel(currentOrganizationState);
                actionResponse = await organizationService.CreateDistributorOrganization(createModel);
            }
        } else {
            if (isDistributorType) {
                const updateModel = mapDistributorOrganizationToUpdateModel(currentOrganizationState);
                actionResponse = await organizationService.UpdateDistributorOrganization(updateModel);
            }
            else if (isCustomerCenterType) {
                const updateModel = mapCustomerCenterOrganizationToUpdateModel(currentOrganizationState);
                actionResponse = await organizationService.UpdateCustomerCenterOrganization(updateModel);
            }
        }

        if (actionResponse && actionResponse.isSuccess) {
            history.push(Routes.organizations.getPath());
            dispatch(showSuccess(t(SUCCESS_MESSAGE)));
            await getOrganizations();
        } else {
            dispatch(showErrors(actionResponse?.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <>
            <Box>
                <Box sx={{ padding: '20px 0' }}>
                    <Stepper
                        steps={[
                            { label: t('General Info and Company Details') },
                            { label: t('Address') }
                        ]}
                        activeStep={step}
                    />
                </Box>
                <Formik
                    initialValues={organization}
                    validationSchema={() => getOrganizationFormValidationSchema(isDistributorType, isCustomerCenterType)}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                >
                    {({ values, dirty, isSubmitting, isValid, handleSubmit }) => (

                        <Form noValidate>
                            <Box display={'flex'} flexDirection={'column'} gap={2}>
                                {
                                    step === OrganizationFormStep.GENERALINFO &&
                                    <Box mt={2}>
                                        <OrganizationGeneralInfo 
                                            isNewForm={isNewForm}
                                            type={organizationType}
                                            brands={brands}
                                            parentOrganizations={parentOrganizations}
                                        />
                                    </Box>
                                }
                                {
                                    step === OrganizationFormStep.GENERALINFO && isDistributorType &&
                                    <DistributorDetails isNewForm={isNewForm} erpTypes={erpTypes} countries={countries} />
                                }
                                {
                                    step === OrganizationFormStep.GENERALINFO && isCustomerCenterType && !isNewForm &&
                                    <CustomerCenterDetail />
                                }
                                {
                                    step === OrganizationFormStep.ADDRESS &&
                                    <AddressSection countries={countries} />
                                }

                                {
                                    step === OrganizationFormStep.GENERALINFO &&
                                    <SaveSection
                                        validateNext={() => organizationGeneralInfoIsValid(values, isDistributorType, isCustomerCenterType)}
                                        handleCancel={handleClose}
                                        handleNext={() => setStep(OrganizationFormStep.ADDRESS)}
                                    />
                                }
                                {
                                    step === OrganizationFormStep.ADDRESS &&
                                    <SaveSection
                                        handleBack={() => setStep(OrganizationFormStep.GENERALINFO)}
                                        handleClose={handleClose}
                                        handleSubmit={handleSubmit}
                                        validateSubmit={() => dirty && isValid && !isSubmitting}
                                    />
                                }
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};
