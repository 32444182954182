import * as Yup from 'yup';
import { RequiredMessage, ShortValueMessage } from '../../shared/utils/validationMessages';
import { OrganizationFormModel } from '../models/organizationModel';

export const getOrganizationFormValidationSchema = (isDistributorType: boolean, isCustomerCenterType: boolean) => Yup.object().shape({
    name: Yup.string()
        .trim()
        .required(RequiredMessage)
        .min(2, ShortValueMessage),
    organizationBrandIds: Yup.array().min(1, RequiredMessage),
    parentOrganizationId: Yup.lazy(() => {
        if (isDistributorType) {
            return Yup.number().required(RequiredMessage).min(1, RequiredMessage);
        }

        return Yup.number().optional();
    }),
    distributorDetail: Yup.lazy(() => {
        if (isDistributorType) {
            return Yup.object().shape({
                erpTypeId: Yup.number().min(1, RequiredMessage),
                erpCustomerNumber: Yup.string()
                    .trim()
                    .required(RequiredMessage)
                    .min(2, ShortValueMessage),
                phoneNumber: Yup.string()
                    .trim()
                    .required(RequiredMessage)
                    .min(2, ShortValueMessage),
            });
        }

        return Yup.object().shape({});
    }),
    address: Yup.object().shape({
        countryId: Yup.number()
            .min(1, RequiredMessage),
        city: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        postalCode: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        addressLine1: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
    }),
    customerCenterDetail: Yup.lazy(() => {
        if (isCustomerCenterType) {
            return Yup.object().shape({
                famCode: Yup.string()
                    .trim()
                    .required(RequiredMessage)
                    .min(2, ShortValueMessage),
            });
        }

        return Yup.object().shape({});
    }),
});

const customerCenterFormGeneralInfoValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required()
        .min(2),
    organizationBrandIds: Yup.array().min(1),

    customerCenterDetail: Yup.object().shape({
        famCode: Yup.string()
            .trim()
            .required()
            .min(2),
    }),

    address: Yup.object().shape({
    }),
});

const distributorFormGeneralInfoValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .required()
        .min(2),
    organizationBrandIds: Yup.array().min(1),
    parentOrganizationId: Yup.number().required().min(1),

    distributorDetail: Yup.object().shape({
        erpTypeId: Yup.number().min(1),
        erpCustomerNumber: Yup.string()
            .trim()
            .required()
            .min(2),
        phoneNumber: Yup.string()
            .trim()
            .required()
            .min(2),
    }),

    address: Yup.object().shape({
        countryId: Yup.number().min(1),
    }),

});

export const organizationGeneralInfoIsValid = (organization: OrganizationFormModel, isDistributorType: boolean, isCustomerCenterType: boolean): boolean => {
    if (isCustomerCenterType) {
        return customerCenterFormGeneralInfoValidationSchema.isValidSync(organization);
    } else if (isDistributorType) {
        return distributorFormGeneralInfoValidationSchema.isValidSync(organization);
    }

    return false;
};
