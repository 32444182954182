import { InstalledBase } from '../equipments/models/installedBase';
import { ActionResult, api } from '../shared/utils/api';

class InstalledBasesService {
    async GetInstalledBaseForEquipmentValues(equipmentTypeId: number, size: number, inverterDrive: boolean | undefined): Promise<ActionResult<InstalledBase>> {
        const url = inverterDrive === undefined ? 
        `/installedBases/GetInstalledBaseForEquipmentValues?equipmentTypeId=${equipmentTypeId}&size=${size}` :
        `/installedBases/GetInstalledBaseForEquipmentValues?equipmentTypeId=${equipmentTypeId}&size=${size}&inverterDrive=${inverterDrive}`;
        
        return await api.get<InstalledBase>(url);
    }

    async GetInstalledBaseOptions(): Promise<ActionResult<InstalledBase[]>> {
        const url = '/installedBases/GetInstalledBaseOptions';
        
        return await api.get<InstalledBase[]>(url);
    }
}

export const insallBassesService = new InstalledBasesService();