import { FormikErrors } from 'formik';
import moment from 'moment-timezone';
import { ARABIC_SHORT_MONTHS } from './constants';

export const isNullOrUndefined = (input: any) => {
    return (input == null);
};

export const nullWhenEmptyOrUndefined = (input: string | null) => {
    if (isNullOrUndefined(input)) return null;
    if (input!.length === 0) return null;

    return input;
};

export const currencyRound = (input: number | null) => {
    if (isNullOrUndefined(input)) return input;

    return Math.round((input! + Number.EPSILON) * 100) / 100;
};

export const isStringInputRequired = (isRequired: boolean, isEnabled: boolean, value: string | null) => {
    return isRequired
        && isEnabled
        && (isNullOrUndefined(value)
            || !isNullOrUndefined(value) && value?.length == 0);
};

export const isSelectInputRequired = (isRequired: boolean, isEnabled: boolean, value: number | null) => {
    return isRequired
        && isEnabled
        && isNullOrUndefined(value);
};

export const isNumberInputRequired = (isRequired: boolean, isEnabled: boolean, value: number | null) => {
    return isRequired
        && isEnabled
        && isNullOrUndefined(value);
};

export const isDateInputRequired = (isRequired: boolean, isEnabled: boolean, value: Date | null) => {
    return isRequired
        && isEnabled
        && isNullOrUndefined(value);
};

export const isBlank = (str: string): boolean => {
    return (!str || /^\s*$/.test(str));
};

export const lengthIsLessThanTwoChars = (str: string): boolean => {
    return (!str || !/(\S){2,}/gm.test(str));
};

export const isValidEmail = (str: string): boolean => {
    return (!str || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm.test(str));
};

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const getRandomColor = (): string => {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
};

export const roundToNearestFutureHour = (initialDate: Date): Date => {
    const resultDate = moment(initialDate);
    resultDate.add(1, 'h').startOf('h');

    return resultDate.toDate();
};

export const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iPhone|Android/i.test(navigator.userAgent);
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    return isMobile || isTablet;
};

export const convertStringToByteArray = (string: string): Uint8Array => {
    return Uint8Array.from(atob(string), c => c.charCodeAt(0));
};

export const calculatePercentage = (partialValue: number, totalValue: number): number => {
    return (100 * partialValue) / totalValue;
};

export const truncate = (str: string, n: number): string => {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
};

export const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export const areObjectsEqualNonStrict = (objA: Record<string, any>, objB: Record<string, any>): boolean => {
    // Get the keys of both objects
    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    // Check if the number of keys is the same
    if (keysA.length !== keysB.length) {
        return false;
    }

    // Compare values for each key
    for (const key of keysA) {
        // Check if the key exists in both objects
        // eslint-disable-next-line no-prototype-builtins
        if (!objB.hasOwnProperty(key)) {
            return false;
        }

        // Compare the values for the current key
        const valueA = objA[key];
        const valueB = objB[key];

        // Use a deep comparison for objects and arrays
        if (typeof valueA == 'object' && typeof valueB == 'object') {
            if (!areObjectsEqualNonStrict(valueA, valueB)) {
                return false;
            }
        } else if (valueA != valueB) {
            return false;
        }
    }

    // If all keys and values are the same, the objects are equal
    return true;
};

export const validateForm = (validateFunction: () => Promise<FormikErrors<any>>, setTouchedFunction: (field: string, isTouched?: boolean) => Promise<void | FormikErrors<any>>) => {
    validateFunction().then((errors: any) => {
        const errorsFlattened = flatten(errors);
        
        Object.keys(errorsFlattened).forEach(key => {
            setTouchedFunction(key, true);
        });
    });
}

export const flatten = (input: any) => {
    if (Array.isArray(input)) {
        return flattenArray(input);
    } else if (typeof input === 'object' && input !== null) {
        return flattenObject(input);
    } else {
        return input;
    }
};

const flattenObject = (obj: Record<string, any>, parentKey = ''): Record<string, any> => {
    return Object.keys(obj).reduce((result, key) => {
        const value = obj[key];
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (Array.isArray(value)) {
            const nestedObj = flattenArray(value, newKey);
            return { ...result, ...nestedObj };
        } else if (typeof value === 'object' && value !== null) {
            const nestedObj = flattenObject(value, newKey);
            return { ...result, ...nestedObj };
        } else {
            return { ...result, [newKey]: value };
        }
    }, {});
};

const flattenArray = (arr: any[], parentKey = ''): Record<string, any> => {
    return arr.reduce((result, value, index) => {
        const key = parentKey ? `${parentKey}.${index}` : index.toString();

        if (Array.isArray(value)) {
            const nestedObj = flattenArray(value, key);
            return { ...result, ...nestedObj };
        } else if (typeof value === 'object' && value !== null) {
            const nestedObj = flattenObject(value, key);
            return { ...result, ...nestedObj };
        } else {
            return { ...result, [key]: value };
        }
    }, {});
};

export const truncateStringWithEllipsis = (inputString: string, maxNumberOfChars: number, shouldUseRtl?: boolean) => {
    const inputStringTrimmed = inputString.trim();

    if (inputStringTrimmed.length <= maxNumberOfChars) {
        // If the input string is within the limit, return it unchanged    
        return inputStringTrimmed;
    } 
    
    // If the input string is longer than the limit, truncate and add ellipsis
    const resultsArray = [ inputStringTrimmed.slice(0, maxNumberOfChars - 3).trim(), '...'];
    
    return shouldUseRtl ? resultsArray.reverse().join('') : resultsArray.join('');
};

export const stringNumericComparator = (v1: string, v2: string) => {
    return Math.sign(Number(v1) - Number(v2));
};

export const getLanguageCodeFromBrowserLanguage = () => {
    return navigator.language.split('-')[0];
};

export const changeApplicationMomentLocale = (locale: string) => {
    moment.updateLocale('ar-ly', {
      monthsShort: ARABIC_SHORT_MONTHS,
      week: {
        dow: 1,
      }
    });
    moment.locale(locale);
};