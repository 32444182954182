import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { CustomerFormModel, mapCustomerToCreateModel, mapCustomerToUpdateModel } from '../../models/customerModel';
import { mapPersonOfInterestToCreateModel, mapPersonOfInterestToUpdateModel, PersonOfInterestModel } from '../../models/personOfInterestModel';
import { customerService } from '../../services/CustomerService';
import { peopleOfInterestService } from '../../services/PeopleOfInterestService';
import { CustomerGeneralInfo } from '../customerGeneralInfo/CustomerGeneralInfo';
import { CustomerDetail } from '../customerDetails/CustomerDetail';
import { CustomerAddress } from '../customerAddress/CustomerAddress';
import { customerFormValidationSchema, customerGeneralInfoIsValid } from '../../services/CustomerUtility';
import { PeopleOfInterest } from '../peopleOfInterest/PeopleOfInterest';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { Stepper } from '@ui-components/ui-library';
import { ICustomerFormProps, CustomerFormStep } from './ICustomerFormProps';
import { PersonOfInterestForm } from '../personOfInterestForm/PersonOfInterestForm';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { isEqual } from 'lodash';
import { ActionResult } from '../../../shared/utils/api';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';

export const CustomerForm = (props: ICustomerFormProps): JSX.Element => {
    const { customer, countries, states, distributorOrganizations, handleClose, getCustomers } = props;
    const isNewForm = customer.id === 0;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [selectedPersonOfInterest, setSelectedPersonOfInterest] = useState<PersonOfInterestModel | undefined>(undefined);
    
    const [step, setStep] = useState<number>(CustomerFormStep.GENERALINFO);
    const [isAddStepThree, setIsAddStepThree] = useState<boolean>(false);

    const [peopleOfInterestToCreateIds, setPeopleOfInterestToCreateIds] = useState<number[]>([]);
    const [peopleOfInterestToUpdateIds, setPeopleOfInterestToUpdateIds] = useState<number[]>([]);
    const [peopleOfInterestToDeleteIds, setPeopleOfInterestToDeleteIds] = useState<number[]>([]);
    const [peopleOfInterestToDelete, setPeopleOfInterestToDelete] = useState<PersonOfInterestModel[]>([]);

    const handleFormSubmit = async (currentCustomerState: CustomerFormModel) => {
        dispatch(setIsLoading(true));

        let peopleOfInterestProcessResponse = undefined;

        if (isNewForm) {
            const createModel = mapCustomerToCreateModel(currentCustomerState);
            const createResponse = await customerService.CreateCustomer(createModel);
            if (createResponse && createResponse.isSuccess) {
                const customerId = createResponse.result!;
                peopleOfInterestProcessResponse = await processPeopleOfInterest(currentCustomerState.customerDetail.peopleOfInterest, customerId);

                if (!peopleOfInterestProcessResponse || peopleOfInterestProcessResponse.isSuccess) {
                    handleClose();
                    dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                } else {
                    dispatch(showErrors(peopleOfInterestProcessResponse.errorModel?.errors));
                }
            } else {
                dispatch(showErrors(createResponse.errorModel?.errors));
            }
        } else {
            const updateModel = mapCustomerToUpdateModel(currentCustomerState);
            const updateResponse = await customerService.UpdateCustomer(updateModel);
            if (updateResponse && updateResponse.isSuccess) {
                const customerId = updateModel.id;
                peopleOfInterestProcessResponse = await processPeopleOfInterest(currentCustomerState.customerDetail.peopleOfInterest, customerId);

                if (!peopleOfInterestProcessResponse || peopleOfInterestProcessResponse.isSuccess) {
                    handleClose();
                    dispatch(showSuccess(t(SUCCESS_MESSAGE)));
                } else {
                    dispatch(showErrors(peopleOfInterestProcessResponse.errorModel?.errors));
                }
            } else {
                dispatch(showErrors(updateResponse.errorModel?.errors));
            }
        }
        await getCustomers();
        dispatch(setIsLoading(false));
    };

    const processPeopleOfInterest = async (currentPeopleOfInterestState: PersonOfInterestModel[], customerId: number): Promise<ActionResult<any> | undefined> => {
        let actionResult = undefined;

        for (const personToCreateId of peopleOfInterestToCreateIds) {
            if (!actionResult || actionResult.isSuccess) {
                const personToCreate = currentPeopleOfInterestState.find(p => p.id === personToCreateId);
                if (personToCreate) {
                    actionResult = await createPersonOfInterest(personToCreate, customerId);
                }
            }
        }

        if (!actionResult || actionResult.isSuccess) {
            for (const personToUpdateId of peopleOfInterestToUpdateIds) {
                if (!actionResult || actionResult.isSuccess) {
                    const personToUpdateFromCurrentState = currentPeopleOfInterestState.find(p => p.id === personToUpdateId);
                    if (personToUpdateFromCurrentState) {
                        actionResult = await updatePersonOfInterest(personToUpdateFromCurrentState);
                    }

                    if (!actionResult || actionResult.isSuccess) {
                        const personToUpdateFromDeletedState = peopleOfInterestToDelete.find(p => p.id === personToUpdateId);
                        if (personToUpdateFromDeletedState) {
                            await updatePersonOfInterest(personToUpdateFromDeletedState);
                        }
                    }
                }
            }
        }

        if (!actionResult || actionResult.isSuccess) {
            for (const personToDeleteId of peopleOfInterestToDeleteIds) {
                if (!actionResult || actionResult.isSuccess) {
                    actionResult = await deletePersonOfInterest(personToDeleteId);
                }
            }
        }

        return actionResult;
    };

    const createPersonOfInterest = async (personToCreate: PersonOfInterestModel, customerId: number): Promise<ActionResult<number>> => {
        const createModel = mapPersonOfInterestToCreateModel(personToCreate, customerId);
        const createResponse = await peopleOfInterestService.AddPersonOfInterest(createModel);

        return createResponse;
    };

    const updatePersonOfInterest = async (personToUpdate: PersonOfInterestModel): Promise<ActionResult<void>> => {
        const updateModel = mapPersonOfInterestToUpdateModel(personToUpdate);
        let updateResponse = await peopleOfInterestService.UpdatePersonOfInterest(updateModel);

        return updateResponse;
    };

    const deletePersonOfInterest = async (personOfInterestId: number): Promise<ActionResult<void>> => {
        const deleteResponse = await peopleOfInterestService.DeletePersonOfInterest(personOfInterestId);

        return deleteResponse;
    };


    const onAddEditPerson = (selectedPOI: any) => {
        setIsAddStepThree(true);
        setSelectedPersonOfInterest(selectedPOI);
        setStep(CustomerFormStep.PEOPLEOFINTEREST);
    };

    const handlePersonOfInterestChange = (propName: keyof PersonOfInterestModel, value: any) => {
        if (selectedPersonOfInterest) {
            setSelectedPersonOfInterest({ ...selectedPersonOfInterest, [propName]: value });
        }
    };

    const onPersonOfInterestSave = (peopleOfInterest: PersonOfInterestModel[]): PersonOfInterestModel[] => {
        let _peopleOfInterestUpdated = [...peopleOfInterest];

        if (selectedPersonOfInterest) {
            let _selectedPersonOfInterestUpdated = { ...selectedPersonOfInterest };

            if (_selectedPersonOfInterestUpdated.id !== 0) {
                markPeopleOfInterestAsUpdated([_selectedPersonOfInterestUpdated]);

                const personIndex = _peopleOfInterestUpdated.findIndex(p => p.id === _selectedPersonOfInterestUpdated.id);

                _peopleOfInterestUpdated = [
                    ..._peopleOfInterestUpdated.slice(0, personIndex),
                    _selectedPersonOfInterestUpdated,
                    ..._peopleOfInterestUpdated.slice(personIndex + 1),
                ];
            } else {
                _selectedPersonOfInterestUpdated.id = new Date().getTime();

                markPersonOfInterestAsCreated(_selectedPersonOfInterestUpdated.id);

                if (peopleOfInterest.length === 0) {
                    _selectedPersonOfInterestUpdated.isDefault = true;
                }

                _peopleOfInterestUpdated.push(_selectedPersonOfInterestUpdated);
            }
        }

        return _peopleOfInterestUpdated;
    };

    const onSetPersonAsDefault = (personOfInterestId: number, peopleOfInterest: PersonOfInterestModel[]): PersonOfInterestModel[] => {
        const peopleUpdated: PersonOfInterestModel[] = [];

        let _peopleOfInterestUpdated = peopleOfInterest.map(p => {
            if (p.isDefault) {
                let personUpdated = { ...p, isDefault: false };
                peopleUpdated.push(personUpdated);
                return personUpdated;
            }
            return p;
        });

        const personIndex = _peopleOfInterestUpdated.findIndex(p => p.id === personOfInterestId);
        const _personOfInterestUpdated = { ..._peopleOfInterestUpdated[personIndex], isDefault: true };

        peopleUpdated.push(_personOfInterestUpdated);

        markPeopleOfInterestAsUpdated(peopleUpdated);

        _peopleOfInterestUpdated = [
            ..._peopleOfInterestUpdated.slice(0, personIndex),
            _personOfInterestUpdated,
            ..._peopleOfInterestUpdated.slice(personIndex + 1),
        ];

        return _peopleOfInterestUpdated;
    };

    const onRemovePerson = (personOfInterestId: number, peopleOfInterest: PersonOfInterestModel[]): PersonOfInterestModel[] => {
        let _peopleOfInterestUpdated = [...peopleOfInterest];

        const _personOfInterestUpdated = _peopleOfInterestUpdated.find(p => p.id === personOfInterestId);

        if (_personOfInterestUpdated) {
            markPersonOfInterestAsDeleted(_personOfInterestUpdated);
        }

        _peopleOfInterestUpdated = _peopleOfInterestUpdated.filter(p => p.id !== personOfInterestId);

        return _peopleOfInterestUpdated;
    };

    const markPersonOfInterestAsCreated = (id: number) => {
        setPeopleOfInterestToCreateIds([...peopleOfInterestToCreateIds, id]);
    };

    const markPeopleOfInterestAsUpdated = (peopleUpdated: PersonOfInterestModel[]) => {
        const idsToAdd: number[] = [];
        const idsToRemove: number[] = [];

        peopleUpdated.forEach(personUpdated => {
            if (isPersonInitialForm(personUpdated)) {
                idsToRemove.push(personUpdated.id);
            } else {
                if (!peopleOfInterestToCreateIds.includes(personUpdated.id)) {
                    idsToAdd.push(personUpdated.id);
                }
            }
        });

        let _updated = [...peopleOfInterestToUpdateIds];

        _updated = _updated.filter(u => !idsToRemove.includes(u));

        idsToAdd.forEach(id => {
            if (!_updated.includes(id)) {
                _updated.push(id);
            }
        });

        setPeopleOfInterestToUpdateIds(_updated);
    };

    const markPersonOfInterestAsDeleted = (personToDelete: PersonOfInterestModel) => {
        if (!peopleOfInterestToCreateIds.includes(personToDelete.id)) {
            setPeopleOfInterestToDeleteIds([...peopleOfInterestToDeleteIds, personToDelete.id]);
            setPeopleOfInterestToDelete([...peopleOfInterestToDelete, personToDelete]);
        } else {
            setPeopleOfInterestToCreateIds([...peopleOfInterestToCreateIds.filter(pId => pId !== personToDelete.id)]);
        }
    };

    const isPersonInitialForm = (personUpdated: PersonOfInterestModel): boolean => {
        const personInitial = customer.customerDetail.peopleOfInterest.find(p => p.id === personUpdated.id);

        if (!personInitial) {
            return false;
        }

        return (isEqual(personInitial, personUpdated));
    };

    const handleBack = () => {
        setIsAddStepThree(false);
        setStep(CustomerFormStep.COMPANYDETAILS);
    };

    const twoSteps: any[] = [
        { label: t('General Info') },
        { label: t('Company Details') },
    ];

    const threeSteps = twoSteps.concat([{ label: t('Add or edit a person of interest'), optional: true, optionalLabel: t('Optional') }]);

    return (
        <>
            <Box>
                <Box sx={{ padding: '20px 0' }}>
                    <Stepper
                        steps={isAddStepThree ? threeSteps : twoSteps}
                        activeStep={step}
                    />
                </Box>
                <Formik
                    initialValues={customer}
                    validationSchema={customerFormValidationSchema}
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                >
                    {({ values, dirty, isSubmitting, isValid, handleSubmit, setFieldValue }) => (
                        <Form>
                            <Box display={'flex'} flexDirection={'column'} gap={2}>
                                {
                                    step === CustomerFormStep.GENERALINFO &&
                                    <>
                                        <CustomerGeneralInfo
                                            isNewForm={isNewForm}
                                            distributorOrganizations={distributorOrganizations}
                                        />
                                        <CustomerAddress
                                            isNewForm={isNewForm}
                                            countries={countries}
                                            states={states}
                                        />
                                    </>
                                }
                                {
                                    step === CustomerFormStep.COMPANYDETAILS &&
                                    <>
                                        <CustomerDetail
                                            countries={countries}
                                            isNewForm={isNewForm}
                                        />
                                    </>
                                }

                                {
                                    step === CustomerFormStep.COMPANYDETAILS &&
                                    <Box>
                                        {
                                            <PeopleOfInterest
                                                peopleOfInterest={values.customerDetail.peopleOfInterest}
                                                countries={countries}
                                                onAddEditPerson={onAddEditPerson}
                                                onSetPersonAsDefault={(personOfInterestId: number) => setFieldValue('customerDetail.peopleOfInterest', onSetPersonAsDefault(personOfInterestId, values.customerDetail.peopleOfInterest))}
                                                onRemovePerson={(personOfInterestId: number) => setFieldValue('customerDetail.peopleOfInterest', onRemovePerson(personOfInterestId, values.customerDetail.peopleOfInterest))}
                                            />
                                        }
                                    </Box>
                                }
                                {
                                    step === CustomerFormStep.PEOPLEOFINTEREST &&
                                    selectedPersonOfInterest &&
                                    <PersonOfInterestForm
                                        personOfInterest={selectedPersonOfInterest}
                                        countries={countries}
                                        onPersonOfInterestChange={handlePersonOfInterestChange}
                                        onPersonOfInterestSubmit={() => setFieldValue('customerDetail.peopleOfInterest', onPersonOfInterestSave(values.customerDetail.peopleOfInterest))}
                                        handleBack={handleBack}
                                    />
                                }

                                {
                                    step === CustomerFormStep.GENERALINFO &&
                                    <SaveSection
                                        validateNext={() => customerGeneralInfoIsValid(values)}
                                        handleNext={() => setStep(CustomerFormStep.COMPANYDETAILS)}
                                        handleCancel={handleClose}
                                        validateCancel={() => !dirty}
                                    />
                                }
                                {
                                    step === CustomerFormStep.COMPANYDETAILS &&
                                    <SaveSection
                                        handleBack={() => setStep(CustomerFormStep.GENERALINFO)}
                                        handleSubmit={handleSubmit}
                                        validateSubmit={() => dirty && isValid && !isSubmitting}
                                    />
                                }
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};
