import { CountryFormModel } from "../../../organizations/models/countryModel";
import { ParentOrganizationOptionModel } from "../../../organizations/models/organizationModel";
import { StateFormModel } from "../../../organizations/models/stateModel";
import { CustomerFormModel } from "../../models/customerModel";

export interface ICustomerFormProps {
	customer: CustomerFormModel;
	countries: CountryFormModel[];
	states: StateFormModel[];
	distributorOrganizations: ParentOrganizationOptionModel[];
	handleClose: () => void;
	getCustomers(): Promise<void>;
	refreshDialog: (id: number) => void;
}

export enum CustomerFormStep {
	GENERALINFO = 0,
	COMPANYDETAILS = 1,
	PEOPLEOFINTEREST = 2
}