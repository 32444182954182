import React, { useState } from 'react';
import { Modal, Stepper } from '@ui-components/ui-library';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { PlanningTypeSelectionStep } from './PlanningTypeSelectionStep';
import { PlanningTimeSelectionStep } from './PlanningTimeSelectionStep';
import { ServicePlanningFormModel, mapServicePlanningToCreateModel, mapServicePlanningToUpdateModel } from '../../models/servicePlanningModel';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { planningValidationSchema, servicePlanningTypeStepIsValid } from '../../services/servicePlanningUtils';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { servicePlanningService } from '../../services/servicePlanningService';
import { TechnicianModel } from '../../../users/models/technicianModel';
import { ServiceOrderViewModel } from '../../../serviceOrders/models/serviceOrderModel';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import moment from 'moment-timezone';

interface IPlanningFormProps {
    planning: ServicePlanningFormModel;
    isOpen: boolean;
    technicians: TechnicianModel[];
    notPlannedServiceOrders: ServiceOrderViewModel[];
    handleClose(): void;
    refreshPlannings(): Promise<void>;
}

enum PlanningFormStep {
    TypeSelection = 0,
    TimeSelection = 1,
}

export const PlanningForm = (props: IPlanningFormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { planning, isOpen, technicians, notPlannedServiceOrders, handleClose, refreshPlannings } = props;

    const isNewForm: boolean = planning.id === undefined;

    const [step, setStep] = useState<PlanningFormStep>(isNewForm ? PlanningFormStep.TypeSelection : PlanningFormStep.TimeSelection);

    const handlePlanningSubmit = async (servicePlanningFormValues: ServicePlanningFormModel) => {
        dispatch(setIsLoading(true));
        const isNewPlanning = planning.id === undefined;
        let actionResponse = undefined;

        const start = moment(servicePlanningFormValues.start).toDate();
        const end = moment(servicePlanningFormValues.end).toDate();

        if (isNewPlanning) {
            const createBody = mapServicePlanningToCreateModel({ ...servicePlanningFormValues, start, end });
            actionResponse = await servicePlanningService.CreateServicePlanning(createBody);
        } else {
            const updateBody = mapServicePlanningToUpdateModel({ ...servicePlanningFormValues, start, end });
            actionResponse = await servicePlanningService.UpdateServicePlanning(updateBody);
        }

        if (actionResponse && actionResponse.isSuccess) {
            await refreshPlannings();
            dispatch(showSuccess(t(SUCCESS_MESSAGE)));
        } else {
            dispatch(showErrors(actionResponse?.errorModel?.errors));
        }
        handleClose();
        dispatch(setIsLoading(false));
    };

    return (
        <>
            <Modal
                title={t('Planning')}
                open={isOpen}
                onClose={handleClose}
                maxWidth='lg'
                scroll='body'
                titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
            >
                <Box>
                    <Stepper
                        steps={[
                            { label: t('Planning Type Selection') },
                            { label: t('Time Slot Selection') }
                        ]}
                        activeStep={step}
                    />
                </Box>
                <Formik
                    initialValues={planning}
                    validationSchema={planningValidationSchema}
                    enableReinitialize
                    onSubmit={handlePlanningSubmit}
                >
                    {({ values, dirty, isSubmitting, isValid, handleSubmit, setFieldValue }) => (
                        <Form>
                            <Box display={'flex'} flexDirection={'column'} sx={theme => ({ [theme.breakpoints.up('md')]: { height: '628px', width: '600px' } })}>
                                <Box flex={'1 1 auto'}>
                                    {
                                        step === PlanningFormStep.TypeSelection &&
                                        <PlanningTypeSelectionStep
                                            isNewForm={isNewForm}
                                        />
                                    }
                                    {
                                        step === PlanningFormStep.TimeSelection &&
                                        <PlanningTimeSelectionStep
                                            isNewForm={isNewForm}
                                            onFormClose={handleClose}
                                            technicians={technicians}
                                            notPlannedServiceOrders={notPlannedServiceOrders}
                                        />
                                    }
                                </Box>
                                <Box flex={'0 1 auto'}>
                                    {
                                        step === PlanningFormStep.TypeSelection &&
                                        <SaveSection
                                            validateNext={() => servicePlanningTypeStepIsValid(values)}
                                            handleCancel={handleClose}
                                            handleNext={() => setStep(PlanningFormStep.TimeSelection)}
                                        />
                                    }
                                    {
                                        step === PlanningFormStep.TimeSelection &&
                                        <SaveSection
                                            handleBack={() => {
                                                setStep(PlanningFormStep.TypeSelection);
                                            }}
                                            handleClose={handleClose}
                                            handleSubmit={handleSubmit}
                                            validateSubmit={() => dirty && isValid && !isSubmitting}
                                        />
                                    }
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};