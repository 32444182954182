import React from 'react';
import { IFilterModalProps } from './IFilterModalProps';
import { Box } from '@mui/material';
import { Button, Modal } from '@ui-components/ui-library';
import { useTranslation } from 'react-i18next';

export const FilterModal = (props: IFilterModalProps): JSX.Element => {

    const { isOpen, children, disableApply, onClose, onApply } = props;

    const { t } = useTranslation();

    return (
        <Modal
            title={t('Filters')}
            open={isOpen}
            onClose={onClose}
            maxWidth='sm'
            scroll='body'
            titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
            sx={{ '.MuiPaper-root': { width: '100%' } }}
        >
            {
                children
            }

            <Box mt={5} display='flex' justifyContent='flex-end' gap={2}>
                <Button
                    id={'Cancel'}
                    type={'button'}
                    label={t('Cancel')}
                    onClick={onClose}
                    variant='secondary'
                />

                <Button
                    id='apply'
                    type={'button'}
                    label={t('Apply')}
                    onClick={onApply}
                    endIcon
                    disabled={disableApply}
                />
            </Box>
        </Modal>
    );
};