import React from 'react';
import { Box, Divider } from '@mui/material';
import { ChartTooltipLabel } from '../../../shared/utils/sxStyleObjects';
import { ChartSeriesDefaultized, ChartSeriesType } from '@mui/x-charts/models/seriesType/config';

export interface CustomChartTooltipContentProps {
    series: ChartSeriesDefaultized<ChartSeriesType>[]
    dataIndex?: null | number,
    axisValue: any,
    xAxisLabels?: string[] | undefined;
    seriesLabels?: string[] | undefined;
}

export const CustomChartTooltipContent = (props: CustomChartTooltipContentProps): JSX.Element => {
    const { xAxisLabels, seriesLabels, axisValue, dataIndex, series } = props;

    return (
        <Box sx={{ backgroundColor: 'black', borderRadius: '4px', border: '1px solid', borderColor: '#2a2a2a' }}>
            <Box sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 1.5, paddingRight: 1.5 }}>
                {
                    xAxisLabels && dataIndex !== undefined && dataIndex !== null &&
                    <ChartTooltipLabel>{xAxisLabels[dataIndex]}</ChartTooltipLabel>
                }
                {
                    !xAxisLabels &&
                    <ChartTooltipLabel>{axisValue}</ChartTooltipLabel>
                }
            </Box>
            <Divider />
            {
                series.map((s, i) => {
                    return (
                        <div key={i}>
                            {
                                // display data for single series
                                !seriesLabels &&
                                <Box
                                    key={i}
                                    sx={{
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        paddingLeft: 1.5,
                                        paddingRight: 1.5,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Box sx={{ height: '8px', width: '8px', backgroundColor: s.color, borderRadius: '50%' }}></Box>
                                    <Box>
                                        <ChartTooltipLabel>
                                            {
                                                dataIndex !== undefined && dataIndex !== null && `${s.data[dataIndex]}`
                                            }
                                        </ChartTooltipLabel>
                                    </Box>
                                </Box>
                            }

                            {
                                // display data for multiple series
                                seriesLabels &&
                                <Box
                                    key={i}
                                    sx={{
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        paddingLeft: 1.5,
                                        paddingRight: 1.5,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: '10px'
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <Box sx={{ height: '8px', width: '8px', backgroundColor: s.color, borderRadius: '50%' }}></Box>
                                        <Box >
                                            <ChartTooltipLabel>{seriesLabels[i]}</ChartTooltipLabel>
                                        </Box>
                                    </Box>
                                    {
                                        dataIndex !== undefined && dataIndex !== null &&
                                        <Box>
                                            <ChartTooltipLabel>
                                                {`${s.data[dataIndex]}`}
                                            </ChartTooltipLabel>
                                        </Box>
                                    }                                    
                                </Box>
                            }
                        </div>
                    );
                })
            }
        </Box>
    );
};