import { ParentOrganizationOptionModel } from '../../organizations/models/organizationModel';
import { CustomerRelatedDataModel } from '../../serviceOrders/models/equipmentRelatedDataModel';
import { PersonOfInterest } from '../../serviceOrders/models/personOfInterestModel';
import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { PersonOfInterestModel } from '../models/personOfInterestModel';
import { CustomerCreateModel, CustomerFormModel, CustomerUpdateModel, CustomerViewModel } from '../models/customerModel';
import { ImportFileViewModel } from '../../models/ImportFileViewModel';
import { DownloadFileModel } from '../../shared/models/DownloadFileModel';

class CustomerService {
    async ActivateCustomer(customerId: number): Promise<ActionResult<void>> {
        const url = `/customers/ActivateCustomer/${customerId}`;

        return await api.put(url, {});
    }

    async CreateCustomer(createModel: CustomerCreateModel): Promise<ActionResult<number>> {
        const url = '/customers/CreateCustomer';

        return await api.post<number>(url, JSON.stringify(createModel));
    }

    async DeactivateCustomer(customerId: number): Promise<ActionResult<void>> {
        const url = `/customers/DeactivateCustomer/${customerId}`;

        return await api.put(url, {});
    }

    async GetCustomers(): Promise<ActionResult<CustomerViewModel[]>> {
        const url = '/customers';
        return await api.get<CustomerViewModel[]>(url);
    }

    async GetCustomerById(id: number): Promise<ActionResult<CustomerFormModel>> {
        const url = `/customers/${id}`;
        return await api.get<CustomerFormModel>(url);
    }

    async GetCustomerParentOptions(): Promise<ActionResult<ParentOrganizationOptionModel[]>> {
        const url = '/customers/GetCustomerParentOptions';
        return await api.get<ParentOrganizationOptionModel[]>(url);
    }

    async UpdateCustomer(updateModel: CustomerUpdateModel): Promise<ActionResult<void>> {
        const url = '/customers/UpdateCustomer';

        return await api.put(url, JSON.stringify(updateModel));
    }

    async GetCustomerRelatedData(equipmentId: number): Promise<ActionResult<CustomerRelatedDataModel>> {
        const url = `/customers/GetCustomerRelatedData/${equipmentId}`;

        const response = await api.get<CustomerRelatedDataModel>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetPeopleOfInterestForCustomer(customerId: number): Promise<ActionResult<PersonOfInterest[]>> {
        const url = `/customers/GetPeopleOfInterestForCustomer/${customerId}`;

        const response = await api.get<PersonOfInterest[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetCustomerPeopleOfInterest(customerId: number): Promise<ActionResult<PersonOfInterestModel[]>> {
        const url = `/customers/GetCustomerPeopleOfInterest/${customerId}`;

        const response = await api.get<PersonOfInterestModel[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async UploadBulkCustomerFile(attachmentFile: File): Promise<ActionResult<void>> {
        const url = '/customers/BulkUploadCustomers';

        var response = await api.filePost<void>(url, attachmentFile, []);

        return response;
    }

    async DownloadImportTemplateFile(): Promise<ActionResult<DownloadFileModel>> {
        const url = '/customers/DownloadImportTemplateFile';

        var response = await api.get<DownloadFileModel>(url);

        return response;
    }

    async GetCustomerUploadFilesForUser(): Promise<ActionResult<ImportFileViewModel[]>> {
        const url = '/customers/GetCustomerUploadFilesForUser';

        const response = await api.get<ImportFileViewModel[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }
}

export const customerService = new CustomerService();