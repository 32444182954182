import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { getDefaultServiceExecutionUsedPart, mapServiceExecutionPartToPlannedUpdateModel, mapServiceExecutionUsedPartToCreateModel, maptServiceExecutionUsedPartToUpdateModel, ServiceExecutionPartFormModel } from '../../models/serviceExecutionPartFormModel';
import { serviceExecutionUsedPartService } from '../../services/ServiceExecutionUsedPartService';
import styles from './MaterialConformationSection.module.scss';
import { IMaterialConformationSectionProps } from './IMaterialConformationSectionProps';
import { RequiredMessage } from '../../../shared/utils/validationMessages';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Title } from '../../../components/form/title/Title';
import { Button, DataGrid, Icon, TextField } from '@ui-components/ui-library';
import { GridEnrichedColDef, GridRowParams, useGridApiRef, GridRenderCellParams, GridRowClassNameParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { InnerTableSxObject } from '../../../shared/utils/sxStyleObjects';

export const MaterialConformationSection = (props: IMaterialConformationSectionProps): JSX.Element => {
	const { showHeader, serviceExecution, serviceExecutionUsedParts, refreshPartsTable, readonly } = props;

	const [selectedPartRow, setSelectedPartRow] = useState<ServiceExecutionPartFormModel | undefined>(undefined);
	const [partDialogIsOpen, setPartDialogIsOpen] = useState<boolean>(false);
	const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

	const apiRef = useGridApiRef();

	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const handlePartChange = (propName: keyof ServiceExecutionPartFormModel, value: any) => {
		if (selectedPartRow) {
			setSelectedPartRow({ ...selectedPartRow, [propName]: value });
		}
	};

	const onServiceExecutionPartSubmit = async () => {
		dispatch(setIsLoading(true));
		if (selectedPartRow) {
			let response = undefined;
			const isNewItem = selectedPartRow.id < 1;
			const isPrePlannedPart = selectedPartRow.plannedPartId !== undefined;
			if (isNewItem && !isPrePlannedPart) {
				const createModel = mapServiceExecutionUsedPartToCreateModel(selectedPartRow);
				response = await serviceExecutionUsedPartService.AddServiceExecutionUsedPart(createModel);
			} else if (!isNewItem && !isPrePlannedPart) {
				const updateModel = maptServiceExecutionUsedPartToUpdateModel(selectedPartRow);
				response = await serviceExecutionUsedPartService.UpdateServiceExecutionUsedPart(updateModel);
			} else {
				const plannedUpdateModel = mapServiceExecutionPartToPlannedUpdateModel(selectedPartRow);
				response = await serviceExecutionUsedPartService.UpdateServiceExecutionPlannedPart(plannedUpdateModel);
			}

			if (response.isSuccess) {
				hidePartDialog();
				await refreshPartsTable();
				dispatch(showSuccess(t(SUCCESS_MESSAGE)));
			} else {
				dispatch(showErrors(response.errorModel?.errors));
			}
		}
		dispatch(setIsLoading(false));
	};

	const onServiceExecutionPartDelete = async () => {
		dispatch(setIsLoading(true));
		if (selectedPartRow) {
			const response = await serviceExecutionUsedPartService.DeleteServiceExecutionUsedPart(selectedPartRow.id);
			if (response.isSuccess) {
				hideDeleteDialog();
				await refreshPartsTable();
			} else {
				dispatch(showErrors(response?.errorModel?.errors));
			}
		}
		dispatch(setIsLoading(false));
	};

	const openPartDialog = async (partId: number | undefined) => {
		let serviceExecutionPartToSet: ServiceExecutionPartFormModel | undefined = undefined;
		if (partId) {
			serviceExecutionPartToSet = serviceExecutionUsedParts.find((seup) => seup.id === partId);
		} else {
			serviceExecutionPartToSet = getDefaultServiceExecutionUsedPart(serviceExecution.id);
		}

		setSelectedPartRow(serviceExecutionPartToSet);
		setPartDialogIsOpen(true);
	};

	const hidePartDialog = () => {
		setSelectedPartRow(undefined);
		setPartDialogIsOpen(false);
	};

	const openDeleteDialog = (serviceExecutionPartId: number) => {
		const selectedServiceExecutionPart = serviceExecutionUsedParts.find((seup) => seup.id === serviceExecutionPartId);

		setSelectedPartRow(selectedServiceExecutionPart);
		setDeleteDialogIsOpen(true);
	};

	const hideDeleteDialog = () => {
		setSelectedPartRow(undefined);
		setDeleteDialogIsOpen(false);
	};

	const rowClassName = (rowData: GridValidRowModel) => {
		return rowData.plannedPartId && rowData.quantityUsed === undefined ? styles.invalid_row : '';
	};

	const quantityUsedTemplate = (params: GridRenderCellParams<ServiceExecutionPartFormModel>) => {
		return params.row.plannedPartId && params.row.quantityUsed === undefined ? (
			<Typography
				variant='body1'
				sx={{ color: '#e24c4c' }}
			>
				{t(RequiredMessage)}
			</Typography>
		) : (
			params.row.quantityUsed
		);
	};

	const serviceExecutionUsedPartSubmitIsDisabled = !selectedPartRow || !selectedPartRow.name || !selectedPartRow.quantityUsed || !selectedPartRow.number;

	let columns: GridEnrichedColDef[] = [
		{ field: 'name', headerName: t('Name'), disableExport: true, flex: 1.5, sortable: false, filterable: false, disableColumnMenu: true },
		{ field: 'number', headerName: t('Number'), disableExport: true, flex: 1.5, sortable: false, filterable: false, disableColumnMenu: true },
		{ field: 'quantityPlanned', headerName: t('Quantity planned'), disableExport: true, flex: 2, sortable: false, filterable: false, disableColumnMenu: true },
		{
			field: 'quantityUsed',
			headerName: t('Quantity used'),
			renderCell: (params: GridRenderCellParams<ServiceExecutionPartFormModel>) => quantityUsedTemplate(params),
			disableExport: true,
			flex: 2,
			sortable: false,
			filterable: false,
			cellClassName: styles.mark_column_when_row_is_invalid,
			disableColumnMenu: true,
		},
	];

	let actionsColumn: GridEnrichedColDef[] = [
		{
			field: 'actions',
			type: 'actions',
			headerName: t('Actions'),
			flex: 1,
			getActions: (params: GridRowParams<ServiceExecutionPartFormModel>) => {
				let actionsArray = [];

				actionsArray.push(
					<DataGridActionOverview
						key={'edit'}
						icon={<Icon type='edit' />}
						label={t('Edit')}
						onClick={() => openPartDialog(params.row.id)}
						showInMenu
					/>
				);

				if (params.row.plannedPartId === undefined) {
					actionsArray.push(
						<DataGridActionOverview
							key={'delete'}
							icon={<Icon type='delete' />}
							label={t('Delete')}
							onClick={() => openDeleteDialog(params.row.id)}
							showInMenu
						/>
					);
				}

				return actionsArray;
			},
		},
	];

	columns = !readonly ? [...columns, ...actionsColumn] : [...columns];

	return (
		<>
			<Box pt={0}>
				<Box
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'space-between'}
					paddingBottom={'5px'}
				>
					{showHeader ? (
						<Title
							variant={'h4'}
							text={t('Material Confirmation')}
							align='left'
						/>
					) : (
						<div></div>
					)}

					<IconButton
						onClick={() => openPartDialog(undefined)}
						disabled={readonly}
						hidden={readonly}
						sx={{ '& .material-symbols-outlined': { fontSize: '40px' } }}
					>
						<Icon
							type='add'
							color={readonly ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255)'}
						/>
					</IconButton>
				</Box>

				<DataGrid
					apiRef={apiRef}
					rows={serviceExecutionUsedParts}
					columns={columns}
					autoHeight
					containerHeight='auto'
					getRowHeight={() => 'auto'}
					hideFooter
					getRowClassName={(params: GridRowClassNameParams<GridValidRowModel>) => rowClassName(params.row)}
					sx={InnerTableSxObject}
					localeText={{ noRowsLabel: t('No rows') }}
				/>
			</Box>

			{partDialogIsOpen && (
				<Dialog
					open={partDialogIsOpen}
					onClose={hidePartDialog}
					maxWidth={'lg'}
					fullWidth
				>
					<DialogTitle component={'h4'}>
						{t('Part')}

						<IconButton
							aria-label='close'
							onClick={hidePartDialog}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<Icon type={'close'} />
						</IconButton>
					</DialogTitle>

					<DialogContent>
						<Grid
							container
							spacing={4}
						>
							<Grid
								item
								xs={12}
								md={3}
							>
								<TextField
									label={t('Name')}
									value={selectedPartRow?.name}
									disabled={selectedPartRow?.plannedPartId != undefined}
									onChange={(e) => handlePartChange('name', e.target.value)}
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								md={3}
							>
								<TextField
									label={t('Part number')}
									value={selectedPartRow?.number}
									disabled={selectedPartRow?.plannedPartId != undefined}
									onChange={(e) => handlePartChange('number', e.target.value)}
									autoFocus={selectedPartRow?.plannedPartId === undefined}
									inputProps={{ maxLength: 50 }}
								/>
							</Grid>

							{selectedPartRow?.plannedPartId && (
								<Grid
									item
									xs={12}
									md={3}
								>
									<TextField
										label={t('Quantity planned')}
										value={selectedPartRow?.quantityPlanned}
										disabled
									/>
								</Grid>
							)}

							<Grid
								item
								xs={12}
								md={3}
							>
								<TextField
									label={t('Quantity used')}
									value={selectedPartRow?.quantityUsed}
									inputProps={{ maxLength: 10 }}
									inputRestriction='decimal'
									onChange={(e) => handlePartChange('quantityUsed', e.target.value)}
									autoFocus={selectedPartRow?.plannedPartId !== undefined}
								/>
							</Grid>
						</Grid>
					</DialogContent>

					<DialogActions sx={{ paddingBottom: '1rem' }}>
						<Button
							id='save'
							label={t('OK')}
							startIcon='check'
							variant='primary'
							onClick={onServiceExecutionPartSubmit}
							disabled={serviceExecutionUsedPartSubmitIsDisabled}
						/>
						<Button
							id='cancel'
							label={t('Cancel')}
							startIcon='cancel'
							variant='secondary'
							onClick={hidePartDialog}
						/>
					</DialogActions>
				</Dialog>
			)}

			{deleteDialogIsOpen && selectedPartRow && (
				<ConfirmationDialog
					open={deleteDialogIsOpen}
					message={`${t('Are you sure you want to delete part {{partNumber}}?', { partNumber: selectedPartRow.number })}`}
					onConfirm={onServiceExecutionPartDelete}
					onClose={hideDeleteDialog}
				/>
			)}
		</>
	);
};
