import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Button, Checkbox, Dropdown, DropdownOption, Modal, StyledDefaultDropdownProps } from '@ui-components/ui-library';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { languages, rtlLanguages } from '../../../i18n/i18nConstants';
import { Language } from '../../../users/models/userPreferences';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { ITermsAndConditionsModalProps } from './ITermsAndConditionsModalProps';
import { CardTextContainer, extendTheme } from '../../../shared/utils/sxStyleObjects';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import { ThemeProvider } from '@mui/material/styles';
import { CustomThemeProvider } from '@ui-components/ui-library';
import { ThemeMode } from '../../../shared/enums/ThemeMode';

export const TermsAndConditionsModal = (props: ITermsAndConditionsModalProps): JSX.Element => {
    const { handleClose, handleSubmit, termsAndConditions } = props;

    const { t } = useTranslation();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const [selectedLanguage, setSelectedLanguage] = useState<Language>(currentUser.preferences.language);
    const [consentChecked, setConsentChecked] = useState<boolean>(false);

    const [shouldUseRtlLocally, setShouldUseRtlLocally] = useState<boolean>(useAppSelector(state => userLanguageIsRtl(state)));

    const languageChoices: DropdownOption[] = Object.keys(languages).map((key, index) => (
        {
            id: index,
            label: key.toUpperCase(),
            value: key,
        }
    ));

    const displayedContent = termsAndConditions.find(t => t.Language === selectedLanguage);

    const changeLanguage = (item: any) => {
        const value = item.target.value;
        setSelectedLanguage(Language[value as keyof typeof Language]);
        
        setShouldUseRtlLocally(Object.values(rtlLanguages).some(v => v === value));
    };

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });

    const cacheLtr = createCache({
        key: 'mui'
    });

    return (
        <>
            <CacheProvider value={shouldUseRtlLocally ? cacheRtl : cacheLtr}>
                <ThemeProvider theme={extendTheme(shouldUseRtlLocally)}>
                    <CustomThemeProvider mode={ThemeMode.Dark} brand={'Smartlink'} isRtl={shouldUseRtlLocally}>
                        <Modal
                            open={true}
                            title={t('Terms and Conditions', { lng: Language[selectedLanguage] })}
                            onClose={handleClose}
                            maxWidth='lg'
                            scroll='body'
                            titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
                            sx={{ '.MuiPaper-root': { width: '100%', maxHeight: 'calc(100vh - 64px)' },
                                '& .MuiDialogContent-root': { height: 'calc(100vh - 150px)' },
                                '& .MuiDialog-paper > .MuiBox-root': { paddingTop: '20px' } }}
                        >
                            <Grid container spacing={2} dir={shouldUseRtlLocally ? 'rtl' : 'ltr'} sx={{ height: '100%' }} >
                                <Grid item xs={12} md={2}>
                                    <Dropdown<StyledDefaultDropdownProps>
                                        label={t('Choose your language', { lng: Language[selectedLanguage] })}
                                        greyedOutLabelOnDisabled
                                        value={Language[selectedLanguage]}
                                        options={languageChoices}
                                        onChange={(item: any) => changeLanguage(item)}
                                        noOptionsText={t(NO_OPTIONS_TEXT)}
                                        sx={{ '& .MuiInputBase-root': { marginTop: '4px' } }}
                                        optionListStyles={{ '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': { direction: 'ltr' } }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} minHeight={'200px'} height={'calc(100% - 170px)'}>
                                    <CardTextContainer sx={{ height: '100%'}}>
                                        <Box mb={2}>
                                            {t('Version {{version}}', { version: displayedContent?.Version, lng: Language[selectedLanguage] })}
                                        </Box>
                                        <Box sx={{ whiteSpace: 'pre-wrap', maxHeight: 'calc(100% - 32px)', overflow: 'auto' }}>
                                            {displayedContent?.Content}
                                        </Box>
                                    </CardTextContainer>
                                </Grid>

                                <Grid item xs={12} md={12} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>                                    
                                    <Checkbox
                                        checked={consentChecked}
                                        onChange={(value: boolean) => setConsentChecked(value)}
                                        label={t('I agree to the Terms and Conditions', { lng: Language[selectedLanguage] })}
                                    />
                                    <Button
                                        id='submit'
                                        type={'submit'}
                                        variant='primary'
                                        label={t('Submit', { lng: Language[selectedLanguage] })}
                                        onClick={handleSubmit}
                                        disabled={!consentChecked}
                                    />                                  
                                </Grid>
                            </Grid>
                        </Modal>
                    </CustomThemeProvider>
                </ThemeProvider>
            </CacheProvider>
        </>
    );
};