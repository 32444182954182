import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from '../../models/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Language, TimeZone, UsePreferencesPartial, localesByLanguage } from '../../models/userPreferences';
import { userService } from '../../services/UserService';
import { Form, Formik } from 'formik';
import { userPreferenceValidationSchema } from '../../services/UserUtility';
import { i18n } from '../../../i18n/config';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { setCurrentUser } from '../../../stores/user/usersSlice';
import { UserPreferenceForm } from './UserPreferenceForm';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { useCookie } from '../../../hooks/useCookie';
import { USER_LANGUAGE_IS_SET, USER_SET_PREFERED_LANGUAGE_COOKIE_NAME } from '../../../shared/utils/constants';
import moment from 'moment-timezone';
import { changeApplicationMomentLocale } from '../../../shared/utils/Utils';

export const UserPreferencePage = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const [userPreferences, setUserPreferences] = useState<UsePreferencesPartial>({ userId: 0, language: 0, timeZone: '', timeZoneIana: '' });
    const [timeZones, setTimeZones] = useState<TimeZone[]>([]);

    const [setCookieValue] = useCookie([USER_SET_PREFERED_LANGUAGE_COOKIE_NAME]);

    useEffect(() => {   
        dispatch(setIsLoading(true)); 
        fetch('timezones.json')
            .then((r) => r.json())
            .then((data) => {
                setTimeZones(data);
                dispatch(setIsLoading(false));
            });
    }, []);

    const handleUserPreferenceSubmit = async (currentState: UsePreferencesPartial) => {
        dispatch(setIsLoading(true));

        const response = await userService.UpdateUserPreferences(currentState);

        if (response.isSuccess) {
            i18n.changeLanguage(Language[currentState.language]);
            setUserPreferences(currentState);
            const localeByLanguage = localesByLanguage[currentState.language];
            dispatch(setCurrentUser({
                ...currentUser,
                preferences: { ...currentUser.preferences, language: currentState.language, timeZone: currentState.timeZone, timeZoneIana: currentState.timeZoneIana, momentLocale: localeByLanguage }
            }));

            moment.tz.setDefault(currentState.timeZoneIana);
            changeApplicationMomentLocale(localeByLanguage);

            // if user selected language we can assume there is a prefered language set
            setCookieValue(USER_SET_PREFERED_LANGUAGE_COOKIE_NAME, USER_LANGUAGE_IS_SET);

            dispatch(showSuccess(t(SUCCESS_MESSAGE)));
        } else {
            dispatch(showErrors(['Error while saving user preferences']));
        }

        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (currentUser && timeZones) {
            let userTimeZone = currentUser.preferences.timeZone;

            if (!userTimeZone) {
                const userTimeZoneIana = currentUser.preferences.timeZoneIana;

                const userTimeZonesByIana = timeZones.filter(t => t.utc.includes(userTimeZoneIana));
                const daylightSavings = userTimeZonesByIana.map(t => t.isdst);

                if (userTimeZonesByIana.length > 0) {
                    if (daylightSavings.every(d => d) || daylightSavings.every(d => !d)) {
                        userTimeZone = userTimeZonesByIana[0].text;
                    } else {
                        const currentTimeZoneOffset = ((new Date().getTimezoneOffset()) / 60) * -1;
                        const timeZone = userTimeZonesByIana.find(u => u.offset === currentTimeZoneOffset);

                        if (timeZone) {
                            userTimeZone = timeZone.text;
                        }
                    }
                }
            }

            setUserPreferences({ timeZone: userTimeZone, timeZoneIana: currentUser.preferences.timeZoneIana, language: currentUser.preferences.language, userId: currentUser.id });
        }
    }, [currentUser, timeZones]);

    if (!userPreferences) {
        return (
            <></>
        );
    }

    return (
        <Formik
            initialValues={userPreferences}
            validationSchema={userPreferenceValidationSchema}
            enableReinitialize
            onSubmit={handleUserPreferenceSubmit}
        >
            <Form>
                <UserPreferenceForm timeZones={timeZones} />
            </Form>
        </Formik>
    );
};