import React from 'react';
import { Grid } from '@mui/material';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { planningTypeLabels } from '../../models/planningType';
import { useFormikContext } from 'formik';
import { ServicePlanningFormModel } from '../../models/servicePlanningModel';
import { useTranslation } from 'react-i18next';

interface IPlanningTypeSelectionStepProps {
    isNewForm: boolean;
}

export const PlanningTypeSelectionStep = (props: IPlanningTypeSelectionStepProps) => {
    const { t } = useTranslation();
    const { values: servicePlanning, setFieldValue } = useFormikContext<ServicePlanningFormModel>();
    const { isNewForm } = props;

    const onPlanningTypeChange = async (selectedPlanningType: DropdownOption | undefined): Promise<void> => {
        setFieldValue('assigneeIds', []);
        setFieldValue('serviceOrderId', undefined);
        setFieldValue('estimatedWorkHours', undefined);

        setFieldValue('type', selectedPlanningType?.id);
    };

    const planningTypeDropdownOptions: DropdownOption[] = Object.entries(planningTypeLabels).map(([id, value]) => {
        return { id: +id, label: t(value), value: value };
    });

    const planningTypeValueOption = planningTypeDropdownOptions.find(o => o.id === servicePlanning.type);

    return (
        <Grid mt={'5%'} item xs={12} md={6}>
            <Grid item xs={12}>
                <Dropdown<StyledSearchableDropdownProps>
                    id={'type'}
                    name={'type'}
                    label={t('Planning Type')}
                    variant={'searchable'}
                    value={planningTypeValueOption || { id: 0, label: '', value: '' }}
                    onChange={(_, item) => onPlanningTypeChange(item as DropdownOption)}
                    options={planningTypeDropdownOptions}
                    required={true}
                    disabled={!isNewForm}
                    greyedOutLabelOnDisabled
                />
            </Grid>
        </Grid>
    );
};
