import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { ServiceExecutionPartFormModel, ServiceExecutionPlannedPartUpdateModel, ServiceExecutionUsedPartCreateModel, ServiceExecutionUsedPartUpdateModel } from '../models/serviceExecutionPartFormModel';

class ServiceExecutionUsedPartService {
    async GetUsedPartsForServiceExecution(serviceExecutionId: number): Promise<ActionResult<ServiceExecutionPartFormModel[]>> {
        const url = `/serviceExecutionUsedParts/GetUsedPartsForServiceExecution/${serviceExecutionId}`;
        const response = await api.get<ServiceExecutionPartFormModel[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async AddServiceExecutionUsedPart(serviceExecutionActivityCreateModel: ServiceExecutionUsedPartCreateModel): Promise<ActionResult<number>> {
        const url = '/serviceExecutionUsedParts/AddServiceExecutionUsedPart';
        const response = await api.post<number>(url, JSON.stringify(serviceExecutionActivityCreateModel));

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async UpdateServiceExecutionUsedPart(serviceExecutionActivityUpdateModel: ServiceExecutionUsedPartUpdateModel): Promise<ActionResult<void>> {
        const url = '/serviceExecutionUsedParts/UpdateServiceExecutionUsedPart';

        return await api.put<void>(url, JSON.stringify(serviceExecutionActivityUpdateModel));
    }

    async UpdateServiceExecutionPlannedPart(serviceExecutionActivityUpdateModel: ServiceExecutionPlannedPartUpdateModel): Promise<ActionResult<void>> {
        const url = '/serviceExecutionUsedParts/UpdatePlannedServiceExecutionUsedPart';

        return await api.put<void>(url, JSON.stringify(serviceExecutionActivityUpdateModel));
    }

    async DeleteServiceExecutionUsedPart(serviceExecutionPartId: number): Promise<ActionResult<void>> {
        const url = `/serviceExecutionUsedParts/DeleteServiceExecutionUsedPart/${serviceExecutionPartId}`;

        return await api.delete<void>(url);
    }
}

export const serviceExecutionUsedPartService = new ServiceExecutionUsedPartService();