import React, { useState } from 'react';
import { IServiceOrderDisplayFormProps } from './IServiceOrderDisplayFormProps';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Modal, Stepper } from '@ui-components/ui-library';
import { ServiceOrderDisplayGeneralInfo } from '../serviceOrderDisplayGeneralInfo/ServiceOrderDisplayGeneralInfo';
import { ServiceOrderDisplayCustomerInfo } from '../serviceOrderDisplayCustomerInfo/ServiceOrderDisplayCustomerInfo';
import { ServiceOrderDisplayParts } from '../serviceOrderDisplayParts/ServiceOrderDisplayParts';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { ServiceOrderDisplayEquipmentInfo } from '../serviceOrderDisplayEquipmentInfo/ServiceOrderDisplayEquipmentInfo';

enum ServiceOrderDisplayFormStep {
    GeneralInfo = 0,
    CustomerInfo = 1,
    EquipmentInfo = 2,
    PlannedParts = 3,
}

export const ServiceOrderDisplayForm = (props: IServiceOrderDisplayFormProps): JSX.Element => {
    const { isOpen, serviceOrder, onClose } = props;

    const { t } = useTranslation();

    const theme = useTheme();
    const isMediumOrLargeDevice = useMediaQuery(theme.breakpoints.up('sm'));

    const [step, setStep] = useState<ServiceOrderDisplayFormStep>(ServiceOrderDisplayFormStep.GeneralInfo);

    const steps = [
        { label: t('General Info') },
        { label: t('Customer Information') },
        { label: t('Equipment Information') },
        { label: t('Parts to be used') },
    ];

    return (
        <>
            <Modal
                title={t('Planning')}
                open={isOpen}
                onClose={onClose}
                maxWidth='lg'
                scroll='body'
                titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
            >
                {
                    !isMediumOrLargeDevice &&
                    <Box sx={{ padding: '20px 0' }}>
                        <Typography sx={{ color: '#13ABF1' }}>
                            {t('Step {{current_step}} / {{steps_count}}', { current_step: step + 1, steps_count: steps.length })}
                        </Typography>
                        <Typography sx={{ color: '#13ABF1' }}>
                            {steps[step].label}
                        </Typography>
                    </Box>
                }

                {
                    isMediumOrLargeDevice &&
                    <Box sx={{ padding: '20px 0' }}>
                        <Stepper
                            steps={steps}
                            activeStep={step}
                        />
                    </Box>
                }

                {
                    step === ServiceOrderDisplayFormStep.GeneralInfo &&
                    <ServiceOrderDisplayGeneralInfo serviceOrder={serviceOrder} />
                }

                {
                    step === ServiceOrderDisplayFormStep.CustomerInfo &&
                    <ServiceOrderDisplayCustomerInfo serviceOrder={serviceOrder} />
                }

                {
                    step === ServiceOrderDisplayFormStep.EquipmentInfo &&
                    <ServiceOrderDisplayEquipmentInfo serviceOrder={serviceOrder} />
                }

                {
                    step === ServiceOrderDisplayFormStep.PlannedParts &&
                    <ServiceOrderDisplayParts serviceOrder={serviceOrder} />
                }

                <Box flex={'0 1 auto'}>
                    {
                        step === ServiceOrderDisplayFormStep.GeneralInfo &&
                        <SaveSection
                            handleNext={() => setStep(ServiceOrderDisplayFormStep.CustomerInfo)}
                        />
                    }

                    {
                        step === ServiceOrderDisplayFormStep.CustomerInfo &&
                        <SaveSection
                            handleNext={() => setStep(ServiceOrderDisplayFormStep.EquipmentInfo)}
                            handleBack={() => setStep(ServiceOrderDisplayFormStep.GeneralInfo)}
                        />
                    }

                    {
                        step === ServiceOrderDisplayFormStep.EquipmentInfo &&
                        <SaveSection
                            handleNext={() => setStep(ServiceOrderDisplayFormStep.PlannedParts)}
                            handleBack={() => setStep(ServiceOrderDisplayFormStep.CustomerInfo)}
                        />
                    }

                    {
                        step === ServiceOrderDisplayFormStep.PlannedParts &&
                        <SaveSection
                            handleBack={() => setStep(ServiceOrderDisplayFormStep.EquipmentInfo)}
                        />
                    }
                </Box>

            </Modal>
        </>
    );
};
