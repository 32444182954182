import { ActionResult, api } from '../../shared/utils/api';
import { ErpTypeModel } from '../models/erpTypeModel';

class ErpTypeService {
    async GetErpTypes(): Promise<ActionResult<ErpTypeModel[]>> {
        const url = '/erpTypes';
        return await api.get<ErpTypeModel[]>(url);
    }
}

export const erpTypeService = new ErpTypeService();