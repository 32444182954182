
import React, { Children } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { Button } from '@ui-components/ui-library';
import { DialogTitle, Typography } from '@mui/material';

interface ConfirmationDialogProps {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void | Promise<void>;
	message: string;
	children?: React.ReactNode,
	conformationDisabled?: boolean;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
	const { open, onClose, onConfirm, message, children, conformationDisabled } = props;
	const { t } = useTranslation();


	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle style={{fontSize: '18px'}}>
					{message}
			</DialogTitle>
			<DialogContent>
				<Typography variant='body1' sx={{ fontFamily: 'system-ui', fontSize: '16px' }}>
					{children}
				</Typography>
			</DialogContent>
			<DialogActions sx={{ paddingBottom: '1rem' }}>
				<Button
					id='confirm-delete'
					label={t('Yes')} startIcon='check'
					variant='primary'
					type={'button'}
					onClick={onConfirm}
					disabled={conformationDisabled}
				/>
				<Button
					id='cancel-delete'
					label={t('Cancel')}
					startIcon='cancel'
					variant='secondary'
					type={'button'}
					onClick={onClose}
				/>
			</DialogActions>
		</Dialog>
	);
};
