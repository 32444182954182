
export type ConnectivityDetail = {
    isConnected: boolean;
}

export const getDefaultConnectivityDetail = (): ConnectivityDetail => {
    return {
        isConnected: false,
    };
};
