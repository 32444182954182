import { IEnvironmentConfig } from '../interfaces/IEnvironmentConfig';
import { MSMEnvironment, msmEnvironmentFactory } from '../utils/constants';

export class AppEnvironment {
    /**
     * returns the current environment configuration
     * @returns {IEnvironmentConfig}
     */

    public static get(): IEnvironmentConfig {
        const url: string = window.location.href;

        const environments = msmEnvironmentFactory.GetAll();

        const selectedEnvironment = environments
            .find(e => url.indexOf(e.WebsiteUrl) >= 0);

        const activeEnvironment = AppEnvironment.MapEnvironmentToConfig(selectedEnvironment!);

        return activeEnvironment;
    }

    private static MapEnvironmentToConfig(environment: MSMEnvironment): IEnvironmentConfig {
        return {
            baseUrl: `${environment.ApiUrl}`,
            newBaseUrl: `${environment.NewApiUrl}`,
            msalConfig: {
                auth: {
                    clientId: `${environment.ClientId}`,
                    authority: 'https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9',
                    navigateToLoginRequestUrl: true,
                    redirectUri: `${environment.SignInUrl}`,
                }
            },
            loginRequest: {
                scopes: [`${environment.ClientId}/.default`]
            },
        };
    }
}