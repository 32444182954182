import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { ParentOrganizationOptionModel } from '../models/customer';
import { EquipmentCreateModel, EquipmentFormModel, EquipmentUpdateModel, EquipmentViewModel } from '../models/equipment';
import { getDefaultEquipmentDetail } from '../models/equipmentDetail';
import { EquipmentOptionModel } from '../models/equipmentOptionModel';

class EquipmentService {
    async CreateEquipment(createModel: EquipmentCreateModel): Promise<ActionResult<number>> {
        const url = '/equipments/CreateEquipment';

        return await api.post<number>(url, JSON.stringify(createModel));
    }

    async GetEquipments(): Promise<ActionResult<EquipmentViewModel[]>> {
        const url = '/equipments';
        const response = await api.get<EquipmentViewModel[]>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetEquipmentOptions(): Promise<ActionResult<EquipmentOptionModel[]>> {
        const url = '/equipments/GetEquipmentOptions';
        const response = await api.get<EquipmentOptionModel[]>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async GetEquipmentById(id: number): Promise<ActionResult<EquipmentFormModel>> {
        const url = `/equipments/getEquipmentById/${id}`;

        const response = await api.get<EquipmentFormModel>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        response.result.equipmentDetail ??= getDefaultEquipmentDetail();

        return response;
    }

    async UpdateEquipment(updateModel: EquipmentUpdateModel): Promise<ActionResult<void>> {
        const url = '/equipments/UpdateEquipment';

        return await api.put<void>(url, JSON.stringify(updateModel));
    }

    async GetDistributorOrganizationsForEquipment(): Promise<ActionResult<ParentOrganizationOptionModel[]>> {
        const url = '/equipments/GetDistributorOrganizationsForEquipment';

        return await api.get<ParentOrganizationOptionModel[]>(url);
    }
}

export const equipmentService = new EquipmentService();