import React, { useEffect } from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Dropdown, StyledSearchableDropdownProps, DropdownOption } from '@ui-components/ui-library';
import { useTranslation } from 'react-i18next';
import { UserFormValues } from '../../models/user';
import { IUserGeneralInfoProps } from './IUserGeneralInfoProps';
import { validateForm as validateInitialValues } from '../../../shared/utils/Utils';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { orderBy } from 'lodash';

export const UserGeneralInfo = (props: IUserGeneralInfoProps): JSX.Element => {

    const { values: user, handleBlur, handleChange, setFieldValue, validateForm, setFieldTouched } = useFormikContext<UserFormValues>();
    const { t } = useTranslation();
    const { formIsReadonly, countries } = props;

    const countryOptions = orderBy(countries, c => c.description)?.map(b => {
        return { id: b.id, label: b.description, value: b.id };
    });

    const valueOption = countryOptions?.find(country => country.id === user.userAdditionalInformation.countryId);

    useEffect(() => {
        if (!formIsReadonly && user.id && user.id > 0) {
            validateInitialValues(validateForm, setFieldTouched);
        }
    }, [formIsReadonly, user]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('First Name')}
                        id={'firstName'}
                        name={'firstName'}
                        value={user.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                        disabled={formIsReadonly}
                    />
                    <ErrorMessage name={'firstName'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Last Name')}
                        id={'lastName'}
                        name={'lastName'}
                        value={user.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                        disabled={formIsReadonly}
                    />
                    <ErrorMessage name={'lastName'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Email')}
                        id={'email'}
                        name={'email'}
                        value={user.email}
                        type='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                        disabled={formIsReadonly}
                    />
                    <ErrorMessage name={'email'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Phone Number')}
                        id={'userAdditionalInformation.phoneNumber'}
                        name={'userAdditionalInformation.phoneNumber'}
                        value={user.userAdditionalInformation.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                        disabled={formIsReadonly}
                    />
                    <ErrorMessage name={'userAdditionalInformation.phoneNumber'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('City')}
                        id={'userAdditionalInformation.city'}
                        name={'userAdditionalInformation.city'}
                        value={user.userAdditionalInformation.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                        disabled={formIsReadonly}
                    />
                    <ErrorMessage name={'userAdditionalInformation.city'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'userAdditionalInformation.countryId'}
                        label={t('Country')}
                        variant={'searchable'}
                        name={'userAdditionalInformation.countryId'}
                        value={valueOption ?? {id: '', label: '', value: ''}}
                        options={countryOptions ? countryOptions : []}
                        onChange={(e, item) => setFieldValue('userAdditionalInformation.countryId', item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        required={true}
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                        disabled={formIsReadonly}
                        greyedOutLabelOnDisabled
                    />
                    <ErrorMessage name={'userAdditionalInformation.countryId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};
 