import React from 'react';
import { IWarningModalProps } from './IWarningModalProps';
import { Button, Modal } from '@ui-components/ui-library';
import { Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const WarningModal = (props: IWarningModalProps): JSX.Element => {
    const { isOpen, message, children, onClose } = props;

    const { t } = useTranslation();

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            maxWidth='sm'
            scroll='body'
            titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
            sx={{ '.MuiPaper-root': { width: '100%' } }}
        >
            <Alert severity="warning" sx={{ '&.MuiAlert-root': { display: 'flex', justifyContent: 'center'} }}>
                {t(message)}
            </Alert>
            <Box mt={5}>
                {children}
            </Box>

            <Box mt={5} display='flex' justifyContent='center'>
                <Button
                    id={'Close'}
                    type={'button'}
                    label={t('Close')}
                    onClick={onClose}
                    variant='secondary'
                />
            </Box>
        </Modal>
    )
}