
export type PersonOfInterestModel = {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    countryId: number;
    phoneNumber: string;
    role: string;
    isDefault: boolean;
};

export const getDefaultPersonOfInterest = (): PersonOfInterestModel => {
    return {
        id: 0,
        firstName: '',
        lastName: '',
        emailAddress: '',
        countryId: 0,
        phoneNumber: '',
        role: '',
        isDefault: false,
    };
};

export type PersonOfInterestCreateModel = {
    customerId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    countryId: number;
    phoneNumber: string;
    role: string;
    isDefault: boolean;
}

export const mapPersonOfInterestToCreateModel = (personOfInterest: PersonOfInterestModel, customerId: number): PersonOfInterestCreateModel => {
    return {
        customerId: customerId,
        firstName: personOfInterest.firstName,
        lastName: personOfInterest.lastName,
        emailAddress: personOfInterest.emailAddress,
        countryId: personOfInterest.countryId,
        phoneNumber: personOfInterest.phoneNumber,
        role: personOfInterest.role,
        isDefault: personOfInterest.isDefault,
    };
};

export type PersonOfInterestUpdateModel = {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    countryId: number;
    phoneNumber: string;
    role: string;
    isDefault: boolean;
}

export const mapPersonOfInterestToUpdateModel = (personOfInterest: PersonOfInterestModel): PersonOfInterestUpdateModel => {
    return {
        id: personOfInterest.id,
        firstName: personOfInterest.firstName,
        lastName: personOfInterest.lastName,
        emailAddress: personOfInterest.emailAddress,
        countryId: personOfInterest.countryId,
        phoneNumber: personOfInterest.phoneNumber,
        role: personOfInterest.role,
        isDefault: personOfInterest.isDefault,
    };
};