import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';

export const LimitedAccessComponent = (): JSX.Element => {
    const { t } = useTranslation();
    const isLoading: boolean = useAppSelector(state => state.Layout.isLoading);

    return (
        <>
            {!isLoading && (
                <Alert severity="info" sx={{ margin: '1rem 0', fontSize: '14px' }}>
                    <AlertTitle>
                        {t('You don\'t have sufficient rights to access this page!')}
                    </AlertTitle>
                </Alert>
            )}
        </>
    );
};