export enum ContractType {
    None = 0,
    PreventiveWithLabor = 1,
    TotalResponsibility = 2,
    Labor = 3,
    PartsPlan = 4,
    Other = 5,
}

export const contractTypeLabels: Record<ContractType, string> = {
    [ContractType.None]: 'None',
    [ContractType.PreventiveWithLabor]: 'Preventive with Labor',
    [ContractType.TotalResponsibility]: 'Total Responsibility',
    [ContractType.Labor]: 'Labor',
    [ContractType.PartsPlan]: 'Parts Plan',
    [ContractType.Other]: 'Other',
};

export const getContractTypeLabel = (contractType: ContractType): string => {
    return contractTypeLabels[contractType];
};