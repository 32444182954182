import { FormikProps, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { serviceExecutionService } from '../../services/ServiceExecutionService';
import { IFutureRecommendationSectionProps } from './IFutureRecommendationSectionProps';
import { Box, Dialog, DialogActions, DialogContent, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { Title } from '../../../components/form/title/Title';
import { Button, TextField } from '@ui-components/ui-library';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { UploadComponent } from '../../../components/uploadComponent/UploadComponent';
import { ServiceExecutionAttachmentSection } from '../../models/enums/serviceExecutionAttachmentSection';
import DeleteIcon from '@mui/icons-material/Delete';
import { MAX_FILES_PER_SECTION } from '../../../shared/utils/constants';
import { ServiceExecutionAttachment } from '../../models/serviceExecutionAttachment';
import { ServiceExecutionFormModel } from '../../models/serviceExecutionModel';
import './FutureRecommendationSection.scss';
import { futureRecommendationMaximumLength } from '../../services/ServiceExecutionUtility';

export const FutureRecommendationSection = (props: IFutureRecommendationSectionProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { values: serviceExecution, handleChange, handleBlur, setFieldValue} = useFormikContext() as FormikProps<ServiceExecutionFormModel>;
    const { showHeader, readonly } = props;

    const [removeAttachmentDialogIsOpen, setRemoveAttachmentDialogIsOpen] = useState<boolean>(false);
    const [attachmentToRemove, setAttachmentToRemove] = useState<ServiceExecutionAttachment | undefined>(undefined);

    const futureRecommendationCharacterCount = serviceExecution?.futureRecommendation?.description?.length ?? 0;

    const onFileSubmit = async (file: File) => {
        dispatch(setIsLoading(true));

        const uploadResponse = await serviceExecutionService
            .UploadAttachment(file, serviceExecution.id, ServiceExecutionAttachmentSection.FutureServiceRecommendation);

        if (uploadResponse.isSuccess) {
            const attachmentsUpdated = [...serviceExecution.serviceExecutionAttachments, uploadResponse.result];
            setFieldValue('serviceExecutionAttachments', attachmentsUpdated);
            
            dispatch(showSuccess(t('File uploaded.')));
        } else {
            dispatch(showErrors(uploadResponse.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const onRemoveAttachmentClick = (attachment: ServiceExecutionAttachment) => {
        setAttachmentToRemove(attachment);
		setRemoveAttachmentDialogIsOpen(true);
    };

    const onAttachmentRemove = async () => {
        dispatch(setIsLoading(true));

        if (attachmentToRemove) {
            const deleteResponse = await serviceExecutionService.DeleteAttachment(serviceExecution.id, attachmentToRemove.id);
            if (deleteResponse.isSuccess) {
                const attachmentsUpdated = [...serviceExecution.serviceExecutionAttachments].filter(a => a.id !== attachmentToRemove.id);
                setFieldValue('serviceExecutionAttachments', attachmentsUpdated);
                hideRemoveAttachmentDialog();
            } else {
                dispatch(showErrors(deleteResponse.errorModel?.errors));
            }
        }

        dispatch(setIsLoading(false));
    };

    const hideRemoveAttachmentDialog = () => {
        setRemoveAttachmentDialogIsOpen(false);
		setAttachmentToRemove(undefined);
    };

    const futureRecommendationImages = serviceExecution.serviceExecutionAttachments
        .filter(seat => seat.serviceExecutionAttachmentSection === ServiceExecutionAttachmentSection.FutureServiceRecommendation);
        
    return (
        <>
            <Box marginTop={'1rem'}>
                {
                    showHeader && <Title variant={'h4'} text={t('Next Visit Recommendations')} align='left' />
                }

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            value={serviceExecution.futureRecommendation.description}
                            name='futureRecommendation.description'
                            id={'futureRecommendation.description'}
                            sx={{
                                width: '100%',
                                '& .MuiInputBase-root': { height: 'auto' }
                            }}
                            disabled={readonly}
                            multiline={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            minRows={4}
                        />
                        <Box>
							<Typography sx={{ fontSize: '14px', fontFamily: 'system-ui' }}>
								{`${futureRecommendationCharacterCount} / ${futureRecommendationMaximumLength}`} {t('characters')}
							</Typography>
						</Box>
                    </Grid>
                </Grid>
            </Box>

            <Box marginTop={'1rem'}>
                {
                    <ImageList
                        sx={{
                            width: '100%', height: futureRecommendationImages.length > 0 ? 200 : 0,
                            gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))!important'
                        }}
                        cols={5}
                        gap={15}
                        rowHeight={200}>
                        {
                            futureRecommendationImages
                                .map((attachment, index) => (
                                    <ImageListItem key={index}>
                                        <img src={attachment.fileUrl} alt={attachment.fileName} />
                                        <ImageListItemBar
                                            title={attachment.fileName}
                                            actionIcon={!readonly &&
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                    aria-label={`delete ${attachment.fileName}`}
                                                >
                                                    <DeleteIcon onClick={() => onRemoveAttachmentClick(attachment)}/>
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                ))
                        }
                    </ImageList>
                }
            </Box>

            {
                removeAttachmentDialogIsOpen && attachmentToRemove &&
                    <Dialog 
                        open={removeAttachmentDialogIsOpen}
                        onClose={hideRemoveAttachmentDialog}
                    >
                        <DialogContent>
                            <Typography variant='body1' sx={{ fontFamily: 'system-ui' }}>
                                {t('Are you sure you want to remove file {{filename}}?', { filename: attachmentToRemove.fileName })}
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ paddingBottom: '1rem'}}>
                            <Button 
                                id='confirm-delete-poi' 
                                label={t('OK')} startIcon='check' 
                                variant='primary' 
                                type={'button'} 
                                onClick={onAttachmentRemove} 
                            />
                            <Button 
                                id='cancel-delete-poi' 
                                label={t('Cancel')} 
                                startIcon='cancel' 
                                variant='secondary' 
                                type={'button'} 
                                onClick={hideRemoveAttachmentDialog} 
                            />
                        </DialogActions>
                    </Dialog>
            }

            {
                !readonly &&
                <Box marginTop={'1rem'}>
                    <UploadComponent
                        maxFileSizeInMb={2}
                        acceptedFileTypes={['png', 'jpeg', 'jpg']}
                        accept={'image/png, image/jpeg, image/jpg'}
                        placeholderText={t('Drag and Drop Image File Here')}
                        uploadButtonLabel={t('Upload Image')}
                        allowMultiple={false}
                        maximumFilesAllowed={1}
                        disabled={readonly || futureRecommendationImages.length >= MAX_FILES_PER_SECTION}
                        onSubmit={onFileSubmit}
                        uploadOnSelect
                    />
                </Box>
            }
        </>
    );
};