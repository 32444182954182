import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import { AuthenticatedUser } from '../../users/models/user';
import moment from 'moment-timezone';
import { Alert, Box, IconButton, LinearProgress, Typography } from '@mui/material';
import { BlankCard, Button, Chip, ChipType, Icon } from '@ui-components/ui-library';
import { IUploadComponentProps } from './IUploadComponentProps';
import { MAX_CHARACTERS_FOR_FILE_NAME } from '../../shared/utils/constants';

export const UploadComponent = (props: IUploadComponentProps) => {
    const { accept, maxFileSizeInMb, acceptedFileTypes, placeholderText,
        uploadButtonLabel, allowMultiple, maximumFilesAllowed, disabled, onSubmit, uploadOnSelect: directUpload } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputKey, setInputKey] = useState<string>('');

    const { t } = useTranslation();
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const [files, setFiles] = useState<File[]>([]);
    const [totalSize, setTotalSize] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const isUploadButtonVisible = files.length > 0 && !files.some(f => f.size > (maxFileSizeInMb * 1024 * 1024));

    const onTemplateDrop = async (ev: any) => {
        ev.preventDefault();

        let files: File[] = [];

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...ev.dataTransfer.items].forEach((item) => {
                // If dropped items aren't files, reject them
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    files.push(file);
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...ev.dataTransfer.files].forEach((file) => {
                files.push(file);
            });
        }

        await selectHandler(files);
    };

    const onTemplateSelect = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }

        const filesList = e.target.files;
        let files: File[] = [];

        Object.keys(filesList).forEach((_, index) => {
            files.push(filesList[index]);
        });

        await selectHandler(files);
    };

    const selectHandler = async (selectedFiles: File[]) => {
        let _totalSize = totalSize;
        selectedFiles.map(f => _totalSize += f.size || 0);

        const [areValid, message] = filesAreValid(selectedFiles, _totalSize);
        if (!areValid) {
            setErrorMessage(message);
            return;
        }
        
        if (!directUpload) {
            setFiles([...files, ...selectedFiles]);
            setTotalSize(_totalSize);
        } else {
            await onSubmit(selectedFiles[0]).then(() => {
                setInputKey((moment().toDate()).toString());                
                setTotalSize(0);
            });
        }

        setErrorMessage(undefined);
    };

    const onTemplateRemove = (file: any) => {
        setTotalSize(totalSize - file.size);
        setFiles(files.filter(f => f.name !== file.name));
        setInputKey((moment().toDate()).toString());
    };

    const submitFiles = async () => {
        await onSubmit(files[0]).then(() => {
            setInputKey((moment().toDate()).toString());

            setTotalSize(0);
            setFiles([]);
        });
    };


    // #region Helper functions
    const getFileType = (fileName: string): string => {
        let extension = '';

        const match = fileName.match(/\.[0-9a-z]+$/i);
        if (match) {
            extension = match[0].slice(1);
        }

        return extension;
    };

    const filesAreValid = (files: File[], totalSizeAccumulated: number): [boolean, string | undefined] => {
        let areValid = true;
        let message: string | undefined = undefined;

        if (files.length === 0) {
            areValid = false;
        }

        const fileTypes = files.map(f => getFileType(f.name));
        if (fileTypes.some(f => !acceptedFileTypes.includes(f))) {
            message = t('File is not in correct format.');
            areValid = false;
        }

        if (totalSizeAccumulated > (maxFileSizeInMb * maximumFilesAllowed) * 1024 * 1024) {
            setFiles([]);
            setTotalSize(0);
            message = t('Maximum file size is {{maxFileSize}} MB.', { maxFileSize: maxFileSizeInMb });

            areValid = false;
        }

        if(files.some(f => f.name.length > MAX_CHARACTERS_FOR_FILE_NAME)) {
            setFiles([]);
            setTotalSize(0);

            message = t('Maximum file name length is {{maxFileNameLength}} characters.', { maxFileNameLength: MAX_CHARACTERS_FOR_FILE_NAME });

            areValid = false;
        }

        return [areValid, message];
    };

    const getFileSize = (file: File | undefined) => {
        const fSExt = ['B', 'KB', 'MB', 'GB'];

        if (!file) {
            return [0, fSExt[0]];
        }

        let size = file.size;

        let i = 0;
        while (size > 900) {
            size /= 1024;
            i++;
        }

        const sizeCalculated = Math.round(size * 100) / 100;
        const sizeAsInt = Math.trunc(sizeCalculated);
        const unit = fSExt[i];

        return [sizeAsInt, unit];
    };

    const getTotalSize = () => {
        const fSExt = ['B', 'KB', 'MB', 'GB'];
        let totalSize = 0;

        files.forEach(file => {
            totalSize += file.size;
        });

        let i = 0;
        while (totalSize > 900) {
            totalSize /= 1024;
            i++;
        }

        const unit = fSExt[i];

        const sizeCalculated = Math.round(totalSize * 100) / 100;
        const sizeAsInt = Math.trunc(sizeCalculated);

        return [sizeAsInt, unit];
    };
    // #endregion

    // #region Templates
    const headerTemplate = () => {
        const [size, unit] = getTotalSize();

        const formatedValue = t(`{{size}} ${unit}`, { size: size });
        const sizePercentage = (totalSize / (maxFileSizeInMb * 1024 * 1024)) * 100;

        return (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
                <Button
                    id='select'
                    type='button'
                    variant='secondary'
                    startIcon={'image'}
                    label={t('Choose File')}
                    disabled={disabled || files.length >= maximumFilesAllowed}
                    onClick={() => inputRef.current?.click()}
                />
                <input
                    key={inputKey}
                    ref={inputRef}
                    type={'file'}
                    accept={accept}
                    hidden
                    onChange={onTemplateSelect}
                    disabled={disabled || files.length >= maximumFilesAllowed}
                    multiple={allowMultiple}
                />

                {
                    !directUpload &&
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} gap={'0.5rem'}>
                        <span>{formatedValue} / {t('{{size}} MB', { size: maxFileSizeInMb * maximumFilesAllowed })}</span>
                        <LinearProgress id='progress' variant="determinate" value={sizePercentage} sx={{ width: '10rem', height: '12px' }} />
                    </Box>
                }
                
            </Box>
        );
    };

    const emptyTemplate = () => {
        return (
            <>
                {
                    !disabled &&
                    <div id="div1" onDrop={onTemplateDrop} onDragOver={(ev) => ev.preventDefault()}>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <Icon type={'upload'} sx={{ fontSize: '56px' }} />
                            <Typography sx={{fontSize: '14px'}}>{t(placeholderText)}</Typography>
                        </Box>
                    </div>
                }
            </>
        );
    };

    const itemsTemplate = () => {
        return (
            <>
                {
                    files.map((file, index) => {
                        const [size, unit] = getFileSize(file);
                        return (
                            <Box key={index} display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
                                <Box display={'flex'} alignItems={'left'} width={'60%'} flexDirection={'column'}>
                                    <Typography sx={{fontSize: '14px'}}>{file.name}</Typography>
                                    <Typography sx={{fontSize: '12px', fontWeight: '700', lineHeight: '10px'}}>{moment().format(currentUser.preferences.dateFormat)}</Typography>
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-between'} width={'40%'}>
                                    <Chip type={ChipType.FEEDBACK_GREEN} label={t(`{{size}} ${unit}`, { size: size })} />
                                    <IconButton
                                        type='button'
                                        size={'large'}
                                        sx={{ '& .material-symbols-outlined': { fontSize: '28px' } }}
                                        onClick={() => onTemplateRemove(file)}
                                    >
                                        <Icon
                                            type='close'
                                            color={'rgba(255, 255, 255)'}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        );
                    })
                }
            </>
        );
    };

    const errorTemplate = () => {
        return (
            <Alert
                severity='warning'
                sx={{ display: 'flex', justifyContent: 'center', '& .MuiAlert-message': { fontFamily: 'system-ui' } }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setErrorMessage(undefined)}
                    >
                        <Icon type='close' />
                    </IconButton>
                }
            >
                {errorMessage}
            </Alert>
        );
    };
    //#endregion 

    return (
        <Box flex={1}>
            <BlankCard sx={{ '& .MuiCardHeader-root': { padding: '0 !important' }, '& .MuiCardContent-root': { padding: '2rem !important' } }}>
                {headerTemplate()}
                <Box margin={'2rem 0'}>
                    {errorMessage && errorTemplate()}
                    {
                        totalSize === 0 || directUpload ?
                            emptyTemplate() :
                            itemsTemplate()
                    }
                </Box>

                {!directUpload && isUploadButtonVisible &&
                    <Box display={'flex'} flexDirection={'row-reverse'}>
                        <Button
                            id={'upload'}
                            type={'button'}
                            label={t(uploadButtonLabel)}
                            variant={'primary'}
                            onClick={submitFiles}
                        />
                    </Box>
                }
            </BlankCard>
            <Box>
                <Typography sx={{ color: '#ffffff', fontSize: '14px' }}>
                    {t('Accepted file types: {{acceptedTypes}}', { acceptedTypes: acceptedFileTypes.join(', ') })}
                </Typography>
            </Box>
            {
                directUpload &&
                <Typography sx={{ color: '#ffffff', fontSize: '14px' }}>
                    {t('Maximum file size: {{maxFileSize}} MB', { maxFileSize: maxFileSizeInMb })}
                </Typography>
            }
        </Box>
    );
};