import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware } from 'redux';
import UserReducer from './user/usersSlice';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import { createLogger, } from 'redux-logger';
import AlertReducer from './alert/alertSlice';
import LayoutReducer from './layout/layoutSlice';
import DashboardReducer from './dashboard/dashboardSlice';

const loggerMiddleware: any = createLogger();

const middlewares = [loggerMiddleware, immutableCheckMiddleWare()];

const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];

export const store = configureStore({
    reducer: {
        User: UserReducer,
        Alert: AlertReducer,
        Layout: LayoutReducer,
        Dashboard: DashboardReducer,
    },
    enhancers: process.env.NODE_ENV === 'development' ? (getDefaultEnhancers: any) =>
        getDefaultEnhancers().concat(enhancers) : undefined,
});


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch