
export type LeadsFilterModel = {
    customerCenterId: number | undefined;
    distributorOrganizationId: number | undefined;
    equipmentTypeIds: number[];
    brandTypeId: number | undefined;
    brandId: number | undefined;
    countryId: number | undefined;
    installedBaseId: number | undefined;
};

export const getDefaultLeadsFilterModel = (): LeadsFilterModel => {
    return {
        customerCenterId: undefined,
        distributorOrganizationId: undefined,
        equipmentTypeIds: [],
        brandTypeId: undefined,
        brandId: undefined,
        countryId: undefined,
        installedBaseId: undefined,
    };
};
