import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { orderBy } from 'lodash';
import { getDefaultServiceExecutionActivity, mapServiceExecutionActivityToCreateModel, mapServiceExecutionActivityToUpdateModel, ServiceExecutionActivityFormModel } from '../../models/serviceExecutionActivityModel';
import { IActivitiesSectionProps } from './IActivitiesSectionProps';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { AuthenticatedUser } from '../../../users/models/user';
import { serviceExecutionActivityService } from '../../services/ServiceExecutionActivityService';
import { showErrors } from '../../../stores/alert/alertSlice';
import { UnitOptionModel } from '../../models/unitOptionModel';
import { unitService } from '../../services/UnitService';
import { ServiceExecutionAssigneeModel } from '../../models/serviceExecutionAssigneeModel';
import { ActivityTypeTypeOptionModel } from '../../models/activityTypeModel';
import { activityTypeService } from '../../services/ActivityTypeService';
import { serviceExecutionService } from '../../services/ServiceExecutionService';
import { Box, IconButton, Dialog, Grid, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, Icon, TextField, Dropdown, StyledSearchableDropdownProps, DropdownOption, Button } from '@ui-components/ui-library';
import { GridEnrichedColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { Title } from '../../../components/form/title/Title';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { InnerTableSxObject } from '../../../shared/utils/sxStyleObjects';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import styles from './ActivitiesSection.module.scss';

const DropdownSxObject = {
    '& .MuiAutocomplete-clearIndicator': { display: 'none' }
};

export const ActivitiesSection = (props: IActivitiesSectionProps): JSX.Element => {
    const { showHeader, serviceExecution, readonly } = props;
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const [serviceExecutionActivities, setServiceExecutionActivities] = useState<ServiceExecutionActivityFormModel[]>([]);
    const [activityTypeOptions, setActivityTypeOptions] = useState<ActivityTypeTypeOptionModel[]>([]);
    const [technicianOptions, setTechnicianOptions] = useState<ServiceExecutionAssigneeModel[]>([]);
    const [activityDialogIsOpen, setActivityDialogIsOpen] = useState<boolean>(false);
    const [selectedActivityRow, setSelectedActivityRow] = useState<ServiceExecutionActivityFormModel | undefined>(undefined);
    const [activityTypeUnitOptions, setActivityTypeUnitOptions] = useState<UnitOptionModel[]>([]);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

    const apiRef = useGridApiRef();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const getServiceExecutionActivities = async () => {
        dispatch(setIsLoading(true));
        const serviceExecutionActivitiesResponse = await serviceExecutionActivityService.GetServiceExecutionActivitieForServiceExecution(serviceExecution.id);
        if (serviceExecutionActivitiesResponse.isSuccess) {
            setServiceExecutionActivities(serviceExecutionActivitiesResponse.result!);
        } else {
            dispatch(showErrors(serviceExecutionActivitiesResponse?.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    const getActivityTypeOptions = async () => {
        const activityTypeOptionsResponse = await activityTypeService.GetActivityTypeOptions();

        if (activityTypeOptionsResponse.isSuccess) {
            setActivityTypeOptions(orderBy(activityTypeOptionsResponse.result!, x => x.description));
        } else {
            dispatch(showErrors(activityTypeOptionsResponse?.errorModel?.errors));
        }
    };

    const getTechnicianOptions = async () => {
        const technicianOptionsResponse = await serviceExecutionService.GetServiceExecutionAssigneeOptions(serviceExecution.id);

        if (technicianOptionsResponse.isSuccess) {
            setTechnicianOptions(orderBy(technicianOptionsResponse.result!, x => x.name));
        } else {
            dispatch(showErrors(technicianOptionsResponse?.errorModel?.errors));
        }
    };

    const onServiceExecutionActivitySubmit = async () => {
        dispatch(setIsLoading(true));
        if (selectedActivityRow) {
            const isNewItem = selectedActivityRow.id < 1;
            if (isNewItem) {
                const createModel = mapServiceExecutionActivityToCreateModel(selectedActivityRow);
                const serviceExecutionActivityCreateResponse = await serviceExecutionActivityService.CreateServiceExecutionActivity(createModel);
                if (serviceExecutionActivityCreateResponse.isSuccess) {
                    hideActivityDialog();
                    await getServiceExecutionActivities();
                } else {
                    dispatch(showErrors(serviceExecutionActivityCreateResponse?.errorModel?.errors));
                }
            } else {
                const updateModel = mapServiceExecutionActivityToUpdateModel(selectedActivityRow);
                const serviceExecutionActivityUpdateResponse = await serviceExecutionActivityService.UpdateServiceExecutionActivity(updateModel);
                if (serviceExecutionActivityUpdateResponse.isSuccess) {
                    hideActivityDialog();
                    await getServiceExecutionActivities();
                } else {
                    dispatch(showErrors(serviceExecutionActivityUpdateResponse?.errorModel?.errors));
                }
            }
        }
        dispatch(setIsLoading(false));
    };

    const onServiceExecutionActivityDelete = async () => {
        dispatch(setIsLoading(true));
        if (selectedActivityRow) {
            const deleteResponse = await serviceExecutionActivityService.DeleteServiceExecutionActivity(selectedActivityRow.id);
            if (deleteResponse.isSuccess) {
                hideDeleteDialog();
                await getServiceExecutionActivities();
            } else {
                dispatch(showErrors(deleteResponse?.errorModel?.errors));
            }
        }
        dispatch(setIsLoading(false));
    };

    const handleActivityChange = (propName: keyof ServiceExecutionActivityFormModel, value: any) => {
        if (selectedActivityRow) {
            setSelectedActivityRow({ ...selectedActivityRow, [propName]: value });
        }
    };

    const handleActivityTypeChange = async (activityTypeId: number) => {
        if (selectedActivityRow) {
            const selectedActivityType = activityTypeOptions.find(at => at.id === activityTypeId);
            if (selectedActivityType) {
                dispatch(setIsLoading(true));
                const activityTypeUnitOptionsResponse = await unitService.GetUnitOptionsForUnitCategory(selectedActivityType.unitCategoryId);
                if (activityTypeUnitOptionsResponse.isSuccess) {
                    setActivityTypeUnitOptions(orderBy(activityTypeUnitOptionsResponse.result!, x => x.description));
                    if (activityTypeUnitOptionsResponse.result!.length === 1) {
                        setSelectedActivityRow({ ...selectedActivityRow, activityTypeId: activityTypeId, unitId: activityTypeUnitOptionsResponse.result![0].id });
                    } else {
                        setSelectedActivityRow({ ...selectedActivityRow, activityTypeId: activityTypeId, unitId: 0 });
                    }
                }
                dispatch(setIsLoading(false));
            }
        }
    };

    const hideActivityDialog = () => {
        setActivityTypeUnitOptions([]);
        setSelectedActivityRow(undefined);
        setActivityDialogIsOpen(false);
    };

    const hideDeleteDialog = () => {
        setSelectedActivityRow(undefined);
        setDeleteDialogIsOpen(false);
    };

    const openActivityDialog = async (activityId: number | undefined) => {
        let serviceExecutionActivityToSet: ServiceExecutionActivityFormModel | undefined = undefined;
        if (activityId) {
            serviceExecutionActivityToSet = serviceExecutionActivities.find(sea => sea.id === activityId);
            if (serviceExecutionActivityToSet) {
                const currentActivityType = activityTypeOptions.find(at => at.id === serviceExecutionActivityToSet?.activityTypeId);
                if (currentActivityType) {
                    dispatch(setIsLoading(true));
                    const activityTypeUnitOptionsResponse = await unitService.GetUnitOptionsForUnitCategory(currentActivityType.unitCategoryId);
                    if (activityTypeUnitOptionsResponse.isSuccess) {
                        setActivityTypeUnitOptions(orderBy(activityTypeUnitOptionsResponse.result!, x => x.description));
                    }
                    dispatch(setIsLoading(false));
                }
            }
        } else {
            serviceExecutionActivityToSet = getDefaultServiceExecutionActivity(serviceExecution.id);
            if (technicianOptions.length === 1) {
                serviceExecutionActivityToSet.assigneeId = technicianOptions[0].id;
            }
        }

        setSelectedActivityRow(serviceExecutionActivityToSet);
        setActivityDialogIsOpen(true);
    };

    const openDeleteDialog = (serviceExecutionActivityId: number) => {
        const selectedServiceExceutionActivity = serviceExecutionActivities.find(sea => sea.id === serviceExecutionActivityId);

        setSelectedActivityRow(selectedServiceExceutionActivity);
        setDeleteDialogIsOpen(true);
    };

    const serviceExecutionDateTemplate = () => {
        return moment(serviceExecution.servicePlanningStartDate).format(currentUser.preferences.dateFormat);
    };

    const serviceExecutionActivitySubmitIsDisabled = !selectedActivityRow || !selectedActivityRow.assigneeId ||
        !selectedActivityRow.activityTypeId || !selectedActivityRow.unitId || !selectedActivityRow.value;

    useEffect(() => {
        getServiceExecutionActivities();
        getActivityTypeOptions();
        getTechnicianOptions();
    }, []);

    let columns: GridEnrichedColDef[] = [
        { field: 'servicePlanningStartDate', headerName: t('Date of Service Visit'), valueGetter: (params) => serviceExecutionDateTemplate(), type: 'date', disableExport: true, flex: 2, sortable: false, filterable: false, disableColumnMenu: true },
        { field: 'assigneeFullName', headerName: t('Technician'), disableExport: true, flex: 1.5, sortable: false, filterable: false, disableColumnMenu: true },
        { field: 'activityTypeLabel', headerName: t('Activity Type'), valueGetter: (params) => t(params.row.activityTypeLabel), disableExport: true, flex: 2, sortable: false, filterable: false, disableColumnMenu: true },
        { field: 'value', headerName: t('Value'), disableExport: true, flex: 1, sortable: false, filterable: false, disableColumnMenu: true },
        { field: 'unitLabel', headerName: t('Unit'), valueGetter: (params) => t(params.row.unitLabel), disableExport: true, flex: 1, sortable: false, filterable: false, disableColumnMenu: true },
    ];

    let actionsColumn: GridEnrichedColDef[] =
        [
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                flex: 1,
                getActions: (params: GridRowParams<ServiceExecutionActivityFormModel>) => {
                    let actionsArray = [];

                    actionsArray.push(
                        <DataGridActionOverview
                            key={'edit'}
                            icon={<Icon type='edit' />}
                            label={t('Edit')}
                            onClick={() => openActivityDialog(params.row.id)}
                            showInMenu
                        />
                    );

                    actionsArray.push(
                        <DataGridActionOverview
                            key={'delete'}
                            icon={<Icon type='delete' />}
                            label={t('Delete')}
                            onClick={() => openDeleteDialog(params.row.id)}
                            showInMenu
                        />
                    );

                    return actionsArray;
                },
            },
        ];

    columns = !readonly ? [...columns, ...actionsColumn] : [...columns];

    //#region Dropdown and Radio options and values
    const technicianDropdownOptions = technicianOptions.map(o => { return { id: o.id, label: o.name, value: o.id }; });
    const technicianValueOption = technicianDropdownOptions.find(o => o.id === selectedActivityRow?.assigneeId);

    const activityTypeDropdownOptions = activityTypeOptions.map(o => { return { id: o.id, label: t(o.description), value: o.id }; });
    const activityTypeValueOption = activityTypeDropdownOptions.find(o => o.id === selectedActivityRow?.activityTypeId);

    const unitDropdownOptions = activityTypeUnitOptions.map(o => { return { id: o.id, label: t(o.description), value: o.id }; });
    const unitValueOption = unitDropdownOptions.find(o => o.id === selectedActivityRow?.unitId);
    //#endregion

    return (
        <>
            <Box mb={10}>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} paddingBottom={'5px'}>
                    {
                        showHeader ?
                            <Title variant={'h4'} text={t('Time Confirmation')} align='left' /> : <div></div>
                    }

                    <IconButton
                        type='button'
                        disabled={readonly}
                        hidden={readonly}
                        sx={{ '& .material-symbols-outlined': { fontSize: '40px' } }}
                        onClick={() => openActivityDialog(undefined)}
                    >
                        <Icon
                            type='add'
                            color={readonly ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255)'}
                        />
                    </IconButton>
                </Box>

                <DataGrid
                    apiRef={apiRef}
                    rows={serviceExecutionActivities}
                    columns={columns}
                    autoHeight
                    containerHeight='auto'
                    getRowHeight={() => 'auto'}
                    hideFooter
                    sx={InnerTableSxObject}
                    localeText={{ noRowsLabel: t('No rows') }}
                />
            </Box>

            {
                activityDialogIsOpen &&
                <Dialog
                    open={activityDialogIsOpen}
                    onClose={hideActivityDialog}
                    maxWidth={'lg'}
                    fullWidth
                    PaperProps={{ className: styles.activities_dialog_paper }}
                >
                    <DialogTitle component='h4'>
                        {t('Activity')}

                        <IconButton
                            aria-label="close"
                            onClick={hideActivityDialog}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Icon type={'close'} />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent style={{ overflowY: 'visible' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={t('Date of Service Visit')}
                                    value={moment(serviceExecution.servicePlanningStartDate).format(currentUser.preferences.dateFormat)}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Dropdown<StyledSearchableDropdownProps>
                                    id={'assigneeId'}
                                    label={t('Technician')}
                                    name={'assigneeId'}
                                    variant={'searchable'}
                                    options={technicianDropdownOptions ? technicianDropdownOptions : []}
                                    value={technicianValueOption ?? { id: '', label: '', value: '' }}
                                    onChange={(e, item) => handleActivityChange('assigneeId', (item as DropdownOption).value)}
                                    sx={DropdownSxObject}
                                    optionListStyles={{ fontFamily: 'system-ui' }}
                                    noOptionsText={t(NO_OPTIONS_TEXT)}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Dropdown<StyledSearchableDropdownProps>
                                    id={'selectedActivityRow?.activityTypeId'}
                                    label={t('Activity Type')}
                                    name={'activityTypeId'}
                                    variant={'searchable'}
                                    options={activityTypeDropdownOptions ? activityTypeDropdownOptions : []}
                                    value={activityTypeValueOption ?? { id: '', label: '', value: '' }}
                                    onChange={(e, item) => handleActivityTypeChange(Number((item as DropdownOption).value))}
                                    sx={DropdownSxObject}
                                    noOptionsText={t(NO_OPTIONS_TEXT)}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={t('Value')}
                                    value={selectedActivityRow?.value}
                                    inputProps={{ maxLength: 10 }}
                                    inputRestriction='decimal'
                                    onChange={(e) => handleActivityChange('value', e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Dropdown<StyledSearchableDropdownProps>
                                    id={'selectedActivityRow?.unitId'}
                                    label={t('Unit')}
                                    name={'unitId'}
                                    variant={'searchable'}
                                    options={unitDropdownOptions ? unitDropdownOptions : []}
                                    value={unitValueOption ?? { id: '', label: '', value: '' }}
                                    onChange={(e, item) => handleActivityChange('unitId', (item as DropdownOption).value)}
                                    sx={DropdownSxObject}
                                    noOptionsText={t(NO_OPTIONS_TEXT)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions sx={{ paddingBottom: '1rem' }}>
                        <Button
                            id='save'
                            label={t('OK')}
                            startIcon='check'
                            variant='primary'
                            onClick={onServiceExecutionActivitySubmit}
                            disabled={serviceExecutionActivitySubmitIsDisabled}
                        />
                        <Button id='cancel' label={t('Cancel')} startIcon='cancel' variant='secondary' onClick={hideActivityDialog} />
                    </DialogActions>
                </Dialog>
            }


            {
                deleteDialogIsOpen &&
                <ConfirmationDialog
                    open={deleteDialogIsOpen}
                    message={t('Are you sure you want to delete this Activity?')}
                    onConfirm={onServiceExecutionActivityDelete}
                    onClose={hideDeleteDialog}
                />
            }
        </>
    );
};
