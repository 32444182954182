import uuid from 'react-uuid';
import { getDefaultPermissions, UserPermissions } from './userPermissions';
import { UserAdditionalInfoFormModel, getDefaultUserAdditionalInfo } from './userAdditionalInfoModel';
import { getDefaultPreferences, UserPreferences } from './userPreferences';
import { RoleActions } from '../../shared/enums/RoleActions';

export class ExternalIdentity {
    id: number = 0;
    userId: number = 0;
    identifier: string = '';
    tempKey: string = '';
}

export const getDefaultIdentity = (): ExternalIdentity => {
    return {
        id: 0,
        userId: 0,
        identifier: '',
        tempKey: uuid(),
    };
};

export const getDefaultUserOrganizationRole = (): UserOrganizationRoleFormValues => {
    return {
        id: 0,
        roleId: 0,
        role: '',
        organizationId: undefined,
        organization: '',
        userId: 0,
        brandIds: [],
        brands: [],
        famCode: '',
        tempKey: uuid(),
    };
};

export const getDefaultUserFormValues = (): UserFormValues => {
    return {
        id: 0,
        isActive: false,
        firstName: '',
        lastName: '',
        email: '',
        userAdditionalInformation: getDefaultUserAdditionalInfo(),
        externalIdentities: [getDefaultIdentity()],
        userOrganizationRoles: [getDefaultUserOrganizationRole()],
        canBeActivatedByUser: false,
        canBeDeactivatedByUser: false,
        canBeModifiedByUser: false,
    };
};

export interface RoleToAssign {
    id: number;
    key: string;
}

export type UserFormModel = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
};

export type UserOverviewModelDto = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    city: string;
    createdOn: Date;
    userOrganizationInfo: UserOrganizationInfoForOverview[];
    creationDate: Date;
    isActive: boolean;
    canBeActivatedByUser: boolean;
    canBeDeactivatedByUser: boolean;
    canBeModifiedByUser: boolean;
}

export type UserOverviewModel = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    city: string;
    createdOn: Date;
    userOrganizationInfo: UserOrganizationInfoForOverview;
    creationDate: Date;
    isActive: boolean;
    canBeActivatedByUser: boolean;
    canBeDeactivatedByUser: boolean;
    canBeModifiedByUser: boolean;
}

export type UserGridModel = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    city: string;
    createdOn: Date;
    role: string;
    distributorName: string;
    famCode: string;
    brands: string[];
    creationDate: Date;
    isActive: boolean;
    canBeActivatedByUser: boolean;
    canBeDeactivatedByUser: boolean;
    canBeModifiedByUser: boolean;
};

export type UserOrganizationInfoForOverview = {
    famCode: string;
    distributorName: string;
    brands: string[];
    role: string;
    roleId: number;
    organizationId?: number;
}

export type UserFormValues = {
    id: number;
    isActive: boolean;
    firstName: string;
    lastName: string;
    email: string;
    userAdditionalInformation: UserAdditionalInfoFormModel;
    externalIdentities: ExternalIdentity[];
    userOrganizationRoles: UserOrganizationRoleFormValues[];
    canBeActivatedByUser: boolean;
    canBeDeactivatedByUser: boolean;
    canBeModifiedByUser: boolean;
}

export class AuthenticatedUser {
    id: number = 0;
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    roles: RoleToAssign[] = [];
    userOrganizationRoles: UserOrganizationRole[] = [];
    isOnMobileDevice: boolean = false;
    permissions: UserPermissions = getDefaultPermissions();
    preferences: UserPreferences = getDefaultPreferences();
}

export type UserOrganizationRole = {
    id: number;
    tempKey: string;
    roleId: number;
    role: string;
    isActive: boolean;
    organizationId: number;
    organization: string;
    userId: number;
    rolesToAssign: RoleToAssign[];
    selectedRoles: RoleToAssign[];
    serviceExecutionPermissions: RoleActions;
}

export type UserOrganizationRoleFormValues = {
    id: number;
    roleId: number;
    role: string;
    organizationId: number | undefined;
    organization: string;
    userId: number;
    brandIds: number[];
    brands: string[];
    famCode: string;
    tempKey: string;
}

export type UserCreateModel = {
    firstName: string;
    lastName: string;
    email: string;
    externalIdentities: { identifier: string; }[];
    userAdditionalInformation: {
        phoneNumber: string;
        city: string;
        countryId: number;
    }
    userOrganizationRoles: {
        roleId: number;
        organizationId: number | undefined;
        brandIds: number[];
    }[];
};

export type UserUpdateModel = {
    id: number | undefined;
    firstName: string;
    lastName: string;
    email: string;
    externalIdentities: { id: number; identifier: string; userId: number; }[];
    userAdditionalInformation: {
        id: number;
        phoneNumber: string;
        city: string;
        countryId: number;
        userId: number;
    }
    userOrganizationRoles: {
        id: number;
        userId: number;
        roleId: number;
        organizationId: number | undefined;
        brandIds: number[];
    }[];
};

export const mapUserFormValuesToCreateModel = (values: UserFormValues): UserCreateModel => {
    return {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        userAdditionalInformation: {
            phoneNumber: values.userAdditionalInformation.phoneNumber,
            countryId: values.userAdditionalInformation.countryId,
            city: values.userAdditionalInformation.city,
        },
        externalIdentities: values.externalIdentities.map(ei => ({ identifier: ei.identifier })),
        userOrganizationRoles: values.userOrganizationRoles.map(uor => (
            {
                roleId: uor.roleId,
                organizationId: uor.organizationId,
                brandIds: uor.brandIds,
            }
        ))
    };
};

export const mapUserFormValuesToUpdateModel = (values: UserFormValues): UserUpdateModel => {
    return {
        id: values.id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        userAdditionalInformation: {
            id: values.userAdditionalInformation.id,
            phoneNumber: values.userAdditionalInformation.phoneNumber,
            countryId: values.userAdditionalInformation.countryId,
            city: values.userAdditionalInformation.city,
            userId: values.userAdditionalInformation.userId,
        },
        externalIdentities: values.externalIdentities.map(ei => ({ id: ei.id, identifier: ei.identifier, userId: ei.userId })),
        userOrganizationRoles: values.userOrganizationRoles.map(uor => (
            {
                id: uor.id,
                userId: uor.userId,
                roleId: uor.roleId,
                organizationId: uor.organizationId,
                brandIds: uor.brandIds,
            }
        ))
    };
};

export type ActiveServicePlanningByUserViewModel = {
    id: number;
    description: string;    
    start: Date;
    end: Date;
};