import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { CustomerCreationKpiFilterModel } from '../../models/CustomerCreationKpiFilterModel';
import { CustomerCreationByMonthViewModel } from '../../../models/dashboard/CustomerCreationByMonthViewModel';
import { dashboardService } from '../../../services/DashboardService';
import { ChartsAxisContentProps, LineChart } from '@mui/x-charts';
import { ChartSxObject } from '../../../shared/utils/sxStyleObjects';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { CustomChartTooltipContent } from '../customChartTooltipContent/CustomChartTooltipContent';
import { showErrors } from '../../../stores/alert/alertSlice';

interface ICustomerCreationMonthViewProps {
    appliedFilters: CustomerCreationKpiFilterModel;
}

export const CustomerCreationMonthView = (props: ICustomerCreationMonthViewProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { appliedFilters } = props;

    const [customersData, setCustomersData] = useState<CustomerCreationByMonthViewModel[]>([]);

    const getCustomersCountByMonth = async (filters: any) => {
        dispatch(setIsLoading(true));

        const customersByMonthResponse = await dashboardService.GetCustomersCreationCountByMonth(filters);
        if (customersByMonthResponse.isSuccess) {
            setCustomersData(customersByMonthResponse.result!);
        } else {
            dispatch(showErrors(customersByMonthResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getCustomersCountByMonth(appliedFilters);
    }, [appliedFilters]);

    const months = {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    };

    const distributorNames: string[] = [...new Set(customersData.map(cd => cd.distributor))];

    return (
        <>
            <LineChart
                xAxis={[{
                    data: Object.keys(months).map(k => Number(k)),
                    valueFormatter: (val) => moment(t(months[val as keyof typeof months]), 'MMMM').format('MMM'),
                    min: 1,
                    max: 12,
                }]}
                series={distributorNames.map(d => (
                    {
                        data: Object.keys(months).map(m => {
                            const result = customersData.find(cd => cd.distributor === d && cd.month.toString() === m);
                            const value = result ? result.count : 0;
                            return value;
                        }),
                        label: d
                    }
                ))}
                slotProps={{ legend: { hidden: true } }}
                sx={ChartSxObject}
                slots={{
                    axisContent: (props: ChartsAxisContentProps) =>
                        <CustomChartTooltipContent
                            axisValue={props.axisValue}
                            series={props.series}
                            dataIndex={props.dataIndex}
                            xAxisLabels={Object.values(months).map(v => t(v))}
                            seriesLabels={distributorNames}
                        />
                }}
            />
        </>
    );
};