import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { Box, Grid, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import { OnboardedDistributorsFilterModel, getDefaultOnboardedDistributorsFilterModel } from '../../models/OnboardedDistributorsFilterModel';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { dashboardService } from '../../../services/DashboardService';
import { OnboardedDistributorsCountByMonthViewModel, getDefaultOnboardedDistributorsCountByMonth } from '../../../models/dashboard/OnboardedDistributorsCountByMonthViewModel';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps } from '@ui-components/ui-library';
import { ALL_TEXT, NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import moment from 'moment-timezone';
import { BarChart } from '@mui/x-charts/BarChart';
import { ChartSxObject } from '../../../shared/utils/sxStyleObjects';
import { CustomChartTooltipContent } from '../customChartTooltipContent/CustomChartTooltipContent';
import { isEqual } from 'lodash';
import { showErrors } from '../../../stores/alert/alertSlice';
import { ChartsAxisContentProps } from '@mui/x-charts';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';

export const OnboardedDistributorsDashboard = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));

    const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useState<OnboardedDistributorsFilterModel>(getDefaultOnboardedDistributorsFilterModel());
    const [notAppliedFilters, setNotAppliedFilters] = useState<any>({});

    const [distributorsByMonth, setOnboardedDistributorsByMonth] = useState<OnboardedDistributorsCountByMonthViewModel>(getDefaultOnboardedDistributorsCountByMonth());

    const theme = useTheme();
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const organizationOptions: OrganizationOptionModel[] = useAppSelector(state => state.Dashboard.organizationOptions);
    const customerCenterOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.CustomerCenter), [organizationOptions]);

    const hasAppliedFilters: boolean = appliedFilters.customerCenterId !== undefined ||
        (appliedFilters.year !== undefined && appliedFilters.year !== moment().year());

    const getOnboardedDistributors = async (filters: OnboardedDistributorsFilterModel) => {
        dispatch(setIsLoading(true));

        const onboardedDistributorsResponse = await dashboardService.GetOnboardedDistributorsCount(filters);

        if (onboardedDistributorsResponse.isSuccess) {
            setOnboardedDistributorsByMonth(onboardedDistributorsResponse.result!);
        } else {
            dispatch(showErrors(onboardedDistributorsResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const applyButtonIsDisabled = (): boolean => {
        return Object.keys(notAppliedFilters).length === 0 || !Object.keys(notAppliedFilters).some(naf =>
            !isEqual(appliedFilters[naf as keyof typeof appliedFilters], notAppliedFilters[naf]));
    };

    const clearFilters = async () => {
        await getOnboardedDistributors(getDefaultOnboardedDistributorsFilterModel());
        setAppliedFilters(getDefaultOnboardedDistributorsFilterModel());
    };

    const closeFilterModal = () => {
        setFilterModalIsOpen(false);
        setNotAppliedFilters({});
    };

    const applyFilters = async (): Promise<void> => {
        await getOnboardedDistributors({ ...appliedFilters, ...notAppliedFilters });
        setAppliedFilters({ ...appliedFilters, ...notAppliedFilters });
        closeFilterModal();
    };

    const getSelectedDropdownOption = (propName: keyof OnboardedDistributorsFilterModel, optionsCollection: any[], labelPropName: string): DropdownOption | null | undefined => {
        if ((!appliedFilters[propName as keyof typeof appliedFilters] && !notAppliedFilters[propName as keyof typeof notAppliedFilters]) ||
            (propName in notAppliedFilters && notAppliedFilters[propName as keyof typeof notAppliedFilters] === undefined)) {
            return null;
        }

        if (notAppliedFilters[propName as keyof typeof notAppliedFilters]) {
            return optionsCollection.find(et => et.id === notAppliedFilters[propName as keyof typeof notAppliedFilters]);
        } else if (appliedFilters[propName as keyof typeof appliedFilters]) {
            return optionsCollection.find(o => o.id === appliedFilters[propName as keyof typeof appliedFilters]);
        }
    };

    const handleCustomerCenterChange = (value: number | undefined) => {
        if (value) {
            setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value });
        } else {
            setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: undefined });
        }
    };

    const handleFilterChange = (propName: keyof OnboardedDistributorsFilterModel, value: any): void => {
        setNotAppliedFilters({ ...notAppliedFilters, [propName]: value });
    };

    const customerCenterDropdownOptions = useMemo(() => {
        if (customerCenterOptions && customerCenterOptions.length > 0) {
            return customerCenterOptions.map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        }

        return [];
    }, [customerCenterOptions]);
    const labels = Object.keys(getDefaultOnboardedDistributorsCountByMonth()).map(k => t(k));

    useEffect(() => {
        getOnboardedDistributors(getDefaultOnboardedDistributorsFilterModel());
    }, []);

    return (
        <>
            <Box dir={'ltr'}>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'} dir={shouldUseRtl ? 'rtl' : 'ltr'}>
                    <Box fontSize={18} flex={2} textAlign={'left'}>{t('Onboarded Distributors')}</Box>

                    <Tooltip
                        title={t('Filter')}
                    >
                        <IconButton id={'filter'} onClick={() => setFilterModalIsOpen(true)}>
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>

                    {
                        hasAppliedFilters &&
                        <Tooltip
                            title={t('Clear filters')}
                        >
                            <IconButton id={'clear_filters'} onClick={clearFilters}>
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>

                <BarChart
                    xAxis={[
                        {
                            id: 'onboardedDistributorsByMonth',
                            data: labels,
                            scaleType: 'band',
                            tickLabelInterval: matchUpSm ? () => true : 'auto',
                            valueFormatter: (val) => moment(val, 'MMMM').format('MMM'),
                        },
                    ]}
                    series={[
                        {
                            data: [
                                distributorsByMonth.January || 0,
                                distributorsByMonth.February || 0,
                                distributorsByMonth.March || 0,
                                distributorsByMonth.April || 0,
                                distributorsByMonth.May || 0,
                                distributorsByMonth.June || 0,
                                distributorsByMonth.July || 0,
                                distributorsByMonth.August || 0,
                                distributorsByMonth.September || 0,
                                distributorsByMonth.October || 0,
                                distributorsByMonth.November || 0,
                                distributorsByMonth.December || 0,
                            ],
                        },
                    ]}
                    sx={ChartSxObject}
                    tooltip={{ trigger: 'axis' }}
                    slots={{
                        axisContent: (props: ChartsAxisContentProps) =>
                            <CustomChartTooltipContent
                                axisValue={props.axisValue}
                                series={props.series}
                                dataIndex={props.dataIndex}
                            />
                    }}
                />
            </Box>

            <FilterModal
                isOpen={filterModalIsOpen}
                onClose={closeFilterModal}
                onApply={applyFilters}
                disableApply={applyButtonIsDisabled()}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            id={'year'}
                            inputFormat={'YYYY'}
                            value={notAppliedFilters['year'] ? moment(notAppliedFilters['year'], 'YYYY').startOf('y').toDate() : moment(appliedFilters['year'], 'YYYY').startOf('y').toDate()}
                            onChange={value => value ? handleFilterChange('year', moment(value).startOf('y').year()) : undefined}
                            disableFuture
                            views={['year']}
                            title={t('Year')}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Dropdown<StyledSearchableDropdownProps>
                            label={t('Customer Center')}
                            options={customerCenterDropdownOptions}
                            value={getSelectedDropdownOption('customerCenterId', customerCenterDropdownOptions, 'famCode')}
                            onChange={(e, item) => handleCustomerCenterChange(item ? Number((item as DropdownOption).value) : undefined)}
                            variant='searchable'
                            usePopper
                            disabled={customerCenterDropdownOptions.length === 0}
                            noOptionsText={t(NO_OPTIONS_TEXT)}
                            placeholder={t(ALL_TEXT)}
                        />
                    </Grid>
                </Grid>
            </FilterModal>
        </>
    );
};