import * as Yup from 'yup';
import { EquipmentValidationMessages, InvalidDateMessage, InvalidNumberMessage, NumberTooBigMessage, RequiredMessage, ShortValueMessage } from '../../shared/utils/validationMessages';
import { EquipmentFormModel } from '../models/equipment';
import { ContractType } from '../../shared/enums/ContractType';
import moment from 'moment-timezone';

export const compressorEquipmentFormValidationSchema = () => {
    return Yup.object().shape({
        serialNumber: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        brandId: Yup.number()
            .required(RequiredMessage)
            .min(1, RequiredMessage),
        equipmentTypeId: Yup.number()
            .min(1, RequiredMessage),
        contractType: Yup
            .number()
            .required(RequiredMessage),
        contractStartDate: Yup
            .date()
            .optional()
            .test('contract-start-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractExpirationDate = this.parent.contractExpirationDate;
                if (contractExpirationDate) {
                    return value !== undefined && moment(contractExpirationDate).add(1, 'd') >= moment(value);
                }

                return true;
            }),
        contractExpirationDate: Yup
            .date()
            .optional()
            .test('contract-expiration-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractStartDate: Date | undefined = this.parent.contractStartDate;
                if (contractStartDate) {
                    return value !== undefined && moment(contractStartDate).add(1, 'd') <= moment(value);
                }

                return true;
            }),
        otherContractType: Yup
            .string()
            .max(200, EquipmentValidationMessages.maxCharacters)
            .when('contractType', {
                is: ContractType.Other,
                then: Yup.string().optional(),
            }),
        size: Yup.number()
            .typeError(InvalidNumberMessage)
            .required(RequiredMessage)
            .min(0, RequiredMessage),
        estimatedProductionDate: Yup.date()
            .optional()
            .test('production-date-validation', EquipmentValidationMessages.productionYearAfterStartupDate, function (value) {
                const startupDate = this.parent.startupDate;
                if (startupDate && value) {
                    return moment(value).year() <= moment(startupDate).year();
                }
                return true;
            })
            .test('production-date-validation', EquipmentValidationMessages.productionYearAfterMaintananceDate, function (value) {
                const lastMaintenanceDate = this.parent.equipmentDetail.lastMaintenanceDate;
                if (lastMaintenanceDate && value) {
                    return moment(value).year() <= moment(lastMaintenanceDate).year();
                }
                return true;
            }),
        startupDate: Yup.date()
            .typeError(InvalidDateMessage)
            .optional()
            .test('startup-date-validation', EquipmentValidationMessages.startDateBeforeYearOfProduction, function (value) {
                const estimatedProductionDate = this.parent.estimatedProductionDate;
                if (estimatedProductionDate && value) {
                    return moment(value).year() >= moment(estimatedProductionDate).year();
                }

                return true;
            })
            .test('startup-date-validation', EquipmentValidationMessages.startDateBeforeMaintananceDate, function (value) {
                const lastMaintenanceDate = this.parent.equipmentDetail.lastMaintenanceDate;
                if (lastMaintenanceDate && value) {
                    return moment(value) <= moment(lastMaintenanceDate);
                }

                return true;
            }),
        competitorModel: Yup.string()
            .trim()
            .required(RequiredMessage)
            .min(2, ShortValueMessage),
        modelId: Yup.number().optional(),
        installedBaseCode: Yup.string().optional(),
        inverterDrive: Yup.boolean(),
        distributorOrganizationId: Yup.number()
            .required(RequiredMessage)
            .min(1, RequiredMessage),
        customerId: Yup.number().optional(),
        equipmentDetail: Yup.object().when(['startupDate', 'estimatedProductionDate'], (startupDate, estimatedProductionDate) => {
            const estimatedProductionDateValue =
                (estimatedProductionDate !== undefined && !isNaN(estimatedProductionDate.valueOf())) ?
                    estimatedProductionDate : undefined;

            const startupDateValue = (startupDate !== undefined && !isNaN(startupDate.valueOf())) ?
                startupDate : undefined;

            const minDate = (estimatedProductionDateValue && startupDateValue) ?
                moment.min(moment(estimatedProductionDateValue), moment(startupDateValue)) :
                (estimatedProductionDateValue || startupDateValue) ?? new Date(1, 0, 1);

            return Yup.object().shape({
                equipmentStatusId: Yup.number()
                    .min(1, RequiredMessage),
                estimatedWorkingHoursPerYear: Yup.number()
                    .optional()
                    .min(1, RequiredMessage)
                    .max(8784, NumberTooBigMessage),
                lastMaintenanceDate:
                    Yup.date()
                        .optional()
                        .max(moment().endOf('d').toDate())
                        .min(minDate, EquipmentValidationMessages.maintenanceDateBeforeProductionOrStartup),

                connectivityDetail: Yup.object().shape({
                    isConnected: Yup.boolean(),
                }),
            });
        })
    });
};

export const airTreatmentEquipmentFormValidationSchema = () => {
    return Yup.object().shape({
        serialNumber: Yup.string().trim().required(RequiredMessage)
            .min(2, ShortValueMessage),
        brandId: Yup.number()
            .required(RequiredMessage)
            .min(1, RequiredMessage),
        equipmentTypeId: Yup.number()
            .min(1, RequiredMessage),
        contractType: Yup
            .number()
            .required(RequiredMessage),
        contractStartDate: Yup
            .date()
            .optional()
            .test('contract-start-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractExpirationDate = this.parent.contractExpirationDate;
                if (contractExpirationDate) {
                    return value !== undefined && moment(contractExpirationDate).add(1, 'd') >= moment(value);
                }

                return true;
            }),
        contractExpirationDate: Yup
            .date()
            .optional()
            .test('contract-expiration-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractStartDate = this.parent.contractStartDate;
                if (contractStartDate) {
                    return value !== undefined && moment(contractStartDate).add(1, 'd') <= moment(value);
                }

                return true;
            }),
        otherContractType: Yup
            .string()
            .max(200, EquipmentValidationMessages.maxCharacters)
            .when('contractType', {
                is: ContractType.Other,
                then: Yup.string().optional(),
            }),
        flow: Yup.number()
            .typeError(InvalidNumberMessage)
            .required(RequiredMessage)
            .min(0, RequiredMessage),
        size: Yup.number()
            .typeError(InvalidNumberMessage)
            .optional()
            .min(0, RequiredMessage),
        estimatedProductionDate: Yup.date()
            .typeError(InvalidDateMessage)
            .optional(),
        typeOfGas: Yup.string()
            .optional()
            .max(75),
        competitorModel: Yup.string().trim().required(RequiredMessage).min(2, ShortValueMessage),
        distributorOrganizationId: Yup.number()
            .required(RequiredMessage)
            .min(1, RequiredMessage),
        customerId: Yup.number().optional(),
        equipmentDetail: Yup.object().shape({
            equipmentStatusId: Yup.number()
                .min(1, RequiredMessage),
            lastMaintenanceDate:
                Yup.date()
                    .optional()
                    .max(moment().endOf('d').toDate()),
        })
    });
};

export const otherEquipmentFormValidationSchema = () => {
    return Yup.object().shape({
        serialNumber: Yup.string().trim().required(RequiredMessage)
            .min(2, ShortValueMessage),
        textBrand: Yup.string().trim().required(RequiredMessage).min(2, ShortValueMessage),
        description: Yup.string().optional(),
        equipmentTypeId: Yup.number()
            .min(1, RequiredMessage),
        contractType: Yup
            .number()
            .required(RequiredMessage),
        contractStartDate: Yup
            .date()
            .optional()
            .test('contract-start-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractExpirationDate = this.parent.contractExpirationDate;
                if (contractExpirationDate) {
                    return value !== undefined && moment(contractExpirationDate).add(1, 'd') >= moment(value);
                }

                return true;
            }),
        contractExpirationDate: Yup
            .date()
            .optional()
            .test('contract-expiration-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractStartDate = this.parent.contractStartDate;
                if (contractStartDate) {
                    return value !== undefined && moment(contractStartDate).add(1, 'd') <= moment(value);
                }

                return true;
            }),
        otherContractType: Yup
            .string()
            .max(200, EquipmentValidationMessages.maxCharacters)
            .when('contractType', {
                is: ContractType.Other,
                then: Yup.string().optional(),
            }),
        competitorModel: Yup.string().trim().required(RequiredMessage).min(2, ShortValueMessage),
        distributorOrganizationId: Yup.number()
            .required(RequiredMessage)
            .min(1, RequiredMessage),
        customerId: Yup.number().optional(),
    });
};

const equipmentFormGeneralInfoValidationSchema =
    Yup.object().shape({
        serialNumber: Yup.string()
            .trim()
            .required()
            .min(2),
        equipmentTypeId: Yup.number()
            .min(1),
        competitorModel: Yup.string()
            .trim()
            .required()
            .min(2),
        distributorOrganizationId: Yup.number()
            .min(1),
        contractType: Yup.number()
            .required()
            .min(0),
        contractStartDate: Yup
            .date()
            .optional()
            .test('contract-start-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractExpirationDate = this.parent.contractExpirationDate;
                if (contractExpirationDate) {
                    return value !== undefined && moment(contractExpirationDate).add(1, 'd') >= moment(value);
                }

                return true;
            }),
        contractExpirationDate: Yup
            .date()
            .optional()
            .test('contract-expiration-validation', EquipmentValidationMessages.contractStartDateAfterExpirationDate, function (value) {
                const contractStartDate = this.parent.contractStartDate;
                if (contractStartDate) {
                    return value !== undefined && moment(contractStartDate).add(1, 'd') <= moment(value);
                }

                return true;
            }),
    });


export const equipmentGeneralInfoIsValid = (equipment: EquipmentFormModel): boolean => {
    return equipmentFormGeneralInfoValidationSchema.isValidSync(equipment);
};