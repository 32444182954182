import React, { useEffect, useState } from 'react';
import { UploadProgressComponent } from '../../../components/uploadProgressComponent/UploadProgressComponent';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { ImportFileViewModel } from '../../../models/ImportFileViewModel';
import { showErrors } from '../../../stores/alert/alertSlice';
import { customerService } from '../../services/CustomerService';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Title } from '../../../components/form/title/Title';
import { Button } from '@ui-components/ui-library';
import { setIsLoading } from '../../../stores/layout/layoutSlice';

export const CustomerUploadProgress = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [importedFiles, setImportedFiles] = useState<ImportFileViewModel[]>([]);

    const getUserUplaodedCustomerFiles = async () => {
        dispatch(setIsLoading(true));

        const customerFilesResponse = await customerService.GetCustomerUploadFilesForUser();

        if (customerFilesResponse.isSuccess) {
            setImportedFiles(customerFilesResponse.result!);
        } else {
            dispatch(showErrors(customerFilesResponse.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getUserUplaodedCustomerFiles();
    }, []);

    return (
        <>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} alignContent={'center'} marginBottom={'2rem'}>
                <Box flex={1}>
                    <Button 
                        id={'refresh'}
                        type={'button'}
                        label={t('Refresh')}
                        startIcon={'refresh'}
                        onClick={getUserUplaodedCustomerFiles}                
                    />
                </Box>
                <Title variant={'h2'} text={t('Customer uploads')} marginBottom={'none'} />
                <Box flex={1}></Box>
            </Box>
            <UploadProgressComponent importedFiles={importedFiles} />
        </>
    );
};
