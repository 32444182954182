export enum OrganizationType {
    CustomerCenter = 1,
    Distributor = 2,
}

export const OrganizationTypeLabels: Record<OrganizationType, string> = {
    [OrganizationType.CustomerCenter]: 'Customer Center',
    [OrganizationType.Distributor]: 'Distributor',
};

export const getOrganizationTypeLabel = (organizationType: OrganizationType): string => {
    return OrganizationTypeLabels[organizationType];
};