import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { getDefaultServiceExecution, ServiceEquipmentMeasuredDataModel, ServiceExecutionFormModel } from '../../models/serviceExecutionModel';
import { CustomerDetailsSection } from '../customerDetailsSection/CustomerDetailsSection';
import { serviceExecutionService } from '../../services/ServiceExecutionService';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { EquipmentDetailSection } from '../equipmentDetailSection/EquipmentDetailSection';
import { ActivitiesSection } from '../activitiesSection/ActivitiesSection';
import { WorkCommentsSection } from '../workCommentsSection/WorkCommentsSection';
import { FutureRecommendationSection } from '../futureRecommendationSection/FutureRecommendationSection';
import moment from 'moment-timezone';
import { AuthenticatedUser } from '../../../users/models/user';
import { MaterialConformationSection } from '../materialConformationSection/MaterialConformationSection';
import { ServiceExecutionPartFormModel } from '../../models/serviceExecutionPartFormModel';
import { ServiceOderInformationSection } from '../serviceOrderInformationSection/ServiceOrderInformationSection';
import { ServiceExecutionStatus } from '../../models/serviceExecutionStatus';
import { serviceExecutionUsedPartService } from '../../services/ServiceExecutionUsedPartService';
import { Alert, Box, Grid, Typography, styled, useTheme, useMediaQuery } from '@mui/material';
import { Modal, Stepper, Checkbox } from '@ui-components/ui-library';
import { setIsLoading, setSuccessfullyLoaded } from '../../../stores/layout/layoutSlice';
import { IServiceExecutionFormProps, ServiceExecutionFormStep } from './IServiceExecutionFormProps';
import { SaveSection } from '../../../components/form/saveSection/SaveSection';
import { serviceExecutionEquipmentDetailsIsValid, serviceExecutionIsValid, serviceExecutionRecommendationIsValid, serviceExecutionValidationSchema, serviceExecutionWorkCommentIsValid } from '../../services/ServiceExecutionUtility';
import { Form, Formik } from 'formik';
import { areObjectsEqualNonStrict } from '../../../shared/utils/Utils';
import { ServiceExecutionWorkCommentCreateModel } from '../../models/serviceExecutionWorkCommentModel';
import { ServiceExecutionFutureRecommendationCreateModel } from '../../models/serviceExecutionFutureRecommendationModel';
import { i18n } from '../../../i18n/config';
import { EmailDialog } from '../../../components/emailDialog/EmailDialog';
import { NotificationCreateModel } from '../../models/notificationCreateModel';
import { notificationService } from '../../services/NotificationService';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { FinalizationSection } from '../finalizationSection/FinalizationSection';

const StyledLabel = styled('p')({
    fontSize: '14px',
    color: '#fff',
    marginBottom: '6px'
});

export const ServiceExecutionForm = (props: IServiceExecutionFormProps): JSX.Element => {
    const { formIsOpen, serviceExecutionId, handleClose, getServiceExecutions } = props;

    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const successfullyLoaded: boolean = useAppSelector(state => state.Layout.successfullyLoaded);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [serviceExecution, setServiceExecution] = useState<ServiceExecutionFormModel>(getDefaultServiceExecution());
    const [serviceExecutionUsedParts, setServiceExecutionUsedParts] = useState<ServiceExecutionPartFormModel[]>([]);
    const [step, setStep] = useState<number>(ServiceExecutionFormStep.CUSTOMERDETAILS);
    const [sectionShown, setSectionShown] = useState<number>(0);

    const [confirmCompleteDialogIsOpen, setConfirmCompleteDialogIsOpen] = useState<boolean>(false);
    const [emailDialogIsOpen, setEmailDialogIsOpen] = useState<boolean>(false);

    const formIsReadonly = serviceExecution.status === ServiceExecutionStatus.Completed;

    const theme = useTheme();
    const isMediumOrLargeDevice = useMediaQuery(theme.breakpoints.up('sm'));

    const getServiceExecution = async () => {
        if (serviceExecutionId) {
            dispatch(setIsLoading(true));
            const serviceExecutionResponse = await serviceExecutionService.GetServiceExecutionById(serviceExecutionId);

            if (serviceExecutionResponse.isSuccess) {
                if (serviceExecutionResponse.result?.serviceExecutionSigner === undefined ||
                    serviceExecutionResponse.result?.serviceExecutionSigner === null) {
                    setServiceExecution({ ...serviceExecution, ...serviceExecutionResponse.result!, personOfInterestToggle: true, completionConformation: false });
                } else {
                    setServiceExecution({ ...serviceExecution, ...serviceExecutionResponse.result! });
                }
                dispatch(setSuccessfullyLoaded(true));
            } else {
                dispatch(showErrors(serviceExecutionResponse?.errorModel?.errors));
                dispatch(setSuccessfullyLoaded(false));
            }

            dispatch(setIsLoading(false));
        }
    };

    const getServiceExecutionUsedParts = async () => {
        if (serviceExecutionId) {
            const response = await serviceExecutionUsedPartService.GetUsedPartsForServiceExecution(serviceExecutionId);
            if (response.isSuccess) {
                setServiceExecutionUsedParts(response.result!);
            } else {
                dispatch(showErrors(response.errorModel?.errors));
            }
        }
    };

    const saveEquipmentDetail = async (modelState: any) => {
        dispatch(setIsLoading(true));
        let actionResponse = undefined;

        const equipmentMeasuredDataBody: ServiceEquipmentMeasuredDataModel = {
            visitReportId: serviceExecution.id,
            runningHours: modelState.equipmentDetail.runningHours,
            loadedHours: modelState.equipmentDetail.loadedHours,
        };

        actionResponse = await serviceExecutionService.SetEquipmentMeasuredData(equipmentMeasuredDataBody);

        if (actionResponse && actionResponse.isSuccess) {
            dispatch(showSuccess(t('Equiment details saved.')));

            setServiceExecution({
                ...serviceExecution,
                equipmentDetail: {
                    ...serviceExecution.equipmentDetail,
                    runningHours: modelState.equipmentDetail.runningHours,
                    loadedHours: modelState.equipmentDetail.loadedHours
                }
            });
        } else {
            dispatch(showErrors(actionResponse.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    const handleEquipmentDetailsNext = async (modelState: any) => {
        if (!areObjectsEqualNonStrict(serviceExecution.equipmentDetail, modelState.equipmentDetail)) {
            await saveEquipmentDetail(modelState);
        }

        setStep(ServiceExecutionFormStep.WORKCOMMENTS);
    };

    const saveWorkComment = async (modelState: any) => {
        dispatch(setIsLoading(true));
        let actionResponse = undefined;

        let body: ServiceExecutionWorkCommentCreateModel = {
            visitReportId: serviceExecution.id,
            workComments: modelState.workComment.description,
        };

        actionResponse = await serviceExecutionService.SetServiceExecutionWorkComment(body);

        if (actionResponse && actionResponse.isSuccess) {
            dispatch(showSuccess(t('Work comments saved.')));
            setServiceExecution({ ...serviceExecution, workComment: { ...serviceExecution.workComment, description: modelState.workComment.description } });
        } else {
            dispatch(showErrors(actionResponse.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    const handleWorkCommentNext = async (modelState: any) => {
        if (!areObjectsEqualNonStrict(serviceExecution.workComment, modelState.workComment)) {
            await saveWorkComment(modelState);
        }

        setStep(ServiceExecutionFormStep.RECOMMENDATIONS);
    };

    const saveFutureRecommendation = async (modelState: any) => {
        dispatch(setIsLoading(true));
        let actionResponse = undefined;

        let body: ServiceExecutionFutureRecommendationCreateModel = {
            visitReportId: serviceExecution.id,
            description: modelState.futureRecommendation.description,
        };

        actionResponse = await serviceExecutionService.SetServiceExecutionFutureRecommendation(body);

        if (actionResponse && actionResponse.isSuccess) {
            dispatch(showSuccess(t('Next visit recommendations saved.')));
            setServiceExecution({ ...serviceExecution, futureRecommendation: { ...serviceExecution.futureRecommendation, description: modelState.futureRecommendation.description } });
        } else {
            dispatch(showErrors(actionResponse.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    const handleFutureRecommendationNext = async (modelState: any) => {
        if (!areObjectsEqualNonStrict(serviceExecution.futureRecommendation, modelState.futureRecommendation)) {
            await saveFutureRecommendation(modelState);
        }

        setStep(ServiceExecutionFormStep.TIMEMATERIALCONFIRMATION);
    };

    const partsAreInvalid = serviceExecutionUsedParts.some(p => p.quantityUsed === undefined);

    const markAsComplete = async (modelState: ServiceExecutionFormModel) => {
        dispatch(setIsLoading(true));
        let actionResponse = undefined;

        actionResponse = await serviceExecutionService.CompleteServiceExecution(
            {
                serviceExecutionId: serviceExecution.id,
                language: i18n.language,
                signerFirstName: modelState.serviceExecutionSigner.firstName,
                signerLastName: modelState.serviceExecutionSigner.lastName,
                signatureBase64String: modelState.serviceExecutionSigner.signature!,
                poiReceiverToggle: modelState.personOfInterestToggle,
            }
        );

        if (actionResponse && actionResponse.isSuccess) {
            dispatch(showSuccess(t(SUCCESS_MESSAGE)));

            if (getServiceExecutions) {
                await getServiceExecutions();
            }

            handleClose();
        } else {
            dispatch(showErrors(actionResponse.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    const sendServiceExecutionEmail = async (email: string, languageCode: string) => {
        dispatch(setIsLoading(true));
        const notificationCreateModel: NotificationCreateModel = {
            serviceExecutionId: serviceExecution.id,
            email: email,
            languageCode: languageCode,
        };

        const notificationCreateResponse = await notificationService.CreateServiceExecutionNotification(notificationCreateModel);

        if (notificationCreateResponse.isSuccess) {
            setEmailDialogIsOpen(false);
            dispatch(showSuccess(t(SUCCESS_MESSAGE)));
        } else {
            dispatch(showErrors(notificationCreateResponse.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getServiceExecution();
    }, []);

    useEffect(() => {
        getServiceExecutionUsedParts();
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setSuccessfullyLoaded(false));
        };
    }, []);

    const hideConfirmCompleteDialog = () => {
        setConfirmCompleteDialogIsOpen(false);
    };

    const steps: any = [
        { label: t('Customer Details') },
        { label: t('Service Order and Equipment Details') },
        { label: t('Work Comments') },
        { label: t('Next Visit Recommendations') },
        { label: t('Time and Material Confirmation') },
        { label: t('Finalization') },
    ];

    if (!successfullyLoaded) {
        return (
            <></>
        );
    }

    return (
        <>
            <Modal
                title={t('Service Execution')}
                open={formIsOpen}
                onClose={handleClose}
                maxWidth='lg'
                scroll='body'
                titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
                sx={{ '.MuiPaper-root': { width: '100%' } }}
            >
                <>
                    {
                        !isMediumOrLargeDevice &&
                        <Box sx={{ padding: '20px 0' }}>
                            <Typography sx={{ color: '#13ABF1' }}>
                                {t('Step {{current_step}} / {{steps_count}}', { current_step: step + 1, steps_count: steps.length })}
                            </Typography>
                            <Typography sx={{ color: '#13ABF1' }}>
                                {steps[step].label}
                            </Typography>
                        </Box>
                    }

                    {
                        isMediumOrLargeDevice &&
                        <Box sx={{ padding: '20px 0' }}>
                            <Stepper
                                steps={steps}
                                activeStep={step}
                            />
                        </Box>
                    }

                    <Formik
                        initialValues={serviceExecution}
                        validationSchema={serviceExecutionValidationSchema}
                        onSubmit={() => { }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <>
                                    {
                                        step === ServiceExecutionFormStep.CUSTOMERDETAILS &&
                                        <>
                                            <Grid container mt={1} mb={2}>
                                                <Grid item xs={6} md={6} xl={6}>
                                                    <StyledLabel>{t('Status')}</StyledLabel>
                                                    <StyledLabel>{t(serviceExecution.status)}</StyledLabel>
                                                </Grid>

                                                <Grid item xs={6} md={6} xl={6}>
                                                    <StyledLabel>{t('Created on')}</StyledLabel>
                                                    <StyledLabel>{moment(serviceExecution.createdOn).format(currentUser.preferences.dateFormat)}</StyledLabel>
                                                </Grid>
                                            </Grid>

                                            <CustomerDetailsSection showHeader={true} serviceExecution={serviceExecution} />
                                        </>
                                    }

                                    {
                                        step === ServiceExecutionFormStep.CUSTOMERDETAILS &&
                                        <SaveSection
                                            handleCancel={handleClose}
                                            handleNext={() => setStep(ServiceExecutionFormStep.SERVICEORDERANDEQUIPMENTDETAILS)}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.SERVICEORDERANDEQUIPMENTDETAILS &&
                                        <>
                                            <Box>
                                                <ServiceOderInformationSection showHeader={true} serviceExecution={serviceExecution} />
                                            </Box>
                                            <EquipmentDetailSection
                                                showHeader={true}
                                                readonly={formIsReadonly}
                                            />
                                        </>
                                    }

                                    {
                                        step === ServiceExecutionFormStep.SERVICEORDERANDEQUIPMENTDETAILS &&
                                        <SaveSection
                                            handleBack={() => setStep(ServiceExecutionFormStep.CUSTOMERDETAILS)}
                                            handleNext={() => handleEquipmentDetailsNext(values)}
                                            validateNext={() => serviceExecutionEquipmentDetailsIsValid(values.equipmentDetail)}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.WORKCOMMENTS &&
                                        <WorkCommentsSection
                                            showHeader={true}
                                            readonly={formIsReadonly}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.WORKCOMMENTS &&
                                        <SaveSection
                                            handleBack={() => setStep(ServiceExecutionFormStep.SERVICEORDERANDEQUIPMENTDETAILS)}
                                            handleNext={() => handleWorkCommentNext(values)}
                                            validateNext={() => serviceExecutionWorkCommentIsValid(values.workComment)}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.RECOMMENDATIONS &&
                                        <Box mt={2}>
                                            <FutureRecommendationSection
                                                showHeader={true}
                                                readonly={formIsReadonly}
                                            />
                                        </Box>
                                    }

                                    {
                                        step === ServiceExecutionFormStep.RECOMMENDATIONS &&
                                        <SaveSection
                                            handleBack={() => setStep(ServiceExecutionFormStep.WORKCOMMENTS)}
                                            handleNext={() => handleFutureRecommendationNext(values)}
                                            validateNext={() => serviceExecutionRecommendationIsValid(values.futureRecommendation)}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.TIMEMATERIALCONFIRMATION &&
                                        <Box mt={2} display='flex' flexDirection={'column'} gap={5}>
                                            <ActivitiesSection
                                                showHeader={true}
                                                serviceExecution={serviceExecution}
                                                sectionShown={sectionShown}
                                                setSectionShown={setSectionShown}
                                                readonly={formIsReadonly}
                                            />

                                            {
                                                partsAreInvalid &&
                                                <Alert
                                                    severity='warning'
                                                    sx={{ display: 'flex', justifyContent: 'center' }}                                                        >
                                                    {t('Section is missing required information.')}
                                                </Alert>
                                            }

                                            <MaterialConformationSection
                                                showHeader={true}
                                                serviceExecution={serviceExecution}
                                                serviceExecutionUsedParts={serviceExecutionUsedParts}
                                                refreshPartsTable={getServiceExecutionUsedParts}
                                                sectionShown={sectionShown}
                                                setSectionShown={setSectionShown}
                                                readonly={formIsReadonly}
                                            />
                                        </Box>
                                    }

                                    {
                                        step === ServiceExecutionFormStep.TIMEMATERIALCONFIRMATION &&
                                        <SaveSection
                                            handleBack={() => setStep(ServiceExecutionFormStep.RECOMMENDATIONS)}
                                            handleNext={() => setStep(ServiceExecutionFormStep.FINALIZATION)}
                                            validateNext={() => !partsAreInvalid}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.FINALIZATION &&
                                        <FinalizationSection
                                            readonly={formIsReadonly}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.FINALIZATION && !formIsReadonly &&
                                        <SaveSection
                                            handleBack={() => setStep(ServiceExecutionFormStep.TIMEMATERIALCONFIRMATION)}
                                            additionalButtons={[
                                                {
                                                    id: 'markascomplete',
                                                    type: 'button',
                                                    label: t('Mark as complete'),
                                                    variant: 'primary',
                                                    validate: () => { return serviceExecutionIsValid(values) && !partsAreInvalid; },
                                                    onClick: () => setConfirmCompleteDialogIsOpen(true)
                                                }
                                            ]}
                                        />
                                    }

                                    {
                                        step === ServiceExecutionFormStep.FINALIZATION && formIsReadonly &&
                                        <SaveSection
                                            handleBack={() => setStep(ServiceExecutionFormStep.TIMEMATERIALCONFIRMATION)}
                                            additionalButtons={[
                                                {
                                                    id: 'sendemail',
                                                    type: 'button',
                                                    label: t('Send as Email'),
                                                    variant: 'primary',
                                                    onClick: () => setEmailDialogIsOpen(true)
                                                }
                                            ]}
                                        />
                                    }
                                </>

                                {
                                    confirmCompleteDialogIsOpen &&
                                    <ConfirmationDialog
                                        message={t('Confirmation: Email Notification and Service Execution completion')}
                                        open={confirmCompleteDialogIsOpen}
                                        onConfirm={() => markAsComplete(values)}
                                        onClose={hideConfirmCompleteDialog}
                                        conformationDisabled={!serviceExecution.completionConformation}
                                    >
                                        {
                                            <>
                                                <Checkbox
                                                    checked={serviceExecution.completionConformation}
                                                    onChange={(value: boolean) => {
                                                        setFieldValue('completionConformation', value)
                                                        setServiceExecution({ ...serviceExecution, completionConformation: value })
                                                    }}
                                                    label={t('Are you sure you want to complete this service report?')}
                                                />
                                                <Checkbox
                                                    checked={serviceExecution.personOfInterestToggle}
                                                    onChange={(value: boolean) => {
                                                        setFieldValue('personOfInterestToggle', value);
                                                        setServiceExecution({ ...serviceExecution, personOfInterestToggle: value })
                                                    }}
                                                    label={t('Should person of interest receive an email after completion?')}
                                                />
                                            </>
                                        }
                                    </ConfirmationDialog>
                                }

                                {
                                    emailDialogIsOpen &&
                                    <EmailDialog
                                        header={t('Send as Email')}
                                        visible={emailDialogIsOpen}
                                        onClose={() => setEmailDialogIsOpen(false)}
                                        onSubmit={sendServiceExecutionEmail}
                                    />
                                }
                            </Form>
                        )}
                    </Formik>
                </>
            </Modal>
        </>
    );
};
