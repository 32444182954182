import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers-pro';
import { Box } from '@mui/material';
import { DatePickerTextField } from '@ui-components/ui-library';
import {
    GridFilterInputValueProps,
} from '@mui/x-data-grid-pro';
import { Moment } from 'moment-timezone';
import { DatePickerLocalizationProvider } from '../datePickerLocalizationProvider/DatePickerLocalizationProvider';
import { DatePickerSxObject } from '../../shared/utils/sxStyleObjects';

export const FilterDatePicker = (props: GridFilterInputValueProps & { inputFormat?: string }) => {

    const { item, applyValue, inputFormat } = props;

    const handleFilterChange = (event: Moment | null) => {
        applyValue({ ...item, value: event?.format('YYYY-MM-DD') });
    };

    return (
        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
            <DatePickerLocalizationProvider>
                <MuiDatePicker
                    value={item.value || null}
                    onChange={handleFilterChange}
                    showDaysOutsideCurrentMonth
                    inputFormat={inputFormat}
                    PopperProps={{
                        sx: { 
                            '&.MuiPickersPopper-root': { zIndex: '1000 !important'},
                            ...DatePickerSxObject
                        },
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => {
                        inputProps!.placeholder = '';
                        inputProps!.disabled = true;

                        return (
                            <DatePickerTextField
                                inputRef={inputRef}
                                inputProps={inputProps}
                                InputProps={InputProps}
                            />
                        );
                    }}
                />
            </DatePickerLocalizationProvider>
        </Box>
    );
};
