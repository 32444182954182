import { CustomerAddressFormModel, getDefaultCustomerAddress } from './customerAddressModel';
import { CustomerDetailModel, getDefaultCustomerDetail } from './customerDetailModel';
import moment from 'moment-timezone';

export const getDefaultCustomer = (): CustomerFormModel => {
    return {
        id: 0,
        createdOn: moment().toDate(),
        lastModifiedOn: undefined,
        name: '',
        isActive: true,
        distributorOrganizationId: 0,
        customerAddress: getDefaultCustomerAddress(),
        customerDetail: getDefaultCustomerDetail(),
    };
};

export type CustomerViewModel = {
    id: number;
    name: string;
    isActive: boolean;
    customerNumber: string;
    country: string;
    city: string;
    distributorOrganization: string;
    famCode: string;
};

export type CustomerFormModel = {
    id: number;
    createdOn: Date;
    lastModifiedOn: Date | undefined;
    name: string;
    isActive: boolean;
    distributorOrganizationId: number;
    customerAddress: CustomerAddressFormModel;
    customerDetail: CustomerDetailModel;
};

export type CustomerCreateModel = {
    name: string;
    distributorOrganizationId: number;
    customerAddress: {
        city: string;
        postalCode: string;
        addressLine1: string;
        countryId: number;
        state: string | null;
    };
    customerDetail: {
        customerNumber: string;
        phoneNumber: string;
    };
    peopleOfInterest: {
        firstName: string;
        lastName: string;
        emailAddress: string;
        phoneNumber: string;
        countryId: number;
        role: string;
    }[];
};

export type CustomerUpdateModel = {
    id: number;
    name: string;
    customerAddress: {
        id: number;
        city: string;
        postalCode: string;
        addressLine1: string;
        countryId: number;
        state: string | null;
    };
    customerDetail: {
        id: number;
        phoneNumber: string;
        customerNumber: string;
        customerId: number;
    };
    peopleOfInterest: {
        id: number;
        firstName: string;
        lastName: string;
        emailAddress: string;
        phoneNumber: string;
        countryId: number;
        role: string;
    }[];
};

export const mapCustomerToCreateModel = (customer: CustomerFormModel): CustomerCreateModel => {
    return {
        name: customer.name,
        distributorOrganizationId: customer.distributorOrganizationId,
        customerAddress: {
            city: customer.customerAddress.city,
            postalCode: customer.customerAddress.postalCode,
            addressLine1: customer.customerAddress.addressLine1,
            countryId: customer.customerAddress.countryId,
            state: customer.customerAddress.state,
        },
        customerDetail: {
            customerNumber: customer.customerDetail.customerNumber.trim(),
            phoneNumber: customer.customerDetail.phoneNumber,
        },
        peopleOfInterest: customer.customerDetail.peopleOfInterest.map(poi => (
            {
                firstName: poi.firstName,
                lastName: poi.lastName,
                emailAddress: poi.emailAddress,
                phoneNumber: poi.phoneNumber,
                countryId: poi.countryId,
                role: poi.role,
            })),
    };
};

export const mapCustomerToUpdateModel = (customer: CustomerFormModel): CustomerUpdateModel => {
    return {
        id: customer.id,
        name: customer.name,
        customerAddress: {
            id: customer.customerAddress.id,
            city: customer.customerAddress.city,
            postalCode: customer.customerAddress.postalCode,
            addressLine1: customer.customerAddress.addressLine1,
            countryId: customer.customerAddress.countryId,
            state: customer.customerAddress.state,
        },
        customerDetail: {
            id: customer.customerDetail.id,
            customerId: customer.customerDetail.customerId,
            phoneNumber: customer.customerDetail.phoneNumber,
            customerNumber: customer.customerDetail.customerNumber.trim(),
        },
        peopleOfInterest: customer.customerDetail.peopleOfInterest.map(poi => (
            {
                id: poi.id,
                firstName: poi.firstName,
                lastName: poi.lastName,
                emailAddress: poi.emailAddress,
                phoneNumber: poi.phoneNumber,
                countryId: poi.countryId,
                role: poi.role,
            })),
    };
};