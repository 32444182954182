import { RoleToAssign, UserFormValues } from '../../models/user';

export interface IUserFormProps {
	selectedUser: UserFormValues;
	handleClose: () => void;
	getUsers(): Promise<void>;
	roleOptions: RoleToAssign[];
}

export enum UserFormStep {
	GENERALINFO = 0,
	ROLEORGANIZATIONS = 1
}
