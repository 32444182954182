import React from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';
import { StyledSearchableDropdownProps, TextField, Dropdown, DropdownOption } from '@ui-components/ui-library';
import { Grid } from '@mui/material';
import { IAddressSectionProps } from './IAddressSectionProps';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const AddressSection = (props: IAddressSectionProps): JSX.Element => {
    const { values: organization, handleChange, handleBlur, setFieldValue } = useFormikContext() as any;
    const { t } = useTranslation();
    const { countries } = props;

    const countryOptions = orderBy(countries, c => c.description)?.map(b => {
        return { id: b.id, label: b.description, value: b.id };
    });

    const selectedCountry = countryOptions?.find(obj => obj.id === organization.address?.countryId);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Street')}
                        id={'address.addressLine1'}
                        name={'address.addressLine1'}
                        value={organization.address.addressLine1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 250 }}
                        required={true} 
                    />
                    <ErrorMessage name={'address.addressLine1'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Postal Code')}
                        id={'address.postalCode'}
                        name={'address.postalCode'}
                        value={organization.address.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 16 }}
                        required={true} 
                    />
                    <ErrorMessage name={'address.postalCode'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('City')}
                        id={'address.city'}
                        name={'address.city'}
                        value={organization.address.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 85 }}
                        required={true} 
                    />
                    <ErrorMessage name={'address.city'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'address.countryId'}
                        label={t('Country')} 
                        name={'address.countryId'}
                        options={countryOptions ?? []}
                        value={selectedCountry ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => setFieldValue('address.countryId', item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        required={true}
                        variant='searchable'
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'address.countryId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};
