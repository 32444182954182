import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { StyledSearchableDropdownProps, TextField, Dropdown, DropdownOption } from '@ui-components/ui-library';
import { Box, Grid } from '@mui/material';
import { useFormikContext, ErrorMessage } from 'formik';
import { StyledTitle } from '../../../components/form/title/Title';
import { ICustomerAddressProps } from './ICustomerAddressProps';
import { StateFormModel } from '../../../organizations/models/stateModel';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { validateForm as validateInitialValues } from '../../../shared/utils/Utils';

export const CustomerAddress = (props: ICustomerAddressProps): JSX.Element => {
    const { values: customer, handleChange, handleBlur, setFieldValue, validateForm, setFieldTouched } = useFormikContext() as any;
    const { t } = useTranslation();
    const { isNewForm, countries, states } = props;

    const [statesByCountry, setStatesByCountry] = useState<string[]>([]);

    const countryOptions = orderBy(countries, c => c.description)?.map(b => {
        return { id: b.id, label: b.description, value: b.id };
    });
    const selectedCountry = countryOptions?.find(obj => obj.id === customer.customerAddress?.countryId);

    const statesOptions = orderBy(statesByCountry, s => s)?.map(s => {
        return { id: s, label: s, value: s };
    });
    const selectedState = statesOptions?.find(obj => obj.id === customer.customerAddress?.state);

    useEffect(() => {
        if (!isNewForm) {
            validateInitialValues(validateForm, setFieldTouched);
        }
    }, [isNewForm]);

    useEffect(() => {
        if (customer.customerAddress.countryId && countries.length > 0 && states.length > 0) {
            let statesBySelectedCountry: string[] = [];
            let hasStates = false;
            
            const country = countries.find(c => c.id === customer.customerAddress.countryId);

            if (country && country.hasStates) {
                const byCountry = states.find((d: StateFormModel) => d.countryKey === country?.key);
                
                statesBySelectedCountry = byCountry ? byCountry.states : [];
                hasStates = true;
            } else {
                setFieldValue('customerAddress.state', null);
            }
            
            setStatesByCountry(statesBySelectedCountry);
            setFieldValue('customerAddress.hasStates', hasStates);
        }
    }, [customer.customerAddress.countryId, countries, states]);

    return (
        <>
            <Box pt={5} display={'flex'} justifyContent={'space-between'}>
                <StyledTitle variant='h4'>{t('Address')}</StyledTitle>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Street')}
                        id={'customerAddress.addressLine1'}
                        name={'customerAddress.addressLine1'}
                        value={customer.customerAddress.addressLine1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 250 }}
                        required={true} 
                    />
                    <ErrorMessage name={'customerAddress.addressLine1'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Postal Code')}
                        id={'customerAddress.postalCode'}
                        name={'customerAddress.postalCode'}
                        value={customer.customerAddress.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 16 }}
                        required={true} 
                    />
                    <ErrorMessage name={'customerAddress.postalCode'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('City')}
                        id={'customerAddress.city'}
                        name={'customerAddress.city'}
                        value={customer.customerAddress.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 85 }}
                        required={true} 
                    />
                    <ErrorMessage name={'customerAddress.city'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'customerAddress.countryId'}
                        label={t('Country')} 
                        name={'customerAddress.countryId'}
                        options={countryOptions ?? []}
                        value={selectedCountry ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) =>  {
                            setFieldValue('customerAddress.state', undefined)
                            setFieldValue('customerAddress.countryId', item ? (item as DropdownOption).value : 0)
                        }}
                        onBlur={handleBlur}
                        required={true} 
                        variant='searchable'
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'customerAddress.countryId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                {
                    statesByCountry.length > 0 && 
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                id={'customerAddress.state'}
                                label={t('State')} 
                                name={'customerAddress.state'}
                                options={statesOptions ?? []}
                                value={selectedState ?? { id: '', label: '', value: '' }}
                                onChange={(e, item) => setFieldValue('customerAddress.state', item ? (item as DropdownOption).value : null)}
                                onBlur={handleBlur}
                                required={true} 
                                variant='searchable'
                                usePopper
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                            />
                            <ErrorMessage name={'customerAddress.state'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                        </Grid>
                }
            </Grid>                               
        </>
    );
};
