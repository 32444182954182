import { useFormikContext, ErrorMessage } from 'formik';
import React from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, StyledSearchableDropdownProps, TextField } from '@ui-components/ui-library';
import { Box, Grid } from '@mui/material';
import { ICustomerDetailProps } from './ICustomerDetailProps';
import { StyledTitle } from '../../../components/form/title/Title';
import { StateRequiredForCountryGeneralInfoStep } from '../../../shared/utils/validationMessages';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

export const CustomerDetail = (props: ICustomerDetailProps): JSX.Element => {
    const { isNewForm } = props;
    const { t } = useTranslation();
    const { values: customer, handleChange, handleBlur, setFieldValue, setFieldTouched } = useFormikContext() as any;
    const { countries } = props;

    const onPhonePrefixChange = (value: any) => {
        const country = countries.find(c => c.id === value);
        
        let addressObject = {...customer.customerAddress, countryId: value, state: null, hasStates: country && country.hasStates};
        
        setFieldTouched('customerAddress.state', true);
        setFieldValue('customerAddress', addressObject);
    };

    const disableNonEditableFields = (): boolean => {
        return !isNewForm && !customer.customerDetail;
    };

    const phonePrefixOptions = orderBy(countries, c => c.phonePrefix)?.map(b => {
        return { id: b.id, label: `${b.phonePrefix} (${b.description})`, value: b.id };
    });

    const selectedPhonePrefix = phonePrefixOptions?.find(obj => obj.id === customer.customerAddress?.countryId);

    return (
        <>
            <Box>
                <StyledTitle variant='h4'>{t('Company details')}</StyledTitle>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Customer Number')}
                        id={'customerDetail.customerNumber'}
                        name={'customerDetail.customerNumber'}
                        value={customer.customerDetail?.customerNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableNonEditableFields()}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                    />
                    <ErrorMessage name={'customerDetail.customerNumber'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'customerAddress.countryId'}
                        label={t('Phone Number Prefix')} 
                        name={'customerAddress.countryId'}
                        options={phonePrefixOptions ?? []}
                        value={selectedPhonePrefix ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => onPhonePrefixChange(item ? (item as DropdownOption).value : 0)}
                        onBlur={handleBlur}
                        required={true}
                        variant='searchable'
                        usePopper
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'customerAddress.countryId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                    <ErrorMessage name={'customerAddress.state'} render={() => <span className='p-error'>{t(StateRequiredForCountryGeneralInfoStep)}</span>} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Phone Number')}
                        id={'customerDetail.phoneNumber'}
                        name={'customerDetail.phoneNumber'}
                        value={customer.customerDetail?.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 50 }}
                        required={true}
                    />
                    <ErrorMessage name={'customerDetail.phoneNumber'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
            </Grid>
        </>
    );
};
