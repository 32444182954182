import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleType } from '../../shared/enums/RoleType';
import { AuthenticatedUser } from '../../users/models/user';
import { getDefaultPermissions } from '../../users/models/userPermissions';
import { getDefaultPreferences, Language } from '../../users/models/userPreferences';
import { RootState } from '../store';
import { RoleActions } from '../../shared/enums/RoleActions';
import { rtlLanguages } from '../../i18n/i18nConstants';

export interface IUsersState {
    currentUser: AuthenticatedUser
}

const initialState: IUsersState = {
    currentUser: {
        id: 0, firstName: '', lastName: '',
        email: '', roles: [], userOrganizationRoles: [],
        isOnMobileDevice: false,
        permissions: getDefaultPermissions(),
        preferences: getDefaultPreferences(),
    }
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<AuthenticatedUser>) => {
            const currentUser = action.payload;

            state.currentUser = currentUser;
        },
    },
});

// Export Actions
export const { setCurrentUser } = usersSlice.actions;

export default usersSlice.reducer;

export const userIsGlobalAdmin = (state: RootState) => state.User.currentUser.userOrganizationRoles
    .some(r => r.role === RoleType.GlobalAdministrator && r.isActive);

export const userIsLocalAdmin = (state: RootState) => state.User.currentUser.userOrganizationRoles
    .some(r => r.role === RoleType.LocalAdministrator && r.isActive);

export const userIsDistributorOwner = (state: RootState) => state.User.currentUser.userOrganizationRoles
    .some(r => r.role === RoleType.DistributorOwner && r.isActive);

export const userIsPlanner = (state: RootState) => state.User.currentUser.userOrganizationRoles
    .some(r => r.role === RoleType.Planner && r.isActive);

export const userIsTechnician = (state: RootState) => state.User.currentUser.userOrganizationRoles
    .some(r => r.role === RoleType.Technician && r.isActive);

const userHasServiceExecutionPermission = (state: RootState, action: RoleActions): boolean => {
    const combinedPermissions = state.User.currentUser.userOrganizationRoles
        .filter(uor => uor.isActive)
        .map(uor => uor.serviceExecutionPermissions)
        .reduce((acc, role) => acc | role, RoleActions.None);

    return (combinedPermissions & action) === action;
};

export const userLoaded = (state: RootState) => state.User.currentUser && state.User.currentUser.id > 0;

export const userCanViewEquipments = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanEditEquipments = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanEditEquipmentMainInfo = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state);

export const userCanEditEquipmentDetailInfo = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanEditEquipmentContractInfo = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanEditEquipmentCustomerInfo = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanViewEquipmentContractInformation = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanViewServiceOrders = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanCreateServiceOrders = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanEditServiceOrders = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanViewOrganizations = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state);

export const userCanViewUsers = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanCreateUsers = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanViewCustomers = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanCreateCustomers = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanViewServicePlannings = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state) || userIsTechnician(state);

export const userCanCreateServicePlannings = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanUpdateServicePlannings = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanUpdateServiceExecutions = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state) || userIsTechnician(state);

export const userCanViewServiceExecutions = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state) || userIsTechnician(state);

export const userCanViewStartupServiceExecutions = (state: RootState) => (userIsGlobalAdmin(state) || userIsLocalAdmin(state)) && userHasServiceExecutionPermission(state, RoleActions.Read);

export const userCanBulkImportCustomers = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanSeeInstalledBaseCode = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state);

export const userCanCancelServices = (state: RootState) => userIsGlobalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userCanAccessAccountSetting = (state: RootState) => userIsGlobalAdmin(state);

export const userCanViewKPIs = (state: RootState) => userIsGlobalAdmin(state) || userIsLocalAdmin(state) || userIsDistributorOwner(state) || userIsPlanner(state);

export const userLanguageIsRtl = (state: RootState) => Object.keys(rtlLanguages).includes(Language[state.User.currentUser.preferences.language]);