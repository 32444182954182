import React, { useEffect, useState } from 'react';
import { OneToOneDashboard } from '../oneToOneDashboard/OneToOneDashboard';
import { dashboardService } from '../../../services/DashboardService';
import { OneToOneFilterModel, getDefaultOneToOneFilterModel } from '../../models/OneToOneFilterModel';
import { OneToOneKpiViewModel, getDefaultOneToOneKpiViewModel } from '../../../models/dashboard/OneToOneKpiViewModel';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { ServiceOrderByStatusDashboard } from '../serviceOrderByStatusDashboard/ServiceOrderByStatusDashboard';
import { Restricted } from '../../../components/elements/restricted/Restricted';
import { ServiceOrderCreationDashboard } from '../serviceOrdersCreationDashboard/ServiceOrderCreationDashboard';
import { CustomerCreationDashboard } from '../CustomerCreationDashboard/CustomerCreationDashboard';
import { InstalledBaseCreationDashboard } from '../installedBaseCreationDashboard/InstalledBaseCreationDashboard';
import { OnboardedDistributorsDashboard } from '../onboardedDistributors/OnboardedDistributorsDashboard';
import { showErrors } from '../../../stores/alert/alertSlice';
import { EquipmentTrafficLightDashboard } from '../equipmentTrafficLightDashboard/EquipmentTrafficLightDashboard';
import { LeadsDashboard } from '../leadsDashboard/LeadsDashboard';

export const DashboardContainer = (): JSX.Element => {
    const [onToOneKpi, setOneToOneKpi] = useState<OneToOneKpiViewModel>(getDefaultOneToOneKpiViewModel());

    const dispatch = useAppDispatch();

    const getOneToOneKpi = async (filters: OneToOneFilterModel) => {
        dispatch(setIsLoading(true));
        const oneToOneResponse = await dashboardService.OneToOneKpi(filters);

        if (oneToOneResponse.isSuccess) {
            setOneToOneKpi(oneToOneResponse.result!);
        } else {
            dispatch(showErrors(oneToOneResponse?.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getOneToOneKpi(getDefaultOneToOneFilterModel());
    }, []);

    return (
        <>
            <OneToOneDashboard
                oneToOneKpi={onToOneKpi}
                applyOneToOneFilters={getOneToOneKpi}
            />

            <Restricted to={'canViewServiceOrders'}>
                <ServiceOrderByStatusDashboard />
            </Restricted>

            <Restricted to={'canViewServiceOrderCreationKpi'}>
                <ServiceOrderCreationDashboard />
            </Restricted>

            <Restricted to={'canViewCustomerCreationKpi'}>
                <CustomerCreationDashboard />
            </Restricted>

            <Restricted to={'canViewInstalledBaseDashboard'}>
                <InstalledBaseCreationDashboard />
            </Restricted>

            <Restricted to={'canViewOnboardedDistributorsKpi'}>
                <OnboardedDistributorsDashboard />
            </Restricted>

            <Restricted to={'canViewTrafficLightKpi'}>
                <EquipmentTrafficLightDashboard />
            </Restricted>

            <Restricted to={'canViewLeadsKpi'}>
                <LeadsDashboard />
            </Restricted>
        </>
    );
};