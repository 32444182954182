import React, { useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import { StyledSearchableDropdownProps, Dropdown, DropdownOption } from '@ui-components/ui-library';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import { ServiceOrdersFilterModel, getDefaultServiceOrdersFilterModel } from '../../models/ServiceOrdersFilterModel';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { ALL_TEXT, NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { ServiceOrderCreationMonthView } from './ServiceOrderCreationMonthView';
import { ServiceOrderCreationYearView } from './ServiceOrderCreationYearView';
import { userLanguageIsRtl } from '../../../stores/user/usersSlice';
import { DropdownListItemLongContentSxObject } from '../../../shared/utils/sxStyleObjects';
import { Restricted } from '../../../components/elements/restricted/Restricted';

export const ServiceOrderCreationDashboard = (): JSX.Element => {
    const { t } = useTranslation();

    const shouldUseRtl: boolean = useAppSelector(state => userLanguageIsRtl(state));
    const organizationOptions: OrganizationOptionModel[] = useAppSelector(state => state.Dashboard.organizationOptions);

    const customerCenterOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.CustomerCenter), [organizationOptions]);
    const distributorOptions = useMemo(() => organizationOptions.filter(oo => oo.organizationType === OrganizationType.Distributor), [organizationOptions]);

    const [yearViewIsSelected, setYearViewIsSelected] = useState<boolean>(false);

    const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useState<ServiceOrdersFilterModel>(getDefaultServiceOrdersFilterModel());

    const [notAppliedFilters, setNotAppliedFilters] = useState<any>({});

    const onViewChange = () => {
        setYearViewIsSelected(!yearViewIsSelected);
    };

    const closeFilterModal = () => {
        setFilterModalIsOpen(false);
        setNotAppliedFilters({});
    };

    const handleCustomerCenterChange = (value: number | undefined) => {
        if (value) {
            const childrenOrganizations = organizationOptions.filter(oo => oo.parentOrganizationId === value);
            const currentSelectedDistributorId = notAppliedFilters['distributorOrganizationId'] || appliedFilters['distributorOrganizationId'];
            const shouldClearDistributor: boolean = currentSelectedDistributorId &&
                !childrenOrganizations.some(co => co.id === currentSelectedDistributorId);

            if (shouldClearDistributor) {
                setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value, distributorOrganizationId: undefined });
            } else {
                setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: value });
            }

        } else {
            setNotAppliedFilters({ ...notAppliedFilters, customerCenterId: undefined });
        }
    };

    const handleDistributorOrganizationChange = (value: number | undefined) => {
        if (value) {
            const selectedDistributor = distributorOptions.find(o => o.id === value);
            const customerCenterOrganizations = customerCenterOptions.filter(cc => cc.id === selectedDistributor?.parentOrganizationId);

            const currentCustomerCenterId = notAppliedFilters['customerCenterId'] || appliedFilters['customerCenterId'];
            const shouldClearCustomerCenter: boolean = currentCustomerCenterId &&
                !customerCenterOrganizations.some(cc => cc.id === currentCustomerCenterId);

            if (shouldClearCustomerCenter) {
                setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: value, customerCenterId: undefined });
            } else {
                setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: value });
            }

        } else {
            setNotAppliedFilters({ ...notAppliedFilters, distributorOrganizationId: undefined });
        }
    };

    const handleYearChange = (value: number) => {
        setNotAppliedFilters({ ...notAppliedFilters, year: value });
    };

    const applyFilters = async (): Promise<void> => {
        setAppliedFilters({ ...appliedFilters, ...notAppliedFilters });
        closeFilterModal();
    };

    const clearFilters = async () => {
        setAppliedFilters(getDefaultServiceOrdersFilterModel());
    };

    const applyButtonIsDisabled = (): boolean => {
        return Object.keys(notAppliedFilters).length === 0 || !Object.keys(notAppliedFilters).some(naf =>
            !isEqual(appliedFilters[naf as keyof typeof appliedFilters], notAppliedFilters[naf]));
    };

    const getSelectedDropdownOption = (propName: keyof ServiceOrdersFilterModel, optionsCollection: any[]): DropdownOption | null | undefined => {
        if ((!appliedFilters[propName as keyof typeof appliedFilters] && !notAppliedFilters[propName as keyof typeof notAppliedFilters]) ||
            (propName in notAppliedFilters && notAppliedFilters[propName as keyof typeof notAppliedFilters] === undefined)) {
            return null;
        }

        if (notAppliedFilters[propName as keyof typeof notAppliedFilters]) {
            return optionsCollection.find(et => et.id === notAppliedFilters[propName as keyof typeof notAppliedFilters]);
        } else if (appliedFilters[propName as keyof typeof appliedFilters]) {
            return optionsCollection.find(o => o.id === appliedFilters[propName as keyof typeof appliedFilters]);
        }
    };

    const hasAppliedFilters: boolean = appliedFilters.customerCenterId !== undefined ||
        appliedFilters.distributorOrganizationId !== undefined ||
        (appliedFilters.year !== undefined && appliedFilters.year !== moment().year());

    const customerCenterDropdownOptions = useMemo(() => {
        if (customerCenterOptions && customerCenterOptions.length > 0) {
            const distributorIsSelected: boolean = notAppliedFilters['distributorOrganizationId'] !== undefined ||
                (appliedFilters['distributorOrganizationId'] !== undefined && !('distributorOrganizationId' in notAppliedFilters));

            if (distributorIsSelected) {
                if (notAppliedFilters['distributorOrganizationId'] !== undefined) {
                    const selectedDistributor = distributorOptions.find(dop => dop.id === notAppliedFilters['distributorOrganizationId']);

                    return customerCenterOptions
                        .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
                        .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
                } else if (appliedFilters['distributorOrganizationId'] !== undefined) {
                    const selectedDistributor = distributorOptions.find(dop => dop.id === appliedFilters['distributorOrganizationId']);

                    return customerCenterOptions
                        .filter(cc => cc.id === selectedDistributor?.parentOrganizationId)
                        .map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
                }
            }
            
            return customerCenterOptions.map(cc => ({ id: cc.id, label: cc.famCode, value: cc.id }));
        }

        return [];
    }, [customerCenterOptions, distributorOptions, appliedFilters, notAppliedFilters]);

    const distributorDropdownOptions = useMemo(() => {
        if (distributorOptions && distributorOptions.length > 0) {
            const customerCenterIsSelected: boolean = notAppliedFilters['customerCenterId'] !== undefined ||
                (appliedFilters['customerCenterId'] !== undefined && !('customerCenterId' in notAppliedFilters));

            if (customerCenterIsSelected) {
                if (notAppliedFilters['customerCenterId'] !== undefined) {
                    return distributorOptions
                        .filter(dop => dop.parentOrganizationId === notAppliedFilters['customerCenterId'])
                        .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
                } else if (appliedFilters['customerCenterId'] !== undefined) {
                    return distributorOptions
                        .filter(dop => dop.parentOrganizationId === appliedFilters['customerCenterId'])
                        .map(et => ({ id: et.id, label: t(et.name), value: et.id }));
                }
            }

            return distributorOptions.map(et => ({ id: et.id, label: t(et.name), value: et.id }));
        }

        return [];
    }, [distributorOptions, appliedFilters, notAppliedFilters]);

    return (
        <>
            <Box dir={'ltr'}>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'} dir={shouldUseRtl ? 'rtl' : 'ltr'}>
                    <Box fontSize={18} flex={2} textAlign={'left'}>{t('Service order creation')}</Box>

                    <Tooltip
                        title={yearViewIsSelected ? t('Month view') : t('Year view')}
                    >
                        <IconButton id={'view_toggle'} onClick={onViewChange}>
                            <ViewModuleOutlinedIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={t('Filter')}
                    >
                        <IconButton id={'filter'} onClick={() => setFilterModalIsOpen(true)}>
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>

                    {
                        hasAppliedFilters &&
                        <Tooltip
                            title={t('Clear filters')}
                        >
                            <IconButton id={'clear_filters'} onClick={clearFilters}>
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>

                {
                    yearViewIsSelected &&
                    <ServiceOrderCreationYearView appliedFilters={appliedFilters} />
                }

                {
                    !yearViewIsSelected &&
                    <ServiceOrderCreationMonthView appliedFilters={appliedFilters} />
                }
            </Box>

            <FilterModal
                isOpen={filterModalIsOpen}
                disableApply={applyButtonIsDisabled()}
                onClose={closeFilterModal}
                onApply={applyFilters}>

                <Grid container spacing={2}>

                    <Grid item xs={12} md={12}>
                        <DatePicker
                            id={'year'}
                            inputFormat={'YYYY'}
                            value={notAppliedFilters['year'] ? moment(notAppliedFilters['year'], 'YYYY').startOf('y').toDate() : moment(appliedFilters['year'], 'YYYY').startOf('y').toDate()}
                            onChange={value => handleYearChange(moment(value).year())}
                            disableFuture
                            views={['year']}
                            title={t('Year')}
                        />
                    </Grid>

                    <Restricted to={'canFilterByCustomerCenter'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Customer Center')}
                                options={customerCenterDropdownOptions}
                                value={getSelectedDropdownOption('customerCenterId', customerCenterDropdownOptions)}
                                onChange={(e, item) => handleCustomerCenterChange(item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={customerCenterDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Grid item xs={12} md={6}>
                        <Dropdown<StyledSearchableDropdownProps>
                            label={t('Distributor Organization')}
                            options={distributorDropdownOptions}
                            value={getSelectedDropdownOption('distributorOrganizationId', distributorDropdownOptions)}
                            onChange={(e, item) => handleDistributorOrganizationChange(item ? Number((item as DropdownOption).value) : undefined)}
                            variant='searchable'
                            usePopper
                            disabled={distributorDropdownOptions.length === 0}
                            noOptionsText={t(NO_OPTIONS_TEXT)}
                            optionListStyles={DropdownListItemLongContentSxObject}
                            placeholder={t(ALL_TEXT)}
                        />
                    </Grid>
                </Grid>

            </FilterModal>
        </>
    );
};
