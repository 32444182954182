import { AddressFormModel, getDefaultAddress } from './addressModel';
import { CustomerCenterDetailModel, getDefaultCustomerCenterDetail } from './customerCenterDetailModel';
import { CustomerDetailModel, getDefaultCustomerDetail } from '../../customers/models/customerDetailModel';
import { DistributorDetailsModel, getDefaultDistributorDetails } from './distributorDetailModel';
import moment from 'moment-timezone';
import { OrganizationType } from '../../shared/enums/OrganizationType';

export type OrganizationViewModel = {
    id: number;
    name: string;
    erpCustomerNumber: string;
    isActive: boolean;
    organizationType: OrganizationType;
    country: string;
    city: string;
    brands: string[];
    famCode: string;
    customerNumber: string;
    personOfInterest: string;
    email: string;
};

export type ParentOrganizationOptionModel = {
    id: number;
    name: string;
    organizationType: string;
};

export type OrganizationOptionModel = {
    id: number;
    name: string;
    organizationType: OrganizationType;
    famCode: string;
    parentOrganizationId: number | undefined;
};

export type OrganizationFormModel = {
    id: number;
    createdOn: Date;
    lastModifiedOn: Date | undefined;
    name: string;
    isActive: boolean;
    organizationTypeId: number;
    parentOrganizationId: number | undefined;
    organizationBrandIds: number[];
    address: AddressFormModel;
    distributorDetail: DistributorDetailsModel;
    customerDetail: CustomerDetailModel;
    customerCenterDetail: CustomerCenterDetailModel;
};

export type DistributorOrganizationCreateModel = {
    name: string;
    organizationTypeId: number;
    parentOrganizationId: number | undefined;
    organizationBrandIds: number[];
    address: {
        city: string;
        postalCode: string;
        addressTypeId: number;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        countryId: number;
    };
    distributorDetail: {
        erpTypeId: number;
        erpCustomerNumber: string;
        phoneNumber: string;
    };
}

export type DistributorOrganizationUpdateModel = {
    id: number;
    name: string;
    organizationTypeId: number;
    parentOrganizationId: number | undefined;
    organizationBrandIds: number[];
    address: {
        id: number;
        city: string;
        postalCode: string;
        addressTypeId: number;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        countryId: number;
    };
    distributorDetail: {
        id: number;
        erpTypeId: number,
        erpCustomerNumber: string,
        phoneNumber: string,
    };
}

export type CustomerCenterOrganizationUpdateModel = {
    id: number;
    name: string;
    organizationTypeId: number;
    organizationBrandIds: number[];
    address: {
        id: number;
        city: string;
        postalCode: string;
        addressTypeId: number;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        countryId: number;
    };
    customerCenterDetail: {
        id: number;
        famCode: string;
        organizationId: number;
    };
};

export const getDefaultOrganization = (): OrganizationFormModel => {
    return {
        id: 0,
        createdOn: moment().toDate(),
        lastModifiedOn: undefined,
        name: '',
        isActive: true,
        organizationTypeId: 0,
        parentOrganizationId: undefined,
        organizationBrandIds: [],
        address: getDefaultAddress(),
        distributorDetail: getDefaultDistributorDetails(),
        customerDetail: getDefaultCustomerDetail(),
        customerCenterDetail: getDefaultCustomerCenterDetail(),
    };
};

export const mapDistributorOrganizationToCreateModel = (organization: OrganizationFormModel): DistributorOrganizationCreateModel => {
    return {
        name: organization.name,
        organizationTypeId: organization.organizationTypeId,
        parentOrganizationId: organization.parentOrganizationId,
        organizationBrandIds: organization.organizationBrandIds,
        address: {
            city: organization.address.city,
            postalCode: organization.address!.postalCode,
            addressTypeId: organization.address!.addressTypeId,
            addressLine1: organization.address!.addressLine1,
            addressLine2: organization.address!.addressLine2,
            addressLine3: organization.address!.addressLine3,
            countryId: organization.address!.countryId,
        },
        distributorDetail: {
            erpTypeId: organization.distributorDetail!.erpTypeId,
            erpCustomerNumber: organization.distributorDetail!.erpCustomerNumber,
            phoneNumber: organization.distributorDetail!.phoneNumber,
        }
    };
};

export const mapDistributorOrganizationToUpdateModel = (organization: OrganizationFormModel): DistributorOrganizationUpdateModel => {
    return {
        id: organization.id,
        name: organization.name,
        organizationTypeId: organization.organizationTypeId,
        parentOrganizationId: organization.parentOrganizationId,
        organizationBrandIds: organization.organizationBrandIds,
        address: {
            id: organization.address.id,
            city: organization.address.city,
            postalCode: organization.address.postalCode,
            addressTypeId: organization.address.addressTypeId,
            addressLine1: organization.address.addressLine1,
            addressLine2: organization.address.addressLine2,
            addressLine3: organization.address.addressLine3,
            countryId: organization.address.countryId,
        },
        distributorDetail: {
            id: organization.distributorDetail.id,
            erpTypeId: organization.distributorDetail.erpTypeId,
            erpCustomerNumber: organization.distributorDetail.erpCustomerNumber,
            phoneNumber: organization.distributorDetail.phoneNumber,
        }
    };
};

export const mapCustomerCenterOrganizationToUpdateModel = (organization: OrganizationFormModel): CustomerCenterOrganizationUpdateModel => {
    return {
        id: organization.id,
        name: organization.name,
        organizationTypeId: organization.organizationTypeId,
        organizationBrandIds: organization.organizationBrandIds,
        address: {
            id: organization.address.id,
            city: organization.address.city,
            postalCode: organization.address.postalCode,
            addressTypeId: organization.address.addressTypeId,
            addressLine1: organization.address.addressLine1,
            addressLine2: organization.address.addressLine2,
            addressLine3: organization.address.addressLine3,
            countryId: organization.address.countryId,
        },
        customerCenterDetail: {
            id: organization.customerCenterDetail.id,
            organizationId: organization.customerCenterDetail.organizationId,
            famCode: organization.customerCenterDetail.famCode,
        }
    };
};
