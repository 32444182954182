
export type OneToOneKpiViewModel = {
    totalNumberOfEquipment: number;
    servicedEquipment: number;
}

export const getDefaultOneToOneKpiViewModel = (): OneToOneKpiViewModel => {
    return {
        totalNumberOfEquipment: 0,
        servicedEquipment: 0,
    };
};