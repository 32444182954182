import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { EquipmentStatusOption } from '../models/equipmentStatus';

class EquipmentStatusService {
    async GetEquipmentStatusOptions(): Promise<ActionResult<EquipmentStatusOption[]>> {
        const url = '/equipmentStatuses/GetEquipmentStatusOptions';

        const response = await api.get<EquipmentStatusOption[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }
}

export const equipmentStatusService = new EquipmentStatusService();
