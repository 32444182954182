import React, { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { debounce, isEqual } from 'lodash';
import { Chip, ChipType, Button, SearchBar, Icon, DataGrid } from '@ui-components/ui-library';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Grid } from '@mui/material';
import { GridEnrichedColDef, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { userService } from '../../services/UserService';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { useTranslation } from 'react-i18next';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import moment from 'moment-timezone';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { FilterDatePicker } from '../../../components/filterDatePicker/FilterDatePicker';
import { UsersPagedQueryParameters, getDefaultUsersPagedQueryParameters } from '../../models/usersPagedQueryParameters';
import { ActiveServicePlanningByUserViewModel, AuthenticatedUser, RoleToAssign, UserFormValues, UserGridModel, getDefaultUserFormValues } from '../../models/user';
import { UserOverviewPagedList, getDefaultUserOverviewPagedList } from '../../models/UserOverviewPagedList';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { InnerTableSxObject, StyledGridCellContent } from '../../../shared/utils/sxStyleObjects';
import { GridFilterModel, GridSortModel, getDefaultGridFilterModel, getGridDateOperators } from '@mui/x-data-grid-pro';
import { ServerSideDataGridOverview } from '../../../components/datagrid/ServerSideDataGridOverview';
import { Restricted } from '../../../components/elements/restricted/Restricted';
import { UserForm } from '../userForm/UserForm';
import { userIsGlobalAdmin, userIsLocalAdmin } from '../../../stores/user/usersSlice';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { WarningModal } from '../../../components/warningModal/WarningModal';
import { i18n } from '../../../i18n/config';

export const UsersPagedOverview = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
    const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));
    const isLocalAdmin: boolean = useAppSelector(state => userIsLocalAdmin(state));

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [queryParameters, setQueryParameters] = useState<UsersPagedQueryParameters>(getDefaultUsersPagedQueryParameters());

    const debouncedSetQueryParameters = useCallback(
        debounce((value: UsersPagedQueryParameters) => {
            setQueryParameters(value);
        }, 500), []
    );

    const [filterModel, setFilterModel] = useState<GridFilterModel>(getDefaultGridFilterModel());
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [page, setPage] = useState<number>(1);
    const [pagedUsers, setPagedUsers] = useState<UserOverviewPagedList>(getDefaultUserOverviewPagedList());
    const [selectedFormUser, setSelectedFormUser] = useState<UserFormValues>();
    const [selectedUser, setSelectedUser] = useState<UserGridModel>();
    const [activationDialogVisible, setActivationDialogVisible] = useState<boolean>(false);
    const [roleOptions, setRoleOptions] = useState<RoleToAssign[]>([]);

    const [userToDeactivate, setUserToDeactivate] = useState<UserGridModel>();
    const [userToDeactivatePlanningsData, setUserToDeactivatePlanningsData] = useState<ActiveServicePlanningByUserViewModel[]>([]);
    const [warningDialogVisible, setWarningDialogVisible] = useState<boolean>(false);

    const getPagedUsers = async () => {
        dispatch(setIsLoading(true));
        const pagedUsersResponse = await userService.GetUsersPaged(queryParameters);
        if (pagedUsersResponse.isSuccess) {
            setPagedUsers(pagedUsersResponse.result!);
        }
        dispatch(setIsLoading(false));
    };

    const getUserById = async (id: number) => {
        var fetchedUserResponse = await userService.GetUserById(id);
        if (fetchedUserResponse.isSuccess) {
            setSelectedFormUser(fetchedUserResponse.result!);
        } else {
            dispatch(showErrors(fetchedUserResponse?.errorModel?.errors));
        }
    };

    const getRoleOptions = async () => {
        const rolesResponse = await userService.GetRolesToAssign();
        if (rolesResponse.isSuccess) {
            setRoleOptions(rolesResponse.result!);
        }
    };

    const handleOpenUserForm = async (id: number | undefined) => {
        dispatch(setIsLoading(true));
        if (id) {
            await getUserById(id);
        } else {
            setSelectedFormUser(getDefaultUserFormValues());
        }
        dispatch(setIsLoading(false));
    };

    const handleCloseFormDialog = () => {
        setSelectedFormUser(undefined);
    };

    const showActivationUserDialog = (userToChange: UserGridModel) => {
        setSelectedUser(userToChange);
        setActivationDialogVisible(true);
    };

    const hideActivationUserDialog = () => {
        setSelectedUser(undefined);
        setActivationDialogVisible(false);
    };

    const showDeactivationWarningDialog = (userToDeactivate: UserGridModel | undefined, userToDeactivatePlanningData: ActiveServicePlanningByUserViewModel[]) => {
        setUserToDeactivate(userToDeactivate);
        setUserToDeactivatePlanningsData(userToDeactivatePlanningData);
        setWarningDialogVisible(true);
    };

    const hideDeactivationWarningDialog = () => {
        setUserToDeactivate(undefined);
        setUserToDeactivatePlanningsData([]);
        setWarningDialogVisible(false);
    };

    const changeUserStatus = async (id: number) => {
        const result = selectedUser?.isActive ?
            await userService.DeactivateUser(id, moment().utcOffset()) :
            await userService.ActivateUser(id);

        if (result.isSuccess) {
            var userPlanningsData = result.result as ActiveServicePlanningByUserViewModel[];

            if (userPlanningsData && userPlanningsData.length > 0) {
                showDeactivationWarningDialog(selectedUser, userPlanningsData);
            } else {
                dispatch(showSuccess(SUCCESS_MESSAGE));
                selectedUser!.isActive = !selectedUser!.isActive;
                setSelectedUser(selectedUser);
            }
        } else {
            if (result?.errorModel) {
                dispatch(showErrors(result.errorModel?.errors));
            }
        }
    };

    const onStatusChangeConfirm = async () => {
        if (selectedUser) {
            dispatch(setIsLoading(true));
            await changeUserStatus(selectedUser.id);
            hideActivationUserDialog();
            dispatch(setIsLoading(false));
        }
    };

    const clearFilters = async () => {
        setFilterModel(getDefaultGridFilterModel());
        setSortModel([]);
        setGlobalFilterValue('');

        if (isEqual(queryParameters, getDefaultUsersPagedQueryParameters())) {
            return;
        }

        setQueryParameters(getDefaultUsersPagedQueryParameters());
    };

    const handlePageChange = (selectedPage: number) => {
        if (selectedPage === page) {
            return;
        }

        setPage(selectedPage);

        setQueryParameters({
            ...queryParameters,
            pageNumber: selectedPage
        });
    };

    const handleSortChange = (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);

        setQueryParameters({
            ...queryParameters,
            sortBy: newSortModel[0]?.field,
            sortDesc: newSortModel[0]?.sort === 'desc'
        });
    };

    const handleFilterChange = (newFilterModel: GridFilterModel) => {
        setFilterModel(newFilterModel);

        const appliedFilters: any = newFilterModel.items.reduce((acc, item) => {
            (acc as any)[item.columnField] = item.value;
            return acc;
        }, {});

        const newQueryParameters = {
            ...queryParameters,
            firstName: appliedFilters.firstName,
            lastName: appliedFilters.lastName,
            email: appliedFilters.email,
            city: appliedFilters.city,
            country: appliedFilters.country,
            distributorName: appliedFilters.distributorName,
            famCode: appliedFilters.famCode,
            brands: appliedFilters.brands,
            role: appliedFilters.role,
            createdOn: appliedFilters.createdOn,
            isActive: appliedFilters.isActive,
        };

        if (isEqual(queryParameters, newQueryParameters)) {
            return;
        }

        debouncedSetQueryParameters({
            ...queryParameters,
            firstName: appliedFilters.firstName,
            lastName: appliedFilters.lastName,
            email: appliedFilters.email,
            city: appliedFilters.city,
            country: appliedFilters.country,
            distributorName: appliedFilters.distributorName,
            famCode: appliedFilters.famCode,
            brands: appliedFilters.brands,
            role: appliedFilters.role,
            createdOn: appliedFilters.createdOn,
            isActive: appliedFilters.isActive,
            pageNumber: 1,
        });

        setPage(1);
    };

    const onGlobalFilterChange = (value: string) => {
        setGlobalFilterValue(value);
        debouncedSetQueryParameters({
            ...queryParameters,
            globalSearchValue: value,
            pageNumber: 1,
        });

        setPage(1);
    };

    const activeBodyTemplate = (params: GridRenderCellParams<UserGridModel>) => {
        if (params.row.isActive) {
            return <TaskAltIcon />;
        } else {
            return <HighlightOffIcon />;
        }
    };

    const planningTypeBody = (fieldValue: any) => {
        return (
            <>
                {t(fieldValue)}
            </>
        );
    };

    const dateBody = (fieldValue: any) => {
        const formatedDate = moment(fieldValue).format(currentUser.preferences.dateFormat);

        return (
            <StyledGridCellContent>{formatedDate}</StyledGridCellContent>
        );
    };

    const roleBody = (params: GridRenderCellParams<UserGridModel>) => {
        if (params.row.role)
            return (
                <Box display={'flex'} maxWidth={'100%'}>
                    <Chip type={ChipType.LIGHTBLUE} label={t(params.row.role)} />
                </Box>
            );
    };

    const brandsBody = (params: GridRenderCellParams<UserGridModel>) => {
        return (
            <Grid container gap={1}>
                {params.row.brands.map((brand: string) => {
                    return (
                        <Box display='flex' maxWidth='100%' key={brand}>
                            {
                                brand &&
                                <Grid item xs={12} key={brand}>
                                    <Chip type={ChipType.LIGHTBLUE} label={brand} />
                                </Grid>
                            }
                        </Box>
                    );
                })}
            </Grid>
        );
    };

    const getColumns = (): GridEnrichedColDef[] => {
        let adminColumns: GridEnrichedColDef[] = [];

        if (isLocalAdmin || isGlobalAdmin) {
            adminColumns = [
                { field: 'distributorName', headerName: t('Distributor Organization'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Distributor Organization') },
                { field: 'famCode', headerName: t('Fam Code'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Fam Code') },
                { field: 'brands', headerName: t('Brands'), renderCell: brandsBody, sortable: false, filterable: true, pinnable: false, flex: 3, description: t('Brands') },
            ];
        }

        const columns: GridEnrichedColDef[] = [
            { field: 'firstName', headerName: t('First Name'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('First Name') },
            { field: 'lastName', headerName: t('Last Name'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('Last Name') },
            { field: 'email', headerName: t('Email'), sortable: true, filterable: true, pinnable: false, flex: 3, description: t('Email') },
            {
                field: 'role', headerName: t('Role'), renderCell: roleBody, sortable: true,
                filterable: true, pinnable: false, flex: 3, type: 'singleSelect', description: t('Role'),
                valueOptions: roleOptions.map(r => ({ label: t(r.key), value: r.key }))
            },
            { field: 'city', headerName: t('City'), sortable: true, filterable: true, pinnable: false, flex: 1.5, description: t('City') },
            { field: 'country', headerName: t('Country'), sortable: true, filterable: true, pinnable: false, flex: 1, description: t('Country') },
            ...adminColumns,
            {
                field: 'createdOn',
                headerName: t('Created'),
                renderCell: (params: GridRenderCellParams<UserGridModel>) => dateBody(params.row.createdOn),
                type: 'date',
                sortable: true,
                filterable: true,
                pinnable: false,
                flex: 1.5,
                description: t('Created'),
                filterOperators: getGridDateOperators()
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? FilterDatePicker
                            : undefined,
                        InputComponentProps: { inputFormat: currentUser.preferences.dateFormat }
                    })),
            },
            {
                field: 'isActive', headerName: t('Status'), renderCell: activeBodyTemplate,
                sortable: true, filterable: true, pinnable: false, flex: 1,
                type: 'singleSelect',
                valueOptions: [{ label: t('Active'), value: true }, { label: t('Inactive'), value: false }],
                description: t('Status')
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                flex: 1,
                description: t('Actions'),
                getActions: (params: GridRowParams<UserGridModel>) => {
                    let actionsArray = [];

                    actionsArray.push(
                        <DataGridActionOverview
                            key={'edit'}
                            icon={<Icon type={params.row.canBeModifiedByUser ? 'edit' : 'visibility'} />}
                            label={params.row.canBeModifiedByUser ? t('Edit') : t('View')}
                            onClick={() => handleOpenUserForm(params.row.id)}
                            showInMenu
                        />
                    );

                    if (params.row.isActive && params.row.canBeDeactivatedByUser) {
                        actionsArray.push(
                            <DataGridActionOverview
                                key={'deactivate'}
                                icon={<PowerSettingsNewIcon />}
                                label={t('Deactivate')}
                                onClick={() => showActivationUserDialog(params.row as UserGridModel)}
                                showInMenu
                            />
                        );
                    } else if (!params.row.isActive && params.row.canBeActivatedByUser) {
                        actionsArray.push(
                            <DataGridActionOverview
                                key={'activate'}
                                icon={<PowerSettingsNewIcon />}
                                label={t('Activate')}
                                onClick={() => showActivationUserDialog(params.row as UserGridModel)}
                                showInMenu
                            />
                        );
                    }
                    return actionsArray;
                },
            },
        ];

        return columns;
    };

    const memoizedColumns = useMemo(() => getColumns(), [roleOptions, i18n.language]);

    const renderHeader = (): JSX.Element => {
        return (
            <Box display='flex' justifyContent='space-between' marginBottom={2}>

                <Restricted to={'canCreateUsers'}>
                    <Button
                        id='new-user'
                        onClick={() => handleOpenUserForm(undefined)}
                        variant='primary'
                        label={t('New user')}
                        startIcon='add'
                    />
                </Restricted>

                <SearchBar
                    variant='default'
                    onChange={onGlobalFilterChange}
                    value={globalFilterValue}
                    placeholder={t('Search')}
                />
                <Button
                    id='clear-filter'
                    onClick={clearFilters}
                    variant='secondary'
                    label={t('Clear')}
                    startIcon='filter_list_off'
                />
            </Box>
        );
    };

    let userPlanningsDataColumns: GridEnrichedColDef[] = [
        {
            field: 'description',
            headerName: t('Planning Type'),
            flex: 1,
            disableExport: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams<ActiveServicePlanningByUserViewModel>) => planningTypeBody(params.row.description)
        },
        {
            field: 'start',
            headerName: t('Start'),
            flex: 1,
            disableExport: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams<ActiveServicePlanningByUserViewModel>) => dateBody(params.row.start), type: 'date'
        },
        {
            field: 'end',
            headerName: t('End'),
            flex: 1,
            disableExport: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams<ActiveServicePlanningByUserViewModel>) => dateBody(params.row.end),
        },
    ];

    useEffect(() => {
        getPagedUsers();
    }, [queryParameters]);

    useEffect(() => {
        getRoleOptions();
    }, []);

    useEffect(() => {
        return () => debouncedSetQueryParameters.cancel();
    }, [debouncedSetQueryParameters]);

    return (
        <>
            <Box>
                {renderHeader()}
            </Box>

            <ServerSideDataGridOverview
                rows={pagedUsers.items}
                columns={memoizedColumns}
                rowCount={pagedUsers.totalCount}
                page={page}
                pageSize={pagedUsers.pageSize}
                onPageChange={handlePageChange}
                filterModel={filterModel}
                onFilterModelChange={handleFilterChange}
                sortModel={sortModel}
                onSortModelChange={handleSortChange}
                getRowClassName={(params) =>
                    params.row.isActive ? 'active' : 'inactive'
                }
            />

            {
                activationDialogVisible &&
                <ConfirmationDialog
                    open={activationDialogVisible}
                    message={t(`Are you sure you want to ${selectedUser?.isActive ? 'deactivate' : 'activate'} this user?`)}
                    onConfirm={onStatusChangeConfirm}
                    onClose={hideActivationUserDialog}
                />
            }

            {
                selectedFormUser &&
                <UserForm
                    selectedUser={selectedFormUser}
                    handleClose={handleCloseFormDialog}
                    getUsers={getPagedUsers}
                    roleOptions={roleOptions}
                />
            }

            {
                warningDialogVisible && !activationDialogVisible &&
                <WarningModal
                    isOpen={warningDialogVisible}
                    message={t('User {{userName}} has the following active plannings and cannot be deactivated.', { userName: `${userToDeactivate?.firstName} ${userToDeactivate?.lastName}` })}
                    onClose={hideDeactivationWarningDialog}
                >
                    <DataGrid
                        rows={userToDeactivatePlanningsData}
                        columns={userPlanningsDataColumns}
                        autoHeight
                        containerHeight='auto'
                        getRowHeight={() => 'auto'}
                        hideFooter
                        sx={InnerTableSxObject}
                        localeText={{ noRowsLabel: t('No rows') }}
                    />
                </WarningModal>
            }
        </>
    );
};
