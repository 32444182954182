
export const Routes = {
    home: {
        pattern: '/',
        getPath: (): string => '/'
    },
    users: {
        pattern: '/users',
        getPath: (): string => '/users'
    },
    userForm: {
        pattern: ['/user/create', '/user/:id'],
        getPath: (id: number): string => `/user/${id}`
    },
    userPreferences: {
        pattern: ['/userPreferences'],
        getPath: (): string => '/userPreferences'
    },

    organizations: {
        pattern: '/organizations',
        getPath: (): string => '/organizations'
    },

    organizationForm: {
        pattern: ['/organization/create/:type', '/organization/:type/:id'],
        getPath: (id: number, type: string): string => `/organization/${type}/${id}`,
        getCreatePath: (type: string): string => `organization/create/${type}`,
    },

    customers: {
        pattern: '/customers',
        getPath: (): string => '/customers'
    },

    customersUpload: {
        pattern: '/customersUpload',
        getPath: (): string => '/customersUpload'
    },

    customerUploads: {
        pattern: '/customerUploads',
        getPath: (): string => '/customerUploads'
    },

    customersForm: {
        pattern: ['/customer/create/', '/customer/:id'],
        getPath: (id: number): string => `/customer/${id}`,
        getCreatePath: (): string => '/customer/create'
    },

    equipments: {
        pattern: '/equipments',
        getPath: (): string => '/equipments'
    },

    equipmentForm: {
        pattern: ['/equipment/create/', '/equipment/:id'],
        getPath: (id: number): string => `/equipment/${id}`,
        getCreatePath: (): string => '/equipment/create'
    },

    serviceOrders: {
        pattern: '/serviceOrders',
        getPath: (): string => '/serviceOrders'
    },

    serviceOrderForm: {
        pattern: ['/serviceOrder/create/', '/serviceOrder/:id'],
        getPath: (id: number): string => `/serviceOrder/${id}`,
        getCreatePath: (): string => '/serviceOrder/create'
    },

    servicePlanning: {
        pattern: '/servicePlanning',
        getPath: (): string => '/servicePlanning'
    },

    serviceExecutionForm: {
        pattern: '/serviceExecution/:id',
        getPath: (id: number): string => `/serviceExecution/${id}`,
    },

    serviceExecution: {
        pattern: '/serviceExecutions',
        getPath: (): string => '/serviceExecutions'
    },

    startupServiceExecution: {
        pattern: '/startupServiceExecutions',
        getPath: (): string => '/startupServiceExecutions'
    },
};
