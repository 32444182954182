import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { BarChart } from '@mui/x-charts/BarChart';
import { ServiceOrderCountByMonthViewModel, getDefaultServiceOrderCountByMonth } from '../../../models/dashboard/ServiceOrderCountByMonthViewModel';
import { dashboardService } from '../../../services/DashboardService';
import { ServiceOrdersFilterModel } from '../../models/ServiceOrdersFilterModel';
import { ChartSxObject } from '../../../shared/utils/sxStyleObjects';
import { CustomChartTooltipContent } from '../customChartTooltipContent/CustomChartTooltipContent';
import { useMediaQuery, useTheme } from '@mui/material';
import { showErrors } from '../../../stores/alert/alertSlice';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { ChartsAxisContentProps } from '@mui/x-charts';

interface IServiceOrderCreationMonthViewProps {
    appliedFilters: ServiceOrdersFilterModel;
}

export const ServiceOrderCreationMonthView = (props: IServiceOrderCreationMonthViewProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { appliedFilters } = props;

    const [serviceOrdersByMonth, setServiceOrdersByMonth] = useState<ServiceOrderCountByMonthViewModel>(getDefaultServiceOrderCountByMonth());

    const theme = useTheme();
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const getServiceOrdersCountByMonth = async (filters: any) => {
        const serviceOrdersByMonthResponse = await dashboardService.ServiceOrdersCountByMonth(filters);
        if (serviceOrdersByMonthResponse.isSuccess) {
            setServiceOrdersByMonth(serviceOrdersByMonthResponse.result!);
        } else {
            dispatch(showErrors(serviceOrdersByMonthResponse?.errorModel?.errors));
        }
    };

    useEffect(() => {
        getServiceOrdersCountByMonth(appliedFilters);
    }, [appliedFilters]);

    const labels = Object.keys(getDefaultServiceOrderCountByMonth()).map(k => t(k));

    return (
        <>
            <BarChart
                xAxis={[
                    {
                        id: 'serviceOrdersByMonth',
                        data: labels,
                        scaleType: 'band',
                        tickLabelInterval: matchUpSm ? () => true : 'auto',
                        valueFormatter: (val) => moment(val, 'MMMM').format('MMM'),
                    },
                ]}
                series={[
                    {
                        data: [
                            serviceOrdersByMonth.January || 0,
                            serviceOrdersByMonth.February || 0,
                            serviceOrdersByMonth.March || 0,
                            serviceOrdersByMonth.April || 0,
                            serviceOrdersByMonth.May || 0,
                            serviceOrdersByMonth.June || 0,
                            serviceOrdersByMonth.July || 0,
                            serviceOrdersByMonth.August || 0,
                            serviceOrdersByMonth.September || 0,
                            serviceOrdersByMonth.October || 0,
                            serviceOrdersByMonth.November || 0,
                            serviceOrdersByMonth.December || 0,
                        ],
                    },
                ]}
                sx={ChartSxObject}
                tooltip={{ trigger: 'axis' }}
                slots={{
                    axisContent: (props: ChartsAxisContentProps) =>
                        <CustomChartTooltipContent
                            axisValue={props.axisValue}
                            series={props.series}
                            dataIndex={props.dataIndex}
                        />
                }}
            />
        </>
    );
};
