import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languages, namespaces } from './i18nConstants';
import HttpApi from 'i18next-http-backend';
import uuid from 'react-uuid';

const createI18n = (language: string): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);

  i18n
    .use(HttpApi)
    .init({
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        queryStringParams: {'v': uuid()}
      },      
      lng: language,
      fallbackLng: language,
      ns: [namespaces.common],
      preload: Object.values(languages)
    });

  return i18n;
};

export const i18n = createI18n(languages.en);