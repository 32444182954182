import moment from 'moment-timezone';

export type CustomerCreationKpiFilterModel = {
    customerCenterId: number | undefined;
    distributorOrganizationId: number | undefined;
    year: number;
    userOffset: number;
}

export const getDefaultCustomerCreationFilterModel = (): CustomerCreationKpiFilterModel => {
    return {
        customerCenterId: undefined,
        distributorOrganizationId: undefined,
        year: moment().year(),
        userOffset: moment().utcOffset(),
    };
};