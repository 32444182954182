import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { Button, SearchBar, Icon } from '@ui-components/ui-library';
import { IServiceOrdersOverviewProps } from './IServiceOrdersOverviewProps';
import { ServiceOrderViewModel } from '../../models/serviceOrderModel';
import { AuthenticatedUser } from '../../../users/models/user';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { userCanCreateServiceOrders, userIsGlobalAdmin } from '../../../stores/user/usersSlice';
import { GridEnrichedColDef, GridRenderCellParams, GridRowParams, getGridDateOperators } from '@mui/x-data-grid-pro';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { serviceOrderService } from '../../services/ServiceOrderService';
import { ConfirmationDialog } from '../confirmationDialog/ConfirmationDialog';
import { StyledGridCellContent } from '../../../shared/utils/sxStyleObjects';
import { ServiceOrderForm } from '../serviceOrderForm/ServiceOrderForm';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import moment from 'moment-timezone';
import { FilterDatePicker } from '../../../components/filterDatePicker/FilterDatePicker';
import { ToBeAssignedStatus } from '../../models/serviceOrderStatus';
import { serviceOrderNumberComparator } from '../../services/ServiceOrderUtility';
import { i18n } from '../../../i18n/config';

export const ServiceOrdersOverview = (props: IServiceOrdersOverviewProps): JSX.Element => {
	const { serviceOrders, getServiceOrders } = props;
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);
	const canCreateServiceOrders = useAppSelector(state => userCanCreateServiceOrders(state));
	const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));

	const [serviceOrdersFiltered, setServiceOrdersFiltered] = useState<ServiceOrderViewModel[]>(serviceOrders);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [clearGridFilters, setClearGridFilters] = useState<boolean | undefined>(undefined);
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const [selectedServiceOrder, setSelectedServiceOrder] = useState<ServiceOrderViewModel | undefined>(undefined);
	const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

	useEffect(() => {
		initFilters();
	}, []);

	useEffect(() => {
		if (serviceOrders.length > 0) {
            setServiceOrdersFiltered(serviceOrders);
        }
	}, [serviceOrders]);

	const debouncedFilterServiceOrders = useCallback(
        debounce((value: string) => {
			if (serviceOrders.length > 0) {
				let _serviceOrdersFiltered = [...serviceOrders];
				_serviceOrdersFiltered = _serviceOrdersFiltered.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value.toLowerCase())));
				setServiceOrdersFiltered(_serviceOrdersFiltered);
			}
        }, 500), [serviceOrders]
    );

    useEffect(() => {
        return () => debouncedFilterServiceOrders.cancel();
    }, [debouncedFilterServiceOrders]);

    useEffect(() => {
        debouncedFilterServiceOrders(globalFilterValue);
    }, [globalFilterValue]);

	const initFilters = () => {
		setGlobalFilterValue('');
	};

	const clearFilters = () => {
		initFilters();
		setServiceOrdersFiltered(serviceOrders);
		setClearGridFilters(!clearGridFilters);
	};

	const onGlobalFilterChange = (value: string) => {
		setGlobalFilterValue(value);
	};

	const renderHeader = (): JSX.Element => {
		return (
			<Box display='flex' justifyContent='space-between' marginBottom={2}>
				{
					canCreateServiceOrders && (
						<Button
							id='new-service-order'
							onClick={() => setFormIsOpen(true)}
							variant='primary'
							label={t('New Service Order')}
							startIcon='add'
						/>
					)
				}
				<SearchBar
					variant='default'
					onChange={(value) => onGlobalFilterChange(value)}
					value={globalFilterValue}
					placeholder={t('Search')}
				/>
				<Button
					id='clear-filter'
					onClick={clearFilters}
					variant='secondary'
					label={t('Clear')}
					startIcon='filter_list_off'
				/>
			</Box>
		);
	};

	const createdOnBodyTemplate = (params: GridRenderCellParams<ServiceOrderViewModel>) => {
		const formatedDate = moment(params.row.createdOn).format(currentUser.preferences.dateFormat);

		return (
			<StyledGridCellContent>{formatedDate}</StyledGridCellContent>
		);
	};

	const dateOfVisitBodyTemplate = (params: GridRenderCellParams<ServiceOrderViewModel>) => {
		if (params.row.dateOfVisit === null || params.row.dateOfVisit === undefined)
			return;

		const formatedDate = moment(params.row.dateOfVisit).format(currentUser.preferences.dateFormat);

		return (
			<StyledGridCellContent>{formatedDate}</StyledGridCellContent>
		);
	};

	const getTranslatedServiceOrders = () => {
		const result = serviceOrdersFiltered.map(so => ({ ...so, status: t(so.status) }));

		return result;
	};

	const handleServiceOrderDeletion = async () => {
		dispatch(setIsLoading(true));

		if (selectedServiceOrder) {
			var response = await serviceOrderService.DeleteServiceOrder(selectedServiceOrder.id);

			if (response && response.isSuccess) {
				dispatch(showSuccess(t(SUCCESS_MESSAGE)));
				setServiceOrdersFiltered([...serviceOrdersFiltered.filter(sof => sof.id != selectedServiceOrder.id)]);
			} else {
				dispatch(showErrors(response.errorModel?.errors));
			}
		} else {
			dispatch(showErrors([t('Please select a service order.')]));
		}

		setDialogOpen(false);
		dispatch(setIsLoading(false));
	};

	const handleOpenDialog = (serviceOrder: ServiceOrderViewModel) => {
		setSelectedServiceOrder(serviceOrder);
		setDialogOpen(true);
	};

	const handleEdit = (id: number) => {
		setSelectedId(id);
		setFormIsOpen(true);
	};

	const handleCloseFormDialog = () => {
		setSelectedId(undefined);
		setFormIsOpen(false);
	};
	
	const getColumns = (): GridEnrichedColDef[] => {
		let columns: GridEnrichedColDef[] = [
			{
				field: 'createdOn',
				headerName: t('Created on'),
				description: t('Created on'),
				renderCell: createdOnBodyTemplate,
				type: 'date',
				sortable: true,
				filterable: true,
				pinnable: false,
				flex: 2,
				filterOperators: getGridDateOperators()
					.map((operator) => ({
						...operator,
						InputComponent: operator.InputComponent
							? FilterDatePicker
							: undefined,
						InputComponentProps: { inputFormat: currentUser.preferences.dateFormat }
					})),
			},
			{ 
				field: 'number',
				headerName: t('Service Order Number'),
				description: t('Service Order Number'),
				sortable: true,
				sortComparator: serviceOrderNumberComparator,
				filterable: true,
				pinnable: false,
				flex: 2,
			},
			{ field: 'externalId', headerName: t('Service Order ID'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Service Order ID') },
			{ field: 'companyName', headerName: t('Customer Name'), sortable: true, filterable: true, pinnable: false, flex: 4, description: t('Customer Name') },
			isGlobalAdmin && { field: 'distributorOrganization', headerName: t('Distributor organization'), sortable: true, filterable: true, pinnable: false, flex: 4, description: t('Distributor organization') },
			{ field: 'status', headerName: t('Status'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Status') },
			{
				field: 'dateOfVisit',
				headerName: t('Date of visit'),
				renderCell: dateOfVisitBodyTemplate,
				sortable: true,
				filterable: true,
				pinnable: false,
				flex: 2,
				description: t('Date of visit'),
				filterOperators: getGridDateOperators()
					.map((operator) => ({
						...operator,
						InputComponent: operator.InputComponent
							? FilterDatePicker
							: undefined,
						InputComponentProps: { inputFormat: currentUser.preferences.dateFormat }
					})),
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: t('Actions'),
				description: t('Actions'),
				flex: 1,
				getActions: (params: GridRowParams<ServiceOrderViewModel>) => {
					let actionArray: JSX.Element[] = [];

					actionArray.push(
						<DataGridActionOverview
							key={'view'}
							icon={<Icon type='visibility' />}
							label={t('View')}
							onClick={() => handleEdit(params.row.id)}
							showInMenu
						/>,
					);

					if (params.row.status === t(ToBeAssignedStatus)) {
						actionArray.push(
							<DataGridActionOverview
								key={'delete'}
								icon={<Icon type='delete' />}
								label={t('Delete')}
								onClick={() => handleOpenDialog(params.row)}
								showInMenu
							/>
						);
					}

					return actionArray;
				}
			}
		].filter(Boolean) as GridEnrichedColDef[];

		return columns;
	};

	const memoizedColumns = useMemo(() => getColumns(), [i18n.language]);

	return (
		<>
			<Box>
				{renderHeader()}
			</Box>
			<DataGridOverview
				rows={getTranslatedServiceOrders()}
				columns={memoizedColumns}
				pagination
				clearFilters={clearGridFilters}
			/>
			<ConfirmationDialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				onConfirm={async () => await handleServiceOrderDeletion()}
				message={t('Are you sure you want to remove {{serviceOrderNumber}}?', { serviceOrderNumber: selectedServiceOrder?.number })}
			/>
			{
				formIsOpen &&
				<ServiceOrderForm
					formIsOpen={formIsOpen}
					handleClose={handleCloseFormDialog}
					serviceOrderId={selectedId}
					getServiceOrders={getServiceOrders}
				/>
			}
		</>
	);
};