import moment from 'moment-timezone';
import { ServerDateFormat } from '../../shared/utils/constants';

export type ServiceExecutionExportFilterModel = {
    completedDateFrom: string;
    completedDateTo: string;
    userOffset: number;
    languageCode: string;
}

export const getDefaultServiceExecutionExportFilterModel = (userLanguage: string): ServiceExecutionExportFilterModel => {
    return {
        completedDateFrom: moment().add(-1, 'y').startOf('d').format(ServerDateFormat),
        completedDateTo: moment().endOf('d').format(ServerDateFormat),
        userOffset: moment().utcOffset(),
        languageCode: userLanguage,
    };
};