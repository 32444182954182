
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, SearchBar, Icon, Modal } from '@ui-components/ui-library';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { GridRowClassNameParams, GridEnrichedColDef, GridRowParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { CustomerFormModel, CustomerViewModel, getDefaultCustomer } from '../../models/customerModel';
import { customerService } from '../../services/CustomerService';
import styles from './CustomersOverview.module.scss';
import { userCanCreateCustomers, userCanViewCustomers, userIsGlobalAdmin, userLoaded } from '../../../stores/user/usersSlice';
import { DataGridActionOverview } from '../../../components/datagrid/DataGridActionOverview';
import { DataGridOverview } from '../../../components/datagrid/DataGridOverview';
import { setIsLoading } from '../../../stores/layout/layoutSlice';
import { CustomerForm } from '../customerForm/CustomerForm';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { ConfirmationDialog } from '../../../serviceOrders/components/confirmationDialog/ConfirmationDialog';
import { CountryFormModel } from '../../../organizations/models/countryModel';
import { StateFormModel } from '../../../organizations/models/stateModel';
import { getDefaultCustomerAddress } from '../../models/customerAddressModel';
import { getDefaultCustomerDetail } from '../../models/customerDetailModel';
import { ParentOrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { orderBy } from 'lodash';
import { i18n } from '../../../i18n/config';

export const CustomersOverview = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const userIsLoaded: boolean = useAppSelector(state => userLoaded(state));
    const isGlobalAdmin: boolean = useAppSelector(state => userIsGlobalAdmin(state));
    const userCanAccessCustomersView = useAppSelector(state => userCanViewCustomers(state));
    const canCreateCustomers = useAppSelector(state => userCanCreateCustomers(state));

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerViewModel | undefined>(undefined);
    const [activationDialogVisible, setActivationDialogVisible] = useState<boolean>(false);
    const [customers, setCustomers] = useState<CustomerViewModel[]>([]);
    const [customersFiltered, setCustomersFiltered] = useState<CustomerViewModel[]>([]);
    const [clearGridFilters, setClearGridFilters] = useState<boolean | undefined>(undefined);

    const [formIsOpen, setFormIsOpen] = useState<boolean>(false);
    const [selectedFormCustomer, setSelectedFormCustomer] = useState<CustomerFormModel | undefined>(undefined);
    const [states, setStates] = useState<StateFormModel[]>([]);
    const [distributorOrganizations, setDistributorOrganizations] = useState<ParentOrganizationOptionModel[]>([]);

    const countries: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);

    useEffect(() => {
        if (!userIsLoaded) {
            return;
        }

        if (userCanAccessCustomersView) {
            initFilters();
            getCustomers();
        } else {
            dispatch(setIsLoading(false));
        }
    }, [userIsLoaded, userCanAccessCustomersView]);

    const debouncedFilterCustomers = useCallback(
        debounce((value: string) => {
			if (customers.length > 0) {
				let _customersFiltered = [...customers];
				_customersFiltered = _customersFiltered.filter(obj => Object.values(obj).some(val => val?.toString().toLowerCase().includes(value.toLowerCase())));
				setCustomersFiltered(_customersFiltered);
			}
        }, 500), [customers]
    );

    useEffect(() => {
        return () => debouncedFilterCustomers.cancel();
    }, [debouncedFilterCustomers]);

    useEffect(() => {
        debouncedFilterCustomers(globalFilterValue);
    }, [globalFilterValue]);

    const getCustomers = async () => {
        dispatch(setIsLoading(true));
        const customersResponse = await customerService.GetCustomers();

        if (customersResponse.isSuccess) {
            if (customersResponse.result) {
                setCustomers(customersResponse.result);
                setCustomersFiltered(customersResponse.result);
            } else {
                setCustomers([]);
                setCustomersFiltered([]);
            }
        } else {
            dispatch(showErrors(customersResponse?.errorModel?.errors));
        }

        dispatch(setIsLoading(false));
    };

    const initFilters = () => {
        setGlobalFilterValue('');
        setCustomersFiltered(customers);
    };

    const onGlobalFilterChange = (value: string) => {
        setGlobalFilterValue(value);
    };

    const clearFilters = () => {
        initFilters();
        setCustomersFiltered(customers);
        setClearGridFilters(!clearGridFilters);
    };

    const showActivationDialog = (customerToDelete: CustomerViewModel) => {
        setSelectedCustomer(customerToDelete);
        setActivationDialogVisible(true);
    };

    const hideActivationDialog = () => {
        setSelectedCustomer(undefined);
        setActivationDialogVisible(false);
    };

    const onActivationChangeConfirm = async () => {
        if (selectedCustomer) {
            hideActivationDialog();
            dispatch(setIsLoading(true));
            let actionResponse = undefined;
            if (selectedCustomer.isActive) {
                actionResponse = await customerService.DeactivateCustomer(selectedCustomer.id);
            } else {
                actionResponse = await customerService.ActivateCustomer(selectedCustomer.id);
            }

            if (actionResponse && actionResponse.isSuccess) {
                await getCustomers();
                dispatch(showSuccess(SUCCESS_MESSAGE));
            } else {
                dispatch(showErrors(actionResponse?.errorModel?.errors));
            }
            dispatch(setIsLoading(false));
        }
    };

    const rowClassName = (rowData: GridValidRowModel) => {
        return rowData.isActive ? '' : styles.inactive;
    };

    const renderHeader = (): JSX.Element => {
        return (
            <Box display='flex' justifyContent='space-between' marginBottom={2}>
                {
                    canCreateCustomers &&
                    <Button
                        id='new-customer'
                        onClick={handleCreate}
                        variant='primary'
                        label={t('New Customer')}
                        startIcon='add'
                    />
                }
                <SearchBar
                    variant='default'
                    onChange={(value) => onGlobalFilterChange(value)}
                    value={globalFilterValue}
                    placeholder={t('Search')}
                />
                <Button
                    id='clear-filter'
                    onClick={clearFilters}
                    variant='secondary'
                    label={t('Clear')}
                    startIcon='filter_list_off'
                />
            </Box>
        );
    };

    const getColumns = (): GridEnrichedColDef[] => {
        let adminColumns: GridEnrichedColDef[] = [];

        if (isGlobalAdmin) {
            adminColumns = [
                { field: 'distributorOrganization', headerName: t('Distributor organization'), sortable: true, filterable: true, pinnable: false, flex: 3, description: t('Distributor organization') },
                { field: 'famCode', headerName: t('Customer Center'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('Customer Center') },
            ];
        }

        let columns: GridEnrichedColDef[] = [
            { field: 'name', headerName: t('Name'), sortable: true, filterable: true, pinnable: false, flex: 4, description: t('Name') },
            { field: 'city', headerName: t('City'), sortable: true, filterable: true, pinnable: false, flex: 2, description: t('City') },
            { field: 'country', headerName: t('Country'), sortable: true, filterable: true, pinnable: false, flex: 3, description: t('Country') },
            {
                field: 'customerNumber', headerName: t('Customer Number'),
                sortable: true, filterable: true, pinnable: false, flex: 3, align: 'left', headerAlign: 'left', description: t('Customer Number')
            },
            ...adminColumns,
            {
                field: 'actions',
                type: 'actions',
                headerName: t('Actions'),
                flex: 1,
                description: t('Actions'),
                getActions: (params: GridRowParams<CustomerViewModel>) => [
                    <DataGridActionOverview
                        key={'edit'}
                        icon={<Icon type='edit' />}
                        label={t('Edit')}
                        onClick={() => handleEdit(params.row.id)}
                        showInMenu
                    />,
                    <DataGridActionOverview
                        key={params.row.isActive ? 'Deactivate' : 'Activate'}
                        icon={<PowerSettingsNewIcon />}
                        label={params.row.isActive ? t('Deactivate') : t('Activate')}
                        onClick={() => showActivationDialog(params.row as CustomerViewModel)}
                        showInMenu
                    />
                ]
            },
        ];

        return columns;
    };

    const memoizedColumns = useMemo(() => getColumns(), [countries, states, distributorOrganizations, i18n.language]);

    const handleCreate = () => {
        setSelectedFormCustomer(getDefaultCustomer());

        dispatch(setIsLoading(true));
        Promise.all([getStates(), getDistributorOrganizationOptions()]).then(() => {
            setFormIsOpen(true);
            dispatch(setIsLoading(false));
        });
    };

    const handleEdit = (id: number) => {
        dispatch(setIsLoading(true));
        Promise.all([getStates(), getDistributorOrganizationOptions(), fetchCustomer(id)]).then(() => {
            setFormIsOpen(true);
            dispatch(setIsLoading(false));
        });
    };

    const handleCloseFormDialog = () => {
        setFormIsOpen(false);
        setSelectedFormCustomer(undefined);
    };

    const fetchCustomer = async (id: number) => {
        if (id) {
            const customerResponse = await customerService.GetCustomerById(id);

            if (customerResponse.isSuccess) {
                if (customerResponse.result) {
                    if (!customerResponse.result.customerAddress) {
                        customerResponse.result.customerAddress = getDefaultCustomerAddress();
                    }

                    if (!customerResponse.result.customerAddress.state) {
                        customerResponse.result.customerAddress.state = null;
                    }

                    if (!customerResponse.result.customerDetail) {
                        customerResponse.result.customerDetail = getDefaultCustomerDetail();
                    }

                    if (!customerResponse.result.customerDetail.peopleOfInterest || customerResponse.result.customerDetail.peopleOfInterest.length === 0) {
                        customerResponse.result.customerDetail.peopleOfInterest = [];
                    }

                    setSelectedFormCustomer(customerResponse.result);
                } else {
                    dispatch(showErrors(customerResponse.errorModel?.errors));
                }
            }
            else {
                dispatch(showErrors(customerResponse.errorModel?.errors));
            }
        }
    };

    const getStates = async () => {
        if (states.length === 0) {
            fetch('states.json')
                .then((r) => r.json())
                .then((data) => {
                    setStates(data);
                });
        }
    };

    const getDistributorOrganizationOptions = async () => {
        if (distributorOrganizations.length === 0) {
            const distributorOrganizationOptionsResponse = await customerService.GetCustomerParentOptions();

            if (distributorOrganizationOptionsResponse.isSuccess) {
                if (distributorOrganizationOptionsResponse.result) {
                    const orderedOptions = orderBy(distributorOrganizationOptionsResponse.result, dis => dis.name);
                    setDistributorOrganizations(orderedOptions);
                }
            }
        }
    };

    return (
        <>
            {
                userIsLoaded && userCanAccessCustomersView &&
                <>
                    <Box>
                        {renderHeader()}
                    </Box>
                    <DataGridOverview
                        rows={customersFiltered}
                        columns={memoizedColumns}
                        pagination
                        clearFilters={clearGridFilters}
                        getRowClassName={(params: GridRowClassNameParams<GridValidRowModel>) => rowClassName(params.row)}
                    />
                </>
            }

            {
                activationDialogVisible &&
                <ConfirmationDialog
                    open={activationDialogVisible}
                    message={t(`Are you sure you want to ${selectedCustomer?.isActive ? 'deactivate' : 'activate'} this customer?`)}
                    onConfirm={onActivationChangeConfirm}
                    onClose={hideActivationDialog}
                />
            }

            <Modal
                title={t('Customer')}
                open={formIsOpen}
                onClose={handleCloseFormDialog}
                maxWidth='lg'
                scroll='body'
                titlePropsSX={{ '&.MuiDialogTitle-root': { fontFamily: 'system-ui' } }}
                sx={{ '.MuiPaper-root': { width: '100%' } }}
            >
                {
                    selectedFormCustomer &&
                    <CustomerForm
                        customer={selectedFormCustomer}
                        countries={countries}
                        states={states}
                        distributorOrganizations={distributorOrganizations}
                        handleClose={handleCloseFormDialog}
                        getCustomers={getCustomers}
                        refreshDialog={handleEdit}
                    />
                }
            </Modal>
        </>
    );
};