export enum ServiceOrderStatus {
    ToBeAssgined = 0,
    Assigned = 1,
    Completed = 2,
}

export const ServiceOrderStatuses: Record<ServiceOrderStatus, string> = {
    [ServiceOrderStatus.Assigned]: 'Assigned',
    [ServiceOrderStatus.ToBeAssgined]: 'To be assigned',
    [ServiceOrderStatus.Completed]: 'Completed'
};
  
export const getServiceOrderStatusLabel = (status: ServiceOrderStatus): string => {
    return ServiceOrderStatuses[status];
};

export const ToBeAssignedStatus = 'To be assigned';