import moment from 'moment-timezone';
import { ActionResult, api, FailActionResult } from '../../shared/utils/api';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { getDefaultServiceOrderDetail } from '../models/serviceOrderDetailModel';
import { getDefaultPlanningOptimization, ServiceOrderCreateModel, ServiceOrderDisplayModel, ServiceOrderFormModel, ServiceOrderUpdateModel, ServiceOrderViewModel } from '../models/serviceOrderModel';

class ServiceOrderService {
    async CreateServiceOrder(serviceOrderCreateModel: ServiceOrderCreateModel): Promise<ActionResult<number>> {
        const url = '/serviceOrders/CreateServiceOrder';

        return await api.post<number>(url, JSON.stringify(serviceOrderCreateModel));
    }

    async GetServiceOrderById(serviceOrderId: number): Promise<ActionResult<ServiceOrderFormModel>> {
        const url = `/serviceOrders/GetServiceOrderById/${serviceOrderId}`;
        const response = await api.get<ServiceOrderFormModel>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        response.result.serviceOrderDetail ??= getDefaultServiceOrderDetail();
        response.result.planningOptimization ??= getDefaultPlanningOptimization();

        return response;
    }

    async DisplayServiceOrderById(serviceOrderId: number): Promise<ActionResult<ServiceOrderDisplayModel>> {
        const url = `/serviceOrders/DisplayServiceOrderById/${serviceOrderId}`;
        const response = await api.get<ServiceOrderDisplayModel>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        return response;
    }

    async GetServiceOrders(): Promise<ActionResult<ServiceOrderViewModel[]>> {
        const url = '/serviceOrders/GetAllServiceOrders';
        const response = await api.get<ServiceOrderViewModel[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        if (response.isSuccess && response.result) {
            response.result.forEach(so => so.createdOn = moment(so.createdOn).toDate());
        }

        return response;
    }

    async GetNotPlannedServiceOrders(): Promise<ActionResult<ServiceOrderViewModel[]>> {
        const url = '/serviceOrders/GetNotPlannedServiceOrders';
        const response = await api.get<ServiceOrderViewModel[]>(url);
        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        } else if (response.isSuccess && response.result) {
            response.result.forEach(so => so.createdOn = moment(so.createdOn).toDate());
        }

        return response;
    }

    async UpdateServiceOrder(updateModel: ServiceOrderUpdateModel): Promise<ActionResult<void>> {
        const url = '/serviceOrders/UpdateServiceOrder';

        return await api.put(url, JSON.stringify(updateModel));
    }

    async DeleteServiceOrder(serviceOrderId: number): Promise<ActionResult<void>> {
        const url = `/serviceOrders/DeleteServiceOrder/${serviceOrderId}`;

        return await api.delete(url);
    }
}

export const serviceOrderService = new ServiceOrderService();