import { useCookies } from 'react-cookie';

export const useCookie = (cookiesNames: string[]) => {
    const [cookies, setCookie, removeCookie] = useCookies(cookiesNames);

    const setCookieValue = (name: string, value: any) => {
        const expires = new Date(new Date().getTime()+(400*24*60*60*1000));
        const options = { path: '/', expires};
        setCookie(name, value, options);
    };

    const getCookieValue = (name: string): string | undefined => {
        return cookies[name] !== undefined ? cookies[name].toString() : undefined;
    };

    const removeCookieValue = (name: string): void => {
        removeCookie(name);
    };

    return [setCookieValue, getCookieValue, removeCookieValue] as const;
};