import React, { useEffect, useState } from 'react';
import { IEmailDialogProps } from './IEmailDialogProps';
import { languages } from '../../i18n/i18nConstants';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../shared/utils/Utils';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { Title } from '../form/title/Title';
import { Button, Dropdown, DropdownOption, Icon, StyledSearchableDropdownProps, TextField } from '@ui-components/ui-library';
import { NO_OPTIONS_TEXT } from '../../shared/utils/constants';

const DropdownSxObject = {
    width: '100%',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            '& legend': {
                marginBottom: 0, width: 0
            }
        }
    },
    '& .MuiFormControl-root': { marginTop: '4px'},
    '& .MuiAutocomplete-clearIndicator': { display : 'none' },
    '& .MuiAutocomplete-popper': { zIndex: '1000'}
};

export const EmailDialog = (props: IEmailDialogProps): JSX.Element => {
    const { visible, header, onSubmit, onClose } = props;
    const { t } = useTranslation();

    const [emailValue, setEmailValue] = useState<string>('');
    const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(undefined);
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

    const languageChoices = Object.keys(languages).map(k => (
        {
            id: languages[k as keyof typeof languages],
            label: k.toUpperCase(),
            value: languages[k as keyof typeof languages]
        }
    ));

    const handleSubmit = async () => {
        setIsSendingEmail(true);
        await onSubmit(emailValue, selectedLanguage);
        setIsSendingEmail(false);
    };

    useEffect(() => {
        return () => {
            setEmailValue('');
            setSelectedLanguage(undefined);
        };
    }, []);
    
    const languageValueOption = languageChoices.find(o => o.id === selectedLanguage);

    return (
        <>
            {
                visible &&

                    <Dialog
                        open={visible}
                        onClose={onClose}
                        maxWidth={'xs'}
                        fullWidth
                        PaperProps={{style: {overflowY: 'visible'}}}
                    >
                        <DialogTitle>
                            <Title variant='h4' text={header!} align='left' />
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                                >
                                <Icon type={'close'} />
                            </IconButton>
                        </DialogTitle>

                        <DialogContent style={{ overflowY: 'visible' }}>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        label={t('Email')}
                                        value={emailValue}
                                        sx={{ '& .MuiInputLabel-root' : {fontFamily: 'system-ui'}, width: '100%' }}
                                        inputProps={{ maxLength: 50 }}
                                        onChange={(e) => setEmailValue(e.target.value)}
                                        error={!validateEmail(emailValue)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Dropdown<StyledSearchableDropdownProps>
                                        id={'selectedActivityRow?.activityTypeId'}
                                        label={t('Language')}
                                        name={'email_language'}
                                        variant={'searchable'}                                        
                                        options={languageChoices}
                                        value={languageValueOption ?? {id: '', label: '', value: ''}}
                                        onChange={(e, item) => setSelectedLanguage((item as DropdownOption).value.toString() ?? undefined)}
                                        optionListStyles={{ fontFamily: 'system-ui' }}
                                        sx={DropdownSxObject}
                                        error={!languageValueOption?.id}
                                        noOptionsText={t(NO_OPTIONS_TEXT)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions sx={{ paddingBottom: '1rem'}}>
                            <Button 
                                id='send' 
                                label={t('OK')} 
                                startIcon='check' 
                                variant='primary' 
                                onClick={handleSubmit}
                                disabled={!emailValue || !validateEmail(emailValue) || !selectedLanguage || isSendingEmail}
                            />
                        </DialogActions>
                    </Dialog>
            }
        </>
    );
};
