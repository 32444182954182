import { useFormikContext, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { ExternalIdentity, getDefaultIdentity, UserFormValues } from '../../models/user';
import { showErrors, showSuccess } from '../../../stores/alert/alertSlice';
import { userService } from '../../services/UserService';
import { Box, Typography, Grid, DialogContent, Dialog, DialogActions } from '@mui/material';
import { Button, TextField } from '@ui-components/ui-library';
import { StyledTitle } from '../../../components/form/title/Title';
import { SUCCESS_MESSAGE } from '../../../shared/utils/notificationMessages';
import { IUserExternalIdentitiesProps } from './IUserExternalIdentitiesProps';

export const UserExternalIdentities = (props: IUserExternalIdentitiesProps): JSX.Element => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { values: user, handleChange, handleBlur, setValues } = useFormikContext() as any;
    const { formIsReadonly } = props;

    const [externalIdentityDeleteDialogVisible, setExternalIdentityDeleteDialogVisible] = useState<boolean>(false);
    const [identityToRemove, setIdentityToRemove] = useState<ExternalIdentity | undefined>(undefined);

    const handleDeleteIdentity = async (identityId: number) => {
        const deleteIdentityResponse = await userService.DeleteUserExternalIdentity(identityId);

        if (deleteIdentityResponse.isSuccess) {
            dispatch(showSuccess(SUCCESS_MESSAGE));
        } else {
            dispatch(showErrors(deleteIdentityResponse.errorModel?.errors));
        }
    };

    const onAddIdentity = (): void => {
        const updatedUser: UserFormValues = user;
        updatedUser.externalIdentities = [...updatedUser.externalIdentities, getDefaultIdentity()];
        setValues(updatedUser);
    };

    const onDeleteConfirm = async (): Promise<void> => {
        if (identityToRemove) {
            const id: number = identityToRemove.id;
            const tempKey: string = identityToRemove.tempKey;

            const updatedOrganization: UserFormValues = user;

            if (id !== 0) {
                await handleDeleteIdentity(id);
                const filteredAddresses = updatedOrganization.externalIdentities.filter(a => a.id !== id);
                updatedOrganization.externalIdentities = filteredAddresses;
                setValues(updatedOrganization);
                hideDeleteDialog();
            } else {
                const filteredAddresses = updatedOrganization.externalIdentities.filter(a => a.tempKey !== tempKey);
                updatedOrganization.externalIdentities = filteredAddresses;
                setValues(updatedOrganization);
                hideDeleteDialog();
                dispatch(showSuccess(SUCCESS_MESSAGE));
            }
        }
    };

    const showDeleteDialog = (externalIdentity: ExternalIdentity): void => {
        setIdentityToRemove(externalIdentity);
        setExternalIdentityDeleteDialogVisible(true);
    };

    const hideDeleteDialog = () => {
        setIdentityToRemove(undefined);
        setExternalIdentityDeleteDialogVisible(false);
    };


    return (
        <>
            <Box pt={5} display={'flex'} justifyContent={'space-between'}>
                <StyledTitle variant='h4'>{t('External Identities')}</StyledTitle>
                {/* <Button icon='pi pi-plus'
                    className='p-button-rounded p-button-success'
                    aria-label='Add'
                    tooltip={t('Add External Identity')}
                    onClick={onAddIdentity}
                    type={'button'}
                /> */}
            </Box>
            <div>
                {user.externalIdentities.map((ei: ExternalIdentity, index: number) => (
                    <Grid container key={index}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={t('Azure ID')}
                                id={`externalIdentities.${index}.identifier`}
                                name={`externalIdentities.${index}.identifier`}
                                value={ei.identifier}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete={'new-password'}
                                inputProps={{ maxLength: 85 }}
                                required={true}
                                disabled={formIsReadonly}
                            />
                            <ErrorMessage name={`externalIdentities.${index}.identifier`} render={msg => <span className='p-error'>{t(msg)}</span>} />
                        </Grid>
                        {/* <div className={`field col-6 ${styles.remove_identifier_wrapper}`}>
                            <Button icon='pi pi-times'
                                className={`${styles.remove_identity_button} p-button-rounded p-button-danger`}
                                aria-label='Remove Identity'
                                tooltip={t('Remove Identity')}
                                onClick={() => showDeleteDialog(ei)}
                                type={'button'}
                            />

                        </div> */}
                    </Grid>

                ))
                }
            </div>
            {
                externalIdentityDeleteDialogVisible &&
                <Dialog
                    open={externalIdentityDeleteDialogVisible}
                    onClose={hideDeleteDialog}
                >
                    <DialogContent>
                        <Typography variant='body1' sx={{ fontFamily: 'system-ui' }}>
                            {t('Are you sure you want to delete this external identifier?')}
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ paddingBottom: '1rem' }}>
                        <Button id='confirm-deactivate' label={t('Yes')} startIcon='check' variant='primary' onClick={onDeleteConfirm} />
                        <Button id='cancel-deactivate' label={t('Cancel')} startIcon='cancel' variant='secondary' onClick={hideDeleteDialog} />
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};
