// All supported languages
import arMessages from 'devextreme/localization/messages/ar.json';
import enMessages from 'devextreme/localization/messages/en.json';
import esMessages from 'devextreme/localization/messages/es.json';
import frMessages from 'devextreme/localization/messages/fr.json';
import itMessages from 'devextreme/localization/messages/it.json';
import nlMessages from 'devextreme/localization/messages/nl.json';
import ptMessages from 'devextreme/localization/messages/pt.json';
import trMessages from 'devextreme/localization/messages/tr.json';
import { Language, UserPreferences } from '../../users/models/userPreferences';
import { locale, loadMessages } from 'devextreme/localization';

export class SchedulerMessageProvider {
    private _userPreferences: UserPreferences;

    constructor(userPreferences: UserPreferences) {
        this._userPreferences = userPreferences;
    }

    setSchedulerMessages() {
        switch (this._userPreferences.language) {
            case Language.en:
                loadMessages(enMessages);
                break;
            case Language.ar:
                loadMessages(arMessages);
                break;
            case Language.es:
                loadMessages(esMessages);
                break;
            case Language.fr:
                loadMessages(frMessages);
                break;
            case Language.it:
                loadMessages(itMessages);
                break;
            case Language.nl:
                loadMessages(nlMessages);
                break;
            case Language.tr:
                loadMessages(trMessages);
                break;
            case Language.pt:
                loadMessages(ptMessages);
                break;
            default:
                loadMessages(enMessages);
                locale('en');
                return;
        }

        locale(Language[this._userPreferences.language]);
    }
}
