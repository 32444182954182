export interface IServiceExecutionFormProps {
	formIsOpen: boolean;
	serviceExecutionId: number | undefined;
	handleClose: () => void;
	getServiceExecutions?: () => Promise<void>;
}

export enum ServiceExecutionFormStep {
	CUSTOMERDETAILS = 0,
	SERVICEORDERANDEQUIPMENTDETAILS = 1,
	WORKCOMMENTS = 2,
	RECOMMENDATIONS = 3,
	TIMEMATERIALCONFIRMATION = 4,
	FINALIZATION = 5,
}