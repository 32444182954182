import React, { useState } from 'react';
import { Card, Link, styled } from '@mui/material';
import { PRIVACY_POLICY_LINK } from '../../shared/utils/constants';
import { CookiesBanner } from '../cookiesBanner/CookiesBanner';
import { useTranslation } from 'react-i18next';

const StyledBar = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.colors.neutral[500],
    height: '40px',
    padding: 20,
    width: '100%',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px'
}));

const StyledLink = styled(Link)(({ theme }) => ({
	color: `${theme.palette.colors.neutral[100]} !important`,
    fontWeight: 400,
    fontSize: '14px',
    '&:hover': {
        color: `${theme.palette.colors.neutral[50]} !important`,
    }
})) as typeof Link;

export const Footer = () => {
    const { t } = useTranslation();

    const [showCookiesBanner, setShowCookiesBanner] = useState<boolean>(false);

    return (
        <>
            <StyledBar
                square
                variant="outlined"
            >
                <StyledLink href={PRIVACY_POLICY_LINK} underline={'none'} target='_blank'>
                    <span>{t('Privacy Policy')}</span>
                </StyledLink>

                <StyledLink component={'button'} underline={'none'} onClick={() => setShowCookiesBanner(true)}>
                    <span>{t('Cookies Consent')}</span>
                </StyledLink>
            </StyledBar>

            {
                showCookiesBanner &&
                <CookiesBanner handleClose={() => setShowCookiesBanner(false)} />
            }            
        </>
    );
}