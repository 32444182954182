import { ErrorMessage, FormikProps, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { insallBassesService } from '../../../services/InstalledBasesService';
import { EquipmentFormModel } from '../../models/equipment';
import { IEquipmentGeneralInfoProps } from './IEquipmentGeneralInfoProps';
import { AuthenticatedUser } from '../../../users/models/user';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Dropdown, StyledSearchableDropdownProps, TextField, DropdownOption } from '@ui-components/ui-library';
import { userCanEditEquipmentMainInfo, userCanEditEquipments, userCanViewEquipmentContractInformation } from '../../../stores/user/usersSlice';
import { EquipmentCategories } from '../../../shared/enums/EquipmentCategories';
import { ActionResult } from '../../../shared/utils/api';
import { InstalledBase } from '../../models/installedBase';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import { ContractType, contractTypeLabels } from '../../../shared/enums/ContractType';
import { Restricted } from '../../../components/elements/restricted/Restricted';
import { validateForm as validateInitialValues } from '../../../shared/utils/Utils';
import { NO_OPTIONS_TEXT } from '../../../shared/utils/constants';

const StyledLabel = styled('label')(({ theme }) => ({
    fontFamily: 'system-ui',
    color: theme.palette.colors.neutral[50],
    marginBottom: '0.5rem'
}));

const StyledParagraph = styled('p')(({ theme }) => ({
    fontFamily: 'system-ui',
    color: theme.palette.colors.neutral[50]
})) as typeof Typography;

export const EquipmentGeneralInfo = (props: IEquipmentGeneralInfoProps): JSX.Element => {
    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const { equipmentTypeOptions, readonly, isNewForm, isEquipmentLinked, equipmentCategory, contractSectionEditable, distributorOrganizationOptions } = props;
    const { t } = useTranslation();

    const { values: equipment, handleChange, handleBlur, setFieldValue, setFieldTouched, validateForm } = useFormikContext() as FormikProps<EquipmentFormModel>;

    const canEditEquipmentMainInfo: boolean = useAppSelector(state => userCanEditEquipmentMainInfo(state));
    const canEditEquipments = useAppSelector(state => userCanEditEquipments(state));
    const canViewContractInformation = useAppSelector(state => userCanViewEquipmentContractInformation(state));

    const determineInstalledBaseCode = async (equipmentTypeId: number, size: number, inverterDrive: boolean | undefined) => {
        if (equipmentTypeId > 0) {
            let installedBaseResponse: ActionResult<InstalledBase> | undefined = undefined;

            if (equipment.equipmentCategory == EquipmentCategories.Compressor && equipment.size)
                installedBaseResponse = await insallBassesService.GetInstalledBaseForEquipmentValues(equipmentTypeId, equipment.size, inverterDrive);
            else if (equipment.equipmentCategory == EquipmentCategories.AirTreatment && equipment.flow)
                installedBaseResponse = await insallBassesService.GetInstalledBaseForEquipmentValues(equipmentTypeId, equipment.flow, inverterDrive);

            if (installedBaseResponse && installedBaseResponse.isSuccess) {
                if (installedBaseResponse.result && installedBaseResponse.result.description) {
                    setFieldValue('installedBaseCode', installedBaseResponse.result.description);
                } else {
                    setFieldValue('installedBaseCode', '');
                }
            } else {
                setFieldValue('installedBaseCode', '');
            }
        } else {
            setFieldValue('installedBaseCode', '');
        }
    };

    const onEquipmentTypeChange = async (equipmentTypeId: number | string | undefined) => {
        await setFieldValue('equipmentTypeId', equipmentTypeId ? equipmentTypeId : 0);
        if (equipmentCategory === EquipmentCategories.Compressor && equipmentTypeId && equipment.size) {
            await determineInstalledBaseCode(Number(equipmentTypeId), equipment.size, equipment.inverterDrive);
        } else if (equipmentCategory === EquipmentCategories.AirTreatment && equipmentTypeId && equipment.flow) {
            await determineInstalledBaseCode(Number(equipmentTypeId), equipment.flow, equipment.inverterDrive);
        }
    };

    useEffect(() => {
        if (!isNewForm) {
            validateInitialValues(validateForm, setFieldTouched);
        }
    }, [isNewForm]);

    useEffect(() => {
        if (!isNewForm) {
            return;
        }
        if (equipmentTypeOptions.length === 1) {
            setFieldValue('equipmentTypeId', equipmentTypeOptions[0].id);
        }

        if (distributorOrganizationOptions.length === 1) {
            setFieldValue('distributorOrganizationId', distributorOrganizationOptions[0].id);
        }
    }, [isNewForm, equipmentTypeOptions, distributorOrganizationOptions]);

    const serialNumberIsReadonly = readonly ||
        (equipmentCategory === EquipmentCategories.Compressor && !isNewForm && !canEditEquipmentMainInfo) ||
        (equipmentCategory === EquipmentCategories.AirTreatment && !canEditEquipments) ||
        (equipmentCategory === EquipmentCategories.Other && !canEditEquipments);

    //#region Dropdown and Radio options and values
    const distributorOrganizationDropdownOptions = distributorOrganizationOptions
        .map(o => {
            return { id: o.id, label: o.name, value: o.id };
        });
    const distributorOrganizationValueOption = distributorOrganizationDropdownOptions?.find(o => o.id === equipment.distributorOrganizationId);

    const equipmentTypeDropdownOptions = equipmentTypeOptions
        .map(o => {
            return { id: o.id, label: t(o.description), value: o.id };
        });
    const equipmentTypeValueOption = equipmentTypeDropdownOptions.find(o => o.id === equipment.equipmentTypeId);

    //#endregion

    // Equipment contract property will be used once we merged the changes from MSM-1242

    const contractTypeDropdownOptions: DropdownOption[] = Object.entries(contractTypeLabels)
        .map(([id, value]) => {
            return { id: +id, label: t(value), value: +id };
        });

    const selectedContractType = contractTypeDropdownOptions.find(ctdo => ctdo.id === equipment.contractType);
    const isOtherContractTypeSelected = selectedContractType?.id === ContractType.Other;

    const handleContractTypeChange = async (selectedOption: DropdownOption) => {
        if (selectedOption == null) {
            await setFieldValue('contractType', undefined);
            await setFieldValue('otherContractType', undefined);
            await setFieldValue('contractStartDate', undefined);
            await setFieldValue('contractExpirationDate', undefined);
            return;
        }

        if (selectedOption.value !== ContractType.Other) {
            await setFieldValue('otherContractType', undefined);
        }

        if (selectedOption.value === ContractType.None) {
            await setFieldValue('contractStartDate', undefined);
            await setFieldValue('contractExpirationDate', undefined);
        }

        await setFieldValue('contractType', selectedOption.value);
    };

    const handleContractStartDateChange = async (value: Date | null) => {
        await setFieldValue('contractStartDate', value ? moment(value).startOf('d').toDate() : undefined);
        await setFieldTouched('contractStartDate');
        await setFieldTouched('contractExpirationDate');
    };

    const handleContractExpirationDateChange = async (value: Date | null) => {
        await setFieldValue('contractExpirationDate', value ? moment(value).endOf('d').toDate() : undefined);
        await setFieldTouched('contractExpirationDate');
        await setFieldTouched('contractStartDate');
    };

    return (
        <>
            <Grid container spacing={2}>
                {
                    !isNewForm &&
                    <>
                        <Grid item xs={12} md={6}>
                            <StyledLabel htmlFor='createdOn'>{t('Created on')}</StyledLabel>
                            <StyledParagraph>{moment(equipment.createdOn).format(currentUser.preferences.dateFormat)}</StyledParagraph>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <StyledLabel htmlFor='lastModifiedOn'>{t('Last modified on')}</StyledLabel>
                            {equipment.lastModifiedOn &&
                                <StyledParagraph>{moment(equipment.lastModifiedOn).format(currentUser.preferences.dateFormat)}</StyledParagraph>
                            }
                        </Grid>
                    </>
                }
                {
                    !isEquipmentLinked &&
                    <Grid item xs={12} md={6}>
                        <Dropdown<StyledSearchableDropdownProps>
                            id={'distributorOrganizationId'}
                            label={t('Distributor Organization')}
                            variant={'searchable'}
                            name={'distributorOrganizationId'}
                            value={distributorOrganizationValueOption ?? { id: '', label: '', value: '' }}
                            options={distributorOrganizationDropdownOptions ? distributorOrganizationDropdownOptions : []}
                            onChange={(e, item) => setFieldValue('distributorOrganizationId', item ? (item as DropdownOption).value : 0)}
                            onBlur={handleBlur}
                            optionListStyles={{ fontFamily: 'system-ui' }}
                            disabled={readonly}
                            required={true}
                            greyedOutLabelOnDisabled
                            noOptionsText={t(NO_OPTIONS_TEXT)}
                            resultsMaxHeight={200}
                        />
                        <ErrorMessage name={'distributorOrganizationId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                    </Grid>
                }

                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Serial Number')}
                        id={'serialNumber'}
                        name={'serialNumber'}
                        value={equipment.serialNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        required={true}
                        disabled={serialNumberIsReadonly}
                    />
                    <ErrorMessage name={'serialNumber'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Dropdown<StyledSearchableDropdownProps>
                        id={'equipmentTypeId'}
                        label={t('Equipment Type')}
                        name={'equipmentTypeId'}
                        variant={'searchable'}
                        options={equipmentTypeDropdownOptions}
                        value={equipmentTypeValueOption ?? { id: '', label: '', value: '' }}
                        onChange={(e, item) => onEquipmentTypeChange((item as DropdownOption)?.id)}
                        onBlur={handleBlur}
                        optionListStyles={{ fontFamily: 'system-ui' }}
                        required={true}
                        disabled={readonly}
                        greyedOutLabelOnDisabled
                        noOptionsText={t(NO_OPTIONS_TEXT)}
                    />
                    <ErrorMessage name={'equipmentTypeId'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>

                {/* TODO: this will be refactored when we change the structure of equipment and model*/}
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t('Model')}
                        id={'competitorModel'}
                        name={'competitorModel'}
                        value={equipment.competitorModel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete={'new-password'}
                        inputProps={{ maxLength: 50 }}
                        sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                        disabled={readonly}
                        required={true}
                    />
                    <ErrorMessage name={'competitorModel'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                </Grid>
                {canViewContractInformation && (
                    <Restricted to={'canEditEquipmentContractInformation'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Contract Type')}
                                id={'contractType'}
                                name={'contractType'}
                                options={contractTypeDropdownOptions}
                                value={selectedContractType ?? { id: '', label: '', value: '' }}
                                onChange={(_, item) => handleContractTypeChange(item as DropdownOption)}
                                onBlur={handleBlur}
                                disabled={!contractSectionEditable}
                                required
                                variant='searchable'
                                greyedOutLabelOnDisabled
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                            />
                            <ErrorMessage name={'contractType'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                        </Grid>
                        {isOtherContractTypeSelected && (
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label={t('Other Contract Type')}
                                    id={'otherContractType'}
                                    name={'otherContractType'}
                                    value={equipment.otherContractType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete={'new-password'}
                                    inputProps={{ maxLength: 200 }}
                                    sx={{ '& .MuiInputLabel-root': { fontFamily: 'system-ui' }, width: '100%' }}
                                    disabled={!contractSectionEditable}
                                    required={false}
                                />
                                <ErrorMessage name={'otherContractType'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                            </Grid>
                        )}
                        {equipment.contractType != ContractType.None && equipment.contractType && (
                            <>
                                <Grid item xs={12} md={3}>
                                    <DatePicker
                                        id={'contractStartDate'}
                                        inputFormat={currentUser.preferences.dateFormat}
                                        onChange={value => handleContractStartDateChange(value)}
                                        value={equipment.contractStartDate}
                                        disabled={!contractSectionEditable}
                                        actions={['today', 'clear']}
                                        title={t('Contract Start Date')}
                                    />
                                    <ErrorMessage name={'contractStartDate'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <DatePicker
                                        id={'contractExpirationDate'}
                                        inputFormat={currentUser.preferences.dateFormat}
                                        onChange={value => handleContractExpirationDateChange(value)}
                                        value={equipment.contractExpirationDate}
                                        disabled={!contractSectionEditable}
                                        actions={['today', 'clear', 'accept']}
                                        title={t('Contract Expiration Date')}
                                    />
                                    <ErrorMessage name={'contractExpirationDate'} render={msg => <span className='p-error'>{t(msg)}</span>} />
                                </Grid>
                            </>
                        )}
                    </Restricted>
                )}
            </Grid>
        </>
    );
};