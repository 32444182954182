export type ServiceExecutionPartFormModel = {
    id: number;
    number: string;
    name: string;
    quantityPlanned: number | undefined;
    quantityUsed: number;
    plannedPartId: number | undefined;
    visitReportId: number;
};

export type ServiceExecutionUsedPartCreateModel = {
    number: string;
    quantityUsed: number;
    name: string;
    visitReportId: number;
};

export const getDefaultServiceExecutionUsedPart = (serviceExecutionId: number): ServiceExecutionPartFormModel => {
    return {
        id: 0,
        number: '',
        name: '',
        quantityPlanned: undefined,
        quantityUsed: 0,
        plannedPartId: undefined,
        visitReportId: serviceExecutionId,
    };
};

export const mapServiceExecutionUsedPartToCreateModel = (serviceExecutionUsedPartModel: ServiceExecutionPartFormModel): ServiceExecutionUsedPartCreateModel => {
    return {
        number: serviceExecutionUsedPartModel.number,
        quantityUsed: serviceExecutionUsedPartModel.quantityUsed,
        name: serviceExecutionUsedPartModel.name,
        visitReportId: serviceExecutionUsedPartModel.visitReportId,
    };
};

export type ServiceExecutionUsedPartUpdateModel = {
    id: number;
    number: string;
    quantityUsed: number;
    name: string;
};

export const maptServiceExecutionUsedPartToUpdateModel = (serviceExecutionUsedPartModel: ServiceExecutionPartFormModel): ServiceExecutionUsedPartUpdateModel => {
    return {
        id: serviceExecutionUsedPartModel.id,
        number: serviceExecutionUsedPartModel.number,
        quantityUsed: serviceExecutionUsedPartModel.quantityUsed,
        name: serviceExecutionUsedPartModel.name,
    };
};

export type ServiceExecutionPlannedPartUpdateModel = {
    id: number;
    quantityUsed: number;
};

export const mapServiceExecutionPartToPlannedUpdateModel = (serviceExecutionUsedPartModel: ServiceExecutionPartFormModel): ServiceExecutionPlannedPartUpdateModel => {
    return {
        id: serviceExecutionUsedPartModel.id,
        quantityUsed: serviceExecutionUsedPartModel.quantityUsed,
    };
};