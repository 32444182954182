import { ConnectivityDetail, getDefaultConnectivityDetail } from './connectivityDetail';

export type EquipmentDetail = {
    equipmentId: number;
    equipmentStatusId: number;
    estimatedWorkingHoursPerYear: number | undefined;
    lastMaintenanceDate: Date | undefined;
    connectivityDetail: ConnectivityDetail;
};

export const getDefaultEquipmentDetail = (): EquipmentDetail => {
    return {
        equipmentId: 0,
        equipmentStatusId: 0,
        estimatedWorkingHoursPerYear: undefined,
        lastMaintenanceDate: undefined,
        connectivityDetail: getDefaultConnectivityDetail(),
    };
};

export type EquipmentDetailSubmitModel = {
    equipmentId: number;
    equipmentStatusId: number;
    estimatedWorkingHoursPerYear: number | undefined;
    lastMaintenanceDate: string | undefined
    IsConnected: boolean;
};

export const mapEquipmentDetailToSubmitModel = (equipmentDetail: EquipmentDetail): EquipmentDetailSubmitModel => {
    return {
        equipmentId: equipmentDetail.equipmentId,
        equipmentStatusId: equipmentDetail.equipmentStatusId,
        estimatedWorkingHoursPerYear: equipmentDetail.estimatedWorkingHoursPerYear,
        lastMaintenanceDate: equipmentDetail.lastMaintenanceDate?.toISOString(),
        IsConnected: equipmentDetail.connectivityDetail.isConnected,
    };
};
