import { PersonOfInterestModel } from './personOfInterestModel';

export type CustomerDetailModel = {
    id: number;
    customerNumber: string;
    phoneNumber: string;
    vatNumber: string;
    customerId: number;
    peopleOfInterest: PersonOfInterestModel[];
};

export const getDefaultCustomerDetail = (): CustomerDetailModel => {
    return {
        id: 0,
        customerNumber: '',
        phoneNumber: '',
        vatNumber: '',
        customerId: 0,
        peopleOfInterest: [],
    };
};
