import React from 'react';
import { customerService } from '../../customers/services/CustomerService';
import { convertStringToByteArray } from '../../shared/utils/Utils';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { showErrors } from '../../stores/alert/alertSlice';
import styles from './TemplateDownloadSection.module.scss';
import { CustomerTemplateFileName } from '../../shared/utils/constants';
import { Button } from '@ui-components/ui-library';
import { setIsLoading } from '../../stores/layout/layoutSlice';

export const TemplateDownloadSection = (): JSX.Element => {
    
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    
    const downloadTemplate = async () => {
        dispatch(setIsLoading(true));
        const templateResponse = await customerService.DownloadImportTemplateFile();
        if (templateResponse.isSuccess) {
            const bytes: Uint8Array = convertStringToByteArray(templateResponse.result!.content);
            let objectUrl = window.URL.createObjectURL(new Blob([bytes]));
            saveAs(objectUrl, CustomerTemplateFileName);
        } else {
            dispatch(showErrors(templateResponse?.errorModel?.errors));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <div className={styles.download_section_wrapper}>
            <Button
                type='button'
                id='download'
                startIcon='download'
                onClick={downloadTemplate}
                label={t('Download Template')}
            />
        </div>
    );
};
