import { AddUserOrganizationRoleModel } from '../models/AddUserOrganizationRoleModel';
import { api, ActionResult, FailActionResult } from '../../shared/utils/api';
import { ActiveServicePlanningByUserViewModel, AuthenticatedUser, UserFormValues, UserOverviewModelDto, UserUpdateModel, getDefaultIdentity, getDefaultUserOrganizationRole } from '../models/user';
import { RoleToAssign, UserCreateModel } from '../models/user';
import { NoResultMessage } from '../../shared/utils/validationMessages';
import { TechnicianModel } from '../models/technicianModel';
import { UsePreferencesPartial, UserLanguageUpdateModel } from '../models/userPreferences';
import { UsersPagedQueryParameters } from '../models/usersPagedQueryParameters';
import { UserOverviewPagedList } from '../models/UserOverviewPagedList';
import { getDefaultUserAdditionalInfo } from '../models/userAdditionalInfoModel';

class UserService {
    async GetUsers(): Promise<ActionResult<UserOverviewModelDto[]>> {
        const url = '/users';

        const response = await api.get<UserOverviewModelDto[]>(url);

        return response;
    }

    async GetUsersPaged(queryParameters: UsersPagedQueryParameters): Promise<ActionResult<UserOverviewPagedList>> {
        const url = '/users/GetUsersPaged';

        const response = await api.get<UserOverviewPagedList>(url, queryParameters);

        return response;
    }

    async GetTechnicians(): Promise<ActionResult<TechnicianModel[]>> {
        const url = '/users/GetTechnicians';

        const response = await api.get<TechnicianModel[]>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async ActivateUser(id: number) {
        const url = `/users/activateUser/${id}`;

        return api.put(url, {});
    }

    async DeactivateUser(id: number, offset: number): Promise<ActionResult<ActiveServicePlanningByUserViewModel[]>> {
        const url = `/users/deactivate/${id}/${offset}`;

        return api.put(url, {});
    }

    async GetRolesToAssign(): Promise<ActionResult<RoleToAssign[]>> {
        const url = '/users/getRoles';

        const response = await api.get<RoleToAssign[]>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        return response;
    }
    async CreateUser(createModel: UserCreateModel): Promise<ActionResult<number>> {
        const url = '/users';

        return await api.post<number>(url, JSON.stringify(createModel));
    }

    async UpdateUser(updateModel: UserUpdateModel): Promise<ActionResult<void>> {
        const url = '/users';

        return await api.put<void>(url, JSON.stringify(updateModel));
    }


    async GetUserById(id: number): Promise<ActionResult<UserFormValues>> {
        const url = `/users/${id}`;
        const response = await api.get<UserFormValues>(url);

        if (!response.isSuccess)
            return response;

        if (!response.result)
            return new FailActionResult({ errors: [NoResultMessage] });

        response.result.userAdditionalInformation ??= getDefaultUserAdditionalInfo();

        if (response.result.externalIdentities.length === 0) {
            response.result.externalIdentities.push(getDefaultIdentity());
        }

        if (response.result.userOrganizationRoles.length === 0) {
            response.result.userOrganizationRoles.push(getDefaultUserOrganizationRole());
        }

        return response;
    }

    async AddUserOrganizationRole(obj: AddUserOrganizationRoleModel): Promise<ActionResult<number>> {
        const url = '/users/addOrganizationRoleToUser';

        return await api.post<number>(url, JSON.stringify(obj));
    }

    async GetAuthenticatedUser(): Promise<ActionResult<AuthenticatedUser>> {
        const url = '/users/getAuthenticatedUser';

        var response = await api.get<AuthenticatedUser>(url);

        if (response.isSuccess && !response.result) {
            return new FailActionResult({ errors: [NoResultMessage] });
        }

        return response;
    }

    async DeleteUserOrganizationRole(userOrganizationRoleId: number): Promise<ActionResult<void>> {
        const url = `/users/RemoveOrganizationRoleFromUser/${userOrganizationRoleId}`;

        return await api.delete<void>(url);
    }

    async DeleteUserExternalIdentity(identityId: number): Promise<ActionResult<void>> {
        const url = `/users/unlinkUserToExternalProvider/${identityId}`;

        return await api.delete<void>(url);
    }

    async UpdateUserPreferences(body: UsePreferencesPartial): Promise<ActionResult<void>> {
        const url = '/users/UpdateUserPreferences';

        return await api.put<void>(url, JSON.stringify(body));
    }

    async UpdateUserLanguage(body: UserLanguageUpdateModel): Promise<ActionResult<void>> {
        const url = '/users/UpdateUserLanguage/';

        return await api.put<void>(url, JSON.stringify(body));
    }
}

export const userService = new UserService();